import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ScheduleManagerComponent } from '../schedule-manager.component';

@Component({
  selector: 'app-scheduler-notes-details',
  templateUrl: './scheduler-notes-details.component.html',
  styleUrls: ['./scheduler-notes-details.component.less']
})
export class SchedulerNotesDetailsComponent implements OnInit {

  schedulerNotes: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ScheduleManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { schedulerNotes: string }) { }

  ngOnInit() {
    if (this.data && this.data.schedulerNotes) {
      this.schedulerNotes = this.data.schedulerNotes.split('\n');
    }
  }

}
