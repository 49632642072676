import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

const BASE_URL = '/';
const LOADING_DELAY = 1000;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  opened = true;
  fullScreenMode = false;
  showSpinner = false;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any) {
    // ToDo: add floating loading component
    this.router.events.pipe(filter(e => e instanceof NavigationStart))
      .subscribe(_ => {
        this.showSpinner = false;
      });

    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .pipe(delay(LOADING_DELAY)).subscribe(_ => {
        this.showSpinner = false;
      });
  }

  ngOnInit() {
    this.fullScreenMode = this.getFullScreenMode();
    if (this.router.url === BASE_URL) {
      // redirect to dashboard
      this.router.navigate(['/dashboard']);
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
    this.opened = !this.fullScreenMode;
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

}
