import { HttpClient, HttpHeaders, HttpParams, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerInfo } from '../models/customer-info';
import { CustomerOrderHistory } from '../models/customer-order-history';
import { CustomerLogin } from '../models/customer-login';
import { ArchivedUnit } from '../models/archived-unit';
import { User } from '../models/User';
import { ConfigService } from './config.service';
import { CustomerAuthService } from './customer-auth.service';
import { ArchivedLocation } from '../models/archived-location';
import { Quote } from '../models/quote';
import { PackagePlanning } from '../models/package-planning';

const enum ResponseContentType {Blob = 'blob'}
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private headers = new HttpHeaders();

  constructor(private config: ConfigService, 
    private http: HttpClient,
    private handler: HttpBackend,
    private authService: CustomerAuthService) { 
    this.headers.append('Content-Type', 'application/json');  }

  getCustomerInfo(keyword: string, beginDate: string, endDate: string, email: string,): Observable<CustomerInfo[]>{
    let searchParams: any = new Object();
    searchParams['keyword'] = `${keyword}`;
    searchParams['beginDate'] = `${beginDate}`;
    searchParams['endDate'] = `${endDate}`;
    searchParams['email'] = `${email}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const requestOptions = { headers, params: searchParams};

    return this.http.get<CustomerInfo[]>(this.config.buildCustomerInfoURL(`api/customercontact/customerinfo`),requestOptions);
  }

  getCustomerOrderHistory(id: number): Observable<CustomerOrderHistory[]>{
    return this.http.get<CustomerOrderHistory[]>(this.config.buildCustomerInfoURL(`api/customercontact/customerorderhistory/${id}`), { headers: this.headers });
  }

  getCustomerLogin(id: number): Observable<CustomerLogin[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.get<CustomerLogin[]>(this.config.buildCustomerInfoURL(`api/customercontact/customerlogin/${id}`), { headers: this.headers });
  }

  getPackageListAll(orderNumber: string, apartmentNumber: string): Observable<any> {
    let searchParams: any = new Object();
    searchParams['orderNumber'] = `${orderNumber}`;
    searchParams['apartmentNumber'] = `${apartmentNumber}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(this.config.buildCustomerInfoURL('api/orders/print/packageListAll'), requestOptions);
  }

  getOrderAcknowledgement(shoppingCartGroup: number): Observable<any> {
    let searchParams: any = new Object();
    searchParams['shoppingCartGroup'] = `${shoppingCartGroup}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(this.config.buildCustomerInfoURL('api/orders/acknowledgement/pdf'), requestOptions);
  }

  updatePasswordUser( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(this.config.buildCustomerInfoURL('api/account/updateuser'), newUser, {headers});
  }

  deleteUser( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(this.config.buildCustomerInfoURL('api/account/disableuser'), newUser, {headers});
  }

  addAccount( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(this.config.buildCustomerInfoURL('api/account/addaccount'), newUser, {headers});
  }
  
  getArchivedUnit(customerNumber: string): Observable<ArchivedUnit[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.get<ArchivedUnit[]>(this.config.buildCustomerInfoURL(`api/customercontact/getarchivedunit/${customerNumber}`), { headers: this.headers });
  }

  getArchivedLocation(customerNumber: string): Observable<ArchivedLocation[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.get<ArchivedLocation[]>(this.config.buildCustomerInfoURL(`api/customercontact/getarchivedlocation/${customerNumber}`), { headers: this.headers });
  }

  unArchiveUnits(units: ArchivedUnit[]): Observable<ArchivedUnit[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.post<ArchivedUnit[]>(this.config.buildCustomerInfoURL(`api/apartments/unarchiveunit/`),  units, {headers: this.headers});
  }

  unArchiveLocations(locations: ArchivedLocation[]): Observable<ArchivedLocation[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.post<ArchivedLocation[]>(this.config.buildCustomerInfoURL(`api/complexes/unarchivelocation/`),  locations, {headers: this.headers});
  }

  getCustomerQuote(customerNumber: string): Observable<any[]>{
    this.headers.append('Content-Type', 'application/json');
    return this.http.get<Quote[]>(this.config.buildCustomerInfoURL(`api/customercontact/getcustomerquote/${customerNumber}`), { headers: this.headers });
  }
  
  getPackagePlanning(item: PackagePlanning) {
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post<any[]>(this.config.buildPackagePlanningURL(`PackagePlanning/process`),  item, {headers: this.headers});
  }

  verifyPass(state, pass) {
    let str;
    switch (state) {
      case 'pass-number':
        str = /^((?=.*\d)|(?=.*\W+))/;
        break;
      case 'length':
        str = /^(?=^.{8,}$)/;
        break;
      case 'low-uper':
        str = /^(?![.\n])(?=.*[A-Z])(?=.*[a-z])/;
        break;
      default:
        break;
    }
    return !str.test(pass);
  }
}
