import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-palletization-email-update-modal',
  templateUrl: './palletization-email-update-modal.component.html',
  styleUrls: ['./palletization-email-update-modal.component.less']
})
export class PalletizationEmailUpdateModalComponent {

  emailForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { invoicingEmail: string },
    public dialogRef: MatDialogRef<PalletizationEmailUpdateModalComponent>
  ) {
    const re_email = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,12}|[0-9]{1,3})(\]?)$/;

    this.emailForm = this.fb.group({
      invoicingEmail: [data.invoicingEmail, [Validators.required, Validators.pattern(re_email)]]
    });
  }

  get emailControl() {
    return this.emailForm.get('invoicingEmail');
  }

  updateEmail() {
    if (this.emailForm.valid) {
      this.dialogRef.close(this.emailControl.value);
    }
  }
}
