import { Component, OnInit, Inject, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EditProductionOrderComponent } from '../production-orders/edit-production-order/edit-production-order.component';
import { ProductionOrdersService } from '../../services/production-orders.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ProductionOrder } from '../../models/production-order';
import { ProductionOrderFiles } from '../../models/production-order-file';
import { ProductionLocation } from '../../models/production-location';
import { AuthService } from '../../services/auth.service';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Color } from '../../models/color';

const INVALID_LOCATION_ID = -1;

@Component({
  selector: 'app-router-cim',
  templateUrl: './router-cim.component.html',
  styleUrls: ['./router-cim.component.less']
})
export class RouterCimComponent implements OnInit, AfterViewInit {

  loading = false;
  filtersApplied = false;
  fullScreenMode = false;
  machine = 'No IP address to show.';
  poNumberFilter = new UntypedFormControl();
  productionDateFilter = new UntypedFormControl();
  colorsFilter = new UntypedFormControl();

  colors: Color[] = [];
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'color', 'numberOfParts', 'numberOfApartments', 'files', 'action'];

  productionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  filteredProductionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  productionOrdersSort: MatSort;

  @ViewChild('productionOrdersSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.productionOrdersSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private productionOrdersService: ProductionOrdersService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    this.fullScreenMode = this.getFullScreenMode();
    this.getMachine();
    this.getColors();
    await this.getProductionLocations();
    await this.getProductionOrders();
  }

  ngAfterViewInit() {
    // sort options
    this.productionOrdersDataSource.sort = this.productionOrdersSort;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    this.getProductionOrders();
  }

  filterByPONumber(): ProductionOrder[] {
    // eslint-disable-next-line max-len
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(this.poNumberFilter.value.toLowerCase()));
  }

  filterByProductionDate(): ProductionOrder[] {
    // eslint-disable-next-line max-len
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByColors(): ProductionOrder[] {
    const colors: string[] = this.colorsFilter.value;
    const productionOrders: ProductionOrder[] = [];
    for (const color of colors) {
      const r = this.filteredProductionOrdersDataSource.data.filter(u => u.color && u.color.toLowerCase() === color.toLowerCase());
      productionOrders.push(...r);
    }
    return productionOrders;
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  getProductionOrdersDataSource(): MatTableDataSource<ProductionOrder> {
    return this.filtersApplied ? this.filteredProductionOrdersDataSource : this.productionOrdersDataSource;
  }

  applyFilters() {
    this.filteredProductionOrdersDataSource.data = this.productionOrdersDataSource.data;

    if (this.poNumberFilter.valid && this.poNumberFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByPONumber();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.colorsFilter.valid && this.colorsFilter.value && this.colorsFilter.value.length) {
      this.filteredProductionOrdersDataSource.data = this.filterByColors();
      this.filtersApplied = true;
    }
  }

  clearFilters() {
    this.filtersApplied = false;
    this.poNumberFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.colorsFilter.setValue('');
    this.filteredProductionOrdersDataSource.data = [];
  }

  openEditProductionOrder(id: number) {
    const productionOrder = this.productionOrdersDataSource.data.find(p => p.id === id);

    if (productionOrder) {
      const dialogRef = this.dialog.open(EditProductionOrderComponent, {
        width: '480px',
        data: { productionOrder }
      });

      // eslint-disable-next-line @typescript-eslint/no-shadow
      dialogRef.afterClosed().subscribe((productionOrder: ProductionOrder) => {
        if (productionOrder) {
          this.getProductionOrders();
          this.snackBarService.showSuccess('The production order was updated successfully.');
        }
      });
    }
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  getProductionOrders(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.showLoading();
      this.clearDataSource();

      if (this.productionLocationId !== INVALID_LOCATION_ID) {
        this.productionOrdersService.getProductionOrdersByLocation(this.productionLocationId).subscribe((productionOrders: ProductionOrder[]) => {
          this.updateProductionOrdersDataSource(productionOrders);
          resolve();
        },
          err => {
            console.error(err);
            this.hideLoading();
            this.snackBarService.showError('Error getting production orders.');
            reject();
          }, () => {
            this.hideLoading();
          });
      } else {
        this.snackBarService.showError('Please select a production location.');
        reject();
      }
    });
  }

  getMachine() {
    const machine = this.authService.getMachine();

    if (machine) {
      this.machine = machine;
    } else {
      this.snackBarService.showError('Error getting machine. Please contact your supervisor.');
    }
  }

  updateProductionOrdersDataSource(productionOrders: ProductionOrder[]) {
    this.productionOrdersDataSource.data = productionOrders;
  }

  clearDataSource() {
    this.productionOrdersDataSource.data = [];
    this.filteredProductionOrdersDataSource.data = [];
  }

  hasProductionOrders() {
    return this.getProductionOrdersDataSource().data.length > 0;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  updateProductionOrder(id: number, filesDownloaded: boolean) {
    const productionOrder = this.productionOrdersDataSource.data.find(p => p.id === id);
    const filteredProductionOrder = this.filteredProductionOrdersDataSource.data.find(p => p.id === id);

    if (productionOrder) {
      productionOrder.filesDownloaded = filesDownloaded;
    }

    if (filteredProductionOrder) {
      filteredProductionOrder.filesDownloaded = filesDownloaded;
    }
  }

  getFiles(id: number) {
    const machine = this.authService.getMachine();
    if (machine) {
      this.productionOrdersService.getFiles(id, machine).subscribe((files: ProductionOrderFiles) => {
        if (!files.downloaded) {
          this.updateProductionOrder(id, true);
          this.snackBarService.showSuccess('Production order files was sent to download successfully.');
        } else {
          this.snackBarService.showWarning('The production order files was downloaded already.');
        }
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error getting production order files.');
      });
    } else {
      this.snackBarService.showError('We can\'t download the production order files without IP address. Please have your supervisor contact IT.');
    }
  }
}
