import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AddressDetail, FedexAddress } from '../models/address-detail';
import { OvernightQuote } from '../models/overnight-quote';
import { Package } from '../models/package';
import { RatesAndTransitTime } from '../models/rates-transit-time';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class OvernightService {

  public salesOrderNumber: string;
  public isRetail: boolean;
  public customerAddress: AddressDetail;
  public shipDate: Date;
  public packages: Package[];
  public existingPaintPrimer: boolean;
  public packagingStatus: boolean;
  public surcharge: number;

  // Subjects
  public $customerAddress = new Subject<AddressDetail>();
  public $originAddress = new Subject<FedexAddress>();
  public $isRetail = new Subject<boolean>();
  public $packagesDetails = new Subject<Package[]>();
  public $ratesTransitTimes = new Subject<boolean>();

  constructor(private config: ConfigService, private http: HttpClient) { }

  getSurcharge(): Observable<number> {
    return this.http.get<number>(this.config.buildURL(`overnight/surcharge`));
  }

  getShippingCost(address: FedexAddress, shipDate: Date, packagesIds: number[]): Observable<RatesAndTransitTime[]> {
    return this.http.post<RatesAndTransitTime[]>(this.config.buildPackagePlanningURL(`PackagePlanning/GetFedexShippingCost`), { address, shipDate, packagesIds });
  }

  getOriginAddress(): Observable<FedexAddress> {
    return this.http.get<FedexAddress>(this.config.buildPackagePlanningURL(`PackagePlanning/GetOriginAddress`));
  }

  hasPaintPrimer(cartonIds: string): Observable<boolean> {
    const params = new HttpParams().set('cartonIds', cartonIds);
    return this.http.get<boolean>(this.config.buildPackagePlanningURL(`PackagePlanning/HasPaintPrimer`), { params });
  }

  isPackagingStatus(salesOrderNumber: string): Observable<boolean> {
    const params = new HttpParams().set('salesOrderNumber', salesOrderNumber);
    return this.http.get<boolean>(this.config.buildPackagePlanningURL(`PackagePlanning/IsPackagingStatus`), { params });
  }

  createOvernightQuote(model: OvernightQuote) {
    return this.http.post<OvernightQuote>(this.config.buildURL(`overnight/new-overnight-quote`), model);
  }
}
