<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Edit Promo Code
    </h1>
    <hr />
    <div class="container">
        <form [formGroup]="promoCodesForm" (ngSubmit)="submit()">
            <div class="loading" *ngIf="loading">
                <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
            </div>
            <input formControlName="id" type="hidden" required>
            <mat-form-field class="full-width">
                <input matInput formControlName="code" placeholder="Code" required readonly>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" (selectionChange)="setPromoCodesFormValidators()" required>
                    <mat-option *ngFor="let item of promoCodeTypes" [value]="item">
                        {{formatCodeType(item)}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="promoCodesForm.get('type').hasError('required')">
                    Type is required
                </mat-error>
            </mat-form-field>
            <ng-container
                *ngIf="promoCodesForm.get('type') && promoCodesForm.get('type').value !== noChargePartsCodeType">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Amount" [imask]="amountMask" inputmode="numeric" type="number"
                        formControlName="amount" required>
                    <mat-error *ngIf="promoCodesForm.get('amount').hasError('required')">
                        Amount is required
                    </mat-error>
                    <mat-error *ngIf="promoCodesForm.get('amount').hasError('min')">
                        Min value {{minAmount}} required
                    </mat-error>
                    <mat-error *ngIf="promoCodesForm.get('amount').hasError('max')">
                        Max value {{maxAmount}} required
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input matInput placeholder="Max Discount Amount" [imask]="amountMask" inputmode="numeric"
                        type="number" formControlName="maxDiscountAmount">
                    <mat-error *ngIf="promoCodesForm.get('maxDiscountAmount').hasError('required')">
                        Max Discount Amount is required
                    </mat-error>
                    <mat-error *ngIf="promoCodesForm.get('maxDiscountAmount').hasError('min')">
                        Min value {{minAmount}} required
                    </mat-error>
                    <mat-error *ngIf="promoCodesForm.get('maxDiscountAmount').hasError('max')">
                        Max value {{maxAmount}} required
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <mat-form-field class="full-width">
                <input matInput [matDatepicker]="picker1" placeholder="Start Date" formControlName="startDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="promoCodesForm.get('startDate').hasError('required')">
                    Start Date Amount is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput [matDatepicker]="picker2" placeholder="End Date" formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>The End Date must be empty if the promo code never expires.</mat-hint>
                <mat-error
                    *ngIf="promoCodesForm.get('endDate').invalid && !promoCodesForm.get('endDate').hasError('invalid')">
                    Invalid Date
                </mat-error>
                <mat-error *ngIf="promoCodesForm.get('endDate').hasError('invalid')">
                    End Date must be greater than Start Date
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Number of Allowed Uses" type="number" formControlName="allowedUses"
                    required>
                <mat-error *ngIf="promoCodesForm.get('allowedUses').hasError('required')">
                    Number of Allowed Uses is required
                </mat-error>
                <mat-error *ngIf="promoCodesForm.get('allowedUses').hasError('min')">
                    Min value 1 required
                </mat-error>
                <mat-error *ngIf="promoCodesForm.get('allowedUses').hasError('max')">
                    Max value 99999 required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Number of Uses Per Customer" type="number"
                    formControlName="numberOfUsesPerCustomer">
                <mat-error *ngIf="promoCodesForm.get('numberOfUsesPerCustomer').hasError('min')">
                    Min value 1 required
                </mat-error>
                <mat-error *ngIf="promoCodesForm.get('numberOfUsesPerCustomer').hasError('max')">
                    Max value
                    <ng-container *ngIf="promoCodesForm.get('allowedUses').valid">
                        {{promoCodesForm.get('allowedUses').value}}
                    </ng-container>
                    <ng-container *ngIf="!promoCodesForm.get('allowedUses').valid">
                        99999
                    </ng-container>
                    required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Websites</mat-label>
                <mat-select formControlName="websites" multiple required>
                    <mat-option *ngFor="let website of websites" [value]="website">{{website}}</mat-option>
                </mat-select>
                <mat-error *ngIf="promoCodesForm.get('websites').hasError('required')">
                    Websites is required
                </mat-error>
            </mat-form-field>
            <div class="mat-form-field full-width">
                <mat-checkbox formControlName="isActive">
                    Is Active
                </mat-checkbox>
            </div>
            <div class="actions">
                <span>Add Criteria</span>
                <a mat-mini-fab (click)="addCriteria()">
                    <mat-icon>add</mat-icon>
                </a>
            </div>
            <div class="criteria-types">
                <div *ngIf="promoCodesCriteriaForm" [formGroup]="promoCodesCriteriaForm">
                    <div formArrayName="formGroups" class="formGroup"
                        *ngFor="let formGroup of promoCodesCriteriaForm.get('formGroups')['controls']; let i = index;">
                        <div [formGroupName]="i">
                            <mat-form-field class="full-width">
                                <mat-label>Criteria Type</mat-label>
                                <mat-select formControlName="type">
                                    <mat-option *ngFor="let criteriaType of promoCodeCriteriaTypes"
                                        [value]="criteriaType">
                                        {{formatCriteriaType(criteriaType.type)}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formGroup.get('type').hasError('required')">
                                    Criteria Type is required
                                </mat-error>
                            </mat-form-field>
                            <ng-container
                                *ngIf="formGroup.get('type').value && formGroup.get('type').value.requiresValue">
                                <mat-form-field class="full-width">
                                    <input matInput formControlName="value" placeholder="Value">
                                    <mat-error *ngIf="formGroup.get('value')?.hasError('required')">
                                        Value is required
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <button mat-button color="accent" (click)="removeCriteria(i)">
                            Delete
                        </button>
                    </div>
                </div>
            </div>

            <button mat-raised-button color="accent">Update</button>
        </form>
    </div>
</mat-card>