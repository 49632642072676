<div class="header-actions">
    <a routerLink="category-create" mat-flat-button color="accent">Create New Category</a>
</div>
<h1 class="mat-h1">
    Categories
</h1>
<hr />
<mat-accordion>
    <mat-expansion-panel expanded="true" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filters
            </mat-panel-title>
            <mat-panel-description>
                Filters Categories
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div [formGroup]="formFilter">
                    <mat-form-field>
                        <input matInput placeholder="Category" formControlName="category">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Created By" formControlName="created">
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="groups && groups.length > 0">
                        <mat-label>Group</mat-label>
                        <mat-select #groupSelect formControlName="group">
                            <mat-option>- Pick Group -</mat-option>
                            <mat-option *ngFor="let group of groups" [value]="group">
                                {{ group?.groupName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()"><mat-icon>filter_list</mat-icon></button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="reset-action-container" *ngIf="categoryFilterApply">
    <button mat-raised-button (click)="clearFilters()">
  <mat-icon>clear</mat-icon> Reset Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblCategories *ngIf="!loading && getData().length > 0" [dataSource]="getCategoryDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="categoryId">
            <th mat-header-cell *matHeaderCellDef> No </th>
            <td mat-cell *matCellDef="let row"> {{row?.categoryId}} </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let row"> {{row?.category}} </td>
        </ng-container>
        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let row"> {{row?.reasonGroup}} </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let row"> {{row?.createdBy}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let row"> {{row?.created | date }} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button matTooltip="Edit Category" matTooltipPosition="above" (click)="editCategory(row)" ><mat-icon color="accent">edit</mat-icon></button>
                <button mat-icon-button matTooltip="Delete Category " matTooltipPosition="above" (click)="disableCategory(row)"><mat-icon color="accent">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>