<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Update Production Limit
  </h1>
  <hr />

  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="editProductionLimitForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Customer" aria-label="Customer" formControlName="customer" readonly required>
        <mat-error *ngIf="editProductionLimitForm.controls.customer.invalid">{{getCustomerErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Apartment Complex" aria-label="Apartment Complex"
          formControlName="apartmentComplex" readonly>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Allowed Custom Parts Capacity" formControlName="allowedCapacity" required>
        <mat-error *ngIf="editProductionLimitForm.controls.allowedCapacity.invalid">
          {{getAllowedCapacityErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="accent">Update</button>
    </form>
  </div>
</mat-card>
