<mat-card>
    <h1 class="mat-h1">
        Audit
    </h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter Customers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Customer Name" [formControl]="customerFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Customer Number" [formControl]="customerNumberFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Company" [formControl]="companyFilter">
                        </mat-form-field>
                    </div>
                </div>

                <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br />

    <div class="reset-action-container" *ngIf="filtersApplied">
        <button mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Filters
        </button>
    </div>

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
        <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <br />
    <h3 *ngIf="!loading && getCustomersData().length === 0">No customers to show.</h3>

    <div *ngIf="!loading && getCustomersData().length > 0">
        <table mat-table [dataSource]="getCustomersDataSource()" #customersSort="matSort" matSort
            class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="customersDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: customersDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> <span class="truncate"> {{element.name}} </span> </td>
            </ng-container>

            <ng-container matColumnDef="customerNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Number </th>
                <td mat-cell *matCellDef="let element">{{element.customerNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let element"> <span class="truncate">{{element.company}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="communities">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Communities </th>
                <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; {{element.communities}} </td>
            </ng-container>

            <ng-container matColumnDef="audit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Audit </th>
                <td mat-cell *matCellDef="let element"> {{element.audit | active}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openAuditAssignments(element.id)" mat-icon-button matTooltip="Audit Details"
                        matTooltipPosition="above">
                        <mat-icon color="accent">notes</mat-icon>
                    </a>
                    <a (click)="openEditAuditAssignments(element.id)" mat-icon-button matTooltip="Edit Audit"
                        matTooltipPosition="above">
                        <mat-icon color="accent">edit</mat-icon>
                    </a>
                </td>
            </ng-container>
        </table>
    </div>
</mat-card>