import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkCentersService } from '../../services/work-centers.service';
import { WorkCenter } from '../../models/work-center';
import { WorkCenterType } from '../../models/work-center-type';
import { WorkCenterItem } from '../../models/work-center-item';
import { ProductionEmployee } from '../../models/production-employee';
import { ResetProductionStatusConfirmationComponent } from './reset-production-status-confirmation/reset-production-status-confirmation.component';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSelectChange } from '@angular/material/select';
import { ProductionStatus } from '../../models/production-status';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

const AUTO_REFRESH = 60 * 1000; // 1 min

@Component({
  selector: 'app-work-center',
  templateUrl: './work-center.component.html',
  styleUrls: ['./work-center.component.less']
})
export class WorkCenterComponent implements OnInit {

  loading = false;
  filtersApplied = false;
  workCenterTypeFilter = new FormControl();
  operatorFilter = new FormControl();
  workCenterFilter = new FormControl();
  autoRefreshIntervalId = -1;

  workCenters: WorkCenter[] = [];
  operators: ProductionEmployee[] = [];
  workCenterTypes: WorkCenterType[] = [];
  workCenterItems: WorkCenterItem[] = [];

  assemblyWorkCenterType = 'Assembly';
  qualityControlWorkCenterType = 'Quality Control';
  assemblyStartedStatus = this.format(ProductionStatus.AssemblyStarted);

  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackbarService,
    private workCentersService: WorkCentersService,
  ) { }

  ngOnInit() {
    this.getWorkCenterTypes();
    this.getProductionEmployees();
    this.setAutoRefresh();
  }

  async applyFilters() {
    const valid = this.workCenterTypeFilter.valid && this.operatorFilter.valid && this.workCenterFilter.valid;

    if (valid) {
      const workCenterTypeId = this.workCenterTypeFilter.value.id;
      const workCenterId = this.workCenterFilter.value.id;
      await this.getWorkCenterItems(workCenterTypeId, workCenterId);
      this.filtersApplied = true;
    } else {
      this.snackBarService.showWarning('Invalid work center filters. Please try again.');
    }
    this.workCenterTypeFilter.markAsTouched();
    this.workCenterFilter.markAsTouched();
    this.operatorFilter.markAsTouched();
  }

  refresh() {
    this.applyFilters();
  }

  format(status: ProductionStatus): string {
    return `${ProductionStatus[status]}`;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    if (e.checked) {
      this.setAutoRefresh();
    }
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(() => {
      const valid = this.workCenterTypeFilter.valid && this.operatorFilter.valid && this.workCenterFilter.valid;
      if (valid && this.filtersApplied) {
        this.refresh();
      }
    }, AUTO_REFRESH);
  }

  async getWorkCenterItems(workCenterTypeId: number, workCenterId: number) {
    try {
      this.loading = true;
      this.workCenterItems = await this.workCentersService.getWorkCenterItems(workCenterTypeId, workCenterId).toPromise();
    } catch (err) {
      console.error('Error getting work center items:', err);
      this.snackBarService.showError('Error getting work center items.');
    }
    finally {
      this.loading = false;
    }
  }

  getWorkCenterTypes() {
    this.workCentersService.getWorkCenterTypes().subscribe((workCenterTypes: WorkCenterType[]) => {
      this.workCenterTypes = workCenterTypes;
    }, err => {
      console.error('Error getting work center types:', err);
      this.snackBarService.showError('Error getting work center types.');
    });
  }

  getWorkCenters(workCenterTypeId: number) {
    this.workCentersService.getWorkCenters(workCenterTypeId).subscribe((workCenters: WorkCenter[]) => {
      this.workCenters = workCenters;
    }, err => {
      console.error('Error getting work centers:', err);
      this.snackBarService.showError('Error getting work centers.');
    });
  }

  getProductionEmployees() {
    this.workCentersService.getProductionEmployees().subscribe((productionEmployees: ProductionEmployee[]) => {
      this.operators = productionEmployees;
    }, err => {
      console.error('Error getting production employees:', err);
      this.snackBarService.showError('Error getting production employees.');
    });
  }

  workCenterTypeChange(e: MatSelectChange) {
    if (e.value) {
      this.workCenterFilter.setValue('');
      this.getWorkCenters(e.value.id);
      this.workCenterItems = [];
    }
  }

  clearFilters() {
    this.workCenterTypeFilter.setValue('');
    this.operatorFilter.setValue('');
    this.workCenterFilter.setValue('');
    this.workCenterItems = [];
    this.filtersApplied = false;
  }

  async updateProductionStatus(item: WorkCenterItem) {
    try {
      const workCenterType: WorkCenterType = this.workCenterTypeFilter.value;
      const operator: ProductionEmployee = this.operatorFilter.value;
      const workCenter: WorkCenter = this.workCenterFilter.value;

      if (workCenterType && operator && workCenter) {
        // work center actions validation
        if (workCenterType.name == this.qualityControlWorkCenterType
          && item.productionStatus === this.format(ProductionStatus.AssemblyStarted)) return;

        // set work center & operator (production employee)
        item.assemblyWorkCenterId = workCenter.id;
        item.workCenterProductionEmployeeId = operator.id;

        const result = await this.workCentersService.updateProductionStatus(item).toPromise();
        item.productionStatus = result.productionStatus;

        // update work center items
        this.updateWorkCenterItems(item);

        this.snackBarService.showSuccess('Production status was updated successfully.');
      } else {
        this.snackBarService.showError('Invalid work center configurations. Please try again.');
      }
    } catch (err) {
      console.error('Error updating production status:', err);
      this.snackBarService.showError('Error updating production status.');
    }
  }

  updateWorkCenterItems(item: WorkCenterItem) {
    const workCenterType: WorkCenterType = this.workCenterTypeFilter.value;
    if (workCenterType.name === this.assemblyWorkCenterType && item.productionStatus === this.format(ProductionStatus.AssemblyComplete)
      || workCenterType.name === this.qualityControlWorkCenterType && item.productionStatus === this.format(ProductionStatus.QC1Complete)
    ) {
      this.workCenterItems = this.workCenterItems.filter(c => c.partialUnitId !== item.partialUnitId);
    }
  }

  openResetProductionStatus($event, item: WorkCenterItem) {
    const workCenterType: WorkCenterType = this.workCenterTypeFilter.value;
    const operator: ProductionEmployee = this.operatorFilter.value;
    const workCenter: WorkCenter = this.workCenterFilter.value;

    if (workCenterType && operator && workCenter) {
      // set work center & operator (production employee)
      item.assemblyWorkCenterId = workCenter.id;
      item.workCenterProductionEmployeeId = operator.id;

      const dialogRef = this.dialog.open(ResetProductionStatusConfirmationComponent, {
        width: '680px',
        data: {
          item
        }
      });

      dialogRef.afterClosed().subscribe((result: WorkCenterItem) => {
        if (result) {
          item.productionStatus = result.productionStatus;
        }
      });
    } else {
      this.snackBarService.showError('Invalid work center configurations. Please try again.');
    }

    $event.stopPropagation();
    return false;
  }
}
