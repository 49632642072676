import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CLAIM_TYPE_NAME, CLAIM_TYPE_EMAIL, CLAIM_TYPE_ID, CLAIM_TYPE_ROLE } from '../constants';
import { ConfigService } from './config.service';
import { JsonWebToken } from '../models/json-web-token';
import { Account } from '../models/account';
import { SignIn } from '../models/sign-in';

const MACHINE_KEY = 'machine';
const ROUTER_ID_KEY = 'router_id';
const ACCESS_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly jwtHelper = new JwtHelperService();

  constructor(
    private router: Router,
    private http: HttpClient,
    private config: ConfigService,
    private storage: SessionStorageService,
  ) { }

  signIn(model: SignIn) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.config.buildURL('auth');
    return this.http.post(url, model, httpOptions);
  }

  signOut() {
    this.clearAccessToken();
    this.router.navigate(['/sign-in']);
  }

  isSessionExpired() {
    return this.getAccessToken() && !this.isAuthenticated();
  }

  destroySession() {
    this.clearAccessToken();
    window.location.href = this.buildUrl('/sign-in?message=Your session has expired. Please sign in again.');
  }

  buildUrl(path: string = '') {
    return `${window.location.origin}${path}`;
  }

  isAuthenticated(): boolean {
    const token = this.getAccessToken();
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  isUserInRoles(allowedRoles: string[]): boolean {
    const { roles } = this.getAccount();
    for (const role of roles) {
      if (allowedRoles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  getAccount(): Account {
    const token = this.getAccessToken();
    const data = this.decode(token);
    const roles = this.getRoles(data);

    return {
      id: data[CLAIM_TYPE_ID] as string,
      userName: data[CLAIM_TYPE_NAME] as string,
      email: data[CLAIM_TYPE_EMAIL] as string,
      roles
    };
  }

  getDefaultPassword(): Observable<string> {
    const url = this.config.buildURL('auth/getDefaultPassword');
    return this.http.get<string>(url);
  }

  private getRoles(decodedToken: any): string[] {
    if (typeof decodedToken[CLAIM_TYPE_ROLE] === 'string') {
      return [decodedToken[CLAIM_TYPE_ROLE]];
    }
    return decodedToken[CLAIM_TYPE_ROLE] as string[];
  }

  decode(token: JsonWebToken | string): any {
    if (typeof token === 'string') {
      return this.jwtHelper.decodeToken(token);
    }
    return this.jwtHelper.decodeToken(token.value);
  }

  setRouter(routerId: number) {
    this.storage.store(ROUTER_ID_KEY, routerId);
  }

  getRouter(): number {
    return this.storage.retrieve(ROUTER_ID_KEY);
  }

  setMachine(machine: string) {
    this.storage.store(MACHINE_KEY, machine);
  }

  getMachine(): string {
    return this.storage.retrieve(MACHINE_KEY);
  }

  setAccessToken(token: JsonWebToken) {
    this.storage.store(ACCESS_TOKEN_KEY, token.value);
  }

  getAccessToken(): string {
    return this.storage.retrieve(ACCESS_TOKEN_KEY);
  }

  clearAccessToken() {
    this.storage.clear(ACCESS_TOKEN_KEY);
  }
}
