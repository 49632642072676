<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h1 class="mat-h1">
            {{ getModalTittle() }}
        </h1>
        <hr />
        <br>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
            </div>

            <!-- Create or Edit-->
            <ng-container *ngIf="settingModal.settingAction === 1 || settingModal.settingAction === 2; else deleteTemplate">
                <div [ngSwitch]="settingModal.settingType">
                    <!-- commissionType -->
                    <div *ngSwitchCase="1">
                        <form [formGroup]="commissionTypeForm" (ngSubmit)="submit()">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Name" formControlName="name" required>
                                <mat-error *ngIf="commissionTypeForm.get('name').hasError('required')">
                                    Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <!-- <input matInput placeholder="Description" formControlName="description" required> -->
                                <textarea matInput placeholder="Description" formControlName="description" rows="3" required></textarea>
                                <mat-error *ngIf="commissionTypeForm.get('description').hasError('required')">
                                    Description is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Commission Amount" inputmode="numeric" type="number" formControlName="commissionAmount" required>
                                <mat-error *ngIf="commissionTypeForm.get('commissionAmount').hasError('required')">
                                    Commission Amount is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <mat-label>Commission Amount Type</mat-label>
                                <mat-select formControlName="commissionAmountType" required>
                                    <mat-option [value]="">- Pick Commission Amount Type -</mat-option>
                                    <mat-option *ngFor="let item of commissionAmountType" [value]="item">
                                        {{ item }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="commissionTypeForm.get('commissionAmountType').hasError('required')">
                                    Commission Amount is required
                                </mat-error>
                            </mat-form-field>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading" type="submit"> {{ getBtnTittle() }} </button>
                            <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()">Cancel</button>
                        </form>
                    </div>
                    <!-- commissionSales -->
                    <div *ngSwitchCase="2">
                        <form [formGroup]="commissionSalesForm" (ngSubmit)="submit()">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Code" formControlName="salesPersonCode" readonly>
                                <mat-error *ngIf="commissionSalesForm.get('salesPersonCode').hasError('required')">
                                    Code is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Name" formControlName="salesPersonName" required>
                                <mat-error *ngIf="commissionSalesForm.get('salesPersonName').hasError('required')">
                                    Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Email" formControlName="emailAddress" required>
                                <mat-error *ngIf="commissionSalesForm.get('emailAddress').hasError('required')">
                                    Email is required
                                </mat-error>
                                <mat-error *ngIf="commissionSalesForm.get('emailAddress').hasError('email')">
                                    You must enter a valid email address
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Cell Phone" formControlName="cellPhone" [imask]="phoneMask" required>
                                <mat-error *ngIf="commissionSalesForm.get('cellPhone').hasError('required')">
                                    Phone is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <mat-label>Sales Person Type</mat-label>
                                <mat-select formControlName="personType">
                                    <mat-option [value]="0">- Pick Sale Person Type -</mat-option>
                                    <mat-option *ngFor="let item of salesPersonTypes" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <mat-label>Region</mat-label>
                                <mat-select formControlName="region">
                                    <mat-option [value]="0">- Pick Region -</mat-option>
                                    <mat-option *ngFor="let item of commissionRegions" [value]="item.regionId">
                                        {{ item.region }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading" type="submit"> {{ getBtnTittle() }} </button>
                            <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()" [disabled]="loading">Cancel</button>
                        </form>
                    </div>
                    <!-- commissionSalesPersonType -->
                    <div *ngSwitchCase="3">
                        <form [formGroup]="commissionSalesPersonTypeForm" (ngSubmit)="submit()">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Name" formControlName="typeName" required>
                                <mat-error *ngIf="commissionSalesPersonTypeForm.get('typeName').hasError('required')">
                                    Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <textarea matInput placeholder="Description" formControlName="description" rows="4"></textarea>
                            </mat-form-field>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading" type="submit"> {{ getBtnTittle() }} </button>
                            <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()" [disabled]="loading">Cancel</button>
                        </form>
                    </div>
                    <!-- commissionTypeAssignments -->
                    <div *ngSwitchCase="4">
                        <form [formGroup]="commissionTypeAssignmentsForm" (ngSubmit)="submit()">
                            <mat-form-field class="full-width">
                                <mat-label>Sales Person Type</mat-label>
                                <mat-select formControlName="salesPersonTypeId">
                                    <mat-option [value]="0">- Pick Sale Person Type -</mat-option>
                                    <mat-option *ngFor="let item of salesPersonTypes" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="commissionTypeAssignmentsForm.get('commissionTypeId').hasError('min')">
                                    Sale Person type is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <mat-label>Sales Person Type</mat-label>
                                <mat-select formControlName="commissionTypeId" required>
                                    <mat-option [value]="0">- Pick Commission Type -</mat-option>
                                    <mat-option *ngFor="let item of commissionTypes" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="commissionTypeAssignmentsForm.get('commissionTypeId').hasError('min')">
                                    Commission type is required
                                </mat-error>
                            </mat-form-field>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading" type="submit"> {{ getBtnTittle() }} </button>
                            <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()" [disabled]="loading">Cancel</button>
                        </form>
                    </div>
                </div>
            </ng-container>
            <!-- Delete-->
            <ng-template #deleteTemplate>
                <div [ngSwitch]="settingModal.settingType">
                    <!-- commissionType -->
                    <div *ngSwitchCase="1"></div>
                    <!-- commissionSales -->
                    <div *ngSwitchCase="2">
                        <p class="font-size-msg">
                            Are you sure you want to disable this sales rep? <br>
                            <strong>Note:</strong> This action cannot be undone.
                        </p>
                        <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading" (click)="confirm()"> {{ getBtnTittle() }} </button>
                        <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()" [disabled]="loading">Cancel</button>
                    </div>
                    <!-- commissionSalesPersonType -->
                    <div *ngSwitchCase="3"></div>
                    <!-- commissionTypeAssignments -->
                    <div *ngSwitchCase="4"></div>
                </div>
            </ng-template>
        </div>
    </mat-card>
</mat-dialog-content>

