import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RouterSchedule } from '../models/router-schedule';

@Injectable({
  providedIn: 'root'
})
export class RouterSchedulesService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getRouterSchedules(): Observable<RouterSchedule[]> {
    const url = this.config.buildURL('routerSchedules');
    return this.http.get<RouterSchedule[]>(url);
  }

  getRouterSchedule(id: number): Observable<RouterSchedule> {
    const url = this.config.buildURL(`routerSchedules/${id}`);
    return this.http.get<RouterSchedule>(url);
  }

  createRouterSchedule(model: RouterSchedule): Observable<RouterSchedule> {
    const url = this.config.buildURL(`routerSchedules`);
    return this.http.post<RouterSchedule>(url, model);
  }

  updateRouterSchedule(model: RouterSchedule): Observable<RouterSchedule> {
    const url = this.config.buildURL(`routerSchedules/${model.id}`);
    return this.http.put<RouterSchedule>(url, model);
  }

  deleteRouterSchedule(id: number) {
    const url = this.config.buildURL(`routerSchedules/${id}`);
    return this.http.delete(url);
  }
}
