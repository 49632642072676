import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterSetup } from '../../../models/router-setup';
import { RouterSetupsService } from '../../../services/router-setups.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-router-setup-details',
  templateUrl: './router-setup-details.component.html',
  styleUrls: ['./router-setup-details.component.less']
})
export class RouterSetupDetailsComponent implements OnInit {
  routerSetup: RouterSetup;

  constructor(
    private route: ActivatedRoute,
    private routerSetupsService: RouterSetupsService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getRouterSetup();
  }

  getRouterSetup(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routerSetupsService.getRouterSetup(id).subscribe((routerSetup: RouterSetup) => {
      this.routerSetup = routerSetup;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router setup details.');
      });
  }
}
