<div class="container">
  <ng-template ngFor let-item [ngForOf]="navLinks">
    <mat-card matRipple (click)="navigate(item.path)">
      <div class="header">
        <mat-icon>{{item.icon}}</mat-icon>
        <strong>{{item.title}} </strong>
      </div>
      <p *ngIf="item.title.toLowerCase() === 'calendar'">
        {{today | date}}
      </p>
    </mat-card>
  </ng-template>
</div>