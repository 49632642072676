import { Component, OnInit, ViewChild } from '@angular/core';

// DateTime
import * as moment from 'moment';
// Material
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { CommissionSalesPerson } from 'src/app/models/commission-sales-person';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CommissionService } from 'src/app/services/commission.service';
import { CommissionReportRegion } from 'src/app/models/commission-region';

@Component({
  selector: 'app-resume-commission-regions',
  templateUrl: './resume-commission-regions.component.html',
  styleUrls: ['./resume-commission-regions.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ]
})
export class ResumeCommissionRegionsComponent implements OnInit {
  salesPersons: CommissionSalesPerson[] = [];
  salesPersonsCharge = false;
  loading = false;

  filterFormPanelDisplaced = true;
  editFormPanelDisplaced = true;

  reportFromDateFilter = new UntypedFormControl();
  reportToDateFilter = new UntypedFormControl();
  reportSaleRepFilter = new UntypedFormControl();

  @ViewChild(MatSelect) dllTeamSales: MatSelect;

  reportDataSource: CommissionReportRegion[] = [];

  displayedHeader: string[] = [
    'Sale Person',
    'Month',
    'Year',
    'Region',
    'Total Revenue'
  ];


  constructor(
    private commissionService: CommissionService,
    private snackBarService: SnackbarService) {
  }

  async ngOnInit(): Promise<void> {
    await this.getAllSalesPerson();
  }

  async getAllSalesPerson(): Promise<void> {
    this.salesPersonsCharge = true;
    await this.commissionService.getCommissionAllSalesPerson()
      .toPromise()
      .then((result: CommissionSalesPerson[]) => this.salesPersons = result.filter(f => f.commissionRegionId !== 0 && f.salesPersonName !== ''))
      .catch((err: any) => {
        console.error(err);
        this.snackBarService.showError('Error getting sales persons.');
      });
    this.salesPersonsCharge = false;
  }

  onRequestedFromDateFilterChange() {
    if (!this.reportFromDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested From date.');
      this.reportFromDateFilter.setValue('');
    }
  }

  onRequestedToDateFilterChange() {
    if (!this.reportToDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested To date.');
      this.reportToDateFilter.setValue('');
    }
  }

  onAllSelectionChange(option: MatOption) {
    this.dllTeamSales.options.map((item: MatOption) => option.selected ? item.select() : item.deselect());
    this.dllTeamSales.close();
  }

  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  rangeDatesIsValid(isRecalculate: boolean): boolean {
    const startPeriod = this.reportFromDateFilter.value;
    const endPeriod = this.reportToDateFilter.value;
    const days = moment(endPeriod)
      .startOf('day')
      .diff(moment(startPeriod).startOf('day'), 'days');

    if (startPeriod && endPeriod && days <= 0) {
      this.reportFromDateFilter.setValue('');
      this.reportToDateFilter.setValue('');
      this.snackBarService.showWarning('Range of dates is not valid.');
    } else if (startPeriod === null || endPeriod === null) {
      this.snackBarService.showWarning('Enter a valid range dates.');
    } else if (isRecalculate && days > 60) {
      this.snackBarService.showWarning('The recalculate execution only allows a range no greater than 60 days.');
    } else if (!isRecalculate && days > 365) {
      this.snackBarService.showWarning('The commission report only allows a range no greater than one year.');
    } else {
      return true;
    }
  }

  async runReport(): Promise<void> {
    if (this.rangeDatesIsValid(false)) {
      this.reportDataSource = [];
      await this.getDataReport();
    }
  }

  async getDataReport(): Promise<void> {
    this.showLoading();
    await this.commissionService.getCommissionRegionReportByFilters(
      this.reportFromDateFilter.value, this.reportToDateFilter.value, this.reportSaleRepFilter.value ? (this.reportSaleRepFilter.value as CommissionSalesPerson[]).map((m) => m.salesPersonId) : [])
      .toPromise()
      .then((result: CommissionReportRegion[]) => {
        this.reportDataSource = result;
        if (this.reportDataSource.length === 0) {
          this.snackBarService.showWarning(`Your parameters don't get any record, try again.`);
        }
        this.hideLoading();
      })
      .catch((err: any) => {
        console.error(err);
        this.hideLoading();
        this.snackBarService.showError('Error getting commission report.');
      });
  }

}
