import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ScheduleManagerComponent } from '../schedule-manager.component';
import { ScheduleService } from '../../../services/schedule.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProductionLocation } from '../../../models/production-location';
import { FormattedScheduledUnit } from '../../../models/formatted-scheduled-unit';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProductionStatus } from '../../../models/production-status';
import * as moment from 'moment';
import { Address, ShippingAddress } from '../../../models/shipping-address';
import { AddressesService } from '../../../services/addresses.service';
import { UserRole } from '../../../models/user-role';
import { AuthService } from '../../../services/auth.service';
import { ServiceTypeOvernight } from 'src/app/models/overnight-type-service';

const PRIMARY_ROLES = [UserRole.Admin, UserRole.ScheduleManager];
const SECONDARY_ROLES = [UserRole.Scheduler];
const MAIN_ROLES = [...PRIMARY_ROLES, ...SECONDARY_ROLES];

@Component({
  selector: 'app-edit-scheduled-unit',
  templateUrl: './edit-scheduled-unit.component.html',
  styleUrls: ['./edit-scheduled-unit.component.less']
})
export class EditScheduledUnitComponent implements OnInit {

  productionDate = new UntypedFormControl();
  requestedShipDate = new UntypedFormControl();
  mustShipDate = new UntypedFormControl();
  plannedShipDate = new UntypedFormControl();
  productionLocation = new UntypedFormControl();
  sequence = new UntypedFormControl('', Validators.compose([
    Validators.required,
    Validators.min(0),
    Validators.pattern('[0-9]*')])
  );
  productionStatus = new UntypedFormControl();
  overnightServiceType = new UntypedFormControl();
  updateAllPartialUnitsRelated = new UntypedFormControl();
  addressLine1 = new UntypedFormControl();
  addressLine2 = new UntypedFormControl();
  addressLine3 = new UntypedFormControl();
  zipCode = new UntypedFormControl();
  state = new UntypedFormControl();
  city = new UntypedFormControl();
  shipToName = new UntypedFormControl();
  phoneNumber = new UntypedFormControl();
  updateForEntireSalesOrder = new UntypedFormControl('true');
  schedulerNotes = new UntypedFormControl();
  isHot = new UntypedFormControl();
  isOvernight = new UntypedFormControl();
  isLockedIn = new UntypedFormControl();
  isAudit = new UntypedFormControl();
  isPalletized = new UntypedFormControl();

  productionStatuses: string[] = [];
  overnightServiceTypes: string[] = [];
  productionLocations: ProductionLocation[] = [];
  shippingAddress: ShippingAddress;
  invalidShippingAddress = false;
  enableUpdateScheduledUnit = true;
  scheduledUnit: FormattedScheduledUnit;

  constructor(
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private addressesService: AddressesService,
    public dialogRef: MatDialogRef<ScheduleManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      formControlsDisabled: boolean,
      scheduledUnit: FormattedScheduledUnit,
      productionLocations: ProductionLocation[]
    }) { }

  ngOnInit() {
    this.configureAccessControls();
    this.scheduledUnit = { ...this.data.scheduledUnit };
    this.productionLocations = this.data.productionLocations;
    this.productionDate.setValue(this.scheduledUnit.productionDate);
    this.mustShipDate.setValue(this.scheduledUnit.mustShipDate);
    this.requestedShipDate.setValue(this.scheduledUnit.requestedShipDate);
    this.plannedShipDate.setValue(this.scheduledUnit.plannedShipDate);
    this.sequence.setValue(this.scheduledUnit.sequence);
    this.getProductionStatuses();
    this.getOvernightServices();
    this.getShippingAddress(this.scheduledUnit.uniqueUnitId);
  }

  configureAccessControls() {
    if (!this.authService.isUserInRoles(PRIMARY_ROLES)) {
      this.disableFormControls();
    }

    if (this.authService.isUserInRoles(SECONDARY_ROLES)) {
      this.enableProductionStatusFormControl();
    }

    if (this.isExportedToProduction()) {
      this.disableLockedInFormControl();
    }

    this.overnightServiceType.enable();
    this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
  }

  enableProductionStatusFormControl() {
    this.productionStatus.enable();
    this.updateAllPartialUnitsRelated.enable();
  }

  disableFormControls() {
    this.productionDate.disable();
    this.requestedShipDate.disable();
    this.mustShipDate.disable();
    this.plannedShipDate.disable();
    this.productionLocation.disable();
    this.sequence.disable();
    this.productionStatus.disable();
    this.overnightServiceType.disable();
    this.updateAllPartialUnitsRelated.disable();
    this.addressLine1.disable();
    this.addressLine2.disable();
    this.addressLine3.disable();
    this.zipCode.disable();
    this.state.disable();
    this.city.disable();
    this.shipToName.disable();
    this.phoneNumber.disable();
    this.updateForEntireSalesOrder.disable();
    this.schedulerNotes.disable();
    this.isHot.disable();
    this.isOvernight.disable();
    this.isLockedIn.disable();
    this.isAudit.disable();
    this.isPalletized.disable();
  }

  disableLockedInFormControl() {
    this.isLockedIn.disable();
  }

  isExportedToProduction(): boolean {
    return this.data.scheduledUnit.productionDate
      && this.data.scheduledUnit.productionOrderNumber
      && this.data.scheduledUnit.isLockedIn;
  }

  getProductionStatuses() {
    for (const status in ProductionStatus) {
      if (isNaN(parseInt(status, 10)) && status !== ProductionStatus[ProductionStatus.NotScheduled]) {
        this.productionStatuses.push(status);
      }
    }
  }

  getOvernightServices() {
    for (const service in ServiceTypeOvernight) {
      this.overnightServiceTypes.push(ServiceTypeOvernight[service]);
    }
  }

  getShippingAddress(uniqueUnitId: number) {
    this.enableUpdateScheduledUnit = false;
    this.scheduleService.getShippingAddress(uniqueUnitId).subscribe((shippingAddress: ShippingAddress) => {
      this.setShippingAddress(shippingAddress);
      this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
    }, (err) => {
      console.error('Error getting shipping address:', err);
      this.snackBarService.showError('Error getting shipping address.');
      this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
    });
  }

  setShippingAddress(shippingAddress: ShippingAddress) {
    this.shippingAddress = shippingAddress;
    this.addressLine1.setValue(shippingAddress.addressLine1);
    this.addressLine2.setValue(shippingAddress.addressLine2);
    this.addressLine3.setValue(shippingAddress.addressLine3);
    this.zipCode.setValue(shippingAddress.zipCode);
    this.state.setValue(shippingAddress.state);
    this.city.setValue(shippingAddress.city);
    this.phoneNumber.setValue(shippingAddress.phoneNumber);
    this.shipToName.setValue(shippingAddress.shipToName);

    this.addressLine1.markAsTouched();
    this.addressLine2.markAsTouched();
    this.addressLine3.markAsTouched();
    this.zipCode.markAsTouched();
    this.state.markAsTouched();
    this.city.markAsTouched();
    this.phoneNumber.markAsTouched();
    this.shipToName.markAsTouched();
  }

  format(shippingAddress: ShippingAddress): string {
    if (shippingAddress) {
      const line = [shippingAddress.addressLine1, shippingAddress.addressLine2, shippingAddress.zipCode, shippingAddress.state, shippingAddress.city];
      return line.filter(s => s).join(', ');
    }
    return 'No shipping address to show.';
  }

  async onShippingAddressChange() {
    try {
      this.enableUpdateScheduledUnit = false;

      const address: Address = {
        countryCode: 'US',
        postalCode: this.zipCode.value,
        city: this.city.value,
        stateOrProvince: this.state.value,
        streetLines: [this.addressLine1.value ?? '', this.addressLine2.value ?? ''],
      };

      this.shippingAddress = {
        ...this.shippingAddress,
        addressLine1: this.addressLine1.value,
        addressLine2: this.addressLine2.value,
        addressLine3: this.addressLine3.value,
        zipCode: this.zipCode.value,
        state: this.state.value,
        city: this.city.value,
        phoneNumber: this.phoneNumber.value,
        shipToName: this.shipToName.value
      };

      console.log('Shipping Address:', address);
      const fedexAddress = await this.addressesService.validate(address);
      console.log('Fedex Address:', fedexAddress);

      if (fedexAddress.warningMessage) {
        this.invalidShippingAddress = true;
        this.snackBarService.showWarning(fedexAddress.warningMessage);
        return false;
      }

      this.scheduledUnit.address = this.shippingAddress;
      this.scheduledUnit.updateForEntireSalesOrder = this.updateForEntireSalesOrder.value;
      this.invalidShippingAddress = false;

    } catch (err) {
      console.error('Error validating shipping address:', err);
      this.snackBarService.showError('Error validating shipping address. Please try again');
    } finally {
      this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
    }
  }

  onProductionDateChange() {
    if (!this.productionDate.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDate.setValue(this.scheduledUnit.productionDate);
    } else {
      this.scheduledUnit.productionDate = this.productionDate.value;
    }
  }

  onMustShipDateChange() {
    if (!this.mustShipDate.valid) {
      this.snackBarService.showWarning('Invalid must ship date.');
      this.mustShipDate.setValue(this.scheduledUnit.mustShipDate);
    } else {
      this.scheduledUnit.mustShipDate = this.mustShipDate.value;
    }
  }

  onRequestedShipDateChange() {
    if (!this.requestedShipDate.valid) {
      this.snackBarService.showWarning('Invalid requested ship date.');
      this.requestedShipDate.setValue(this.scheduledUnit.requestedShipDate);
    } else {
      this.scheduledUnit.requestedShipDate = this.requestedShipDate.value;
    }
  }

  onPlannedShipDateChange() {
    if (!this.plannedShipDate.valid) {
      this.snackBarService.showWarning('Invalid planned ship date.');
      this.plannedShipDate.setValue(this.scheduledUnit.plannedShipDate);
    } else {
      this.scheduledUnit.plannedShipDate = this.plannedShipDate.value;
    }
  }

  onSequenceChange() {
    if (!this.sequence.valid) {
      this.snackBarService.showWarning('Invalid sequence.');
      this.sequence.setValue(this.scheduledUnit.sequence);
    } else {
      this.scheduledUnit.sequence = this.sequence.value;
    }
  }

  updateScheduledUnit() {
    this.enableUpdateScheduledUnit = false;

    const updateCallback = () => {
      this.scheduleService.updateScheduledUnit(this.scheduledUnit).subscribe((schedUnit: FormattedScheduledUnit) => {
        if ((this.scheduledUnit.isPalletized && this.scheduledUnit.status == 'Shipped')   //Update order status for CPU orders
            || (this.scheduledUnit.shipMethod == "CPU" && this.scheduledUnit.status == 'Shipped')) {
          let updateOrderObj = { 
            shippingMethodID: this.scheduledUnit.isPalletized ? 3 : null,
            orderStatusTypeId: 5
          };

          var reqHandler = this.scheduleService.updatePalletizedOrderStatus(this.scheduledUnit.salesOrderNumber, updateOrderObj)
                              .subscribe(
                                {
                                  next: () => {},
                                  complete: () => { reqHandler.unsubscribe(); }
                                }
                              );
        }
      
        this.dialogRef.close(schedUnit);
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error updating scheduled unit. Please try again.');
          this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
        });
    };

    const a = moment(this.data.scheduledUnit.productionDate);
    const b = moment(this.scheduledUnit.productionDate);
    const productionDateChanged = b.diff(a, 'days') >= 0;

    if (!this.authService.isUserInRoles(MAIN_ROLES)) {
      this.snackBarService.showError('You don\'t have access to update this scheduled unit.');
      return;
    }

    if (this.isExportedToProduction() && !productionDateChanged) {
      this.snackBarService.showWarning('This scheduled unit has already been exported to production. You can\'t change the production date to a previous date or the locked-in value.');
      this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
      return;
    }

    if (this.invalidShippingAddress) {
      this.snackBarService.showWarning('Invalid shipping address. Please enter a valid FedEx address.');
      this.enableUpdateScheduledUnit = this.authService.isUserInRoles(MAIN_ROLES);
      return;
    }

    updateCallback();
  }
}
