import { PromoCodeCriteria } from './promo-code-criteria';

export enum CodeType {
    FlatAmountOffPurchase = 1,
    PercentAmountOffPurchase = 2,
    ShippingDiscount = 3,
    SomeNumberOfFreeCustomItems = 4,
    NoChargeParts = 5,
    FlatAmountValue = 6,
}

export interface PromoCode {
    id?: number;
    isRandom?: boolean;
    positiveWord?: string;
    code?: string;
    type: CodeType;
    amount: number;
    maxDiscountAmount?: number;
    startDate: Date;
    endDate?: Date;
    numberOfAllowedUses: number;
    numberOfUsesPerCustomer?: number;
    numberOfPromoCodes?: number;
    dateCreated?: Date;
    createdBy?: string;
    isActive?: boolean;
    websites?: string[];
    criteria?: PromoCodeCriteria[];
}
