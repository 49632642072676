import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { Account } from '../../models/account';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  title = '';
  account: Account;

  @Output() toggle = new EventEmitter();

  constructor(
    private config: ConfigService,
    private authService: AuthService) { }

  ngOnInit() {
    this.title = this.config.getAppName();
    this.account = this.authService.getAccount();
  }

  toggleMenu() {
    this.toggle.emit();
  }

  signOut() {
    this.authService.signOut();
  }

}
