import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountingService } from '../../../services/accounting.service';
import { BaseForm } from '../../../forms/base-form';
import { RebateGroup } from '../../../models/rebate-group';
import { SnackbarService } from '../../../services/snackbar.service';
import * as moment from 'moment';
@Component({
  selector: 'app-create-rebate-group',
  templateUrl: './create-rebate-group.component.html',
  styleUrls: ['./create-rebate-group.component.less']
})
export class CreateRebateGroupComponent extends BaseForm {

  rebateGroup: RebateGroup;
  createRebateGroupForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountingService: AccountingService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  createForm(): void {
    this.createRebateGroupForm = this.fb.group({
      name: ['', Validators.required],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(10)]],
      startPeriod: ['', Validators.required],
      endPeriod: ['']
    });
  }

  isValid(): boolean {
    const startPeriod = this.createRebateGroupForm.get('startPeriod').value;
    const endPeriod = this.createRebateGroupForm.get('endPeriod').value;
    const days = moment(endPeriod).startOf('day').diff(moment(startPeriod).startOf('day'), 'days');

    if (startPeriod && endPeriod && days <= 0) {
      this.createRebateGroupForm.get('endPeriod').setErrors({ min: true });
    }

    return this.createRebateGroupForm.valid;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.createRebateGroup();
    }
  }

  createRebateGroup() {
    const model: RebateGroup = {
      name: this.createRebateGroupForm.get('name').value.trim(),
      rebatePercentage: this.createRebateGroupForm.get('percentage').value,
      startPeriod: this.createRebateGroupForm.get('startPeriod').value,
      endPeriod: this.createRebateGroupForm.get('endPeriod').value
    };

    this.accountingService.createRebateGroup(model).subscribe(_ => {
      this.router.navigate(['/accounting']);
      this.snackBarService.showSuccess('The rebate group was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new rebate group.');
    });
  }

}
