import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { RolesService } from '../../../services/roles.service';
import { Role } from '../../../models/role';

@Component({
  selector: 'app-delete-role',
  templateUrl: './delete-role.component.html',
  styleUrls: ['./delete-role.component.less']
})
export class DeleteRoleComponent implements OnInit {

  role: Role;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getRole();
  }

  getRole() {
    const id = this.route.snapshot.paramMap.get('id');
    this.rolesService.getRole(id).subscribe((role: Role) => {
      this.role = role;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the role details.');
      });
  }

  deleteRole() {
    const id = this.route.snapshot.paramMap.get('id');
    this.rolesService.deleteRole(id).subscribe(_ => {
      this.router.navigate(['/roles']);
      this.snackBarService.showSuccess('The role was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting role.');
      });
  }

}
