import { Component, ViewChild } from '@angular/core';
// Material
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
// Services
import { CommissionService } from '../../../services/commission.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// Models
import { CommissionType } from '../../../models/commission-type';
import { CommissionSalesPerson } from '../../../models/commission-sales-person';
import { CommissionTypeAssignments } from 'src/app/models/commission-type-assignments';
import { SettingsCommissionModalComponent } from '../settings-commission-modal/settings-commission-modal.component';
import { CommissionSalesPersonType } from '../../../models/commission-sales-person-type';
import { CommissionRegion } from '../../../models/commission-region';
// Enums
import {
  ModalSettingConfig,
  TypeSetting,
  TypeSettingAction,
} from 'src/app/models/commission-general';
import { CommissionRegionComponent } from '../commission-region/commission-region.component';

@Component({
  selector: 'app-settings-commissions',
  templateUrl: './settings-commissions.component.html',
  styleUrls: ['./settings-commissions.component.less'],
})
export class SettingsCommissionsComponent {
  loading = false;
  settingConfig = TypeSetting;
  actionConfig = TypeSettingAction;

  @ViewChild(MatTable) tblCommissionType: MatTable<CommissionType>;
  commissionTypeDataSource = new MatTableDataSource<CommissionType>();

  @ViewChild(MatTable) tblRegions: MatTable<CommissionRegion>;
  regionDataSource = new MatTableDataSource<CommissionRegion>();

  @ViewChild(MatTable) tblSalesRep: MatTable<CommissionSalesPerson>;
  salesRepDataSource = new MatTableDataSource<CommissionSalesPerson>();

  @ViewChild(MatTable) tblCommissionTypeAssignments: MatTable<CommissionTypeAssignments>;
  commissionTypeAssignmentsDataSource = new MatTableDataSource<CommissionTypeAssignments>();

  @ViewChild(MatTable) tblCommissionSalesPersonType: MatTable<CommissionSalesPersonType>;
  commissionSalesPersonTypeDataSource = new MatTableDataSource<CommissionSalesPersonType>();

  displayedColumnsCommissionType: string[] = [
    'name',
    'commissionAmount',
    'commissionAmountType',
    'description',
    'actions',
  ];
  displayedColumnsRegions: string[] = [
    'region',
    'noStates',
    'isActive',
    'actions',
  ];
  displayedColumnsSalesRep: string[] = [
    'salesPersonName',
    'emailAddress',
    'cellPhone',
    'salesPersonType',
    'region',
    'isActive',
    'actions',
  ];
  displayedColumnsPersonType: string[] = [
    'name',
    'description',
    'actions',
  ];
  displayedColumnsAssignments: string[] = [
    'commissionAmountType',
    'salesPersonName',
    'commissionDescription',
    'commissionAmount',
    'actions',
  ];

  constructor(
    private commissionService: CommissionService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog
  ) { }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  getCommissionTypes(): Promise<void> {
    this.showLoading();
    return new Promise<void>((resolve, reject) => {
      this.commissionService.getAllCommissionType().subscribe(
        (resultCommissionTypes: CommissionType[]) =>
          (this.commissionTypeDataSource.data = resultCommissionTypes),
        (err) => {
          console.error(err);
          this.snackBarService.showError('Error getting commission types.');
          reject();
        },
        () => {
          this.hideLoading();
          resolve();
        }
      );
    });
  }

  getDataCommissionTypes(): CommissionType[] {
    return this.commissionTypeDataSource.connect().value;
  }

  getCommissionTypeDataSource(): MatTableDataSource<CommissionType> {
    return this.commissionTypeDataSource;
  }

  async onDataCommissionTypesOpened() {
    if (this.getDataCommissionTypes().length === 0) {
      await this.getCommissionTypes();
    }
  }

  async getRegions(): Promise<void> {
    this.showLoading();
    await this.commissionService.getCommissionRegions()
      .toPromise()
      .then(result => this.regionDataSource.data = result)
      .catch(err => {
        console.error(err);
        this.snackBarService.showError('Error getting sales reps.');
      });
    this.hideLoading();
  }

  getDataRegion(): CommissionRegion[] {
    return this.regionDataSource.connect().value;
  }

  getRegionsDataSource(): MatTableDataSource<CommissionRegion> {
    return this.regionDataSource;
  }

  async onRegionsOpened() {
    if (this.getDataRegion().length === 0) {
      await this.getRegions();
    }
  }

  openRegionModal(typeAction: TypeSettingAction, row: CommissionRegion) {
    this.dialog.open(CommissionRegionComponent, {
      width: '40%',
      disableClose: true,
      data: {
        settingAction: typeAction,
        value: row,
      },
    })
      .afterClosed()
      .toPromise()
      .then(( async (response: ModalSettingConfig) => {
        if (response && response.result) {
          switch (response.result) {
            case 1:
              this.snackBarService.showSuccess(
                response.settingAction === 1
                  ? 'The region was saved successfully.'
                  : response.settingAction === 2
                    ? 'The region was updated successfully'
                    : 'The commission type was deleted successfully'
              );
              await this.getRegions();
              break;
            case 2:
              this.snackBarService.showError('An error occurred during the selected operation.');
              break;
            case -1:
              if (response.value) {
                const idx = this.regionDataSource.data.findIndex(f => f.regionId === (response.value as CommissionRegion).regionId);
                if (idx !== -1) {
                  this.regionDataSource.data[idx] = response.value;
                  this.tblRegions.renderRows();
                }
              }
              break;
          }

        }
      }));
  }

  getSalesReps(): Promise<void> {
    this.showLoading();
    return new Promise<void>((resolve, reject) => {
      this.commissionService.getCommissionAllSalesPerson().subscribe(
        (resultCommissionSalesPerson: CommissionSalesPerson[]) =>
          (this.salesRepDataSource.data = resultCommissionSalesPerson),
        (err) => {
          console.error(err);
          this.snackBarService.showError('Error getting sales reps.');
          reject();
        },
        () => {
          this.hideLoading();
          resolve();
        }
      );
    });
  }

  getDataSalesReps(): CommissionSalesPerson[] {
    return this.salesRepDataSource.connect().value;
  }

  getSalesRepDataSource(): MatTableDataSource<CommissionSalesPerson> {
    return this.salesRepDataSource;
  }

  async onSalesRepOpened() {
    if (this.getDataSalesReps().length === 0) {
      await this.getSalesReps();
    }
  }

  getCommissionTypeAssignments(): Promise<void> {
    this.showLoading();
    return new Promise<void>((resolve, reject) => {
      this.commissionService.getAllCommissionTypeAssignments().subscribe(
        (resultCommissionTypeAssignments: CommissionTypeAssignments[]) =>
        (this.commissionTypeAssignmentsDataSource.data =
          resultCommissionTypeAssignments),
        (err) => {
          console.error(err);
          this.snackBarService.showError(
            'Error getting commission type assignments.'
          );
          reject();
        },
        () => {
          this.hideLoading();
          resolve();
        }
      );
    });
  }

  getDataCommissionTypeAssignments(): CommissionTypeAssignments[] {
    return this.commissionTypeAssignmentsDataSource.connect().value;
  }

  getCommissionTypeAssignmentsDataSource(): MatTableDataSource<CommissionTypeAssignments> {
    return this.commissionTypeAssignmentsDataSource;
  }

  async onCommissionTypeAssignmentsOpened() {
    if (this.getDataCommissionTypeAssignments().length === 0) {
      await this.getCommissionTypeAssignments();
    }
  }

  getSalesPersonTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.commissionService.getAllSalesPersonsType().subscribe(
        (resultSalesPersonType: CommissionSalesPersonType[]) =>
        (this.commissionSalesPersonTypeDataSource.data =
          resultSalesPersonType),
        (err) => {
          console.error(err);
          this.snackBarService.showError('Error getting sales person types.');
          reject();
        },
        () => {
          this.hideLoading();
          resolve();
        }
      );
    });
  }

  getDataSalesPersonTypes(): CommissionSalesPersonType[] {
    return this.commissionSalesPersonTypeDataSource.connect().value;
  }

  getSalesPersonTypesDataSource(): MatTableDataSource<CommissionSalesPersonType> {
    return this.commissionSalesPersonTypeDataSource;
  }

  async onSalesPersonTypesOpened() {
    if (this.getDataSalesPersonTypes().length === 0) {
      await this.getSalesPersonTypes();
    }
  }

  openSettingsModal(
    typeSetting: TypeSetting,
    typeAction: TypeSettingAction,
    row: any
  ) {
    this.dialog
      .open(SettingsCommissionModalComponent, {
        width: '40%',
        disableClose: true,
        data: {
          settingType: typeSetting,
          settingAction: typeAction,
          value: row,
        },
      })
      .afterClosed()
      .subscribe((result: ModalSettingConfig) => {
        if (result) {
          if (result.result === 2) {
            this.snackBarService.showError(
              'An error occurred during the selected operation.'
            );
          } else if (result.result !== -1) {
            switch (result.settingType) {
              case 1:
                this.snackBarService.showSuccess(
                  result.settingAction === 1
                    ? 'The commission type was saved successfully.'
                    : result.settingAction === 2
                      ? 'The commission type was updated successfully'
                      : 'The commission type was deleted successfully'
                );
                break;
              case 2:
                this.snackBarService.showSuccess(
                  result.settingAction === 1
                    ? 'The sale rep was saved successfully.'
                    : result.settingAction === 2
                      ? 'The sale rep was updated successfully'
                      : result.settingAction === 3
                        ? 'The sale rep was deleted successfully'
                        : 'The sale rep was disabled successfully'
                );
                break;
              case 3:
                this.snackBarService.showSuccess(
                  result.settingAction === 1
                    ? 'The sale person type was saved successfully.'
                    : result.settingAction === 2
                      ? 'The sale person type was updated successfully'
                      : 'The sale person type was deleted successfully'
                );
                break;
              case 4:
                this.snackBarService.showSuccess(
                  result.settingAction === 1
                    ? 'The Type Assignments was saved successfully.'
                    : result.settingAction === 2
                      ? 'The Type Assignments was updated successfully'
                      : 'The Type Assignments was deleted successfully'
                );
                break;
            }
            this.updateTableValues(typeSetting, typeAction, row, result.value);
          }
        } else {
          this.snackBarService.showError(
            'An error occurred during the selected operation.'
          );
        }
      });
  }

  private updateTableValues(
    typeSetting: TypeSetting,
    typeAction: TypeSettingAction,
    row: any,
    updateValue: any
  ): void {
    switch (typeSetting) {
      case 1:
        switch (typeAction) {
          case 1:
            this.commissionTypeDataSource.data.push(updateValue);
            break;
          case 2:
            const idx = this.commissionTypeDataSource.data.findIndex(
              (fi) => fi.id === (row as CommissionType).id
            );
            if (idx !== -1) {
              this.commissionTypeDataSource.data[idx] = updateValue;
            }
            break;
          case 2:
            break;
        }
        this.tblCommissionType.renderRows();
        break;
      case 2:
        switch (typeAction) {
          case 1:
            this.salesRepDataSource.data.push(updateValue);
            break;
          case 2:
            const idx = this.salesRepDataSource.data.findIndex(
              (fi) =>
                fi.salesPersonId ===
                (row as CommissionSalesPerson).salesPersonId
            );
            if (idx !== -1) {
              this.salesRepDataSource.data[idx] = updateValue;
            }
            break;
          case 3:
            break;
          case 4:
            const idxDisable = this.salesRepDataSource.data.findIndex(
              (fi) =>
                fi.salesPersonId ===
                (row as CommissionSalesPerson).salesPersonId
            );
            if (idxDisable !== -1) {
              this.salesRepDataSource.data.splice(idxDisable, 1);
            }
            break;
        }
        this.tblSalesRep.renderRows();
        break;
      case 3:
        switch (typeAction) {
          case 1:
            this.commissionSalesPersonTypeDataSource.data.push(updateValue);
            break;
          case 2:
            const idx = this.commissionSalesPersonTypeDataSource.data.findIndex(
              (fi) => fi.id === (row as CommissionSalesPersonType).id
            );
            if (idx !== -1) {
              this.commissionSalesPersonTypeDataSource.data[idx] = updateValue;
            }
            break;
          case 3:
            break;
        }
        this.tblCommissionSalesPersonType.renderRows();
        break;
      case 4:
        switch (typeAction) {
          case 1:
            this.commissionTypeAssignmentsDataSource.data.push(updateValue);
            break;
          case 2:
            const idx = this.commissionTypeAssignmentsDataSource.data.findIndex(
              (fi) =>
                fi.commissionTypeAssignmentsId ===
                (row as CommissionTypeAssignments).commissionTypeAssignmentsId
            );
            if (idx !== -1) {
              this.commissionTypeAssignmentsDataSource.data[idx] = updateValue;
            }
            break;
          case 3:
            break;
        }
        this.tblCommissionTypeAssignments.renderRows();
        break;
    }
  }
}
