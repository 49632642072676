<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Create Expedited Production Setting</h1>
  <hr />

  <div class="container">
    <form [formGroup]="createExpeditedProductionForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="Description"
          formControlName="description"
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('description')
              .hasError('required')
          "
          >Description is required</mat-error
        >
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          matInput
          type="number"
          placeholder="Minimum Fee"
          formControlName="minimumFee"
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('minimumFee').hasError('required')
          "
          >Minimum Fee is required</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('minimumFee').hasError('min')
          "
          >Minimum Fee cannot be negative</mat-error
        >
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          matInput
          type="string"
          placeholder="Item Number"
          formControlName="itemNumber"
          appUppercase
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('itemNumber').hasError('required')
          "
          >Item Number is required</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('itemNumber')
              .hasError('patternMismatch')
          "
          >Item number format is invalid</mat-error
        >
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          matInput
          type="number"
          placeholder="Fee Per Part"
          formControlName="feePerPart"
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('feePerPart').hasError('required')
          "
          >Fee Per Part is required</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('feePerPart').hasError('min')
          "
          >Fee Per Part cannot be negative</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm.get('feePerPart').hasError('max')
          "
          >Fee Per Part cannot be more than $100.00</mat-error
        >
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          matInput
          type="number"
          placeholder="Production Capacity Percentage"
          formControlName="productionCapacityPercentage"
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('productionCapacityPercentage')
              .hasError('required')
          "
          >Production Capacity Percentage is required</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('productionCapacityPercentage')
              .hasError('min')
          "
          >Production Capacity Percentage cannot be negative</mat-error
        >
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('productionCapacityPercentage')
              .hasError('max')
          "
          >Production Capacity Percentage cannot be more than 100%</mat-error
        >
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="Production Type"
          formControlName="productionType"
        />
        <mat-error
          *ngIf="
            createExpeditedProductionForm
              .get('productionType')
              .hasError('required')
          "
          >Production Type is required</mat-error
        >
      </mat-form-field>

      <div class="checkbox-container">
        <mat-checkbox formControlName="capacityCheck"
          >Capacity Check</mat-checkbox
        >
      </div>

      <div class="actions">
        <button mat-raised-button color="primary" type="submit">Create</button>
      </div>
    </form>
  </div>
</mat-card>
