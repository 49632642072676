<h1 class="mat-h1">
    Palletized Orders
</h1>
<hr />

<mat-accordion>
    <mat-expansion-panel expanded="true" class="">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Order Search
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Search" [(ngModel)]="filter" (keyup.enter)="search()">
                    </mat-form-field>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Search" (click)="search()">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z0 table" matSort #palletizedOrdersTbl="matSort"
    *ngIf="!loading" matSortActive="dateOrder" matSortDirection="desc">
    <ng-container matColumnDef="dateOrder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </th>
        <td mat-cell *matCellDef="let row"> {{ row.dateOrder | date}} </td>
    </ng-container>
    <ng-container matColumnDef="customerNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Number </th>
        <td mat-cell *matCellDef="let row"> {{ row.customerNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name</th>
        <td mat-cell *matCellDef="let row"> {{ row?.customerName }} </td>
    </ng-container>
    <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Location Name </th>
        <td mat-cell *matCellDef="let row"> {{ row?.locationName }} </td>
    </ng-container>
    <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Approved </th>
        <td mat-cell *matCellDef="let row">
            {{
            row?.approved !== -1
            ? row?.approved === 1 ? 'Yes' : 'No'
            : ""
            }}
        </td>
    </ng-container>
    <ng-container matColumnDef="pickupDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pickup Date </th>
        <td mat-cell *matCellDef="let row">
            <mat-form-field style="width: 70%">
                <input matInput [matDatepicker]="picker1" [(ngModel)]="row.pickupDate"
                    (dateChange)="onPickupDateChange(row.pickupDate, row.locationId, row.palletFormId)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                *ngIf="row.palletFormId">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="viewDetails(row.palletFormId)">
                    <span>View Details</span>
                </button>
                <button mat-menu-item (click)="viewUnits(row.palletizedOrders)">
                    <span>View Units</span>
                </button>
                <button mat-menu-item
                    (click)="openConfirmationModal(row.invoicingEmail, row.salesOrderNumber, row.customerId, row.palletFormId, row.orderId)"
                    *ngIf="row?.approved === -1 && !row?.isPerPartCustomer">
                    <span>Send Quote</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedHeader"></tr>
</table>

<div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
</div>