<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Expedited Production Setting Details</h1>
  <hr />

  <div class="actions">
    <a
      routerLink="/settings/expedited-production/edit/{{
        expeditedProductionSettings?.id
      }}"
      mat-raised-button
      >Edit Expedited Production Setting
    </a>
  </div>

  <div class="content">
    <ul>
      <ul>
        <li><strong>Id:</strong> {{ expeditedProductionSettings?.id }}</li>
        <li>
          <strong>Description:</strong>
          {{ expeditedProductionSettings?.description }}
        </li>
        <li>
          <strong>Minimum Fee:</strong>
          {{ expeditedProductionSettings?.minimumFee | currency }}
        </li>
        <li>
          <strong>Item Number:</strong>
          {{ expeditedProductionSettings?.itemNumber }}
        </li>

        <li>
          <strong>Fee Per Part:</strong>
          {{ expeditedProductionSettings?.feePerPart | currency }}
        </li>
        <li>
          <strong>Production Capacity Percentage:</strong>
          {{
            formatPercentage(
              expeditedProductionSettings?.productionCapacityPercentage
            )
          }}
        </li>
        <li>
          <strong>Production Type:</strong>
          {{ expeditedProductionSettings?.productionType }}
        </li>
        <li>
          <strong>Is Active:</strong>
          {{ expeditedProductionSettings?.isActive ? "Yes" : "No" }}
        </li>
        <li>
          <strong>Capacity Check:</strong>
          {{ expeditedProductionSettings?.capacityCheck ? "Yes" : "No" }}
        </li>
        <li>
          <strong>Created By User Id:</strong>
          {{ expeditedProductionSettings?.createdByUserId }}
        </li>
        <li>
          <strong>Date Created:</strong>
          {{ expeditedProductionSettings?.dateCreated | date }}
        </li>
        <li>
          <strong>Date Modified:</strong>
          {{ expeditedProductionSettings?.dateModified | date }}
        </li>
        <li>
          <strong>Modified By User Id:</strong>
          {{ expeditedProductionSettings?.modifiedByUserId }}
        </li>
      </ul>
    </ul>
  </div>
</mat-card>
