<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
			<button mat-mini-fab matTooltip="Back to clients" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
        <!-- <div fxflex="80" fxlayoutalign="left left" class="container-actions"></div> -->
    </div>
</div>

<mat-card>
    <h1 class="mat-h1">
        Communities for <strong>{{ titleCustomer }}</strong> 
    </h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}" (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filters communities
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Community" [formControl]="communityFilter">
                        </mat-form-field>
                    </div>
                    <div class="checkboxes">
                        <mat-checkbox [(ngModel)]="withSplitCommunities" matTooltip="Split communities">Split communities</mat-checkbox>
                    </div>
                </div>
                <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()"><mat-icon>filter_list</mat-icon></button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
<div class="reset-action-container" *ngIf="customersFiltersApplied">
    <button mat-raised-button (click)="clearCommunitiesFilters()">
  <mat-icon>clear</mat-icon> Reset Communities Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblCommissionCustomers *ngIf="!loading && getData().length > 0" [dataSource]="getCommissionCustomersDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="communityID">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let row"> {{ row.communityID}} </td>
        </ng-container>
        <ng-container matColumnDef="communityName">
            <th mat-header-cell *matHeaderCellDef> Community </th>
            <td mat-cell *matCellDef="let row"> {{ row.communityName}} </td>
        </ng-container>
        <ng-container matColumnDef="dateActive">
            <th mat-header-cell *matHeaderCellDef> Date Active </th>
            <td mat-cell *matCellDef="let row"> {{ row?.dateActive | date }} </td>
        </ng-container>
        <ng-container matColumnDef="lastOrderDate">
            <th mat-header-cell *matHeaderCellDef> Last Order Date </th>
            <td mat-cell *matCellDef="let row"> {{ row?.lastOrderDate | date }} </td>
        </ng-container>
        <ng-container matColumnDef="newSalesOverride">
            <th mat-header-cell *matHeaderCellDef> New Sales Override </th>
            <td mat-cell *matCellDef="let row"> {{ row.newSalesOverride === null ? '' : row.newSalesOverride | date}} </td>
        </ng-container>
        <ng-container matColumnDef="assignedReps">
            <th mat-header-cell *matHeaderCellDef> Assigned Reps </th>
            <td mat-cell *matCellDef="let row"> {{row?.assignedReps }} </td>
        </ng-container>
        <ng-container matColumnDef="splitType">
            <th mat-header-cell *matHeaderCellDef> Split Type </th>
            <td mat-cell *matCellDef="let row"> {{ row?.splitType }} </td>
        </ng-container>
        <ng-container matColumnDef="currentCommissionType">
            <th mat-header-cell *matHeaderCellDef> Current Commission Type </th>
            <td mat-cell *matCellDef="let row">{{ row?.currentCommissionType }}</td>
        </ng-container>
        <ng-container matColumnDef="leadSource">
            <th mat-header-cell *matHeaderCellDef> Lead Source </th>
            <td mat-cell *matCellDef="let row"> {{row.leadSource}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <a mat-icon-button matTooltip="Edit" matTooltipPosition="above" (click)="openCommunityModal(row)">
                    <mat-icon color="accent">edit</mat-icon>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>