import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ArchivedUnit } from 'src/app/models/archived-unit';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UnarchiveUnitConfirmationComponent } from './unarchive-unit-confirmation/unarchive-unit-confirmation.component';

@Component({
  selector: 'app-cl-archived-unit',
  templateUrl: './cl-archived-unit.component.html',
  styleUrls: ['./cl-archived-unit.component.less']
})
export class ClArchivedUnitComponent implements OnInit, AfterViewInit {

  public _customerInfoService: CustomerService;
  public archivedUnit: ArchivedUnit[] = [];
  public unitsToUnArchice: ArchivedUnit[] = [];
  public dataSource = new MatTableDataSource<ArchivedUnit>();

  public archivedUnitDisplayedColumns: string[] =
    [ 'id'
    ,'customerNumber'
    , 'apartmentNumber'
    , 'name'
    , 'unArchive'];

    formControl: any;

  constructor(public dialogRef: MatDialogRef<ClArchivedUnitComponent>,
    private customerInfoService: CustomerService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { units: ArchivedUnit[] }){
      this._customerInfoService = customerInfoService;


    this.dataSource.filterPredicate = ((data, filter) => {
      const a = !filter.word || data.apartmentNumber?.toLowerCase().includes(filter.word);
      const b = !filter.word || data.name?.toLowerCase().includes(filter.word);
      return a || b;
    }) as (PeriodicElement, string) => boolean;

    this.formControl = formBuilder.group({
      word: ''
    })

    this.formControl.valueChanges.subscribe(value => {
      const filter = {...value, name: value?.name?.trim().toLowerCase()} as string;
      this.dataSource.filter = filter;
    });
  }

  tblSort: MatSort;
  @ViewChild('tblSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.tblSort = ms;
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.tblSort;
  }

  ngOnInit() {
    this.archivedUnit = this.data.units;
    this.dataSource.data = this.archivedUnit;
  }

  closeWithOutSaving() {
    this.unitsToUnArchice = [];
    this.dialogRef.close();
  }

  addUnitToUnArchive(i: number, row: any, $event: any){
    if($event.checked){
      this.unitsToUnArchice.push(row);
    }else{
      this.unitsToUnArchice.splice(this.unitsToUnArchice.findIndex(item => item.id === row.id), 1)
    }
  }

  unArchiveSelectedUnits(){
    this.confirmUnArchivingModal();
  }

  confirmUnArchivingModal(){
    let archivedUnit = this.unitsToUnArchice;

    this.dialog
    .open(UnarchiveUnitConfirmationComponent, {
      width: '40%',
      height:'30%',
      disableClose: false,
      data: {
        archivedUnit
      },
    })
    .afterClosed()
    .subscribe((result: any) => {
      if(result.success){
        this.archivedUnit.forEach((value, index) =>{
          this.unitsToUnArchice.forEach((value2, index2) =>{
            if(value.id === value2.id){
              this.archivedUnit.splice(index,1);
              this.unitsToUnArchice.splice(index2,1);
            }
          });
        });
        this.archivedUnit = [...this.archivedUnit];
        this.unitsToUnArchice = [...this.unitsToUnArchice];
        this.dataSource.data = this.archivedUnit;
      }
      this.dialog.closeAll();
    });
  }

  closeXWithoutSaving(){
    this.unitsToUnArchice = [];
  }
}
