import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { CustomerAuthService } from './customer-auth.service';

@Injectable()
export class CustomerAuthInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public authService: CustomerAuthService,
    private snackBarService: SnackbarService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler,): Observable<HttpEvent<unknown>> {

  request = request.clone({ setHeaders: { Accept: 'application/json' } });

  if (!request.headers.has('Content-Type')) {
      request = request.clone({ setHeaders: { 'Content-Type': 'application/json' } });
  }

    const token = this.authService.getAccessToken();

    if (token) {
        request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

      return next.handle(request).pipe(tap(_ => { }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                  console.error(err);
                  this.snackBarService.showError('You are unauthorized. Please sign in.');
                  this.router.navigate(['/sign-in']);
              }

              if (err.status === 403) {
                  console.error(err);
                  this.snackBarService.showError('Forbidden. You don\'t have access to this section.');
                  this.router.navigate(['/forbidden']);
              }
          }
      }));
    }
  }

