import { Component, Input } from '@angular/core';
import { CustomerInfo } from 'src/app/models/customer-info';

@Component({
  selector: 'app-invoicing-info',
  templateUrl: './invoicing-info.component.html',
  styleUrls: ['./invoicing-info.component.less']
})
export class InvoicingInfoComponent {
  @Input() invInfo: CustomerInfo;
  public math = Math;

  constructor(){

  }
}
