<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h3 class="mat-h1">Manage Community</h3>
        <hr />
        <mat-accordion multi>
            <mat-expansion-panel expanded="true" class="filters-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Community
                    </mat-panel-title>
                    <mat-panel-description>
                        General information
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ul>
                    <li><strong>Id:</strong> {{communitySelect.communityID}}</li>
                    <li><strong>Community:</strong> {{communitySelect.communityName}}</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true" class="filters-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Edit information
                    </mat-panel-title>
                    <mat-panel-description>
                        Manage values for community
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="container">
                    <mat-form-field class="medium-width">
                        <input matInput #inputDate [matDatepicker]="pickerTo" [formControl]="newSaleOverrideDate" placeholder="New Sales Override" readonly (dateInput)="onDatePickerChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTo></mat-datepicker>
                        <button mat-button *ngIf="inputDate.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearNewSalesOverride()" matTooltip="Quit new sale date">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="medium-width">
                        <input matInput #inputOverride placeholder="Commission Rate Override" [formControl]="rateOverride" maxlength="5" (keypress)="decimalFilter($event)">
                        <mat-icon matSuffix *ngIf="inputOverride.value.length > 0">percent</mat-icon>
                        <mat-error *ngIf="rateOverride?.errors && rateOverride.hasError('max')"> The max value for rate is 100 </mat-error>
                        <mat-error *ngIf="rateOverride?.errors && rateOverride.hasError('min')"> The minimum value for rate is 1 </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true" class="filters-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Manager split
                    </mat-panel-title>
                    <mat-panel-description>
                        Add or edit split configuration
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="container">
                    <table mat-table #tblSalesSplits *ngIf="getData().length > 0" [dataSource]="getCommunitiesSalesSplitsDataSource()" class="mat-elevation-z0 table">
                        <ng-container matColumnDef="salesCode">
                            <th mat-header-cell *matHeaderCellDef class="header-cell-repcode"> Rep Code </th>
                            <td mat-cell *matCellDef="let row" class="header-cell-repcode"> {{row.salesCode}} </td>
                        </ng-container>
                        <ng-container matColumnDef="salesRepName">
                            <th mat-header-cell *matHeaderCellDef class="header-cell-repname"> Rep Name </th>
                            <td mat-cell *matCellDef="let row" class="header-cell-repname">
                                <mat-select [(ngModel)]="row.salesRepId" (selectionChange)="changeSalesPerson($event.value, row)" name="item">
                                    <mat-option [value]="0">- Pick Sale Rep -</mat-option>
                                    <mat-option *ngFor="let item of availableSalesPersons" [value]="item.salesPersonId">
                                        {{item.salesPersonCode}} - {{item.salesPersonName}}
                                    </mat-option>
                                </mat-select>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="percent">
                            <th mat-header-cell *matHeaderCellDef class="header-cell-percent"> Percent </th>
                            <td mat-cell *matCellDef="let row" class="header-cell-percent">
                                <input matInput placeholder="Percent" type="number" [(ngModel)]="row.percent" min="1.00" max="99.99" maxlength="5">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="header-cell-actions"> Actions </th>
                            <td mat-cell *matCellDef="let row; let i = index" class="header-cell-actions">
                                <a mat-icon-button matTooltip="Delete" matTooltipPosition="above" (click)="removeRepSale(i, row)">
                                    <mat-icon color="accent">delete</mat-icon>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="leyend-error">
                            <span class="status" *ngIf="showWarnMsj">{{ warnMsj }}</span>
                        </div>
                        <div class="filters">
                            <button mat-mini-fab matTooltip="Add Sale Rep" (click)="addNewRepSale()"><mat-icon>add</mat-icon></button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div class="loading" *ngIf="loading" >
                <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading...</span>
            </div>
        </div>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()">Cancel</button>
    <button mat-raised-button color="accent" class="btn-modal" [disabled]="!allowSaveSplits()" (click)="saveSalesSplits()">Save</button>
</mat-dialog-actions>