import { Component } from '@angular/core';

@Component({
  selector: 'app-details-strategic-expedited',
  templateUrl: './details-strategic-expedited.component.html',
  styleUrls: ['./details-strategic-expedited.component.less']
})
export class DetailsStrategicExpeditedComponent {

}
