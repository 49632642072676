import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ReasonGroup } from 'src/app/models/reason-group';
import { ReasonGroupsService } from '../../../services/reason-groups.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReasonPrevent } from 'src/app/models/reason-code-config-modal';
import { ReasonCodeConfigModal, ReasonModalState } from '../../../models/reason-code-config-modal';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ReasonGroupsModalComponent } from '../reason-groups-modal/reason-groups-modal.component';

@Component({
  selector: 'app-list-reason-groups',
  templateUrl: './list-reason-groups.component.html',
  styleUrls: ['./list-reason-groups.component.less']
})
export class ListReasonGroupsComponent implements OnInit {
  loading = false;
  groupFilterApply = false;
  formFilter: FormGroup;

  @ViewChild(MatTable) tblGroups: MatTable<ReasonGroup>;
  reasonGroupsDataSource = new MatTableDataSource<ReasonGroup>();
  filterGroupsDataSource = new MatTableDataSource<ReasonGroup>();
  displayedColumns: string[] = [
    'id',
    'groupName',
    'description',
    'createdBy',
    'created',
    'actions'
  ];

  constructor(
    private reasonGroupsService: ReasonGroupsService,
    private snackService: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.createGroupForm();
    this.getReasonGroupsAsync();
  }

  private createGroupForm() {
    this.formFilter = this.fb.group({
      group: new FormControl(''),
      created: new FormControl(''),
      description: new FormControl('')
    });
  }

  async getReasonGroupsAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.reasonGroupsService.getReasonGroups().subscribe(
          (response: ReasonGroup[]) => this.reasonGroupsDataSource.data = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the categories.');
      }
    }).then(() => this.loading = false);
  }

  getGroupDataSource(): MatTableDataSource<ReasonGroup> {
    return this.groupFilterApply ? this.filterGroupsDataSource : this.reasonGroupsDataSource;
  }

  getData(): ReasonGroup[] {
    return this.groupFilterApply ? this.filterGroupsDataSource.connect().value : this.reasonGroupsDataSource.connect().value;
  }

  applyFilters() {
    this.filterGroupsDataSource.data = this.reasonGroupsDataSource.data;

    const group = this.formFilter.get('group');
    const description = this.formFilter.get('description');
    const created = this.formFilter.get('created');

    if (group && group.value) {
      this.filterGroupsDataSource.data = this.filterByGroup();
      this.groupFilterApply = true;
    }

    if (description && description.value) {
      this.filterGroupsDataSource.data = this.filterByDescription();
      this.groupFilterApply = true;
    }
    
    if (created && created.value) {
      this.filterGroupsDataSource.data = this.filterByCreatedBy();
      this.groupFilterApply = true;
    }

  }

  private filterByGroup(): ReasonGroup[] {
    return this.filterGroupsDataSource.data.filter((r) => {
      if (r.groupName !== null) {
        return r.groupName
          .toLowerCase()
          .includes(this.formFilter.get('group').value.trim().toLowerCase());
      }
    });
  }

  private filterByDescription(): ReasonGroup[] {
    return this.filterGroupsDataSource.data.filter((r) => {
      if (r.description !== null) {
        return r.description
          .toLowerCase()
          .includes(this.formFilter.get('description').value.trim().toLowerCase());
      }
    });
  }

  private filterByCreatedBy(): ReasonGroup[] {
    return this.filterGroupsDataSource.data.filter((r) => {
      if (r.createdBy !== null) {
        return r.createdBy
          .toLowerCase()
          .includes(this.formFilter.get('created').value.trim().toLowerCase());
      }
    });
  }

  clearFilters() {
    this.formFilter.setValue({
      group: '',
      created: '',
      description: ''
    });

    this.groupFilterApply = false;
  }

  editGroup(group: ReasonGroup) {
    if (group) {
      localStorage.setItem(`group-${group.id}`, JSON.stringify(group));
      this.router.navigate([`reason-codes/group-edit/${group.id}`]);
    }
  }

  disableGroup(group: ReasonGroup) {
    this.dialog.open(ReasonGroupsModalComponent, {
      width: '40%',
      disableClose: true,
      data: {
        title: `Delete Group / ${group.groupName.toUpperCase()}`,
        message: 'The Delete action cannot be undone, please note that once this action is executed.',
        typePrevent: ReasonPrevent.category,
        payload: group
      }
    }).afterClosed().subscribe((result: ReasonCodeConfigModal) => {
      switch (result.state) {
        case ReasonModalState.success:
          this.snackService.showSuccess('Group delete is successfully');
          const idx = this.reasonGroupsDataSource.data.findIndex(fi => fi.id === group.id);
          if (idx !== -1) {
              this.reasonGroupsDataSource.data.splice(idx, 1);
              this.tblGroups.renderRows();
              if (this.groupFilterApply) {
                this.applyFilters();
              }
            }
          break;
        case ReasonModalState.error:
          this.snackService.showError('An error occurred during the delete operation.');
          break;
      }
    });

  }
}
