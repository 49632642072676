import { Component, OnInit } from '@angular/core';
import { GlobalSetting } from 'src/app/models/global-setting';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-setting-details',
  templateUrl: './setting-details.component.html',
  styleUrls: ['./setting-details.component.less']
})
export class SettingDetailsComponent implements OnInit {

  globalSetting: GlobalSetting;

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getGlobalSetting();
  }

  getGlobalSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getSetting(id).subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting global setting details.');
      });
  }

}
