import { Component, OnInit, Inject, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ProductionLocation } from '../../models/production-location';
import { Color } from '../../models/color';
import { DoorStyle } from '../../models/door-style';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { TrainsService } from '../../services/trains.service';
import { Train } from '../../models/train';
import { AuthService } from '../../services/auth.service';
import { EditTrainComponent } from './edit-train/edit-train.component';
import { RdbFilesComponent } from './rdb-files/rdb-files.component';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

const INVALID_LOCATION_ID = -1;

@Component({
  selector: 'app-miter-saw',
  templateUrl: './miter-saw.component.html',
  styleUrls: ['./miter-saw.component.less']
})
export class MiterSawComponent implements OnInit, AfterViewInit {

  loading = false;
  filtersApplied = false;
  fullScreenMode = false;
  poNumberFilter = new UntypedFormControl();
  trainNumberFilter = new UntypedFormControl();
  productionDateFilter = new UntypedFormControl();
  colorsFilter = new UntypedFormControl();
  doorStyleFilter = new UntypedFormControl();

  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  machine = 'No IP address to show.';
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  displayedColumns: string[] = ['id', 'productionOrderNumber', 'trainNumber', 'productionDate', 'color', 'doorStyle', 'files', 'action'];

  trainsDataSource = new MatTableDataSource<Train>();
  filteredTrainsDataSource = new MatTableDataSource<Train>();

  trainsSort: MatSort;

  @ViewChild('trainsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.trainsSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any,
    private snackBarService: SnackbarService,
    private trainsService: TrainsService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    this.fullScreenMode = this.getFullScreenMode();
    this.getMachine();
    this.getColors();
    this.getDoorStyles();
    await this.getProductionLocations();
    await this.getTrains();
  }

  ngAfterViewInit() {
    // sort options
    this.trainsDataSource.sort = this.trainsSort;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    this.getTrains();
  }

  filterByPONumber(): Train[] {
    return this.filteredTrainsDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(this.poNumberFilter.value.toLowerCase()));
  }

  filterByTrainNumber(): Train[] {
    return this.filteredTrainsDataSource.data.filter(u => u.trainNumber && u.trainNumber.toString().includes(this.trainNumberFilter.value.toString().trim()));
  }

  filterByProductionDate(): Train[] {
    return this.filteredTrainsDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  getMachine() {
    const machine = this.authService.getMachine();

    if (machine) {
      this.machine = machine;
    } else {
      this.snackBarService.showError('Error getting machine. Please contact your supervisor.');
    }
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  filterByColors(): Train[] {
    const colors: string[] = this.colorsFilter.value;
    const trains: Train[] = [];
    for (const color of colors) {
      const r = this.filteredTrainsDataSource.data.filter(u => u.color && u.color.toLowerCase() === color.toLowerCase());
      trains.push(...r);
    }
    return trains;
  }

  filterByDoorStyle(): Train[] {
    return this.filteredTrainsDataSource.data.filter(u => u.doorStyle && u.doorStyle.toLowerCase() === this.doorStyleFilter.value.toLowerCase());
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  getTrainsDataSource(): MatTableDataSource<Train> {
    return this.filtersApplied ? this.filteredTrainsDataSource : this.trainsDataSource;
  }

  hasTrains() {
    return this.getTrainsDataSource().data.length > 0;
  }

  applyFilters() {
    this.filteredTrainsDataSource.data = this.trainsDataSource.data;

    if (this.poNumberFilter.valid && this.poNumberFilter.value) {
      this.filteredTrainsDataSource.data = this.filterByPONumber();
      this.filtersApplied = true;
    }
    if (this.trainNumberFilter.valid && this.trainNumberFilter.value) {
      this.filteredTrainsDataSource.data = this.filterByTrainNumber();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredTrainsDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.colorsFilter.valid && this.colorsFilter.value && this.colorsFilter.value.length) {
      this.filteredTrainsDataSource.data = this.filterByColors();
      this.filtersApplied = true;
    }
    if (this.doorStyleFilter.valid && this.doorStyleFilter.value) {
      this.filteredTrainsDataSource.data = this.filterByDoorStyle();
      this.filtersApplied = true;
    }
  }

  clearFilters() {
    this.filtersApplied = false;
    this.poNumberFilter.setValue('');
    this.trainNumberFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.colorsFilter.setValue('');
    this.doorStyleFilter.setValue('');
    this.filteredTrainsDataSource.data = [];
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  clearDataSource() {
    this.trainsDataSource.data = [];
    this.filteredTrainsDataSource.data = [];
  }

  getTrains(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.showLoading();
      this.clearDataSource();
      if (this.productionLocationId !== INVALID_LOCATION_ID) {
        this.trainsService.getTrains(this.productionLocationId).subscribe((trains: Train[]) => {
          this.trainsDataSource.data = trains;
          resolve();
        }, err => {
          console.error(err);
          this.hideLoading();
          this.snackBarService.showError('Error getting trains.');
          reject();
        }, () => {
          this.hideLoading();
        });
      } else {
        this.snackBarService.showError('Please select a production location.');
        reject();
      }
    });
  }

  openEditTrain(id: number) {
    const train = this.trainsDataSource.data.find(t => t.id === id);

    if (train) {
      const dialogRef = this.dialog.open(EditTrainComponent, {
        width: '620px',
        data: { train }
      });

      // eslint-disable-next-line @typescript-eslint/no-shadow
      dialogRef.afterClosed().subscribe((train: Train) => {
        if (train) {
          this.getTrains();
          this.snackBarService.showSuccess('The train was updated successfully.');
        }
      });
    }
  }

  openRDBFiles(id: number) {
    const train = this.trainsDataSource.data.find(t => t.id === id);
    if (train) {
      const dialogRef = this.dialog.open(RdbFilesComponent, {
        width: '940px',
        data: { train }
      });

      // eslint-disable-next-line @typescript-eslint/no-shadow
      dialogRef.afterClosed().subscribe((train: Train) => {
        if (train) {
          this.getTrains();
        }
      });
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

}
