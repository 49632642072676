import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ArchivedUnit } from 'src/app/models/archived-unit';
import { ArchivedLocation } from 'src/app/models/archived-location';
import { CustomerInfo } from 'src/app/models/customer-info';
import { ClArchivedUnitComponent } from '../cl-archived-unit/cl-archived-unit.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClArchivedLocationComponent } from '../cl-archived-location/cl-archived-location.component';

@Component({
  selector: 'app-cl-tab-customer-info',
  templateUrl: './cl-tab-customer-info.component.html',
  styleUrls: ['./cl-tab-customer-info.component.less']
})
export class ClTabCustomerInfoComponent implements OnChanges {

  @Input()info: CustomerInfo;

  public archivedUnit: ArchivedUnit[] = [];
  public archivedLocation: ArchivedLocation[] = [];
  public _customerInfoService: CustomerService;
  public math = Math;
  public loadingCustomerLogins: Boolean = false;
  public company: string = "";
  public inputValue: any;

  constructor(public dialog: MatDialog,
    customerInfoService: CustomerService,
    private snackBarService: SnackbarService,){
      this._customerInfoService = customerInfoService;
  }
 ngOnChanges(changes: SimpleChanges){
    this.info = changes.info.currentValue;
    this.loadingCustomerLogins = true;
    this._customerInfoService.getArchivedUnit(this.info?.customerNumber).subscribe((result: ArchivedUnit[]) => {
      this.archivedUnit = result;
      this.company = this.info.company;

      this._customerInfoService.getArchivedLocation(this.info?.customerNumber).subscribe((locations: ArchivedLocation[]) => {
        this.archivedLocation = locations;
      });
    },
      err => {
      console.error(err);
      this.loadingCustomerLogins = false;
      this.snackBarService.showError('Error getting Archived Unit(s).');
    })
  }

  archivedUnitDetailModal(){
    let units = this.archivedUnit;
    this.dialog.open(ClArchivedUnitComponent, {
      width: '50%',
      disableClose: false,
      data: {
        units
      }
    })
    .afterClosed()
    .subscribe((result: any) => {

    });
  }

  archivedLocationDetailModal(){
    let locations = this.archivedLocation;
  
    this.dialog.open(ClArchivedLocationComponent, {
      width: '50%',
      disableClose: false,
      data: {
        locations
      }
    })
    .afterClosed()
    .subscribe((result: any) => {

    });
  }

}
