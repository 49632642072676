<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Change Password
  </h1>
  <hr />

  <p><strong>UserName:</strong> {{account?.userName}}</p>
  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="changePasswordForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Current Password" type="password" formControlName="currentPassword" required>
        <mat-error *ngIf="changePasswordForm.controls.currentPassword.invalid">{{getCurrentPasswordErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="New Password" type="password" formControlName="newPassword" required>
        <mat-error *ngIf="changePasswordForm.controls.newPassword.invalid">{{getNewPasswordErrorMessage()}}</mat-error>
      </mat-form-field>

      <button mat-raised-button color="accent">Change</button>
    </form>
  </div>
</mat-card>
