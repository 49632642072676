import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
// Angular
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
// Models
import { CommissionCustomer } from '../../../models/commission-customer';
import { CommissionSalesPerson } from '../../../models/commission-sales-person';
// Services
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CommissionService } from 'src/app/services/commission.service';
import { AuthService } from 'src/app/services/auth.service';


interface CustomerSettingsModal {
  selection: CommissionCustomer;
  customers: CommissionCustomer[];
}

@Component({
  selector: 'app-customer-modal-commissions',
  templateUrl: './customer-modal-commissions.component.html',
  styleUrls: ['./customer-modal-commissions.component.less'],
})
export class CustomerModalCommissionsComponent implements OnInit, AfterViewInit {
  loading = false;
  hideCustomerGrid = true;
  hasExpandedCustomer = false;
  salesPersons: CommissionSalesPerson[] = [];
  customer: CommissionCustomer;


  customerDataSource = new MatTableDataSource<CommissionCustomer>();
  displayedColumns: string[] = ['number', 'name', 'activateDate'];
  dateActiveSort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dateActiveSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.dateActiveSort = ms;
    this.ngAfterViewInit();
  }

  expandedCustomerDate: Date;
  expandedCustomer = new UntypedFormControl();
  rateOverride = new FormControl<number | null >(null, [
    Validators.min(1),
    Validators.max(100)
  ]);
  salePerson = new UntypedFormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomerSettingsModal,
    public dialogRef: MatDialogRef<CustomerModalCommissionsComponent>,
    private snackBarService: SnackbarService,
    private commissionService: CommissionService,
    private authService: AuthService
  ) {
    if (this.data.customers) {
      this.customerDataSource.data = this.data.customers.sort((a: CommissionCustomer, b: CommissionCustomer) => {
        if (b?.activateDate > a?.activateDate) { return -1; }
        return 0;
      });
    } else {
      this.snackBarService.showInfo(`Don't have any customer for set Expanded Customer Link`);
    }

    this.customer = this.data.selection ?? this.data.selection;
  }

  ngAfterViewInit(): void {
    this.customerDataSource.paginator = this.paginator;
    this.customerDataSource.sort = this.dateActiveSort;
  }

  async ngOnInit(): Promise<void> {
    await this.getAllSalesPerson();
    this.setInitialValuesControls();
  }

  async getAllSalesPerson(): Promise<void> {
    this.loading = true;
    await this.commissionService.getCommissionAllSalesPerson()
      .toPromise()
      .then((result: CommissionSalesPerson[]) => this.salesPersons = result)
      .catch(err => {
        console.error(err);
        this.snackBarService.showError('Error getting sales persons.');
      })
      .finally(() => this.loading = false);
  }

  setInitialValuesControls() {
    this.rateOverride.valueChanges.subscribe((value: any) => {
      if (value !== null && value !== '' && value >= 0) {
        this.expandedCustomer.disable();
        this.hideCustomerGrid = true;
      } else {
        this.expandedCustomer.enable();
      }
    });


    if (this.customer.salesPersonId) {
      if (this.salesPersons.filter(f => f.salesPersonId === this.customer.salesPersonId).length !== 0) {
        this.salePerson.setValue(this.customer.salesPersonId);
      } else {
        this.salePerson.setValue(0);
      }
    } else {
      this.salePerson.setValue(0);
    }

    if (this.customer.expandedFromCustomerNumber) {
      this.expandedCustomer.setValue(this.customer.expandedFromCustomerNumber);
      this.hasExpandedCustomer = true;
      this.rateOverride.disable();
    } else {
      this.expandedCustomer.setValue('');
    }

    if (this.customer.commissionRateOverride && this.customer.commissionRateOverride !== null) {
      this.rateOverride.setValue(this.customer.commissionRateOverride);
      this.expandedCustomer.disable();
    } else {
      this.rateOverride.setValue(null);
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
        event.preventDefault();
    }
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customerDataSource.filter = filterValue.trim().toLowerCase();

    if (this.customerDataSource.paginator) {
      this.customerDataSource.paginator.firstPage();
    }
  }

  getCustomerList(): MatTableDataSource<CommissionCustomer> {
    return this.customerDataSource;
  }

  getExpandedCustomer(selection: CommissionCustomer) {
    this.expandedCustomer.setValue(selection.number);
    this.expandedCustomerDate = selection.expandedActiveDate;
    this.rateOverride.disable();
    if (this.expandedCustomer.value) {
      this.hideCustomerGrid = true;
    }
  }

  validateHasCustomer(): boolean {
    return this.customerDataSource.connect().value.length !== 0;
  }

  closeWithOutSaving() {
    this.dialogRef.close();
  }

  clearCustomerLink() {
    this.expandedCustomer.setValue('');
    this.rateOverride.enable();
    this.hideCustomerGrid = false;
  }

  async UpdateCustomer() {
    this.loading = true;
    this.customer.assignedRep = this.salesPersons.find(f => f.salesPersonId === this.salePerson.value).salesPersonName;
    this.customer.salesPersonId = this.salePerson.value;
    this.customer.commissionRateOverride = this.rateOverride.value;
    this.customer.expandedFromCustomerNumber = this.expandedCustomer.value;
    this.customer.expandedActiveDate = this.expandedCustomerDate;
    this.customer.modifiedUser = this.authService.getAccount().userName;

    await this.commissionService.updateCommissionCustomer(this.customer)
      .toPromise()
      .then((response: CommissionCustomer) => this.dialogRef.close({
        state: 'ok',
        result: response
      }))
      .catch((err) => {
        console.error(err);
        this.dialogRef.close({ state: 'error' });
      })
      .finally(() => this.loading = false)
  }
}
