export enum ProductionStatus {
  NotScheduled = 0,
  ExportedToProduction = 1,
  OptiCutProcessingCompleted = 2,
  RouterCimProcessed = 3,
  ReadyForGiben = 4,
  StartPanelsCut = 5,
  PanelsCut = 6,
  StartMoldingsCut = 7,
  AssemblyStarted = 8,
  QC1Complete = 9,
  FinalQC = 10,
  Packaging = 11,
  PackagingComplete = 12,
  Shipped = 13,
  Invoiced = 14,
  AssemblyComplete = 15,
}
