<h1 class="mat-h1" style="color: #81bb40;">
  Customer Lookup
</h1>
<hr />
  <mat-accordion>
    <mat-expansion-panel expanded="true">
      <div class="actions">
      <mat-form-field>
        <mat-label>Begin Date</mat-label>
        <input matInput [matDatepicker]="beginPicker" [(ngModel)]="beginDate">
        <mat-datepicker-toggle matSuffix [for]="beginPicker"></mat-datepicker-toggle>
        <mat-datepicker #beginPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [max]="maxDate" (dateChange)="onEndDateChange()">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      </div>
        <div>
            <mat-form-field class="full-width" style="bottom: 20px">
              <button mat-icon-button color="accent" (click)="onEnter(inputValue)" aria-label="small button" style="float: left; width: fit-content;">
                <mat-icon>search</mat-icon>
                   <mat-label style="color: gray;">Customer Lookup</mat-label>
              </button>
                <input class="input"
                        type="text"
                        aria-label="Number"
                        matInput
                        [matAutocomplete]="auto"
                        (keyup.enter)="onEnter(inputValue)"
                        [(ngModel)]="inputValue">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (onSelectionChange)="onSelect($event, i, info.id)" 
                                *ngFor="let info of _customerInfo; let i = index" 
                                [value]="inputValue">
                    {{info.customerNumber}} - {{info.company }} - {{info.contactName }} - {{info.emailAddress }} - <span style="color:#81bb40; font-weight:bold;">Match Type: {{info.matchType}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div>
              <h1 class="mat-h1" style="color: #81bb40;">
                <span>{{company}} </span> <span>{{customerNumber}}</span>
              </h1>
            </div>
        </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="loading">
    <div  class="loading"  *ngIf="_customerInfo.length === 0 && !loadingCustomerInfo">
      <span>No Customer info was found.</span>
    </div>
    <div class="loading" >

    </div>
  </div>
  <div>
      <div class="loading" *ngIf="loadingCustomerInfo">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Loading customer info...</span>
      </div>
  </div>
<!-- <mat-card class="mat-elevation-z2"> -->
  <div>
    <mat-tab-group mat-align-tabs="right" [(selectedIndex)]="tabIndex"  mat-stretch-tabs>
      <mat-tab (click)="changeTab()" label="Customer Info" *ngIf="_selectedCustomer">
        <ng-template matTabContent>
          <app-cl-tab-customer-info 
            [info]="_selectedCustomer">
          </app-cl-tab-customer-info>
        </ng-template>
      </mat-tab>
      <mat-tab (click)="changeTab()" label="Order History" *ngIf="customerId">
        <ng-template matTabContent>
          <app-cl-tab-customer-order-history
            [customerId]="customerId">
          </app-cl-tab-customer-order-history>
        </ng-template>
      </mat-tab>
      <mat-tab (click)="changeTab()" label="Customer Logins" *ngIf="customerId">
        <ng-template matTabContent>
          <app-cl-tab-customer-logins
            [customerId]="customerId">
          </app-cl-tab-customer-logins>
        </ng-template>
      </mat-tab>
      <mat-tab (click)="changeTab()" label="Quotes" *ngIf="customerNumber">
        <ng-template matTabContent>
          <app-qwikquote-search
            [customerNumber]="customerNumber">
          </app-qwikquote-search>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
