import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '../../../models/router';
import { RoutersService } from '../../../services/routers.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-router-details',
  templateUrl: './router-details.component.html',
  styleUrls: ['./router-details.component.less']
})
export class RouterDetailsComponent implements OnInit {
  router: Router;

  constructor(
    private route: ActivatedRoute,
    private routersService: RoutersService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getRouter();
  }

  getRouter(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routersService.getRouter(id).subscribe((router: Router) => {
      this.router = router;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router details.');
      });
  }
}
