import { Component } from '@angular/core';
import { BaseForm } from '../../../forms/base-form';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductionLocationsService } from '../../../services/production-locations.service';
import { ProductionLocation } from '../../../models/production-location';

@Component({
  selector: 'app-create-production-location',
  templateUrl: './create-production-location.component.html',
  styleUrls: ['./create-production-location.component.less']
})
export class CreateProductionLocationComponent extends BaseForm {

  minWipHours = 0;
  maxWipHours = 8;
  minSlabWipPercentage = 0;
  maxWipSlabPercentage = 100;
  createProductionLocationForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  createForm() {
    this.createProductionLocationForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      lat: [''],
      long: [''],
      wipHours: ['0', [Validators.required, Validators.min(this.minWipHours), Validators.max(this.maxWipHours)]],
      wipSlabPercentage: ['0', [Validators.required, Validators.min(this.minSlabWipPercentage), Validators.max(this.maxWipSlabPercentage)]],
      allowRetail: [false],
      allowFusion: [false],
      allowMultiColorUnit: [false],
      allowPartsOrder: [false],
      allowPalletized: [false],
      allowAccessoriesOnly: [false],
      allowCPU: [false],
      allowFedEx: [false],
      allowDropOff: [false],
      allowOvernight: [false]
    });
  }

  isValid(): boolean {
    return this.createProductionLocationForm.valid;
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.createProductionLocation();
    }
  }

  getNameErrorMessage() {
    return this.createProductionLocationForm.controls.name.hasError('required') ? 'You must enter a name' : '';
  }

  getAddressErrorMessage() {
    return this.createProductionLocationForm.controls.address.hasError('required') ? 'You must enter a address' : '';
  }

  getCityErrorMessage() {
    return this.createProductionLocationForm.controls.city.hasError('required') ? 'You must enter a city' : '';
  }

  getStateErrorMessage() {
    return this.createProductionLocationForm.controls.state.hasError('required') ? 'You must enter a state' : '';
  }

  getZipCodeErrorMessage() {
    return this.createProductionLocationForm.controls.zipCode.hasError('required') ? 'You must enter a zip code' : '';
  }

  createProductionLocation() {
    const model: ProductionLocation = {
      name: this.createProductionLocationForm.controls.name.value,
      address: this.createProductionLocationForm.controls.address.value,
      address2: this.createProductionLocationForm.controls.address2.value,
      city: this.createProductionLocationForm.controls.city.value,
      state: this.createProductionLocationForm.controls.state.value,
      zipCode: this.createProductionLocationForm.controls.zipCode.value,
      lat: this.createProductionLocationForm.controls.lat.value,
      long: this.createProductionLocationForm.controls.long.value,
      wipHours: this.createProductionLocationForm.controls.wipHours.value,
      wipSlabPercentage: this.createProductionLocationForm.controls.wipSlabPercentage.value,
      allowRetail: this.createProductionLocationForm.controls.allowRetail.value,
      allowFusion: this.createProductionLocationForm.controls.allowFusion.value,
      allowMultiColorUnit: this.createProductionLocationForm.controls.allowMultiColorUnit.value,
      allowPartsOrder: this.createProductionLocationForm.controls.allowPartsOrder.value,
      allowPalletized: this.createProductionLocationForm.controls.allowPalletized.value,
      allowAccessoriesOnly: this.createProductionLocationForm.controls.allowAccessoriesOnly.value,
      allowCPU: this.createProductionLocationForm.controls.allowCPU.value,
      allowFedEx: this.createProductionLocationForm.controls.allowFedEx.value,
      allowDropOff: this.createProductionLocationForm.controls.allowDropOff.value,
      allowOvernight: this.createProductionLocationForm.controls.allowOvernight.value
    };

    this.productionLocationsService.createProductionLocation(model).subscribe(_ => {
      this.router.navigate(['/locations']);
      this.snackBarService.showSuccess('The production location was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new production location.');
    });
  }
}
