<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Router Details
    </h1>
    <hr />

    <div class="actions">
        <a routerLink="/routers/edit/{{router?.id}}" mat-raised-button>Edit Router</a>
    </div>

    <div *ngIf="router" class="content">
        <ul>
            <li><strong>Id:</strong> {{router?.id}}</li>
            <li><strong>Name:</strong> {{router?.name}}</li>
            <li><strong>Production Location:</strong> {{router?.productionLocationStr}}</li>
            <li><strong>IP Address:</strong> {{router?.ipAddress}}</li>
            <li><strong>Is Active:</strong> {{router?.isActive | active}}</li>
            <li><strong>Date Created:</strong> {{router?.dateCreated | date}}</li>
            <li><strong>Created By:</strong> {{router?.createdByUserId}}</li>
            <li><strong>Date Modified:</strong> {{router?.dateModified | date}}</li>
            <li><strong>Modified By:</strong> {{router?.modifiedByUserId}}</li>
        </ul>
    </div>
</mat-card>