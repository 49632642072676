<div class="header-actions">
  <a routerLink="create" mat-flat-button color="accent">Create new Account</a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Accounts
  </h1>
  <hr />

  <h3 *ngIf="accounts.length === 0">No accounts to show.</h3>

  <table *ngIf="accounts.length > 0" mat-table [dataSource]="accounts" class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef> UserName </th>
      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef> Location </th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef> Is Active </th>
      <td mat-cell *matCellDef="let element"> {{element.isActive | active}} </td>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef> Date Created </th>
      <td mat-cell *matCellDef="let element"> {{element.dateCreated | date}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/account/details/{{element.id}}" mat-icon-button matTooltip="Account Details"
          matTooltipPosition="above">
          <mat-icon color="accent">notes</mat-icon>
        </a>
        <a routerLink="/account/edit/{{element.id}}" mat-icon-button matTooltip="Edit Account"
          matTooltipPosition="above">
          <mat-icon color="accent">edit</mat-icon>
        </a>
        <a routerLink="delete/{{element.id}}" mat-icon-button matTooltip="Delete Account" matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>