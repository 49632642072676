<mat-sidenav-container class="side-container">
  <mat-sidenav #sidenav mode="side" [(opened)]="opened" class="sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content id="sidenav-content" class="sidenav-content">
    <app-header *ngIf="!fullScreenMode" (toggle)="sidenav.toggle()"></app-header>

    <div class="card-container" [ngClass]="{fullscreen: fullScreenMode}">
      <div class="spinner-container" *ngIf="showSpinner">
        <b>Loading</b>
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
