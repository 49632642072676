import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductionMoldingSetting } from '../../../../models/production-molding-setting';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-production-molding-details',
  templateUrl: './production-molding-details.component.html',
  styleUrls: ['./production-molding-details.component.less']
})
export class ProductionMoldingDetailsComponent implements OnInit {

  productionMoldingSetting: ProductionMoldingSetting;

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionMoldingSetting();
  }

  getProductionMoldingSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getProductionMoldingSetting(id).subscribe((productionMoldingSetting: ProductionMoldingSetting) => {
      this.productionMoldingSetting = productionMoldingSetting;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production molding setting details.');
      });
  }
}
