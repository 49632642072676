<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
          </button>
         <mat-card-header>
            <h1 class="mat-h1">
                Change password to an existing customer
            </h1>
         </mat-card-header>
         <mat-card-content>
            <div class="container">
                <ng-container>
                    <form [formGroup]="updateUserForm" (ngSubmit)="submit()">
                        <mat-form-field class="full-width">
                            <input matInput 
                                placeholder="Email" 
                                formControlName="email" 
                                [(ngModel)]="user['userEmail']" required>
                                <mat-error *ngIf="updateUserForm.get('email').hasError('required')">
                                Email is required
                                </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                <input matInput 
                                placeholder="Password" 
                                formControlName="password" 
                                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                [(ngModel)]="user['password']" required>
                            <mat-error *ngIf="updateUserForm.get('password').hasError('required')">
                                Password is required
                            </mat-error>
                        </mat-form-field>
                        <mat-card-actions>
                            <button mat-raised-button color="accent" class="btn-modal" type="submit" [disabled]="!updateUserForm.valid"> Save </button>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">Cancel</button>
                        </mat-card-actions>            
                    </form>
                </ng-container>
            </div>
         </mat-card-content>
        <mat-card-footer>
            <span  *ngIf="updateUserForm.controls.password.dirty && _customerInfoService.verifyPass('pass-number', user.password) && user.password.length > 0" class="error">
                *Must have at least one number or special characters
            </span>
            <span  *ngIf="updateUserForm.controls.password.dirty && _customerInfoService.verifyPass('pass-number', user.password) && user.password.length > 0" class="error">
                *Must have at least one number or special characters</span> <br>
            <span  *ngIf="updateUserForm.controls.password.dirty && _customerInfoService.verifyPass('length', user.password) && user.password.length > 0" class="error">
                *Min: 8 characters</span> <br>
            <span  *ngIf="updateUserForm.controls.password.dirty && _customerInfoService.verifyPass('low-uper', user.password) && user.password.length > 0" class="error">
                *Must have lowercase and uppercase</span>
        </mat-card-footer>
    </mat-card>
</mat-dialog-content>
    
