<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
            <button mat-mini-fab matTooltip="Back to notifications" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
    </div>
</div>
<mat-card>
    <mat-card-content>
        <h1 class="mat-h1">
            New Group
        </h1>
        <hr />
        <ng-container *ngIf="loading; else formTemplate">
            <div class="container-loading">
                <div class="loading">
                    <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
        </ng-container>
        <ng-template #formTemplate>
            <div class="container">
                <form [formGroup]="groupForm" (ngSubmit)="createGroup()">
                    <mat-form-field class="full-width">
                        <mat-label>Reason Group</mat-label>
                        <input matInput #iptGroup type="text" placeholder="Name of group"  formControlName="group" maxlength="100">
                        <mat-hint align="end">{{iptGroup.value.length}} / 100</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Description</mat-label>
                        <input matInput #iptDescription type="text" placeholder="Description"  formControlName="description" maxlength="100">
                        <mat-hint align="end">{{iptDescription.value.length}} / 100</mat-hint>
                    </mat-form-field>
                    <button mat-raised-button color="accent" [disabled]="loading || !groupForm.valid">Create</button>
                </form>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>