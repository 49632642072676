interface ICommissionsFilters {
    customerFilter: string;
    customerNumberFilter: string;
    reportFromDateFilter: string;
    reportToDateFilter: string;
    assignedRepFilter: string;
    withSplitCommunities: boolean;
    withActiveInRange: boolean;
}

class CommissionsFilters implements ICommissionsFilters {
    constructor(public customerFilter: string,
                public customerNumberFilter: string,
                public reportFromDateFilter: string,
                public reportToDateFilter: string,
                public assignedRepFilter: string,
                public withSplitCommunities: boolean,
                public withActiveInRange: boolean) {

                }
}

export { ICommissionsFilters, CommissionsFilters };