import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RebateGroup } from '../../../models/rebate-group';
import { AccountingService } from '../../../services/accounting.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-rebate-group-details',
  templateUrl: './rebate-group-details.component.html',
  styleUrls: ['./rebate-group-details.component.less']
})
export class RebateGroupDetailsComponent implements OnInit {

  rebateGroup: RebateGroup;

  constructor(
    private route: ActivatedRoute,
    private accountingService: AccountingService,
    private snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.getRebateGroup();
  }

  getRebateGroup() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.accountingService.getRebateGroup(id).subscribe((rebateGroup: RebateGroup) => {
      this.rebateGroup = rebateGroup;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the rebate group details.');
      });
  }
}
