import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApartmentComplex } from '../../../models/apartment-complex';
import { RebateGroup } from '../../../models/rebate-group';
import { Customer } from '../../../models/customer';
import { AccountingService } from '../../../services/accounting.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { RebateGroupAssignmentsDetailsComponent } from '../rebate-group-assignments-details/rebate-group-assignments-details.component';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-rebate-group-assignments',
  templateUrl: './edit-rebate-group-assignments.component.html',
  styleUrls: ['./edit-rebate-group-assignments.component.less']
})
export class EditRebateGroupAssignmentsComponent implements OnInit {

  customer: Customer;
  rebateGroups: RebateGroup[] = [];
  selectedRebateGroupsForCustomer: number[] = [];
  selectedRebateGroupsForCommunities: number[] = [];
  selectedCommunities = {};
  communityFilter = new UntypedFormControl();
  rebateGroupsAssignments: RebateGroup[] = [];
  rebateGroupCommunitiesAssignments: ApartmentComplex[] = [];
  filteredRebateGroupCommunitiesAssignments: ApartmentComplex[] = [];
  filtersApplied = false;
  reloadRebateGroups = false;

  rebateGroupsDisplayedColumns: string[] = ['name', 'percentage', 'startPeriod', 'endPeriod', 'dateModified', 'isActive'];
  communitiesDisplayedColumns: string[] = ['select', 'id', 'name', 'rebateGroup', 'percentage', 'startPeriod', 'endPeriod', 'dateModified', 'isActive'];

  constructor(
    private accountingService: AccountingService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<RebateGroupAssignmentsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer, rebateGroups: RebateGroup[] }) { }

  ngOnInit() {
    this.customer = this.data.customer;
    this.rebateGroups = this.data.rebateGroups.filter(r => r.isActive);
    this.getRebateGroupsAssignments();
    this.getRebateGroupsCommunitiesAssignments();
  }

  getCommunitiesAssignmentsData(): ApartmentComplex[] {
    return this.filtersApplied ? this.filteredRebateGroupCommunitiesAssignments : this.rebateGroupCommunitiesAssignments;
  }

  getRebateGroupsAssignments(): void {
    const customerId = this.customer.id;
    this.accountingService.getRebateGroupsAssignments(customerId).subscribe((rebateGroups: RebateGroup[]) => {
      this.rebateGroupsAssignments = rebateGroups;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting rebate groups.');
    });
  }

  getRebateGroupsCommunitiesAssignments(): void {
    const customerId = this.customer.id;
    this.accountingService.getRebateGroupsCommunitiesAssignments(customerId).subscribe((communitiesAssignments: ApartmentComplex[]) => {
      this.rebateGroupCommunitiesAssignments = communitiesAssignments;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting communities.');
    });
  }

  setRebateGroups(): void {
    const customerId = this.customer.id;
    const rebateGroupIds = this.selectedRebateGroupsForCustomer;
    if (rebateGroupIds.length > 0) {
      this.accountingService.setRebateGroups(customerId, rebateGroupIds).subscribe(() => {
        this.reloadRebateGroups = true;
        this.clearSelection();
        this.snackBarService.showSuccess('Rebate group assignments was created successfully.');
        this.getRebateGroupsAssignments();
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error creating rebate groups assignments.');
      });
    }
    else {
      this.snackBarService.showError('You must select some rebate groups.');
    }
  }

  unsetRebateGroups(): void {
    const customerId = this.customer.id;
    const rebateGroupIds = this.selectedRebateGroupsForCustomer;
    if (rebateGroupIds.length > 0) {
      this.accountingService.unsetRebateGroups(customerId, rebateGroupIds).subscribe(() => {
        this.reloadRebateGroups = true;
        this.clearSelection();
        this.snackBarService.showSuccess('Rebate group assignments was deleted successfully.');
        this.getRebateGroupsAssignments();
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error deleting rebate groups assignments.');
      });
    }
    else {
      this.snackBarService.showError('You must select some rebate groups.');
    }
  }

  setRebateGroupsForCommunities(): void {
    const customerId = this.customer.id;
    const rebateGroupIds = this.selectedRebateGroupsForCommunities;
    const communitiesIds = this.getSelectedCommunitiesIds();

    if (rebateGroupIds.length > 0 && communitiesIds.length > 0) {
      this.accountingService.setRebateGroupsForCommunities(customerId, rebateGroupIds, communitiesIds).subscribe(() => {
        this.reloadRebateGroups = true;
        this.clearSelection();
        this.snackBarService.showSuccess('Rebate group assignments was created successfully.');
        this.getRebateGroupsCommunitiesAssignments();
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error creating rebate groups assignments.');
      });
    } else {
      this.snackBarService.showError('You must select some rebate groups and communities.');
    }
  }

  unsetRebateGroupsForCommunities(): void {
    const customerId = this.customer.id;
    const rebateGroupIds = this.selectedRebateGroupsForCommunities;
    const communitiesIds = this.getSelectedCommunitiesIds();

    if (rebateGroupIds.length > 0 && communitiesIds.length > 0) {
      this.accountingService.unsetRebateGroupsForCommunities(customerId, rebateGroupIds, communitiesIds).subscribe(() => {
        this.reloadRebateGroups = true;
        this.clearSelection();
        this.snackBarService.showSuccess('Rebate group assignments was deleted successfully.');
        this.getRebateGroupsCommunitiesAssignments();
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error deleting rebate groups assignments.');
      });
    } else {
      this.snackBarService.showError('You must select some rebate groups and communities.');
    }
  }

  toggleSelection(e: MatCheckboxChange): void {
    if (e.checked) {
      for (const item of this.rebateGroupCommunitiesAssignments) {
        this.selectedCommunities[item.id] = true;
      }
    } else {
      this.selectedCommunities = {};
    }
  }

  selectItem(e: MatCheckboxChange, id: number): void {
    this.selectedCommunities[id] = e.checked;
  }

  isSelected(id: number): boolean {
    return this.selectedCommunities[id] === true;
  }

  isAllSelected(): boolean {
    for (const item of this.rebateGroupCommunitiesAssignments) {
      if (this.selectedCommunities[item.id] !== true) {
        return false;
      }
    }
    return true;
  }

  getSelectedCommunitiesIds(): number[] {
    const communitiesIds: number[] = [];

    for (const key in this.selectedCommunities) {
      if (this.selectedCommunities.hasOwnProperty(key) && this.selectedCommunities[key] === true) {
        communitiesIds.push(+key);
      }
    }

    return communitiesIds;
  }

  applyFilters(): void {
    this.clearSelection();
    this.filteredRebateGroupCommunitiesAssignments = this.rebateGroupCommunitiesAssignments;

    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredRebateGroupCommunitiesAssignments = this.filterByCommunity();
      this.filtersApplied = true;
    }
  }

  filterByCommunity(): ApartmentComplex[] {
    return this.filteredRebateGroupCommunitiesAssignments.filter(c => c.name.toLowerCase().includes(this.communityFilter.value.toLowerCase()));
  }

  clearFilters(): void {
    this.filtersApplied = false;
    this.communityFilter.setValue('');
    this.filteredRebateGroupCommunitiesAssignments = [];
  }

  clearSelection(): void {
    this.selectedRebateGroupsForCustomer = [];
    this.selectedRebateGroupsForCommunities = [];
    this.selectedCommunities = {};
  }

  close(): void {
    this.dialogRef.close(this.reloadRebateGroups);
  }

}
