<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    <button mat-button (click)="goBack()"><i class="material-icons">arrow_back_ios</i></button> Apartments
  </h1>
  <hr />

  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
  </div>

  <h3 *ngIf="!loading && !hasApartments()">No apartments to show.</h3>

  <table *ngIf="hasApartments()" mat-table [dataSource]="getApartmentsDataSource()" #apartmentsSort="matSort" matSort
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;">
    </tr>

    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="dateOrdered">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Ordered </th>
      <td mat-cell *matCellDef="let element"> {{element.dateOrdered | date}}</td>
    </ng-container>

    <ng-container matColumnDef="mustShipDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No Del Before </th>
      <td mat-cell *matCellDef="let element"> {{element.mustShipDate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="apartmentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Apt # </th>
      <td mat-cell *matCellDef="let element">
        {{element.apartmentNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="productionOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
      <td mat-cell *matCellDef="let element"> {{element.productionOrderNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let element"> {{element.customer}}</td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.project}}</td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
      <td mat-cell *matCellDef="let element"> {{element.color}}</td>
    </ng-container>

    <ng-container matColumnDef="style">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style </th>
      <td mat-cell *matCellDef="let element"> {{element.style}}</td>
    </ng-container>

    <ng-container matColumnDef="fusion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fusion </th>
      <td mat-cell *matCellDef="let element"> {{element.isFusion | active}}</td>
    </ng-container>

    <ng-container matColumnDef="multicolor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Multicolor </th>
      <td mat-cell *matCellDef="let element"> {{element.isMultiColorUnit | active}}</td>
    </ng-container>

    <ng-container matColumnDef="pallet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pallet </th>
      <td mat-cell *matCellDef="let element"> {{element.isPalletized | active}}</td>
    </ng-container>

    <ng-container matColumnDef="numberOfParts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Parts </th>
      <td mat-cell *matCellDef="let element"> {{element.numberOfParts}}</td>
    </ng-container>

    <ng-container matColumnDef="salesOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> SO # </th>
      <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="schedulerNotes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
      <td mat-cell *matCellDef="let element"> {{element.schedulerNotes}}</td>
    </ng-container>

    <ng-container matColumnDef="productionStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.friendlyProductionStatus}}</td>
    </ng-container>

  </table>
</mat-card>