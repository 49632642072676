import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-alert-overnight-process',
  templateUrl: './alert-overnight-process.component.html',
  styleUrls: ['./alert-overnight-process.component.less']
})
export class AlertOvernightProcessComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string,
    description: string
  }) {

  }
}
