import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeType, PromoCode } from '../../../models/promo-code';
import { PromoCodesService } from '../../../services/promo-codes.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { PromoCodeCriteria } from '../../../models/promo-code-criteria';
import { CriteriaType, PromoCodeCriteriaType } from '../../../models/promo-code-criteria-type';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./edit-promo-code.component.less']
})
export class EditPromoCodeComponent implements OnInit {

  loading = false;
  websites: string[] = [];
  promoCodeTypes: CodeType[] = [];
  promoCodeCriteriaTypes: PromoCodeCriteriaType[] = [];
  promoCodesForm: FormGroup;
  promoCodesCriteriaForm: FormGroup;
  promoCodesCriteriaFormItems: FormArray;
  promoCode: PromoCode;
  minAmount = 1;
  maxAmount = 9999;
  noChargePartsCodeType: CodeType = CodeType.NoChargeParts;
  amountMask: any = {
    mask: Number,
    min: this.minAmount,
    max: this.maxAmount,
    radix: '.',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private promoCodesService: PromoCodesService,
    private snackBarService: SnackbarService
  ) {
    this.createPromoCodesForm();
    this.createPromoCodesCriteriaForm();
  }

  async ngOnInit(): Promise<void> {
    this.getWebsites();
    await this.getPromoCodeTypes();
    await this.getPromoCodeCriteriaTypes();
    await this.getPromoCode();
  }

  setValues(): void {
    const type = this.promoCode.type;
    const amount = type === CodeType.NoChargeParts ? 0 : this.promoCode.amount;
    const maxDiscountAmount = type === CodeType.NoChargeParts ? 0 : this.promoCode.maxDiscountAmount;

    this.promoCodesForm.setValue({
      id: this.promoCode.id,
      code: this.promoCode.code,
      type,
      amount: `${amount}`,
      maxDiscountAmount: maxDiscountAmount ? `${maxDiscountAmount}` : '',
      startDate: this.promoCode.startDate,
      endDate: this.promoCode.endDate,
      allowedUses: this.promoCode.numberOfAllowedUses,
      numberOfUsesPerCustomer: this.promoCode.numberOfUsesPerCustomer || '',
      isActive: this.promoCode.isActive,
      websites: this.promoCode.websites
    });

    if (this.promoCode.criteria) {
      for (const c of this.promoCode.criteria) {
        this.addCriteria(c);
      }
    }
  }

  createPromoCodesForm() {
    this.promoCodesForm = this.fb.group({
      id: ['', Validators.required],
      code: ['', Validators.required],
      type: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(this.minAmount), Validators.max(this.maxAmount)]],
      maxDiscountAmount: ['', [Validators.min(this.minAmount), Validators.max(this.maxAmount)]],
      startDate: ['', Validators.required],
      endDate: [''],
      allowedUses: ['', [Validators.required, Validators.min(1), Validators.max(99999)]],
      numberOfUsesPerCustomer: ['', [Validators.min(1), Validators.max(99999)]],
      isActive: [''],
      websites: ['', Validators.required]
    });
  }

  createPromoCodesCriteriaForm(): void {
    const promoCodesCriteriaFormItems: FormGroup[] = [];
    this.promoCodesCriteriaForm = this.fb.group({
      formGroups: this.fb.array(promoCodesCriteriaFormItems)
    });
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
  }

  createPromoCodesCriteriaFormItem(criteria?: PromoCodeCriteria): FormGroup {
    if (criteria) {
      const type = this.promoCodeCriteriaTypes.find(t => t.id === criteria.promoCodeCriteriaTypeId);
      const value = type && type.requiresValue ? [criteria.value, [Validators.required]] : [criteria.value];
      return this.fb.group({
        type: [type],
        value
      });
    }
    return this.fb.group({
      type: ['', [Validators.required]],
      value: [''],
    });
  }

  setPromoCodesFormValidators() {
    const type = this.promoCodesForm.get('type').value as CodeType;

    if (type !== CodeType.NoChargeParts) {
      this.promoCodesForm.get('amount').setValidators(
        [
          Validators.required,
          Validators.min(this.minAmount),
          Validators.max(this.maxAmount)
        ]);
    } else {
      this.promoCodesForm.get('amount').clearValidators();
    }

    this.promoCodesForm.get('amount').updateValueAndValidity();
  }

  addCriteria(criteria?: PromoCodeCriteria): void {
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
    this.promoCodesCriteriaFormItems.push(this.createPromoCodesCriteriaFormItem(criteria));
  }

  removeCriteria(index: number) {
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
    this.promoCodesCriteriaFormItems.removeAt(index);
  }

  getWebsites(): void {
    this.websites = this.promoCodesService.getWebsites();
  }

  async getPromoCode(): Promise<void> {
    try {
      this.loading = true;
      const id = +this.route.snapshot.paramMap.get('id');
      this.promoCode = await this.promoCodesService.getPromoCode(id).toPromise();
      this.setValues();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting promo code details.');
    } finally {
      this.loading = false;
    }
  }

  async getPromoCodeTypes(): Promise<void> {
    try {
      this.promoCodeTypes = await this.promoCodesService.getCodeTypes().toPromise();
    } catch (err) {
      console.error('Error getting code types:', err);
      this.snackBarService.showError('Error getting code types.');
    }
  }

  async getPromoCodeCriteriaTypes(): Promise<void> {
    try {
      this.promoCodeCriteriaTypes = await this.promoCodesService.getCriteriaTypes().toPromise();
    } catch (err) {
      console.error('Error getting promo code criteria types:', err);
      this.snackBarService.showError('Error getting promo code criteria types.');
    }
  }

  format(word: string, delimiter: string = ' '): string {
    let result = '';
    if (word) {
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        const prevChar = word[i - 1];
        const tokenDelimiter = !isNaN(+char) && !isNaN(+prevChar) ? '' : delimiter;
        result += char === char.toUpperCase() ? `${tokenDelimiter}${char}` : char;
      }
    }
    return result.trim();
  }

  formatCodeType(type: CodeType): string {
    return this.format(CodeType[type]) || 'Unknown';
  }

  formatCriteriaType(type: CriteriaType): string {
    return this.format(CriteriaType[type]) || 'Unknown';
  }

  isValid(): boolean {
    this.promoCodesCriteriaFormItems.markAllAsTouched();
    this.promoCodesCriteriaFormItems.updateValueAndValidity();
    this.promoCodesForm.updateValueAndValidity();

    const startDate = this.promoCodesForm.get('startDate').value;
    const endDate = this.promoCodesForm.get('endDate').value;
    const allowedUses = this.promoCodesForm.get('allowedUses').value;
    const numberOfUsesPerCustomer = this.promoCodesForm.get('numberOfUsesPerCustomer').value;

    if (startDate && endDate && moment(startDate).isAfter(endDate)) {
      this.promoCodesForm.get('endDate').setErrors({ invalid: true });
    }

    if (numberOfUsesPerCustomer && numberOfUsesPerCustomer > allowedUses) {
      this.promoCodesForm.get('numberOfUsesPerCustomer').setErrors({ max: true });
    }

    const formArray = this.promoCodesCriteriaFormItems as FormArray;

    for (const formGroup of formArray.controls) {
      const value = formGroup.get('value').value as string;
      const type = formGroup.get('type').value as PromoCodeCriteriaType;

      if (type && type.requiresValue && !value.trim()) {
        formGroup.get('value').setErrors({ required: true });
      }
    }

    return this.promoCodesForm.valid && this.promoCodesCriteriaFormItems.valid;
  }

  getData(): PromoCode {
    const type = this.promoCodesForm.get('type').value as CodeType;
    const amount = type === CodeType.NoChargeParts ? 0 : this.promoCodesForm.get('amount').value;
    const maxDiscountAmount = type === CodeType.NoChargeParts ? 0 : this.promoCodesForm.get('maxDiscountAmount').value;

    const promoCode: PromoCode = {
      id: this.promoCodesForm.get('id').value,
      code: this.promoCodesForm.get('code').value,
      type,
      amount,
      maxDiscountAmount,
      startDate: this.promoCodesForm.get('startDate').value,
      endDate: this.promoCodesForm.get('endDate').value,
      numberOfAllowedUses: this.promoCodesForm.get('allowedUses').value,
      numberOfUsesPerCustomer: this.promoCodesForm.get('numberOfUsesPerCustomer').value,
      websites: this.promoCodesForm.get('websites').value,
      isActive: this.promoCodesForm.get('isActive').value,
      criteria: []
    };

    const formArray = this.promoCodesCriteriaFormItems as FormArray;

    for (const formGroup of formArray.controls) {
      const value = formGroup.get('value').value as string;
      const criteriaType = formGroup.get('type').value as PromoCodeCriteriaType;
      const codeCriteria: PromoCodeCriteria = {
        value,
        promoCodeCriteriaTypeId: criteriaType?.id
      };
      promoCode.criteria.push(codeCriteria);
    }

    return promoCode;
  }

  updatePromoCode(): void {
    this.loading = true;
    const model = this.getData();

    this.promoCodesService.updatePromoCode(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/promo-codes']);
      this.snackBarService.showSuccess('The promo codes was updated successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error updating promo codes.');
    });
  }

  submit(): void {
    if (this.isValid()) {
      this.updatePromoCode();
    }
  }

}
