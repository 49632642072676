import { Component } from '@angular/core';

@Component({
  selector: 'app-apartment-details',
  templateUrl: './apartment-details.component.html',
  styleUrls: ['./apartment-details.component.less']
})
export class ApartmentDetailsComponent {

  constructor() { }
}
