import { Component, OnInit } from '@angular/core';
import { ProductionLocation } from '../../models/production-location';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ProductionDateSummary } from '../../models/production-date-summary';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ProductionSummary } from '../../models/production-summary';

const INVALID_LOCATION_ID = -1;

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.less']
})
export class SummaryComponent implements OnInit {

  loading = false;
  loadingProductionSummary = false;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  productionDatesSummaries: ProductionDateSummary[] = [];
  productionSummary: ProductionSummary;

  constructor(
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getProductionLocations();
    this.getProductionSummary();
    this.getProductionDatesSummaries();
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  showProductionSummaryLoading() {
    this.loadingProductionSummary = true;
  }

  hideProductionSummaryLoading() {
    this.loadingProductionSummary = false;
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  getProductionSummary() {
    return new Promise<void>((resolve, reject) => {
      this.showProductionSummaryLoading();

      this.scheduleService.getProductionSummary()
        .subscribe((productionSummary: ProductionSummary) => {
          this.productionSummary = productionSummary;
          this.hideProductionSummaryLoading();
          resolve();
        },
          err => {
            console.error(err);
            this.snackBarService.showError('Error getting production summary.');
            reject();
          });
    });
  }

  getProductionDatesSummaries() {
    return new Promise<void>((resolve, reject) => {
      this.showLoading();

      this.scheduleService.getProductionDatesSummaries(this.productionLocationId, new Date())
        .subscribe((productionDatesSummaries: ProductionDateSummary[]) => {
          this.productionDatesSummaries = productionDatesSummaries;
          this.hideLoading();
          resolve();
        },
          err => {
            console.error(err);
            this.snackBarService.showError('Error getting production dates summaries.');
            reject();
          });
    });
  }
}
