import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '../models/shipping-address';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  constructor(
    private config: ConfigService,
    private http: HttpClient,
  ) { }

  validate(address: Address): Promise<Address> {
    const url = this.config.buildFedexURL(`fedexServices/validations/addresses`);
    return this.http.post<Address>(url, address).toPromise();
  }
}
