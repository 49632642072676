<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Global Settings
  </h1>
  <hr />

  <h3 class="mat-h3">{{groupName}}</h3>

  <h3 *ngIf="globalSettings.length === 0">No global settings to show.</h3>

  <table *ngIf="globalSettings.length > 0" mat-table [dataSource]="globalSettings" class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="friendlyName">
      <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
      <td mat-cell *matCellDef="let element"> {{element.friendlyName}} </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <ng-container matColumnDef="modifiedBy">
      <th mat-header-cell *matHeaderCellDef> Modified By</th>
      <td mat-cell *matCellDef="let element"> {{element.modifiedBy}} </td>
    </ng-container>

    <ng-container matColumnDef="dateModified">
      <th mat-header-cell *matHeaderCellDef> Modified </th>
      <td mat-cell *matCellDef="let element"> {{element.dateModified | date}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/settings/details/{{element.id}}" mat-icon-button matTooltip="Setting Details"
          matTooltipPosition="above">
          <mat-icon color="accent">notes</mat-icon>
        </a>
        <a routerLink="/settings/edit/{{element.id}}" mat-icon-button matTooltip="Edit Setting"
          matTooltipPosition="above">
          <mat-icon color="accent">edit</mat-icon>
        </a>
        <a routerLink="/settings/delete/{{element.id}}" mat-icon-button matTooltip="Delete Setting"
          matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>