import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkCenterType } from '../models/work-center-type';
import { WorkCenter } from '../models/work-center';
import { WorkCenterItem } from '../models/work-center-item';
import { ProductionEmployee } from '../models/production-employee';

@Injectable({
  providedIn: 'root'
})
export class WorkCentersService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getWorkCenterTypes(): Observable<WorkCenterType[]> {
    const url = this.config.buildURL(`workCenters/workCenterTypes`);
    return this.http.get<WorkCenterType[]>(url);
  }

  getWorkCenters(workCenterTypeId: number): Observable<WorkCenter[]> {
    const url = this.config.buildURL(`workCenters/${workCenterTypeId}`);
    return this.http.get<WorkCenter[]>(url);
  }

  getProductionEmployees(): Observable<ProductionEmployee[]> {
    const url = this.config.buildURL(`productionEmployee`);
    return this.http.get<ProductionEmployee[]>(url);
  }

  getWorkCenterItems(workCenterTypeId: number, workCenterId: number): Observable<WorkCenterItem[]> {
    const url = this.config.buildURL(`workCenters/workCenterItems`);
    const params = new HttpParams().set('workCenterTypeId', `${workCenterTypeId}`)
      .set('workCenterId', `${workCenterId}`);

    return this.http.get<WorkCenterItem[]>(url, { params });
  }

  updateProductionStatus(item: WorkCenterItem): Observable<WorkCenterItem> {
    const url = this.config.buildURL(`workCenters/updateProductionStatus`);
    return this.http.put<WorkCenterItem>(url, item);
  }

  resetProductionStatus(item: WorkCenterItem): Observable<WorkCenterItem> {
    const url = this.config.buildURL(`workCenters/resetProductionStatus`);
    return this.http.put<WorkCenterItem>(url, item);
  }
}
