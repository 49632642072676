import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '../../../forms/base-form';
import { RebateGroup } from '../../../models/rebate-group';
import { AccountingService } from '../../../services/accounting.service';
import { SnackbarService } from '../../../services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-rebate-group',
  templateUrl: './edit-rebate-group.component.html',
  styleUrls: ['./edit-rebate-group.component.less']
})
export class EditRebateGroupComponent extends BaseForm implements OnInit {

  rebateGroup: RebateGroup;
  editRebateGroupForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountingService: AccountingService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.getRebateGroup();
  }

  createForm(): void {
    this.editRebateGroupForm = this.fb.group({
      name: ['', Validators.required],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(10)]],
      startPeriod: ['', Validators.required],
      endPeriod: [''],
      isActive: false
    });
  }

  isValid(): boolean {
    const startPeriod = this.editRebateGroupForm.get('startPeriod').value;
    const endPeriod = this.editRebateGroupForm.get('endPeriod').value;
    const days = moment(endPeriod).startOf('day').diff(moment(startPeriod).startOf('day'), 'days');

    if (startPeriod && endPeriod && days <= 0) {
      this.editRebateGroupForm.get('endPeriod').setErrors({ min: true });
    }

    return this.editRebateGroupForm.valid;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.updateRebateGroup();
    }
  }

  setValues() {
    this.editRebateGroupForm.setValue({
      name: this.rebateGroup.name.trim(),
      percentage: this.rebateGroup.rebatePercentage,
      startPeriod: this.rebateGroup.startPeriod,
      endPeriod: this.rebateGroup.endPeriod,
      isActive: this.rebateGroup.isActive
    });
  }

  getRebateGroup() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.accountingService.getRebateGroup(id).subscribe((rebateGroup: RebateGroup) => {
      this.rebateGroup = rebateGroup;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the rebate group details.');
      });
  }

  updateRebateGroup() {
    const id = +this.route.snapshot.paramMap.get('id');

    const model: RebateGroup = {
      id,
      name: this.editRebateGroupForm.get('name').value.trim(),
      rebatePercentage: this.editRebateGroupForm.get('percentage').value,
      startPeriod: this.editRebateGroupForm.get('startPeriod').value,
      endPeriod: this.editRebateGroupForm.get('endPeriod').value,
      isActive: this.editRebateGroupForm.get('isActive').value,
    };

    this.accountingService.updateRebateGroup(model).subscribe(_ => {
      this.router.navigate(['/accounting']);
      this.snackBarService.showSuccess('The rebate group was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating rebate group.');
    });
  }

}
