<div class="header-actions">
    <a routerLink="group-create" mat-flat-button color="accent">Create New Group</a>
</div>
<h1 class="mat-h1">
    Groups
</h1>
<hr />
<mat-accordion>
    <mat-expansion-panel expanded="true" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filters
            </mat-panel-title>
            <mat-panel-description>
                Filters Groups
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div [formGroup]="formFilter">
                    <mat-form-field>
                        <input matInput placeholder="Group Name" formControlName="group">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Description" formControlName="description">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Created By" formControlName="created">
                    </mat-form-field>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()"><mat-icon>filter_list</mat-icon></button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="reset-action-container" *ngIf="groupFilterApply">
    <button mat-raised-button (click)="clearFilters()">
  <mat-icon>clear</mat-icon> Reset Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblGroups *ngIf="!loading && getData().length > 0" [dataSource]="getGroupDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> No </th>
            <td mat-cell *matCellDef="let row"> {{row?.id}} </td>
        </ng-container>
        <ng-container matColumnDef="groupName">
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let row"> {{row?.groupName}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let row"> {{row?.description}} </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let row"> {{row?.createdBy}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let row"> {{row?.dateCreated | date }} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button matTooltip="Edit Group" matTooltipPosition="above" (click)="editGroup(row)" ><mat-icon color="accent">edit</mat-icon></button>
                <button mat-icon-button matTooltip="Delete Group " matTooltipPosition="above" (click)="disableGroup(row)"><mat-icon color="accent">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>