import { Component, OnInit } from '@angular/core';
import { ProductionLimitsService } from '../../../services/production-limits.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EditForm } from '../../../forms/edit-form';
import { ProductionLimit } from '../../../models/production-limit';
import { GlobalSetting } from '../../../models/global-setting';

@Component({
  selector: 'app-edit-production-limit',
  templateUrl: './edit-production-limit.component.html',
  styleUrls: ['./edit-production-limit.component.less']
})
export class EditProductionLimitComponent extends EditForm implements OnInit {

  productionLimit: ProductionLimit;
  editProductionLimitForm: UntypedFormGroup;
  globalSetting: GlobalSetting;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private productionLimitsService: ProductionLimitsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getProductionLimit();
    this.getGlobalSetting();
  }

  isValid(): boolean {
    return this.editProductionLimitForm.valid;
  }

  createForm() {
    this.editProductionLimitForm = this.fb.group({
      customer: ['', Validators.required],
      apartmentComplex: [''],
      allowedCapacity: ['', [
        Validators.required,
        Validators.min(0),
        Validators.pattern('[0-9]*')]]
    });
  }

  setValues() {
    this.editProductionLimitForm.setValue({
      customer: this.productionLimit.customerName,
      apartmentComplex: this.productionLimit.apartmentComplexName,
      allowedCapacity: this.productionLimit.allowedCustomPartsCapacity
    });
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.updateProductionLimit();
    }
  }

  getCustomerErrorMessage() {
    return this.editProductionLimitForm.controls.customer.hasError('required') ? 'You must enter a customer' : '';
  }

  getAllowedCapacityErrorMessage() {
    if (this.editProductionLimitForm.controls.allowedCapacity.hasError('min')) {
      return `You must enter a valid custom parts capacity greater than ${this.globalSetting.value}`;
    }

    if (this.editProductionLimitForm.controls.allowedCapacity.hasError('pattern')) {
      return `You must enter a valid custom parts capacity integer value.`;
    }

    return this.editProductionLimitForm.valid ? 'You must enter a valid custom parts capacity' : '';
  }

  getGlobalSetting() {
    this.productionLimitsService.getGlobalSetting().subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
      const minValue = +this.globalSetting.value;
      this.editProductionLimitForm.controls.allowedCapacity.setValidators([
        Validators.required,
        Validators.min(minValue),
        Validators.pattern('[0-9]*')]);
      this.editProductionLimitForm.updateValueAndValidity();
    });
  }

  getProductionLimit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLimitsService.getProductionLimit(id).subscribe((productionLimit: ProductionLimit) => {
      this.productionLimit = productionLimit;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production limit details.');
      });
  }

  updateProductionLimit() {
    const model: ProductionLimit = {
      id: this.productionLimit.id,
      customerId: this.productionLimit.customerId,
      customerName: this.productionLimit.customerName,
      apartmentComplexId: this.productionLimit.apartmentComplexId,
      apartmentComplexName: this.productionLimit.apartmentComplexName,
      allowedCustomPartsCapacity: this.editProductionLimitForm.controls.allowedCapacity.value
    };

    this.productionLimitsService.updateProductionLimit(model).subscribe(_ => {
      this.router.navigate(['/production-limits']);
      this.snackBarService.showSuccess('The production limit was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating the production limit information.');
    });
  }
}
