import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ProductionLimit } from '../models/production-limit';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Customer } from '../models/customer';
import { ApartmentComplex } from '../models/apartment-complex';
import { GlobalSetting } from '../models/global-setting';

@Injectable({
  providedIn: 'root'
})
export class ProductionLimitsService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getProductionLimits(): Observable<ProductionLimit[]> {
    const url = this.config.buildURL(`productionLimits`);
    return this.http.get<ProductionLimit[]>(url);
  }

  getCustomers(): Observable<Customer[]> {
    const url = this.config.buildURL(`productionLimits/Customers`);
    return this.http.get<Customer[]>(url);
  }

  getApartmentsComplex(customerId: number): Observable<ApartmentComplex[]> {
    const url = this.config.buildURL(`productionLimits/ApartmentsComplex/${customerId}`);
    return this.http.get<ApartmentComplex[]>(url);
  }

  getProductionLimit(id: string): Observable<ProductionLimit> {
    const url = this.config.buildURL(`productionLimits/${id}`);
    return this.http.get<ProductionLimit>(url);
  }

  getGlobalSetting(): Observable<GlobalSetting> {
    const url = this.config.buildURL(`productionLimits/GlobalSetting`);
    return this.http.get<GlobalSetting>(url);
  }

  createProductionLimit(model: ProductionLimit): Observable<ProductionLimit> {
    const url = this.config.buildURL(`productionLimits`);
    return this.http.post<ProductionLimit>(url, model);
  }

  updateProductionLimit(model: ProductionLimit): Observable<ProductionLimit> {
    const url = this.config.buildURL(`productionLimits/${model.id}`);
    return this.http.put<ProductionLimit>(url, model);
  }

  deleteProductionLimit(id: string) {
    const url = this.config.buildURL(`productionLimits/${id}`);
    return this.http.delete(url);
  }
}
