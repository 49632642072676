<h2 mat-dialog-title>Update Email Address</h2>
<mat-dialog-content>
  <mat-form-field class="mff-email">
    <input matInput placeholder="Email Address" [formControl]="emailControl">
    <mat-error *ngIf="emailControl.hasError('pattern') && (emailControl.dirty || emailControl.touched)">
      Please enter a valid email address.
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<br>
<mat-dialog-actions>
  <button [disabled]="!emailForm.valid" mat-raised-button color="accent" (click)="updateEmail()">&nbsp; Ok &nbsp;</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
