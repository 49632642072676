import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackbarService } from '../../services/snackbar.service';
import { ScanLookupsService } from '../../services/scan-lookups.service';
import { ProductionScanLog, ScanLookup, ScanLookupsExtended } from '../../models/scan-lookup';

@Component({
  selector: 'app-scan-lookups',
  templateUrl: './scan-lookups.component.html',
  styleUrls: ['./scan-lookups.component.less']
})
export class ScanLookupsComponent implements AfterViewInit {

  loading = false;
  filtersApplied = false;
  readonly accessoryMatchType = 'AccessoryCartonMatch';
  readonly filterBySalesOrderNumber = 'salesOrderNumber';
  readonly filterByCartonId = 'cartonId';
  readonly minLengthForSalesOrderNumber = 5;
  readonly minLengthForCartonId = 2;

  displayedColumns: string[] = ['salesOrderNumber', 'apartmentNumber', 'cartonNumber', 'cartonId', 'color', 'doorStyle', 'scanType', 'dateCreated', 'overrideScan', 'location', 'workCenter', 'fedExTrackingNumber', 'shipMethod', 'mdfSupplier'];
  productionScanLogsDisplayedColumns: string[] = ['employee', 'workCenter', 'scanType', 'overrideScan', 'dateCreated'];

  cartonIdFilter = new UntypedFormControl('');
  salesOrderNumberFilter = new UntypedFormControl('');
  filterBy = new UntypedFormControl(this.filterBySalesOrderNumber);

  scanLookupsDataSource = new MatTableDataSource<ScanLookup>();
  productionScanLogsDataSource = new MatTableDataSource<ProductionScanLog>();

  scanLookupsSort: MatSort;
  productionScanLogsSort: MatSort;

  @ViewChild('scanLookupsSort', { read: MatSort }) set scanLookupsMatSort(ms: MatSort) {
    this.scanLookupsSort = ms;
    this.ngAfterViewInit();
  }

  @ViewChild('productionScanLogsSort', { read: MatSort }) set productionScanLogsMatSort(ms: MatSort) {
    this.productionScanLogsSort = ms;
    this.ngAfterViewInit();
  }


  constructor(
    private scanLookupsService: ScanLookupsService,
    private snackBarService: SnackbarService
  ) { }

  ngAfterViewInit() {
    this.scanLookupsDataSource.sort = this.scanLookupsSort;
    this.productionScanLogsDataSource.sort = this.productionScanLogsSort;
  }

  clearFilters() {
    this.loading = true;
    this.cartonIdFilter.setValue('');
    this.salesOrderNumberFilter.setValue('');
    this.filtersApplied = false;
    this.scanLookupsDataSource.data = [];
    this.productionScanLogsDataSource.data = [];
    this.loading = false;
  }

  isValid(): boolean {
    if (this.filterBy.value === this.filterBySalesOrderNumber && !this.salesOrderNumberFilter.value.trim().toLowerCase().startsWith('so-')) {
      return false;
    }
    if (this.filterBy.value === this.filterBySalesOrderNumber &&
      this.salesOrderNumberFilter.value.trim().length < this.minLengthForSalesOrderNumber) {
      return false;
    }
    if (this.filterBy.value === this.filterByCartonId &&
      this.cartonIdFilter.value.toString().trim().length < this.minLengthForCartonId) {
      return false;
    }

    return true;
  }

  getFilterWarningMessage(): string {
    if (this.filterBy.value === this.filterBySalesOrderNumber && !this.salesOrderNumberFilter.value.trim().toLowerCase().startsWith('so-')) {
      return 'Sales Order Number filter must start with SO-.';
    }
    else {
      return `Please enter more
    than ${this.filterBy.value === this.filterBySalesOrderNumber ?
          this.minLengthForSalesOrderNumber : this.minLengthForCartonId} chars to filter by ${this.filterBy.value === this.filterBySalesOrderNumber ? 'Sales Order Number.' : 'Carton Id.'}`;
    }
  }

  getScanLookups() {
    if (this.isValid()) {
      this.loading = true;
      const filterBy = this.filterBy.value;
      const filter = this.filterBy.value === this.filterBySalesOrderNumber ?
        this.salesOrderNumberFilter.value.trim().toLowerCase() : this.cartonIdFilter.value;

      this.scanLookupsService.getScanLookups(filterBy, filter).subscribe((scanLogsExtended: ScanLookupsExtended) => {
        this.scanLookupsDataSource.data = scanLogsExtended.scanLookups;
        this.productionScanLogsDataSource.data = scanLogsExtended.productionScanLogs;
        this.loading = false;
        this.filtersApplied = true;
      },
        err => {
          this.loading = false;
          console.error('Error getting scan lookups.', err);
          this.snackBarService.showError('Error getting scan lookups.');
        }
      );
    }
    else {
      this.snackBarService.showWarning(this.getFilterWarningMessage());
    }
  }

  getData(): ScanLookup[] {
    return this.getScanLookupsDataSource().connect().value;
  }

  getProductionScanLogsData(): ProductionScanLog[] {
    return this.productionScanLogsDataSource.connect().value;

  }
  getScanLookupsDataSource(): MatTableDataSource<ScanLookup> {
    return this.scanLookupsDataSource;
  }

  applyFilters() {
    this.getScanLookups();
  }
}
