<h2 mat-dialog-title>Rebate Report</h2>
<mat-dialog-content>
    <div class="filters">
        <mat-form-field>
            <input matInput [matDatepicker]="picker1" (dateChange)="generateReportUrl()" (input)="generateReportUrl()"
                placeholder="Start Period" [formControl]="startPeriod" required>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="startPeriod.invalid && startPeriod.hasError('required')">
                Start Period is required.
            </mat-error>
            <mat-error *ngIf="startPeriod.invalid && startPeriod.hasError('min')">
                Start Period must be less than End Period.
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="picker2" (dateChange)="generateReportUrl()" (input)="generateReportUrl()"
                placeholder="End Period" [formControl]="endPeriod">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="filters">
        <mat-form-field>
            <mat-label>Rebate Groups</mat-label>
            <mat-select [formControl]="rebateGroup" (selectionChange)="generateReportUrl()" required>
                <mat-option value="0">All</mat-option>
                <mat-option *ngFor="let item of rebateGroups" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="rebateGroup.invalid">Rebate Group is required.</mat-error>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Close</button>
    <a mat-raised-button color="accent" [href]="csvUrl" [disabled]="!enableReport" target="_blank">Generate</a>
</mat-dialog-actions>