import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductionMoldingSetting } from '../../../../models/production-molding-setting';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-delete-production-molding',
  templateUrl: './delete-production-molding.component.html',
  styleUrls: ['./delete-production-molding.component.less']
})
export class DeleteProductionMoldingComponent implements OnInit {

  productionMoldingSetting: ProductionMoldingSetting;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionMoldingSetting();
  }

  getProductionMoldingSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getProductionMoldingSetting(id).subscribe((productionMoldingSetting: ProductionMoldingSetting) => {
      this.productionMoldingSetting = productionMoldingSetting;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production molding setting details.');
      });
  }

  deleteProductionMoldingSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.deleteProductionMoldingSetting(id).subscribe(_ => {
      this.router.navigate([`/settings`]);
      this.snackBarService.showSuccess('The production molding setting was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting production molding setting.');
      });
  }
}
