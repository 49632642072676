<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h1 class="mat-h1"> {{ getTiTleModal() }} </h1>
        <hr />
        <br>
        <div class="container">
            <form [formGroup]="commissionRegionForm" (ngSubmit)="submit()">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="commissionRegionForm.get('name').hasError('required')">
                        Name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>State</mat-label>
                    <mat-select #dllStates formControlName="state" multiple>
                        <mat-select-trigger>
                            {{commissionRegionForm.get('state').value ? commissionRegionForm.get('state').value[0]?.name
                            : ''}}
                            <span *ngIf="commissionRegionForm.get('state').value?.length > 1"
                                class="additional-selection">
                                (+{{commissionRegionForm.get('state').value.length - 1}}
                                {{commissionRegionForm.get('state').value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option [value]="allStateValue"
                            (onSelectionChange)="onSelectionChange($event.source)">All</mat-option>
                        <mat-option *ngFor="let item of commissionStates" [value]="item">
                            {{ item.name }} ({{ item.abbreviation }})
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="commissionRegionForm.get('state').hasError('required')">
                        State is required
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button mat-mini-fab color="accent" aria-label="Add" matTooltip="Add State(s)" type="button" (click)="onAddStates()" [disabled]="!onValidateStateControl()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="container">
                    <table mat-table #tblState *ngIf="!loading && commissionStateItems.length > 0"
                        [dataSource]="getStateDataSource()" class="mat-elevation-z0 table">
                        <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef> No. </th>
                            <td mat-cell *matCellDef="let row"> {{row?.index}} </td>
                        </ng-container>
                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef> State </th>
                            <td mat-cell *matCellDef="let row"> {{row?.state}} </td>
                        </ng-container>
                        <ng-container matColumnDef="abbreviation">
                            <th mat-header-cell *matHeaderCellDef> Abbreviation </th>
                            <td mat-cell *matCellDef="let row"> {{row?.abbreviation}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button matTooltip="Delete" matTooltipPosition="above"
                                    (click)="onDeleteState(row)">
                                    <mat-icon color="accent">clear</mat-icon>
                                </button>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsStates"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsStates;"></tr>
                    </table>
                </div>
                <button mat-raised-button color="accent" class="btn-modal margin-btn" [disabled]="loading"
                    type="submit" [disabled]="onValidateSaveState()"> Save </button>
                <button mat-raised-button color="accent" class="btn-modal"
                    (click)="closeWithOutSaving()">Cancel</button>
            </form>
        </div>
    </mat-card>
</mat-dialog-content>