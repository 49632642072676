export enum UserRole {
    Rebate = 'Rebate',
    ScheduleViewer = 'ScheduleViewer',
    CommissionViewer = 'CommissionViewer',
    CommissionManager = 'CommissionManager',
    ShipManager = 'ShipManager',
    Shipper = 'Shipper',
    Packager = 'Packager',
    Assembler = 'Assembler',
    RouterSawOperator = 'RouterSawOperator',
    MiterSawOperator = 'MiterSawOperator',
    ProductionManager = 'ProductionManager',
    Scheduler = 'Scheduler',
    ScheduleManager = 'ScheduleManager',
    CustomerService = 'CustomerService',
    Admin = 'Admin',
    MaterialManager = 'MaterialManager',
    PromoCodeManager = 'PromoCodeManager',
    ProductManagement = 'ProductManagement' 
}


