<mat-accordion>
    <mat-expansion-panel expanded="true" class="" [hideToggle]="true">
        <mat-expansion-panel-header #panel (click)="panel._toggle()">
            <mat-panel-title>
                3. Package & Shipment Details
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="details">
            <div class="content">
                <div>
                    <mat-label>Ship Date:</mat-label>
                    &nbsp;
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" (dateChange)="changeShipDate($event)"
                            [(ngModel)]="shipDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="tblContainer" *ngIf="packageGroups">
                    <table mat-table #tblPackages [dataSource]="packageGroups" class="mat-elevation-z0 table" matSort
                        #dateActiveSort="matSort">
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns">
                        </tr>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox [checked]="isAllSelected()" (change)="toggleSelection($event)">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox (change)="selectItem($event, element.id)"
                                    [checked]="isSelected(element.id)">
                                </mat-checkbox>
                            </td> 
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef>Qty</th>
                            <td mat-cell *matCellDef="let row">
                                <input matInput type="number" min="1" class="quantity" (change)="editQuantity(row, row?.packageToDisplay.quantity)"
                                    [(ngModel)]="row?.packageToDisplay.quantity">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="weight">
                            <th mat-header-cell *matHeaderCellDef>Weight (lbs)</th>
                            <td mat-cell *matCellDef="let row"> {{ row?.packageToDisplay.weight }} </td>
                        </ng-container>
                        <ng-container matColumnDef="dimensions">
                            <th mat-header-cell *matHeaderCellDef>Dimensions (in)</th>
                            <td mat-cell *matCellDef="let row"> {{ row?.packageToDisplay.length }} x {{
                                row?.packageToDisplay.width }} x {{ row?.packageToDisplay.height }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="declaredValue">
                            <th mat-header-cell *matHeaderCellDef>Declared Value (USD)</th>
                            <td mat-cell *matCellDef="let row"> {{ row?.packageToDisplay.declaredValue | currency }}
                            </td>
                        </ng-container>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5">No data</td>
                        </tr>
                    </table>
                </div>
                <br>
                <div class="actions-container">
                    <button mat-raised-button color="accent" (click)="delete()"
                        [disabled]="getSelectedIds().length === 0">Delete</button>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>