export enum NotificationOrderOrigen {
  Scheduler_Web = 'Scheduler_Web',
}

export interface NotificationOrder {
  notificationId?: string;
  owner: string;
  ownerName: string;
  origen: NotificationOrderOrigen;
  createdBy: string;
  createdDate: Date;
  notificationsOrderDetail: NotificationOrderDetail[];
}

export interface NotificationOrderDetail {
  orderDetailId?: number;
  notificationText: string;
  isRead?: boolean;
  readDate?: Date;
  readFor?: string;
  notificationJson?: NotificationJsonText;
}

export interface NotificationJsonText {
  from: string;
  text: string;
}
