import { Component, OnInit } from '@angular/core';
import { GlobalSetting } from 'src/app/models/global-setting';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-delete-setting',
  templateUrl: './delete-setting.component.html',
  styleUrls: ['./delete-setting.component.less']
})
export class DeleteSettingComponent implements OnInit {

  globalSetting: GlobalSetting;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getGlobalSetting();
  }

  getGlobalSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getSetting(id).subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting global setting details.');
      });
  }

  deleteSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.deleteSetting(id).subscribe(_ => {
      this.router.navigate([`/settings/group-by/${this.globalSetting.groupName}`]);
      this.snackBarService.showSuccess('The global setting was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting global setting.');
      });
  }
}
