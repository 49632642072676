import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// Services
import { ReasonCodesService } from '../../../services/reason-codes.service';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/snackbar.service';
// Models
import { Account } from '../../../models/account';
import { ReasonGroup } from '../../../models/reason-group';
// Moment
import * as moment from 'moment';
import { ReasonGroupsService } from 'src/app/services/reason-groups.service';

@Component({
  selector: 'app-create-reason-group',
  templateUrl: './create-reason-group.component.html',
  styleUrls: ['./create-reason-group.component.less']
})
export class CreateReasonGroupComponent implements OnInit {
  loading = false;
  user: Account;
  groupForm: FormGroup;

  constructor(
    private auth: AuthService,
    private reasonService: ReasonGroupsService,
    private snackService: SnackbarService,
    private navigation: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createGroupForm();
    this.user = this.auth.getAccount();
  }

  createGroupForm() {
    this.groupForm = this.fb.group({
      group: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });
  }

  goBack() {
    this.navigation.navigate(['reason-codes', { tab: 2 }]);
  }

  async createGroup(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonGroup>((resolve, reject) => {
      let newGroup: ReasonGroup = {
        groupName: this.groupForm.get('group').value,
        description: this.groupForm.get('description').value,
        dateCreated: new Date(moment().format()),
        createdBy: this.user.userName
      };

      try {
        this.reasonService.createReasonGroup(newGroup).subscribe(
          (response: ReasonGroup) => newGroup = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve(newGroup));
      } catch (error) {
        console.error(error);
        reject();
        this.snackService.showError('An error occurred during the save operation.');
      }
    }).then((result: ReasonGroup) => {
      result.id && result.id !== 0 ?
        this.snackService.showSuccess('New category was saved successfully.') :
        this.snackService.showError('An error occurred during the save operation.');
      this.loading = false;
      this.goBack();
    });
  }
}
