import { Component, OnInit } from '@angular/core';
import { Account } from '../../../models/account';
import { AccountsService } from 'src/app/services/accounts.service';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.less']
})
export class AccountDetailsComponent implements OnInit {

  account: Account;

  constructor(
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getAccount();
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getAccount(id).subscribe((account: Account) => {
      this.account = account;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account details.');
      });
  }
}
