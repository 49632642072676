import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Apartment } from '../../models/apartment';
import { ApartmentDetailsComponent } from './apartment-details/apartment-details.component';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { ApartmentsService } from '../../services/apartments.service';

@Component({
  selector: 'app-apartments',
  templateUrl: './apartments.component.html',
  styleUrls: ['./apartments.component.less']
})
export class ApartmentsComponent implements OnInit, AfterViewInit {

  loading = false;
  apartmentsSort: MatSort;
  apartmentsDataSource = new MatTableDataSource<Apartment>();
  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'customer', 'salesOrderNumber', 'project', 'apartmentNumber', 'dateOrdered', 'mustShipDate', 'color', 'style', 'numberOfParts', 'fusion', 'pallet', 'multicolor', 'schedulerNotes', 'productionStatus'];

  constructor(
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private snackBarService: SnackbarService,
    private apartmentsService: ApartmentsService) { }

  @ViewChild('apartmentsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.apartmentsSort = ms;
    this.ngAfterViewInit();
  }

  ngOnInit() {
    this.apartmentsDataSource.data = [];
    this.getApartmentsByPONumber();
  }

  ngAfterViewInit() {
    // sort options
    this.apartmentsDataSource.sort = this.apartmentsSort;
  }

  getApartmentsDataSource() {
    return this.apartmentsDataSource;
  }

  goBack() {
    this.location.back();
  }

  openApartmentDetails() {
    this.dialog.open(ApartmentDetailsComponent, {
      width: '980px',
    });
  }

  hasApartments() {
    return this.apartmentsDataSource.data.length > 0;
  }

  getApartmentsByPONumber() {
    const poNumber = this.route.snapshot.paramMap.get('production-order-number');
    this.loading = true;
    this.apartmentsService.getApartmentsByPONumber(poNumber).subscribe((apartments: Apartment[]) => {
      this.loading = false;
      this.apartmentsDataSource.data = apartments;
    }, err => {
      console.error(err);
      this.loading = false;
      this.snackBarService.showError('Error getting apartments.');
    });
  }

}
