import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from '../../services/snackbar.service';
import { CodeType, PromoCode } from '../../models/promo-code';
import { PromoCodesService } from '../../services/promo-codes.service';
import * as moment from 'moment';

const LOADING_ANIMATION_TIMEOUT = 500; // ms

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.less']
})
export class PromoCodesComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'code', 'type', 'amount', 'startDate', 'endDate', 'numberOfAllowedUses', 'numberOfUsesPerCustomer', 'criteria', 'active', 'dateCreated', 'createdBy', 'actions'];

  isActiveFilter = false;
  codeFilter = new UntypedFormControl();
  typeFilter = new UntypedFormControl();
  startDateFilter = new UntypedFormControl();
  endDateFilter = new UntypedFormControl();
  createdByFilter = new UntypedFormControl();

  loading = false;
  filtersApplied = false;
  promoCodeTypes: string[] = [];

  promoCodesDataSource = new MatTableDataSource<PromoCode>();
  filteredPromoCodesDataSource = new MatTableDataSource<PromoCode>();

  promoCodesSort: MatSort;

  @ViewChild('promoCodesSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.promoCodesSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private promoCodesService: PromoCodesService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getPromoCodeTypes();
    this.getPromoCodes();
  }

  ngAfterViewInit() {
    // sort options
    this.promoCodesDataSource.sort = this.promoCodesSort;
    this.filteredPromoCodesDataSource.sort = this.promoCodesSort;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  getData(): PromoCode[] {
    return this.getPromoCodesDataSource().connect().value;
  }

  getPromoCodesDataSource(): MatTableDataSource<PromoCode> {
    return this.filtersApplied ? this.filteredPromoCodesDataSource : this.promoCodesDataSource;
  }

  getPromoCodeTypes(): void {
    this.promoCodesService.getCodeTypes().subscribe((codeTypes: CodeType[]) => {
      this.promoCodeTypes = codeTypes.map(t => this.formatCodeType(t));
    }, err => {
      console.error('Error getting code types:', err);
      this.snackBarService.showError('Error getting code types.');
    });
  }

  getPromoCodes(): void {
    this.showLoading();
    this.promoCodesService.getPromoCodes().subscribe((promoCodes: PromoCode[]) => {
      this.promoCodesDataSource.data = promoCodes;
      this.hideLoading();
    }, err => {
      console.error('Error getting promo codes:', err);
      this.snackBarService.showError('Error getting promo codes.');
      this.hideLoading();
    });
  }

  filterByCode(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => p.code.toLowerCase().includes(this.codeFilter.value.toLowerCase()));
  }

  filterByType(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => this.formatCodeType(p.type) === this.typeFilter.value);
  }

  filterByGreaterThanStartDate(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => p.startDate.getTime() >= this.startDateFilter.value.getTime());
  }

  filterByLessThanEndDate(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => p.endDate.getTime() <= this.endDateFilter.value.getTime());
  }

  filterByCreatedBy(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => p.createdBy.toLowerCase().includes(this.createdByFilter.value.toLowerCase()));
  }

  filterByIsActive(): PromoCode[] {
    return this.filteredPromoCodesDataSource.data.filter(p => p.isActive);
  }

  applyFilters(): void {
    this.showLoading();

    this.filteredPromoCodesDataSource.data = this.promoCodesDataSource.data;

    if (this.codeFilter.valid && this.codeFilter.value) {
      this.filteredPromoCodesDataSource.data = this.filterByCode();
      this.filtersApplied = true;
    }
    if (this.typeFilter.valid && this.typeFilter.value) {
      this.filteredPromoCodesDataSource.data = this.filterByType();
      this.filtersApplied = true;
    }
    if (this.startDateFilter.valid && this.startDateFilter.value) {
      this.filteredPromoCodesDataSource.data = this.filterByGreaterThanStartDate();
      this.filtersApplied = true;
    }
    if (this.endDateFilter.valid && this.endDateFilter.value) {
      this.filteredPromoCodesDataSource.data = this.filterByLessThanEndDate();
      this.filtersApplied = true;
    }
    if (this.createdByFilter.valid && this.createdByFilter.value) {
      this.filteredPromoCodesDataSource.data = this.filterByCreatedBy();
      this.filtersApplied = true;
    }
    if (this.isActiveFilter) {
      this.filteredPromoCodesDataSource.data = this.filterByIsActive();
      this.filtersApplied = true;
    }

    setTimeout(() => {
      this.hideLoading();
    }, LOADING_ANIMATION_TIMEOUT);
  }

  clearFilters() {
    this.codeFilter.setValue('');
    this.typeFilter.setValue('');
    this.startDateFilter.setValue('');
    this.endDateFilter.setValue('');
    this.createdByFilter.setValue('');
    this.isActiveFilter = false;
    this.filteredPromoCodesDataSource.data = [];
    this.filtersApplied = false;
  }

  copyToClipboard(text: string) {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');  // Security exception may be thrown by some browsers.
        this.snackBarService.showSuccess('Promo Code was copied successfully to clipboard.');
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  format(word: string, delimiter: string = ' '): string {
    let result = '';
    if (word) {
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        const prevChar = word[i - 1];
        const tokenDelimiter = !isNaN(+char) && !isNaN(+prevChar) ? '' : delimiter;
        result += char === char.toUpperCase() ? `${tokenDelimiter}${char}` : char;
      }
    }
    return result.trim();
  }

  formatCodeType(type: CodeType): string {
    return this.format(CodeType[type]) || 'Unknown';
  }

  formatDate(date: Date): string {
    return date ? moment(date).format('l') : '';
  }

  formatBoolean(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  formatAmount(type: CodeType, value: number): string {
    switch (type) {
      case CodeType.FlatAmountOffPurchase:
        return `$${value}`;
      case CodeType.PercentAmountOffPurchase:
        return `${value}%`;
      case CodeType.ShippingDiscount:
        return `$${value}`;
      case CodeType.SomeNumberOfFreeCustomItems:
        return `${value}`;
      case CodeType.NoChargeParts:
        return 'No charge';
      default:
        return `${value}`;
    }
  }

  downloadCSV(): void {
    const rows = this.getData();
    const columns = ['Code', 'Type', 'Amount', 'Start Date', 'End Date', 'Number Of Allowed Uses', 'Active', 'Date Created', 'Created By'];
    let csvContent = `data:text/csv;charset=utf-8, ${columns.join(',')}\n`;

    for (const row of rows) {
      csvContent += `${row.code}, ${this.formatCodeType(row.type)}, ${row.amount}, ${this.formatDate(row.startDate)}, ${this.formatDate(row.endDate)}, ${row.numberOfAllowedUses} , ${this.formatBoolean(row.isActive)}, ${this.formatDate(row.dateCreated)}, ${row.createdBy}  \n`;
    }

    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute('download', 'promo-codes.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
