import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProductionLocationsService } from 'src/app/services/production-locations.service';
import { ProductionLocation } from 'src/app/models/production-location';

@Component({
  selector: 'app-production-location-details',
  templateUrl: './production-location-details.component.html',
  styleUrls: ['./production-location-details.component.less']
})
export class ProductionLocationDetailsComponent implements OnInit {

  productionLocation: ProductionLocation;

  constructor(
    private route: ActivatedRoute,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionLocation();
  }

  getProductionLocation() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLocationsService.getProductionLocation(id).subscribe((productionLocation: ProductionLocation) => {
      this.productionLocation = productionLocation;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production location details.');
      });
  }

}
