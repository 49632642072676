import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RebateGroup } from '../../../models/rebate-group';
import { Customer } from '../../../models/customer';
import { AccountingService } from '../../../services/accounting.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ApartmentComplex } from '../../../models/apartment-complex';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-rebate-group-assignments-details',
  templateUrl: './rebate-group-assignments-details.component.html',
  styleUrls: ['./rebate-group-assignments-details.component.less']
})
export class RebateGroupAssignmentsDetailsComponent implements OnInit {

  customer: Customer;
  communityFilter = new UntypedFormControl();
  rebateGroupsAssignments: RebateGroup[] = [];
  rebateGroupCommunitiesAssignments: ApartmentComplex[] = [];
  filteredRebateGroupCommunitiesAssignments: ApartmentComplex[] = [];
  filtersApplied = false;

  rebateGroupsDisplayedColumns: string[] = ['id', 'name', 'percentage', 'startPeriod', 'endPeriod', 'dateModified', 'isActive'];
  communitiesDisplayedColumns: string[] = ['id', 'name', 'rebateGroup', 'percentage', 'startPeriod', 'endPeriod', 'dateModified', 'isActive'];

  constructor(
    private accountingService: AccountingService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<RebateGroupAssignmentsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer }) { }

  ngOnInit() {
    this.customer = this.data.customer;
    this.getRebateGroupsAssignments();
    this.getRebateGroupsCommunitiesAssignments();
  }

  getCommunitiesAssignmentsData(): ApartmentComplex[] {
    return this.filtersApplied ? this.filteredRebateGroupCommunitiesAssignments : this.rebateGroupCommunitiesAssignments;
  }

  getRebateGroupsAssignments(): void {
    const customerId = this.customer.id;
    this.accountingService.getRebateGroupsAssignments(customerId).subscribe((rebateGroups: RebateGroup[]) => {
      this.rebateGroupsAssignments = rebateGroups;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting rebate groups.');
    });
  }

  getRebateGroupsCommunitiesAssignments(): void {
    const customerId = this.customer.id;
    this.accountingService.getRebateGroupsCommunitiesAssignments(customerId).subscribe((communitiesAssignments: ApartmentComplex[]) => {
      this.rebateGroupCommunitiesAssignments = communitiesAssignments;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting communities.');
    });
  }

  applyFilters(): void {
    this.filteredRebateGroupCommunitiesAssignments = this.rebateGroupCommunitiesAssignments;

    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredRebateGroupCommunitiesAssignments = this.filterByCommunity();
      this.filtersApplied = true;
    }
  }

  filterByCommunity(): ApartmentComplex[] {
    return this.filteredRebateGroupCommunitiesAssignments.filter(c => c.name.toLowerCase().includes(this.communityFilter.value.trim().toLowerCase()));
  }

  clearFilters(): void {
    this.filtersApplied = false;
    this.communityFilter.setValue('');
    this.filteredRebateGroupCommunitiesAssignments = [];
  }

}
