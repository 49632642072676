<mat-card>
  <mat-card-title>
    
  </mat-card-title>
  <mat-card-content class="marginTbl">
    <div class="row">
        <div class="columnOrderH">
          <h1 class="mat-h1">
            Order History
          </h1>
        </div>
        <div class="columnOrderH">
          <mat-label>*Last 2 Months*</mat-label>
        </div>
      </div>

      <div class="loading" *ngIf="loadingCustomerOrderHistory">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Loading Customer Order History...</span>
      </div>
      <div class="loading" *ngIf="gettingAck">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Downloading Acknowledgement...</span>
      </div>
      <div class="loading" *ngIf="gettingPackList">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Downloading Packing List...</span>
      </div>
      <div class="loading" *ngIf="gettingPackagePlanning">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Generating Package Planning...</span>
      </div>
      <div class="filters" *ngIf="!loadingCustomerOrderHistory && _customerOrderHistory.length > 0">
        <div class="filters-container">
          <div>
            <form style="display: flex;" [formGroup]="formControl">
              <mat-form-field>
                <mat-icon matSuffix>search</mat-icon>
                <input matInput formControlName="word" placeholder="Keyword" >
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
      <h3 style="text-align: center;" 
          *ngIf="!loadingCustomerOrderHistory && _customerOrderHistory.length === 0">
          No order history to show.
      </h3>
      <div *ngIf="!loadingCustomerOrderHistory && _customerOrderHistory.length > 0" class="coh-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource"  class="mat-elevation-z8 table" matSort #tblSort="matSort">
            
          <tr mat-header-row *matHeaderRowDef="customerOrderHistoryDisplayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: customerOrderHistoryDisplayedColumns;"></tr>

          <ng-container matColumnDef="apartmentId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apartment Id </th>
            <td mat-cell  *matCellDef="let element"> {{element?.apartmentId}}</td>
          </ng-container>

          <ng-container matColumnDef="communityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Community Name </th>
            <td mat-cell  *matCellDef="let element"> {{element?.communityName}} </td>
          </ng-container>
        
          <ng-container matColumnDef="apartmentNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apartment Number</th>
            <td mat-cell  *matCellDef="let element">{{element.apartmentNumber}}</td>
          </ng-container>
        
          <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Order Number</th>
            <td mat-cell  *matCellDef="let element">{{element.salesOrderNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="customerPoNumber">
            <th mat-header-cell *matHeaderCellDef>Customer PO Number</th>
            <td mat-cell  *matCellDef="let element">{{element.customerPONumber}}</td>
          </ng-container>
        
          <ng-container matColumnDef="orderDate">
            <th mat-header-cell *matHeaderCellDef>Order Date</th>
            <td mat-cell  *matCellDef="let element">{{element.orderDate | date : 'MM/dd/yyyy'}}</td>
          </ng-container>

          <ng-container matColumnDef="orderedBy">
              <th mat-header-cell *matHeaderCellDef>Ordered By</th>
              <td mat-cell  *matCellDef="let element">{{element.orderedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Price</th>
              <td mat-cell  *matCellDef="let element">{{element.price | currency}}</td>
            </ng-container>

            <ng-container matColumnDef="shipDate">
              <th mat-header-cell *matHeaderCellDef>Ship Date</th>
              <td mat-cell  *matCellDef="let element">{{element.shipDate | date : 'MM/dd/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="productionDate">
              <th mat-header-cell *matHeaderCellDef>Production Date</th>
              <td mat-cell  *matCellDef="let element">{{element.productionDate | date :'MM/dd/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledgement">
              <th mat-header-cell *matHeaderCellDef>Acknowledgement</th>
              <td mat-cell  *matCellDef="let element">
                <button mat-raised-button (click)="getOrderAcknowledgement(element)">
                  <mat-icon>download</mat-icon>
              </button>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="packingList">
              <th mat-header-cell *matHeaderCellDef>Packing List</th>
              <td mat-cell  *matCellDef="let element">
                <button mat-raised-button (click)="getpackageListAll(element)">
                  <mat-icon>download</mat-icon>
              </button>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="numberOfCustomParts">
              <th mat-header-cell *matHeaderCellDef># Of Custom Parts</th>
              <td mat-cell  *matCellDef="let element" >{{element.totalCustomParts}}</td>
            </ng-container>
            
            <ng-container matColumnDef="trackingNumber">
              <th mat-header-cell *matHeaderCellDef>Tracking Number</th>
              <td mat-cell  *matCellDef="let element">
                <div class="ship">
                  <span  *ngIf="element.carrier !== 'CPU'" >
                    <div>{{element.status}}</div>
                      <span  *ngIf="element.status === 'Shipped'" >
                        <span  *ngIf="element.carrier === 'UPS'" >
                          <div>UPS tracking:</div>
                          <div><a target="_blank" href="https://wwwapps.ups.com/tracking/tracking.cgi?tracknum={{element.shippingTrackingNumber}}">#{{element.shippingTrackingNumber| slice: 0:10}}</a></div>
                        </span>
                        <span  *ngIf="element.carrier === 'FedEx'" >
                          <div>FedEx tracking:</div>
                          <div><a target="_blank" href="https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{element.shippingTrackingNumber}}&cntry_code=us">#{{element.shippingTrackingNumber| slice: 0:10}}</a></div>
                        </span>
                      </span>
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="shippingMethod">
              <th mat-header-cell *matHeaderCellDef>Shipping Method</th>
              <td mat-cell  *matCellDef="let element">{{element.shippingMethod}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell  *matCellDef="let element">{{element.status}}</td>
            </ng-container>

            <ng-container matColumnDef="more">
              <th mat-header-cell *matHeaderCellDef>More</th>
              <td mat-cell  *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu">...</button>
                  <mat-menu #menu="matMenu">
                    <button mat-raised-button (click)="getPackagePlanning(element)">Generate Package Planning</button>
                </mat-menu>
                <!-- <button mat-raised-button (click)="getPackagePlanning(element)" >
                  <mat-icon>download</mat-icon> -->
              </td>
            </ng-container>
      </table>
      </div>
  </mat-card-content>
</mat-card>