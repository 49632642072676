import { Component, EventEmitter, Output } from '@angular/core';
import { FedexAddress } from '../../../models/address-detail';
import { ApartmentsService } from '../../../services/apartments.service';
import { OvernightService } from '../../../services/overnight.service';
import { ProductionOrdersService } from '../../../services/production-orders.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AlertOvernightProcessComponent } from './alert-overnight-process/alert-overnight-process.component';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.less']
})
export class OrderSearchComponent {
  @Output() showContentEvent = new EventEmitter<boolean>();

  salesOrderNumber: string = "";

  constructor(
    private snackBarService: SnackbarService,
    private productionOrdersService: ProductionOrdersService,
    private overnightService: OvernightService,
    public dialog: MatDialog,
    private apartmentsService: ApartmentsService
  ) { }

  async search() {
    this.overnightService.$ratesTransitTimes.next(true);
    this.overnightService.customerAddress = null;

    if (this.salesOrderNumber == "") {
      return;
    } else if (!this.salesOrderNumber.toLowerCase().startsWith('so-')) {
      this.snackBarService.showWarning('Sales Order Number filter must start with SO-.');
      return;
    }

    let cacheOriginAddress = localStorage.getItem("originAddress");
    if (!cacheOriginAddress) {
      this.overnightService.getOriginAddress()
        .subscribe((value: FedexAddress) => {
          this.overnightService.$originAddress.next(value);
        });
    }

    this.overnightService.getSurcharge()
      .subscribe((value: number) => {
        this.overnightService.surcharge = value;
      });

    this.productionOrdersService.getIsRetail(this.salesOrderNumber)
      .subscribe((value: boolean) => {
        this.overnightService.isRetail = value;
        this.overnightService.$isRetail.next(value);
      });

    forkJoin({
      customerInfo: this.productionOrdersService.getCustomerInfo(this.salesOrderNumber),
      packages: this.apartmentsService.getPackagesAndShipmentDetails(this.salesOrderNumber)
    }).pipe(
      catchError(() => {
        return of(null);
      })
    ).subscribe(({ customerInfo, packages }) => {
      if (!customerInfo || packages.length === 0) {
        this.showContentEvent.emit(false);
        return;
      }

      this.overnightService.customerAddress = customerInfo;
      this.overnightService.customerAddress.country = "US";
      this.overnightService.salesOrderNumber = this.salesOrderNumber;
      this.overnightService.$customerAddress.next(this.overnightService.customerAddress);
      this.overnightService.packages = packages;
      this.overnightService.$packagesDetails.next(packages);

      let packagesIds = packages.map((x: { id: number; }) => x.id).toString();
      this.overnightService.hasPaintPrimer(packagesIds).subscribe((hasPaintPrimer: boolean) => {
        this.overnightService.existingPaintPrimer = hasPaintPrimer;

        if (hasPaintPrimer) {
          this.openPaintPrimerModal();
        }
      });

      this.overnightService.isPackagingStatus(this.salesOrderNumber).subscribe((isPackagingStatus: boolean) => {
        this.overnightService.packagingStatus = isPackagingStatus;

        if (isPackagingStatus) {
          this.openPackagingStatusModal();
        }
      });

      this.showContentEvent.emit(true);
    });
  }

  openPaintPrimerModal() {
    this.dialog.open(AlertOvernightProcessComponent, {
      width: '540px',
      height: '240px',
      disableClose: true,
      data: {
        title: "Paint or Primer in Packages",
        description: "Paint and Primer cannot be shipped overnight. Please remove them from the order and try again."
      }
    });
  }
  openPackagingStatusModal() {
    this.dialog.open(AlertOvernightProcessComponent, {
      width: '540px',
      height: '240px',
      disableClose: true,
      data: {
        title: "Packaged Order",
        description: "We are not able to ship this order overnight because it is already being packaged."
      }
    });
    this.overnightService.$ratesTransitTimes.next(true);
  }
}
