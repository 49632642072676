<mat-card>
    <h1 class="mat-h1">
        Customer Account Visibility
    </h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}" (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filters customers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters" (keyup.enter)="applyFilters()">
                <div class="filters-container toHandleClickEvent" (click)="onClickEventHandler($event)">
                    <div class="toHandleClickEvent">
                        <mat-form-field>
                            <input matInput placeholder="Customer #" [formControl]="customerNumberFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Customer" [formControl]="customerFilter">
                        </mat-form-field>
                        <mat-form-field *ngIf="!salesPersonsCharge">
                            <mat-label>Select individuals</mat-label>
                            <mat-select #dllTeamSales [formControl]="reportSaleRepFilter" multiple (keydown)="onEnterKeyEventHandler($event)">
                                <mat-select-trigger>
                                    {{reportSaleRepFilter.value ? reportSaleRepFilter.value[0]?.salesPersonName : ''}}
                                    <span *ngIf="reportSaleRepFilter.value?.length > 1" class="additional-selection">
                                      (+{{reportSaleRepFilter.value.length - 1}} {{reportSaleRepFilter.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [value]="{ salesPersonId: 0, salesPersonName: 'All' }" (onSelectionChange)="onAllSelectionChange($event.source)">All</mat-option>
                                <mat-option *ngFor="let item of salesPersons" [value]="item">
                                    {{ item.salesPersonName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="checkboxes toHandleClickEvent">
                        <mat-checkbox [(ngModel)]="isBlocked" [disabled]="disableProfileControl">Is Blocked</mat-checkbox>
                        <mat-checkbox [(ngModel)]="withHighBalance" *ngIf="creditLimitRatio !== 0" [disabled]="disableProfileControl">High Balance to Credit Limit Ratio ({{ creditLimitRatio }}%)</mat-checkbox>
                    </div>
                </div>

                <button #filtersButton mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()" (keyup.enter)="stopEventPropagation($event)" [disabled]="disableProfileControl">
              <mat-icon>filter_list</mat-icon>
            </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
<div class="reset-action-container" *ngIf="customersFiltersApplied">
    <button mat-raised-button (click)="clearCustomersFilters()">
  <mat-icon>clear</mat-icon> Reset Customers Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblCommissionCustomers *ngIf="!loading && getData().length > 0" [dataSource]="getCustomerAccountDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Number </th>
            <td mat-cell *matCellDef="let row"> {{ row.number}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let row"> {{ row.name}} </td>
        </ng-container>
        <ng-container matColumnDef="salesPerson">
            <th mat-header-cell *matHeaderCellDef> Assigned Rep </th>
            <td mat-cell *matCellDef="let row"> {{ row?.salesPerson }} </td>
        </ng-container>
        <ng-container matColumnDef="blocked">
            <th mat-header-cell *matHeaderCellDef> Blocked </th>
            <td mat-cell *matCellDef="let row"> {{ row?.blocked }} </td>
        </ng-container>
        <ng-container matColumnDef="creditLimit">
            <th mat-header-cell *matHeaderCellDef> Credit Limit </th>
            <td mat-cell *matCellDef="let row"> {{row.creditLimit | currency }} </td>
        </ng-container>
        <ng-container matColumnDef="openOrders">
            <th mat-header-cell *matHeaderCellDef> Open Orders </th>
            <td mat-cell *matCellDef="let row"> {{ row.openOrders | currency }} </td>
        </ng-container>
        <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef> Balance </th>
            <td mat-cell *matCellDef="let row"> {{row.balance | currency }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
