import { Component, OnInit } from '@angular/core';
import { RatesAndTransitTime, ServiceTypeOvernight } from '../../../models/rates-transit-time';
import { OvernightService } from '../../../services/overnight.service';
import * as moment from 'moment';
import { MatRadioChange } from '@angular/material/radio';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OvernightQuote } from '../../../models/overnight-quote';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmailSendingConfirmationComponent } from './email-sending-confirmation/email-sending-confirmation.component';
import { FedexAddress } from '../../../models/address-detail';

const SERVICE_TRANSIT_TIME_DATE_FORMAT = "hh:mm a ddd MMM DD, YYYY";

@Component({
  selector: 'app-rates-transit-times',
  templateUrl: './rates-transit-times.component.html',
  styleUrls: ['./rates-transit-times.component.less']
})
export class RatesTransitTimesComponent implements OnInit {

  displayedColumns: string[] = ['select', 'serviceAndTransitTimes', 'rate'];
  source: RatesAndTransitTime[];
  loading = false;
  rowSelected: RatesAndTransitTime;
  error: string;

  constructor(
    public overnightService: OvernightService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.overnightService.$ratesTransitTimes.subscribe((value: boolean) => {
      if (value) {
        this.error = null;
        this.source = null;
        this.rowSelected = null;
      }
    });
  }

  async getRates() {
    this.error = null;
    this.rowSelected = null;

    let destinationAddress: FedexAddress = {
      City: this.overnightService.customerAddress.city,
      CountryCode: this.overnightService.customerAddress.country,
      PostalCode: this.overnightService.customerAddress.zip,
      StateOrProvince: this.overnightService.customerAddress.state,
      StreetLines: [
        this.overnightService.customerAddress.address1,
        this.overnightService.customerAddress.address2
      ]
    };
    let shipDate = this.overnightService.shipDate;
    let packagesIds = this.overnightService.packages.map(x => x.id);

    this.showLoading();

    this.overnightService.getShippingCost(destinationAddress, shipDate, packagesIds).subscribe((result) => {
      this.source = result.map((x) => {
        const index = Object.keys(ServiceTypeOvernight).indexOf(x.ServiceType);
        const serviceType = Object.values(ServiceTypeOvernight)[index];

        return {
          select: false,
          Rate: x.Rate,
          ServiceType: serviceType,
          Time: x.Time,
          display: {
            select: false,
            serviceAndTransitTimes: `${serviceType} ${moment(x.Time).format(SERVICE_TRANSIT_TIME_DATE_FORMAT)}`,
            rate: x.Rate + (x.Rate * (this.overnightService.surcharge / 100))
          }
        };
      });

      this.hideLoading();
    }, (err) => {
      this.error = err == null ? "Unable to get Rates, please try again later." : err.error.ExceptionMessage;
      this.hideLoading();
    });
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  radioSelected(event: MatRadioChange) {
    this.rowSelected = event.value;
  }

  sendEmail() {
    let model: OvernightQuote = {
      salesOrderNumber: this.overnightService.salesOrderNumber,
      customerId: this.overnightService.customerAddress.customerId,
      deliveryDate: this.rowSelected.Time,
      shipDate: new Date(this.overnightService.shipDate),
      rate: this.rowSelected.Rate,
      serviceType: this.rowSelected.ServiceType,
      customerEmail: this.overnightService.customerAddress.invoicingEmail
    };

    this.overnightService.createOvernightQuote(model).subscribe(() => {
      this.snackBarService.showSuccess("Email sent successfully!");
    });
  }

  openConfirmationModal() {
    let dialog = this.dialog.open(EmailSendingConfirmationComponent, {
      width: '540px',
      height: '240px',
      disableClose: true,
      data: {
        invoicingEmail: this.overnightService.customerAddress.invoicingEmail,
        serviceAndTransitTimes: this.rowSelected.display.serviceAndTransitTimes,
        rate: this.rowSelected.Rate + (this.rowSelected.Rate * (this.overnightService.surcharge / 100))
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.sendEmail();
      }
    });
  }
}
