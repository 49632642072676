import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from 'src/app/models/account';
import { AccountRole } from '../models/account-role';
import { ChangePassword } from '../models/change-password';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getAccounts(): Observable<Account[]> {
    const url = this.config.buildURL('accounts');
    return this.http.get<Account[]>(url);
  }

  getAccount(userId: string): Observable<Account> {
    const url = this.config.buildURL(`accounts/${userId}`);
    return this.http.get<Account>(url);
  }

  createAccount(model: Account): Observable<Account> {
    const url = this.config.buildURL(`accounts`);
    return this.http.post<Account>(url, model);
  }

  updateAccount(model: Account): Observable<Account> {
    const url = this.config.buildURL(`accounts/${model.id}`);
    return this.http.put<Account>(url, model);
  }

  deleteAccount(userId: string) {
    const url = this.config.buildURL(`accounts/${userId}`);
    return this.http.delete(url);
  }

  resetPassword(userId: string) {
    const url = this.config.buildURL(`accounts/reset-password`);
    return this.http.put(url, JSON.stringify(userId));
  }

  changePassword(model: ChangePassword) {
    const url = this.config.buildURL(`accounts/change-password`);
    return this.http.put(url, model);
  }

  getRoles(userId: string): Observable<AccountRole[]> {
    const url = this.config.buildURL(`accounts/roles/${userId}`);
    return this.http.get<AccountRole[]>(url);
  }

  updateRoles(userId: string, roles: AccountRole[]): Observable<AccountRole[]> {
    const url = this.config.buildURL(`accounts/roles/${userId}`);
    return this.http.post<AccountRole[]>(url, roles);
  }
}
