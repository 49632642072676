<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Delete Router Setup
    </h1>
    <hr />
    <div *ngIf="routerSetup" class="content">
        <ul>
            <li><strong>Id:</strong> {{routerSetup?.id}}</li>
            <li>
                <strong>Router Schedule:</strong>
                <a mat-icon-button color="accent" matTooltip="Router Schedule Details" matTooltipPosition="above"
                    routerLink="/router-schedules/details/{{routerSetup?.routerScheduleId}}">
                    <mat-icon>open_in_new</mat-icon>
                </a>
            </li>
            <li><strong>Router Schedule Id:</strong> {{routerSetup?.routerScheduleId}}</li>
            <li><strong>Colors:</strong> {{routerSetup?.colors}}</li>
            <li><strong>Styles:</strong> {{routerSetup?.styles}}</li>
            <li><strong>Max Parts In Batch:</strong> {{routerSetup?.maxPartsInBatch}}</li>
            <li><strong>Date Created:</strong> {{routerSetup?.dateCreated | date}}</li>
            <li><strong>Created By:</strong> {{routerSetup?.createdByUserId}}</li>
            <li><strong>Date Modified:</strong> {{routerSetup?.dateModified | date}}</li>
            <li><strong>Modified By:</strong> {{routerSetup?.modifiedByUserId}}</li>
        </ul>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="deleteRouterSetup()">Delete</button>
    </div>
</mat-card>