import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProductionCalendarService } from '../../../services/production-calendar.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProductionCalendarComponent } from '../production-calendar.component';
import { MaterialSupplier } from '../../../models/material-supplier';
import { MaterialSupplierConfiguration } from '../../../models/material-supplier-configuration';
import { EditForm } from '../../../forms/edit-form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-material-suppliers',
  templateUrl: './edit-material-suppliers.component.html',
  styleUrls: ['./edit-material-suppliers.component.less']
})
export class EditMaterialSuppliersComponent extends EditForm implements OnInit {

  startDate: Date;
  endDate: Date;
  materialSuppliers: MaterialSupplier[] = [];
  materialSuppliersForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackBarService: SnackbarService,
    private productionCalendarService: ProductionCalendarService,
    private dialogRef: MatDialogRef<ProductionCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { productionLocationId: number },
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getMaterialSuppliers();
  }

  createForm(): void {
    this.materialSuppliersForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      materialSupplierId: ['', Validators.required],
      productionLocationId: ['', Validators.required],
    });
  }

  isValid(): boolean {
    const startDate = this.materialSuppliersForm.get('startDate').value;
    const endDate = this.materialSuppliersForm.get('endDate').value;
    const days = moment(endDate).startOf('day').diff(moment(startDate).startOf('day'), 'days');

    if (startDate && endDate && days < 0) {
      this.materialSuppliersForm.get('endDate').setErrors({ min: true });
    }

    return this.materialSuppliersForm.valid;
  }

  setValues() {
    this.materialSuppliersForm.setValue({
      startDate: '',
      endDate: '',
      materialSupplierId: '',
      productionLocationId: this.data.productionLocationId
    });
  }

  getData(): MaterialSupplierConfiguration {
    const data: MaterialSupplierConfiguration = {
      startDate: this.materialSuppliersForm.get('startDate').value,
      endDate: this.materialSuppliersForm.get('endDate').value,
      materialSupplierId: this.materialSuppliersForm.get('materialSupplierId').value,
      productionLocationId: this.materialSuppliersForm.get('productionLocationId').value,
    };

    return data;
  }

  submit(): void {
    this.reset();
    if (this.isValid()) {
      this.updateMaterialSuppliers();
    }
  }

  getMaterialSuppliers() {
    this.productionCalendarService.getMaterialSuppliers().subscribe((materialSuppliers: MaterialSupplier[]) => {
      this.materialSuppliers = materialSuppliers;
      this.setValues();
    }, err => {
      console.error('Error getting material suppliers.', err);
      this.snackBarService.showError('Error getting material suppliers.');
    });
  }

  updateMaterialSuppliers() {
    const data: MaterialSupplierConfiguration = this.getData();
    this.productionCalendarService.updateMaterialSuppliers(data).subscribe(() => {
      this.dialogRef.close(true);
    }, err => {
      console.error('Error updating material suppliers.', err);
      this.snackBarService.showError('Error updating material suppliers.');
    });

  }
}
