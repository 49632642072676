import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
// Models
import { CommissionService } from '../../../services/commission.service';
import { CommissionCommunities } from '../../../models/commission-communities';
// Services
import { SnackbarService } from 'src/app/services/snackbar.service';
// Material
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// Components
import { CommunityModalCommissionsComponent } from '../community-modal-commissions/community-modal-commissions.component';

@Component({
  selector: 'app-communities-commissions',
  templateUrl: './communities-commissions.component.html',
  styleUrls: ['./communities-commissions.component.less']
})
export class CommunitiesCommissionsComponent implements OnInit {
  loading = false;
  titleCustomer = '';
  customerRol = 0;
  customersFiltersApplied = false;

  @ViewChild(MatTable) tblCommissionCustomers: MatTable<CommissionCommunities>;
  commissionCommunitiesDataSource = new MatTableDataSource<CommissionCommunities>();
  filteredCommissionCommunitiesDataSource = new MatTableDataSource<CommissionCommunities>();
  displayedColumns: string[] = [
    'communityID', 'communityName', 'dateActive', 'lastOrderDate', 'newSalesOverride', 'assignedReps', 'splitType', 'currentCommissionType', 'leadSource', 'actions'
  ];

  communityFilter = new UntypedFormControl();

  filterFormPanelDisplaced = true;
  editFormPanelDisplaced = true;

  withSplitCommunities = false;

  constructor(
    private route: ActivatedRoute,
    private navigation: Router,
    private commissionService: CommissionService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
  ) {
    this.titleCustomer = this.route.snapshot.paramMap.get('customer');
    this.customerRol = Number(this.route.snapshot.paramMap.get('rol'));
  }

  async ngOnInit(): Promise<void> {
    await this.getCommissionCommunities();
  }

  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  getData(): CommissionCommunities[] {
    return this.commissionCommunitiesDataSource.connect().value;
  }

  private filterByCommunities(): CommissionCommunities[] {
    return this.filteredCommissionCommunitiesDataSource.data.filter(r => r.communityName.toLowerCase().includes(this.communityFilter.value.trim().toLowerCase()));
  }

  private filterByWithSplitCommunities(): CommissionCommunities[] {
    return this.filteredCommissionCommunitiesDataSource.data.filter(r => r.assignedReps !== '' && r.splitType !== '');
  }

  applyFilters() {
    this.filteredCommissionCommunitiesDataSource.data = this.commissionCommunitiesDataSource.data;

    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredCommissionCommunitiesDataSource.data = this.filterByCommunities();
      this.customersFiltersApplied = true;
    }

    if (this.withSplitCommunities) {
      this.filteredCommissionCommunitiesDataSource.data = this.filterByWithSplitCommunities();
      this.customersFiltersApplied = true;
    }
  }

  clearCommunitiesFilters() {
    this.withSplitCommunities = false;
    this.communityFilter.setValue('');
    this.customersFiltersApplied = false;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  getCommissionCommunities(): Promise<void> {
    this.showLoading();
    return new Promise<void>((resolve, reject) => {
      const id = this.route.snapshot.paramMap.get('id');
      this.commissionService.getCommissionCommunitiesById(Number(id)).subscribe((commissionCommunities: CommissionCommunities[]) => {
        this.commissionCommunitiesDataSource.data = commissionCommunities;
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error getting commission communities for this customer.');
        reject();
      }, () => {
        this.hideLoading();
        resolve();
      });
    });
  }

  getCommissionCustomersDataSource(): MatTableDataSource<CommissionCommunities> {
    return this.customersFiltersApplied ? this.filteredCommissionCommunitiesDataSource : this.commissionCommunitiesDataSource;
  }

  openCommunityModal(community: CommissionCommunities) {
    this.dialog.open(CommunityModalCommissionsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        selection: community
      }
    }).afterClosed().subscribe(result => {
      if (result.request) {
        this.commissionCommunitiesDataSource.data[this.commissionCommunitiesDataSource.data.findIndex(f => f === community)] = result.request;
      }
      switch (result.responseState) {
        case -1:
          // save without save
          break;
        case 1:
          // save all changes
          this.snackBarService.showSuccess(
            'The Splits configuration was saved successfully.'
          );
          break;
          case 2:
            // save changes and new items but don't delete some sale split record
            this.snackBarService.showWarning(
              'The Splits configuration was saved successfully, But some delete changes were not saved properly.'
            );
            break;
        default:
          this.snackBarService.showError(
            'Error to save the sales splits configuration.'
          );
          break;
      }
      this.tblCommissionCustomers.renderRows();
    });
  }

  goBack() {
    this.navigation.navigate(['commissions', { tab: 2 }]);
  }
}
