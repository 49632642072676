import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerInfo } from 'src/app/models/customer-info';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.less']
})
export class CustomerInfoComponent {
  @Input() custInfo: CustomerInfo;
  public math = Math;

    constructor(){
      
    }

}
