import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StrategicExpedited } from 'src/app/models/strategic-expedited';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-delete-strategic-expedited',
  templateUrl: './delete-strategic-expedited.component.html',
  styleUrls: ['./delete-strategic-expedited.component.less']
})
export class DeleteStrategicExpeditedComponent implements OnInit {

  strategicExpeditedSettings: StrategicExpedited;
  strategicExpeditedSettingId: number;
  errorMessages: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.strategicExpeditedSettingId = +params['id']; 
      this.loadStrategicExpeditedSettingById(this.strategicExpeditedSettingId);
    });
  }

  loadStrategicExpeditedSettingById(id: number) {
    this.settingsService.getStrategicExpeditedById(id).subscribe(
      (response: StrategicExpedited) => {
        this.strategicExpeditedSettings = response;
      },
      err => {
        console.error('Error deleting strategic expedited setting by id', err);
        this.snackBarService.showError('Error deleting strategic expedited setting by id.');
      }
    );
  }
  
  
  deleteExpeditedProductionSetting() {
    this.settingsService
      .deleteStrategicExpedited(this.strategicExpeditedSettings.id)
      .subscribe(
        () => {
          this.router.navigate([`/settings`]);
          this.snackBarService.showSuccess(
            "Strategic setting deleted successfully."
          );
        },
        (err) => {
          console.error(err);
          this.snackBarService.showError(
            "Error deleting expedited production setting."
          );
        }
      );
  }

}
