import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ReasonCodeConfigModal, ReasonModalState, ReasonPrevent } from 'src/app/models/reason-code-config-modal';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { ReasonGroup } from 'src/app/models/reason-group';
import { ReasonGroupsService } from 'src/app/services/reason-groups.service';

@Component({
  selector: 'app-reason-groups-modal',
  templateUrl: './reason-groups-modal.component.html',
  styleUrls: ['./reason-groups-modal.component.less']
})
export class ReasonGroupsModalComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReasonCodeConfigModal,
    public dialogRef: MatDialogRef<ReasonGroupsModalComponent>,
    private reasonService: ReasonGroupsService,
    private auth: AuthService
  ) { }

  private async disableReasonGroup(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonGroup>((resolve, reject) => {
      try {
        let group: ReasonGroup = this.data.payload;
        group.dateModified = new Date(moment().format());
        group.modifiedBy = this.auth.getAccount().userName;

        this.reasonService.deleteReasonGroup(group.id).subscribe(
          (result: ReasonGroup) => group = result,
          (err) => {
            console.error(err);
            reject();
          },
          () => resolve(group));
      } catch (error) {
        console.error(error);
        reject();
      }
    }).then((result: ReasonGroup) => {
      if (result) {
        this.data.state = ReasonModalState.success;
      } else {
        this.data.state = ReasonModalState.error;
      }
      this.loading = false;
      this.dialogRef.close(this.data);
    });
  }


  async disable() {
    await this.disableReasonGroup();
  }

  close() {
    this.data.state = ReasonModalState.cancel;
    this.dialogRef.close(this.data);
  }
}
