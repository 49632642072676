import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private snackBarService: SnackbarService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const roles = route.data.roles;

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/sign-in']);
      return false;
    }

    if (roles && !this.authService.isUserInRoles(roles)) {
      this.snackBarService.showError('Forbidden. You don\'t have access to this section.');
      this.router.navigate(['/forbidden']);
      return false;
    }

    return true;
  }
}
