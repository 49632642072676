import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ReasonCodeConfigModal, ReasonModalState, ReasonPrevent } from 'src/app/models/reason-code-config-modal';
import { ReasonCodesService } from '../../../services/reason-codes.service';
import { ReasonCode } from '../../../models/reason-code';
import { ReasonCategory } from '../../../models/reason-category';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-reason-codes-modal',
  templateUrl: './reason-codes-modal.component.html',
  styleUrls: ['./reason-codes-modal.component.less']
})
export class ReasonCodesModalComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReasonCodeConfigModal,
    public dialogRef: MatDialogRef<ReasonCodesModalComponent>,
    private reasonService: ReasonCodesService,
    private auth: AuthService
  ) { }

  private async disableReason(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonCode>((resolve, reject) => {
      try {
        let code: ReasonCode = this.data.payload;
        code.modified = new Date(moment().format());
        code.modifiedBy = this.auth.getAccount().userName;

        this.reasonService.disableReasonCode(code).subscribe(
          (result: ReasonCode) => code = result,
          (err) => {
            console.error(err);
            reject();
          },
          () => resolve(code));
      } catch (error) {
        console.error(error);
        reject();
      }
    }).then((result: ReasonCode) => {
      if (result) {
        this.data.state = !result.isActive ? ReasonModalState.success : ReasonModalState.error;
      } else {
        this.data.state = ReasonModalState.error;
      }
      this.loading = false;
      this.dialogRef.close(this.data);
    });
  }

  private async disableCategory(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonCategory>((resolve, reject) => {
      try {
        let category: ReasonCategory = this.data.payload;
        category.modified = new Date(moment().format());
        category.modifiedBy = this.auth.getAccount().userName;

        this.reasonService.disableReasonCategory(category).subscribe(
          (result: ReasonCategory) => category = result,
          (err) => {
            console.error(err);
            reject();
          },
          () => resolve(category));
      } catch (error) {
        console.error(error);
        reject();
      }
    }).then((result: ReasonCategory) => {
      if (result) {
        this.data.state = !result.isActive ? ReasonModalState.success : ReasonModalState.error;
      } else {
        this.data.state = ReasonModalState.error;
      }
      this.loading = false;
      this.dialogRef.close(this.data);
    });
  }

  async disable() {
    switch (this.data.typePrevent) {
      case ReasonPrevent.reason:
        await this.disableReason();
        break;
      case ReasonPrevent.category:
        await this.disableCategory();
        break;
    }
  }

  close() {
    this.data.state = ReasonModalState.cancel;
    this.dialogRef.close(this.data);
  }
}
