import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CustomerLogin } from '../../models/customer-login';
import { CustomerOrderHistory } from '../../models/customer-order-history';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerInfo } from '../../models/customer-info';
import { SnackbarService } from '../../services/snackbar.service';

import { CustomerAuthService } from '../../services/customer-auth.service';
import { Credentials } from '../../models/cinch-credentials';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Account } from '../../models/account';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ArchivedUnit } from 'src/app/models/archived-unit';

@Component({
  selector: 'app-customer-lookup',
  templateUrl: './customer-lookup.component.html',
  styleUrls: ['./customer-lookup.component.less']
})
export class CustomerLookupComponent implements OnInit, OnDestroy, AfterViewInit {

  public _customerInfoService: CustomerService;
  public _selectedCustomer: CustomerInfo;

  public loadingCustomerOrderHistory: Boolean = false;
  public loadingCustomerInfo: Boolean = false;
  private dataSource = new MatTableDataSource<CustomerOrderHistory>();

  public tabIndex = 0;
  public _customerLogin: CustomerLogin[] = [];
  public _customerOrderHistory: CustomerOrderHistory[] = [];
  public _keyword = '';
  public account: Account;
  public _customerInfo: CustomerInfo[] = [];
  public user: User;
  public archivedUnit: ArchivedUnit[] = [];
  public customerId: number;
  public company: string = "";
  public customerNumber: string = "";
  public inputValue: any;

  public endDate: Date = new Date(); // Today's date
  public beginDate: Date;
  public maxDate: Date = new Date(); // This is today's date to prevent future dates in the datepicker UI

  private credentials: Credentials = {
    username: '',
    grant_type: 'password',
    password: ''
  };
  formControl: any;

  tblSort: MatSort;
  @ViewChild('tblSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.tblSort = ms;
    this.ngAfterViewInit();
  }

  constructor(customerInfoService: CustomerService,
    private snackBarService: SnackbarService,
    private authService: CustomerAuthService,
    private aService: AuthService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public dialog: MatDialog) {
    this._customerInfoService = customerInfoService;

    // Subtract 2 months from the endDate to get the default beginDate
    this.beginDate = new Date(this.endDate);
    this.beginDate.setMonth(this.endDate.getMonth() - 2);
  }

  ngOnInit(): void {
    const tabBack = this.route.snapshot.paramMap.get('tab');
    if (tabBack) {
      this.tabIndex = Number(tabBack);
    }
    this.account = this.aService.getAccount();
    let reqHandler = this.aService.getDefaultPassword().subscribe({
      next: (response) => this.credentials.password = response,
      complete: () => { reqHandler.unsubscribe(); }
    });
  }
  ngOnDestroy() {
    this.authService.signOut();
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.tblSort;
  }
  changeTab() {
    const tabCount = 3;
    this.tabIndex = (this.tabIndex + 1) % tabCount;
  }

  async onEnter(keyword: any) {
    this._customerInfo = [];
    if (keyword.length >= 3 && keyword.indexOf(';') > 1) {

      this.authService.signOut();

      this.loadingCustomerInfo = true;
      let formattedBeginDate = this.formatDate(this.beginDate);
      let formattedEndDate = this.formatDate(this.endDate);

      await this._customerInfoService.getCustomerInfo(keyword, formattedBeginDate, formattedEndDate, this.getAccountEmail(this.account))
        .subscribe((customerInf: CustomerInfo[]) => {
          this._customerInfo = [...customerInf];
          this.loadingCustomerInfo = false;
        },
          err => {
            console.error(err);
            this.loadingCustomerInfo = false;
            this.snackBarService.showError('Error getting Customer Info.');
          });
    }
  }

  getAccountEmail(account: Account): string {
    if (account.roles.indexOf("commission Viewer") > -1) {
      return account.email;
    }
    return "";
  }

  async onSelect(event: any, index: number, cid: number) {

    if (event.isUserInput) {
      this._selectedCustomer = { ...this._customerInfo[index] };

      this.credentials.username = this._selectedCustomer.customerNumber;
      this.customerId = cid;
      this.company = this._selectedCustomer.company;
      this.customerNumber = this._selectedCustomer.customerNumber;

      this.authService.signIn(this.credentials).subscribe((token: any) => {
        this.authService.setAccessToken(token);
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error signIn setAccessToken.');
        });
    }
  }
  onEndDateChange() {
    this.updateBeginDate();
    this.validateEndDate();
  }

  private updateBeginDate() {
    this.beginDate = new Date(this.endDate);
    this.beginDate.setMonth(this.endDate.getMonth() - 2);
  }

  private validateEndDate() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Remove time component for accurate comparison
    this.endDate.setHours(0, 0, 0, 0);

    if (this.endDate > today) {
      this.endDate = today;
      this.updateBeginDate();
    }
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.pad(date.getMonth() + 1);  // months are 0-based in JS
    const day = this.pad(date.getDate());
    const hours = this.pad(date.getHours());
    const minutes = this.pad(date.getMinutes());
    const seconds = this.pad(date.getSeconds());
    const milliseconds = date.getMilliseconds();

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  private pad(number: number, size: number = 2): string {
    let s = String(number);
    while (s.length < size) { s = "0" + s; }
    return s;
  }
}
