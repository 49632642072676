import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apartment } from '../models/apartment';
import { Package } from '../models/package';

@Injectable({
  providedIn: 'root'
})
export class ApartmentsService {

  constructor( 
    private config: ConfigService,
    private http: HttpClient) { }

  getApartmentsByPONumber(poNumber: string): Observable<Apartment[]> {
    const url = this.config.buildURL(`apartments`);
    const params = new HttpParams().set('productionOrderNumber', `${poNumber}`);

    return this.http.get<Apartment[]>(url, { params });
  }

  getPackagesAndShipmentDetails(salesOrderNumber: string): Observable<Package[]> {
    const url = this.config.buildURL(`apartments/packages-shipment-details`);
    const params = new HttpParams().set('salesOrderNumber', `${salesOrderNumber}`);

    return this.http.get<Package[]>(url, { params });
  }
}
