import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomerOrderHistory } from 'src/app/models/customer-order-history';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-cl-tab-customer-order-history',
  templateUrl: './cl-tab-customer-order-history.component.html',
  styleUrls: ['./cl-tab-customer-order-history.component.less']
})
export class ClTabCustomerOrderHistoryComponent implements AfterViewInit, OnChanges {

  @Input() customerId:number;

  public _customerOrderHistory: CustomerOrderHistory[] = [];
  public _customerInfoService: CustomerService;
  public loadingCustomerOrderHistory:Boolean = false;
  public gettingAck:Boolean = false;
  public gettingPackList:Boolean = false;
  public gettingPackagePlanning:Boolean = false;

  formControl: any;
  private dataSource = new MatTableDataSource<CustomerOrderHistory>();

  tblSort: MatSort;
  @ViewChild('tblSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.tblSort = ms;
    this.ngAfterViewInit();
  }

  customerOrderHistoryDisplayedColumns: string[] = 
  ['apartmentId'
  ,'communityName'
  , 'apartmentNumber'
  , 'salesOrderNumber'
  , 'customerPoNumber'
  , 'orderDate'
  , 'orderedBy'
  , 'price'
  , 'shipDate'
  , 'productionDate'
  , 'acknowledgement'
  , 'packingList'
  , 'numberOfCustomParts'
  , 'trackingNumber'
  , 'status'
  , 'shippingMethod'
  , 'more'];


  constructor(customerInfoService: CustomerService,
    private snackBarService: SnackbarService,
    public formBuilder: FormBuilder){
      this._customerInfoService = customerInfoService;

      this.dataSource.filterPredicate = ((data, filter) => {
        
        const a = !filter.word || data.apartmentNumber?.toLowerCase().includes(filter.word);
        const b = !filter.word || data.communityName?.toLowerCase().includes(filter.word);
        const c = !filter.word || data.salesOrderNumber?.toLowerCase().includes(this.splitSalesOrder(filter.word));
        const d = !filter.word || data.shippingTrackingNumber?.toLowerCase().includes(filter.word);
        const e = !filter.word || data.carrier?.toLowerCase().includes(filter.word);
        const f = !filter.word || data.status?.toLowerCase().includes(filter.word);
       
        return a || b || c || d || e || f;
      }) as (PeriodicElement, string) => boolean;
  
      this.formControl = formBuilder.group({
        word: ''
      })
      
      this.formControl.valueChanges.subscribe(value => {
        const filter = {...value, name: value?.name?.trim().toLowerCase()} as string;
        this.dataSource.filter = filter;
      });
  }

  ngOnChanges(changes: SimpleChanges){
    this.customerId = changes.customerId.currentValue;
    this._customerOrderHistory = [];
    
    this.loadingCustomerOrderHistory = true;
    this._customerInfoService.getCustomerOrderHistory(this.customerId).subscribe((customerOrderH: CustomerOrderHistory[]) => {
        this._customerOrderHistory = customerOrderH;
        this.loadingCustomerOrderHistory = false;
        this.dataSource.data = this._customerOrderHistory;
        this.dataSource.sort = this.tblSort;
         },
      err => {
        console.error(err);
        this.loadingCustomerOrderHistory = false;
        this.snackBarService.showError('Error getting Customer Order History.');
      })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  ngAfterViewInit() {    
    this.dataSource.sort = this.tblSort;
  }

  splitSalesOrder(so: string): any{
    if(so.indexOf('-') != -1)
    {
        return so.split('-')[1];
    }
    return so;
 }

  async getpackageListAll(element: any){
    this.gettingPackList = true;
    await this._customerInfoService.getPackageListAll(element.salesOrderNumber, element.apartmentNumber)
          .subscribe((blob: Blob) => {
            this.gettingPackList = false;
            const file = new Blob([blob], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank', 'width=1000, height=800');        
          },
      err => {
        console.error(err);
        this.gettingPackList = false;
        this.snackBarService.showError('Error getting Package List.');
      });
  }

  async getOrderAcknowledgement(element: any){
    this.gettingAck = true;
    await this._customerInfoService.getOrderAcknowledgement(element.shoppingCartGroup)
          .subscribe((blob: Blob) => {
            this.gettingAck = false;
            const file = new Blob([blob], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank', 'width=1000, height=800');
          },
     err => {
       console.error(err);
       this.gettingAck = false;
       this.snackBarService.showError('Error getting Acknowledgement.');
     });
  }

  async getPackagePlanning(element: any){
    this.gettingPackagePlanning = true;
      await this._customerInfoService.getPackagePlanning({'apartmentId': element.apartmentId, 'isRetail': false}).subscribe((result) => {
          this.gettingPackagePlanning = false;
          this.snackBarService.showSuccess('Package planning processed successfully.');
      },
      err => {
      console.error(err);
        this.gettingPackagePlanning = false;
        this.snackBarService.showError('Package planning process failed.');
      });
  }
}
