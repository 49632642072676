<mat-toolbar class="mat-elevation-z3">
  <button mat-button (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="title"></span>

  <span class="fill-remaining-space"></span>

  <button mat-button [matMenuTriggerFor]="accountMenu">
    {{account?.userName}}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #accountMenu="matMenu">
    <a routerLink="/account/details/{{account?.id}}" mat-menu-item>My Account</a>
    <button mat-menu-item (click)="signOut()">Sign out</button>
  </mat-menu>
</mat-toolbar>