export enum StateCommissionPlanEnum {
  InProgress = 'InProgress',
  Incomplete = 'Incomplete',
  NotMatchApartments = 'NotMatchApartments',
  Complete = 'Complete',
  Error = 'Error',
  BodyNotValid = 'BodyNotValid',
  BadExecution = 'BadExecution',
  PreviousExecutions = 'PreviousExecutions',
  readyForExecution = 'readyForExecution'
}

export interface CommissionRequest {
  userExecute: string;
  startRange: string;
  endRange: string;
  onlyGetListProcess: boolean;
}

export interface CommissionResponse {
  state: string;
  commissionProcessId?: number;
  userExecute?: string;
  newInvoicesForProcess?: number;
  newLedgerEntry?: number;
  entryForLog?: number;
  runStartTime?: Date;
  runEndTime?: Date;
  rangeStart?: Date;
  rangeEnd?: Date;
  runException?: string;
}
