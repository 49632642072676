import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MiterSawComponent } from '../miter-saw.component';
import { Train } from '../../../models/train';
import { UserRole } from '../../../models/user-role';
import { ProductionStatus } from '../../../models/production-status';
import { TrainsService } from '../../../services/trains.service';

const NONE_PRODUCTION_STATUS = 'None';
const ALLOWED_ROLES = [UserRole.Admin];
const PRODUCTION_STATUS = ProductionStatus[ProductionStatus.AssemblyStarted];

@Component({
  selector: 'app-edit-train',
  templateUrl: './edit-train.component.html',
  styleUrls: ['./edit-train.component.less']
})
export class EditTrainComponent implements OnInit {

  train: Train;
  unlockRDBOrderFiles = false;
  disableCancelAction = false;
  disableUpdateAction = false;
  selectedProductionStatus = '';
  productionStatus: string[] = [PRODUCTION_STATUS];

  constructor(
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private trainsService: TrainsService,
    public dialogRef: MatDialogRef<MiterSawComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { train: Train }) { }

  ngOnInit(): void {
    this.train = this.data.train;

    if (this.authService.isUserInRoles(ALLOWED_ROLES)) {
      this.productionStatus.splice(0, 0, NONE_PRODUCTION_STATUS);
    }

    this.selectedProductionStatus = this.productionStatus[0];
  }

  updateTrain() {
    this.disableCancelAction = true;
    this.disableUpdateAction = true;
    // eslint-disable-next-line max-len
    this.trainsService.updateTrain(this.train, this.selectedProductionStatus, this.unlockRDBOrderFiles).subscribe((train: Train) => {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;

      train.status = this.selectedProductionStatus;
      this.dialogRef.close(train);

    }, err => {
      console.error(err);
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
      this.snackBarService.showError('Error updating train. Please try again.');
    });
  }

  userIsAllowedToUnlockRDBOrderFiles() {
    return this.authService.isUserInRoles(ALLOWED_ROLES);
  }

}
