import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApartmentComplexAudit } from '../../../models/apartment-complex-audit';
import { SnackbarService } from '../../../services/snackbar.service';
import { AuditService } from 'src/app/services/audit.service';
import { CustomerAudit } from 'src/app/models/customer-audit';

@Component({
  selector: 'app-audit-assignments-details',
  templateUrl: './audit-assignments-details.component.html',
  styleUrls: ['./audit-assignments-details.component.less']
})
export class AuditAssignmentsDetailsComponent implements OnInit {

  customer: CustomerAudit;
  communityFilter = new FormControl();
  communities: ApartmentComplexAudit[] = [];
  filteredCommunities: ApartmentComplexAudit[] = [];
  filtersApplied = false;

  communitiesDisplayedColumns: string[] = ['id', 'name', 'dateCreated', 'numberOfUnits', 'audit'];

  constructor(
    private auditService: AuditService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<AuditAssignmentsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: CustomerAudit }) { }

  ngOnInit(): void {
    this.customer = this.data.customer;
    this.getCommunities();
  }

  getCommunitiesData(): ApartmentComplexAudit[] {
    return this.filtersApplied ? this.filteredCommunities : this.communities;
  }

  getCommunities(): void {
    const customerId = this.customer.id;
    this.auditService.getCommunities(customerId).subscribe((communities: ApartmentComplexAudit[]) => {
      this.communities = communities;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting communities.');
    });
  }

  applyFilters(): void {
    this.filteredCommunities = this.communities;

    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredCommunities = this.filterByCommunity();
      this.filtersApplied = true;
    }
  }

  filterByCommunity(): ApartmentComplexAudit[] {
    return this.filteredCommunities.filter(c => c.name.toLowerCase().includes(this.communityFilter.value.trim().toLowerCase()));
  }

  clearFilters(): void {
    this.filtersApplied = false;
    this.communityFilter.setValue('');
    this.filteredCommunities = [];
  }

}
