import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingService } from '../../../services/accounting.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { RebateGroup } from '../../../models/rebate-group';

@Component({
  selector: 'app-delete-rebate-group',
  templateUrl: './delete-rebate-group.component.html',
  styleUrls: ['./delete-rebate-group.component.less']
})
export class DeleteRebateGroupComponent implements OnInit {

  rebateGroup: RebateGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountingService: AccountingService,
    private snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.getRebateGroup();
  }

  getRebateGroup() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.accountingService.getRebateGroup(id).subscribe((rebateGroup: RebateGroup) => {
      this.rebateGroup = rebateGroup;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the rebate group details.');
      });
  }

  deleteRebateGroup(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.accountingService.deleteRebateGroup(id).subscribe(_ => {
      this.router.navigate(['/accounting']);
      this.snackBarService.showSuccess('The rebate group was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting rebate group.');
      });
  }

}
