<h2 mat-dialog-title>Reset Production Order Number</h2>
<p *ngIf="processing">
  <mat-spinner diameter="20" color="accent"> </mat-spinner> <span>Processing...</span>
</p>
<mat-dialog-content>
  <p>Are you sure you want to reset this PO #?</p>
  <strong>PO #:</strong> {{scheduledUnit.productionOrderNumber}}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close [disabled]="processing" cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="accent" [disabled]="processing" (click)="resetPONumber()">Reset</button>
</mat-dialog-actions>
