<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Router CIM
    <div>
      <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
        matTooltip="Fullscreen Mode">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
        matTooltip="Exit Fullscreen Mode">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
    </div>
  </h1>
  <hr />

  <div class="container">
    <p class="machine">Machine: <span>{{machine}}</span></p>
    <mat-accordion>
      <mat-expansion-panel expanded="true" class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Router CIM
          </mat-panel-title>
          <mat-panel-description>
            Select a production location
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="actions">
          <mat-form-field>
            <mat-label>Production Location</mat-label>
            <mat-select (selectionChange)="onProductionLocationChange($event)" [value]="productionLocationId">
              <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="filters-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filters
          </mat-panel-title>
          <mat-panel-description>
            Filter production orders
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="filters">
          <div class="filters-container">
            <div>
              <mat-form-field>
                <input matInput placeholder="PO #" [formControl]="poNumberFilter">
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Production Date"
                  [formControl]="productionDateFilter" (dateChange)="onProductionDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Colors</mat-label>
                <mat-select [formControl]="colorsFilter" multiple>
                  <mat-option *ngFor="let color of colors" [value]="color.name">{{color.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
  </div>

  <div class="reset-action-container" *ngIf="filtersApplied">
    <button mat-raised-button (click)="clearFilters()">
      <mat-icon>clear</mat-icon> Reset Production Orders Filters
    </button>
  </div>

  <h4 *ngIf="!loading && !hasProductionOrders()">No production orders to show.</h4>

  <table mat-table *ngIf="hasProductionOrders()" [dataSource]="getProductionOrdersDataSource()"
    #productionOrdersSort="matSort" matSort class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;">
    </tr>

    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
      <td mat-cell *matCellDef="let element"> {{element.productionDateStr}}</td>
    </ng-container>

    <ng-container matColumnDef="productionOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Order Number </th>
      <td mat-cell *matCellDef="let element">
        <a mat-button color="accent" matTooltip="Apartments with this PO#" matTooltipPosition="above"
          routerLink="/apartments/{{element.productionOrderNumber}}">
          <i class="material-icons">open_in_new</i> {{element.productionOrderNumber}}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
      <td mat-cell *matCellDef="let element"> {{element.color}}</td>
    </ng-container>

    <ng-container matColumnDef="numberOfParts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Number of Parts</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.numberOfParts}}</td>
    </ng-container>

    <ng-container matColumnDef="numberOfApartments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Number of Apartments</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.numberOfApartments}}</td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef> Files</th>
      <td mat-cell *matCellDef="let element">
        <a mat-icon-button matTooltip="Download files" (click)="getFiles(element.id)" matTooltipPosition="above">
          <mat-icon color="accent">attach_file</mat-icon>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element">
        <a mat-icon-button matTooltip="Edit Production Order" matTooltipPosition="above"
          (click)="openEditProductionOrder(element.id)">
          <mat-icon color="accent">edit</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>
