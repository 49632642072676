import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { ReasonCode } from '../models/reason-code';
import { ReasonCategory } from '../models/reason-category';

@Injectable({
  providedIn: 'root',
})
export class ReasonCodesService {
  constructor(
    private config: ConfigService,
    private http: HttpClient) {
    }

  getReasonCodes(): Observable<ReasonCode[]> {
    return this.http.get<ReasonCode[]>(this.config.buildURL('reasonCodes'));
  }

  createReasonCode(model: ReasonCode): Observable<ReasonCode> {
    return this.http.post<ReasonCode>(this.config.buildURL('reasonCodes/createReasonCode'), model);
  }

  updateReasonCode(model: ReasonCode): Observable<ReasonCode> {
    return this.http.put<ReasonCode>(this.config.buildURL('reasonCodes/updateReasonCode'), model);
  }

  disableReasonCode(model: ReasonCode): Observable<ReasonCode> {
    return this.http.put<ReasonCode>(this.config.buildURL('reasonCodes/disableReasonCode'), model);
  }

  getReasonCategories(): Observable<ReasonCategory[]> {
    return this.http.get<ReasonCategory[]>(this.config.buildURL('reasonCodes/reasonCategories'));
  }

  createReasonCategory(model: ReasonCategory): Observable<ReasonCategory> {
    return this.http.post<ReasonCategory>(this.config.buildURL('reasonCodes/createReasonCategory'), model);
  }

  updateReasonCategory(model: ReasonCategory): Observable<ReasonCategory> {
    return this.http.put<ReasonCategory>(this.config.buildURL('reasonCodes/updateReasonCategory'), model);
  }

  disableReasonCategory(model: ReasonCategory): Observable<ReasonCategory> {
    return this.http.put<ReasonCategory>(this.config.buildURL('reasonCodes/disableReasonCategory'), model);
  }
}
