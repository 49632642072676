import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Router as RouterDTO } from '../../../models/router';
import { RoutersService } from '../../../services/routers.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-delete-router',
  templateUrl: './delete-router.component.html',
  styleUrls: ['./delete-router.component.less']
})
export class DeleteRouterComponent implements OnInit {
  routerDTO: RouterDTO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routersService: RoutersService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getRouter();
  }

  getRouter(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routersService.getRouter(id).subscribe((router: RouterDTO) => {
      this.routerDTO = router;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router details.');
      });
  }

  deleteRouter(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routersService.deleteRouter(id).subscribe(_ => {
      this.router.navigate([`/routers`]);
      this.snackBarService.showSuccess('The router was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting router.');
      });
  }
}
