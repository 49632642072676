export enum TypeSetting {
    commissionType = 1,
    commissionSales = 2,
    commissionPersonType = 3,
    commissionTypeAssignments = 4
}

export enum TypeSettingAction {
    create = 1,
    edit = 2,
    delete = 3,
    disable = 4
}

export interface ModalSettingConfig {
    settingType: TypeSetting;
    settingAction: TypeSettingAction;
    value: any;
    result?: any;
}
