import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PalletInfo } from 'src/app/models/pallet-info';

@Component({
  selector: 'app-palletization-form',
  templateUrl: './palletization-form.component.html',
  styleUrls: ['./palletization-form.component.less']
})
export class PalletizationFormComponent {

  constructor(public dialogRef: MatDialogRef<PalletizationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PalletInfo) {

  }
}
