import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { Color } from '../../../../models/color';
import { DoorStyle } from '../../../../models/door-style';
import { ProductionCalendarService } from '../../../../services/production-calendar.service';
import { ProductionMoldingSetting } from '../../../../models/production-molding-setting';
import { EditForm } from '../../../../forms/edit-form';

@Component({
  selector: 'app-edit-production-molding',
  templateUrl: './edit-production-molding.component.html',
  styleUrls: ['./edit-production-molding.component.less']
})
export class EditProductionMoldingComponent extends EditForm implements OnInit {

  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  productionMoldingSetting: ProductionMoldingSetting;
  editProductionMoldingSettingForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private productionCalendarService: ProductionCalendarService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getColors();
    this.getDoorStyles();
    this.getProductionMoldingSetting();
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  getProductionMoldingSetting() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getProductionMoldingSetting(id).subscribe((productionMoldingSetting: ProductionMoldingSetting) => {
      this.productionMoldingSetting = productionMoldingSetting;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production molding setting details.');
      });
  }

  createForm() {
    this.editProductionMoldingSettingForm = this.fb.group({
      color: ['', Validators.required],
      doorStyle: ['', Validators.required],
      moldingLength: ['', [
        Validators.required,
        Validators.min(0),
      ]],
      friendlyMoldingLength: ['', Validators.required],
      channelGroove: ['', [
        Validators.required,
        Validators.min(0),
      ]],
      friendlyChannelGroove: ['', Validators.required],
      isDefault: [false]
    });
  }

  setValues() {
    this.editProductionMoldingSettingForm.setValue({
      color: this.productionMoldingSetting.color,
      doorStyle: this.productionMoldingSetting.doorStyle,
      moldingLength: this.productionMoldingSetting.moldingLength,
      friendlyMoldingLength: this.productionMoldingSetting.friendlyMoldingLength,
      channelGroove: this.productionMoldingSetting.channelGroove,
      friendlyChannelGroove: this.productionMoldingSetting.friendlyChannelGroove,
      isDefault: this.productionMoldingSetting.isDefault
    });
  }

  isValid(): boolean {
    return this.editProductionMoldingSettingForm.valid;
  }

  submit() {
    this.reset();
    if (this.isValid()) {
      this.updateProductionMoldingSetting();
    } else {
      this.snackBarService.showError('You must enter a valid production molding setting.');
    }
  }

  updateProductionMoldingSetting() {
    const model: ProductionMoldingSetting = {
      id: this.productionMoldingSetting.id,
      color: this.editProductionMoldingSettingForm.controls.color.value,
      doorStyle: this.editProductionMoldingSettingForm.controls.doorStyle.value,
      moldingLength: this.editProductionMoldingSettingForm.controls.moldingLength.value,
      friendlyMoldingLength: this.editProductionMoldingSettingForm.controls.friendlyMoldingLength.value,
      channelGroove: this.editProductionMoldingSettingForm.controls.channelGroove.value,
      friendlyChannelGroove: this.editProductionMoldingSettingForm.controls.friendlyChannelGroove.value,
      isDefault: this.editProductionMoldingSettingForm.controls.isDefault.value
    };

    this.settingsService.updateProductionMoldingSetting(model).subscribe(_ => {
      this.router.navigate(['/settings']);
      this.snackBarService.showSuccess('The production molding setting was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating production molding setting.');
    });
  }
}