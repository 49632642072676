<table mat-table [dataSource]="dataSource" class="mat-elevation-z0 table" matSort #palletizedOrdersTable="matSort" matSortActive="salesOrderNumber">
    <ng-container matColumnDef="unitNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit #</th>
        <td mat-cell *matCellDef="let row"> {{ row?.unitNumber }} </td>
    </ng-container>
    <ng-container matColumnDef="salesOrderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales Order Number </th>
        <td mat-cell *matCellDef="let row"> {{ row?.salesOrderNumber }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedHeader"></tr>
</table>

<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" mat-dialog-close>Close</button>
</mat-dialog-actions>