<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Delete Expedited Production Setting</h1>
  <hr />
  <p>Are you sure you want to delete this expedited setting?</p>
  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{ expeditedProductionSettings?.id }}</li>
      <li>
        <strong>Description:</strong>
        {{ expeditedProductionSettings?.description }}
      </li>
      <li>
        <strong>Minimum Fee:</strong>
        {{ expeditedProductionSettings?.minimumFee | currency }}
      </li>
      <li>
        <strong>Fee Per Part:</strong>
        {{ expeditedProductionSettings?.feePerPart | currency }}
      </li>
      <li>
        <strong>Production Type:</strong>
        {{ expeditedProductionSettings?.productionType }}
      </li>
    </ul>
  </div>
  <div class="actions">
    <button
      mat-raised-button
      color="accent"
      (click)="deleteExpeditedProductionSetting()"
    >
      Delete
    </button>
  </div>
</mat-card>
