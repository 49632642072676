<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Delete Production Limit
  </h1>
  <hr />
  <p>Are you sure you want to delete this production limit?</p>
  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{productionLimit?.id}}</li>
      <li><strong>Customer:</strong> {{productionLimit?.customerName}}</li>
      <li *ngIf="productionLimit?.apartmentComplexName"><strong>Apartment Complex:</strong>
        {{productionLimit?.apartmentComplexName}}</li>
      <li><strong>Allowed Custom Parts Capacity:</strong> {{productionLimit?.allowedCustomPartsCapacity}}</li>
    </ul>
  </div>
  <div class="actions">
    <button mat-raised-button color="accent" (click)="deleteProductionLimit()">Delete</button>
  </div>
</mat-card>
