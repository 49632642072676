import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router as RouterDTO } from '../../../models/router';
import { BaseForm } from '../../../forms/base-form';
import { SnackbarService } from '../../../services/snackbar.service';
import { RoutersService } from '../../../services/routers.service';
import { ProductionLocation } from '../../../models/production-location';
import { ProductionLocationsService } from '../../../services/production-locations.service';

@Component({
  selector: 'app-create-router',
  templateUrl: './create-router.component.html',
  styleUrls: ['./create-router.component.less']
})
export class CreateRouterComponent extends BaseForm implements OnInit {

  loading = false;
  routerDTO: RouterDTO;
  routerForm: FormGroup;
  productionLocations: ProductionLocation[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routersService: RoutersService,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getProductionLocations();
  }

  createForm(): void {
    this.routerForm = this.fb.group({
      name: ['', Validators.required],
      ipAddress: ['', Validators.required],
      productionLocationId: [],
      isActive: [true]
    });
  }

  getProductionLocations() {
    this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
      this.productionLocations = productionLocations;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production locations.');
      });
  }

  isValid(): boolean {
    return this.routerForm.valid;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.createRouter();
    }
  }

  getData(): RouterDTO {
    const data: RouterDTO = {
      name: this.routerForm.get('name').value,
      productionLocationId: this.routerForm.get('productionLocationId').value,
      ipAddress: this.routerForm.get('ipAddress').value,
      isActive: this.routerForm.get('isActive').value
    };
    return data;
  }

  createRouter() {
    this.loading = true;
    const model = this.getData();

    this.routersService.createRouter(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/routers']);
      this.snackBarService.showSuccess('The router was created successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error creating router.');
    });
  }
}
