import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { BaseForm } from '../../../forms/base-form';
import { ProductionCalendarService } from '../../../services/production-calendar.service';
import { ProductionCalendar } from '../../../models/production-calendar';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProductionCalendarColorDoorStyleCapacity } from '../../../models/production-calendar-color-door-style-capacity';
import { ProductionCalendarColorCapacity } from '../../../models/production-calendar-color-capacity';
import { Color } from '../../../models/color';
import { DoorStyle } from '../../../models/door-style';

const INVALID_PRODUCTION_CALENDAR_ID = -1;
const CONFIRMATION_DURATION = 10000;
@Component({
  selector: 'app-edit-production-calendar',
  templateUrl: './edit-production-calendar.component.html',
  styleUrls: ['./edit-production-calendar.component.less']
})
export class EditProductionCalendarComponent extends BaseForm {

  productionDate: Date;
  productionLocationId: number;
  productionCalendarId: number;
  productionCalendar: ProductionCalendar;
  productionCalendarForm: UntypedFormGroup;
  productionCalendarColorCapacityForm: UntypedFormGroup;
  productionCalendarColorCapacityFormItems: UntypedFormArray;
  productionCalendarColorDoorStyleCapacityForm: UntypedFormGroup;
  productionCalendarColorDoorStyleCapacityFormItems: UntypedFormArray;
  productionCalendarShortcutsForm: UntypedFormGroup;
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  productionCalendarEditMode = false;
  disableActions = false;
  selectedTabIndex = 0;
  loading = true;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBarService: SnackbarService,
    private deleteConfirmation: MatSnackBar,
    private productionCalendarService: ProductionCalendarService,
    private dialogRef: MatDialogRef<EditProductionCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super();

    this.productionDate = this.data.date;
    this.productionLocationId = this.data.productionLocationId;
    this.productionCalendarId = this.data.productionCalendarId;
    this.productionCalendarEditMode = this.productionCalendarId !== INVALID_PRODUCTION_CALENDAR_ID;
    this.selectedTabIndex = data.selectedTabIndex;

    this.createForm();
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async createForm() {
    try {
      if (this.productionCalendarEditMode) {
        await this.getProductionCalendar(this.productionCalendarId);
      }
      this.getColors();
      this.getDoorStyles();
      this.createProductionCalendarForm();
      this.createProductionCalendarColorCapacityForm();
      this.createProductionCalendarColorDoorStyleCapacityForm();
      this.createProductionCalendarShortcutsForm();

      if (!this.allFormFieldsAreAvailable()) {
        this.disableFormFields();
      }
    } finally {
      this.hideLoading();
    }
  }

  createProductionCalendarForm() {
    if (this.productionCalendar) {
      this.productionCalendarForm = this.fb.group({
        productionDate: [{ value: this.data.dateStr, disabled: true }, Validators.required],
        customPartsCapacity: [this.productionCalendar.customPartsCapacity, [
          Validators.required,
          Validators.min(1),
          Validators.pattern('[0-9]*')]
        ],
        allowedCustomPartsCapacity: [{ value: this.productionCalendar.allowedCustomPartsCapacity, disabled: true }],
        plannedCustomPartsCapacity: [this.productionCalendar.plannedCustomPartsCapacity, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        plannedNumberOfEmployees: [this.productionCalendar.plannedNumberOfEmployees, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        actualCustomPartsProduced: [this.productionCalendar.actualCustomPartsProduced, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        actualNumberOfEmployees: [this.productionCalendar.actualNumberOfEmployees, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        preProductionNotes: [this.productionCalendar.preProductionNotes],
        postProductionNotes: [this.productionCalendar.postProductionNotes],
        hoursWorked: [this.productionCalendar.hoursWorked, [
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]+)?$')]
        ],
        plannedHours: [this.productionCalendar.plannedHours, [
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]+)?$')]
        ]
      });
    } else {

      this.productionCalendarForm = this.fb.group({
        productionDate: [{ value: this.data.dateStr, disabled: true }, Validators.required],
        customPartsCapacity: ['', [
          Validators.required,
          Validators.min(1),
          Validators.pattern('[0-9]*')]
        ],
        allowedCustomPartsCapacity: [{ value: '', disabled: true }],
        plannedCustomPartsCapacity: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        plannedNumberOfEmployees: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        actualCustomPartsProduced: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        actualNumberOfEmployees: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
        preProductionNotes: [''],
        postProductionNotes: [''],
        hoursWorked: ['', [
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]+)?$')]
        ],
        plannedHours: ['8', [
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]+)?$')]
        ]
      });
    }

  }

  createProductionCalendarColorCapacityForm() {
    const colorCapacityFormItems: UntypedFormGroup[] = [];
    if (this.productionCalendar) {
      for (const productionCalendarColorCapacity of this.productionCalendar.productionCalendarColorCapacities) {
        colorCapacityFormItems.push(this.createProductionCalendarColorCapacityFormItem(productionCalendarColorCapacity));
      }
    }

    if (colorCapacityFormItems.length === 0) {
      colorCapacityFormItems.push(this.createProductionCalendarColorCapacityFormItem());
    }

    this.productionCalendarColorCapacityForm = this.fb.group({
      formGroups: this.fb.array(colorCapacityFormItems)
    });
  }

  createProductionCalendarColorCapacityFormItem(productionCalendarColorCapacity?: ProductionCalendarColorCapacity): UntypedFormGroup {
    if (productionCalendarColorCapacity) {
      return this.fb.group({
        id: [productionCalendarColorCapacity.id],
        color: [{ value: productionCalendarColorCapacity.color, disabled: true }],
        allowedCapacity: [productionCalendarColorCapacity.allowedCapacity, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ]
      });
    } else {
      return this.fb.group({
        id: [''],
        color: [''],
        allowedCapacity: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ]
      });
    }
  }

  createProductionCalendarShortcutsForm() {
    this.productionCalendarShortcutsForm = this.fb.group({
      clone: false,
      numberOfBusinessDays: ['', [
        Validators.min(1),
        Validators.pattern('[0-9]*')]
      ]
    });
  }

  addProductionCalendarColorCapacityFormItem() {
    if (this.allFormFieldsAreAvailable()) {
      this.productionCalendarColorCapacityFormItems = this.productionCalendarColorCapacityForm.get('formGroups') as UntypedFormArray;
      this.productionCalendarColorCapacityFormItems.push(this.createProductionCalendarColorCapacityFormItem());
    }
  }

  createProductionCalendarColorDoorStyleCapacityForm() {
    const colorDoorStyleCapacityFormItems: UntypedFormGroup[] = [];
    if (this.productionCalendar) {
      for (const colorDoorStyleCapacity of this.productionCalendar.productionCalendarColorDoorStyleCapacities) {
        colorDoorStyleCapacityFormItems.push(this.createProductionCalendarColorDoorStyleCapacityFormItem(colorDoorStyleCapacity));
      }
    }

    if (colorDoorStyleCapacityFormItems.length === 0) {
      colorDoorStyleCapacityFormItems.push(this.createProductionCalendarColorDoorStyleCapacityFormItem());
    }

    this.productionCalendarColorDoorStyleCapacityForm = this.fb.group({
      formGroups: this.fb.array(colorDoorStyleCapacityFormItems)
    });
  }

  // eslint-disable-next-line max-len
  createProductionCalendarColorDoorStyleCapacityFormItem(productionCalendarColorDoorStyleCapacity?: ProductionCalendarColorDoorStyleCapacity): UntypedFormGroup {
    if (productionCalendarColorDoorStyleCapacity) {
      return this.fb.group({
        id: [productionCalendarColorDoorStyleCapacity.id],
        color: [{ value: productionCalendarColorDoorStyleCapacity.color, disabled: true }],
        doorStyle: [{ value: productionCalendarColorDoorStyleCapacity.doorStyle, disabled: true }],
        allowedCapacity: [productionCalendarColorDoorStyleCapacity.allowedCapacity, [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
      });
    } else {
      return this.fb.group({
        id: [''],
        color: [''],
        doorStyle: [''],
        allowedCapacity: ['', [
          Validators.min(0),
          Validators.pattern('[0-9]*')]
        ],
      });
    }
  }

  addProductionCalendarColorDoorStyleCapacityFormItem() {
    if (this.allFormFieldsAreAvailable()) {
      // eslint-disable-next-line max-len
      this.productionCalendarColorDoorStyleCapacityFormItems = this.productionCalendarColorDoorStyleCapacityForm.get('formGroups') as UntypedFormArray;
      this.productionCalendarColorDoorStyleCapacityFormItems.push(this.createProductionCalendarColorDoorStyleCapacityFormItem());
    }
  }

  removeColorCapacityForm(index: number) {
    this.productionCalendarColorCapacityFormItems = this.productionCalendarColorCapacityForm.get('formGroups') as UntypedFormArray;
    this.productionCalendarColorCapacityFormItems.removeAt(index);
  }

  removeColorDoorStyleCapacityForm(index: number) {
    // eslint-disable-next-line max-len
    this.productionCalendarColorDoorStyleCapacityFormItems = this.productionCalendarColorDoorStyleCapacityForm.get('formGroups') as UntypedFormArray;
    this.productionCalendarColorDoorStyleCapacityFormItems.removeAt(index);
  }

  allFormFieldsAreAvailable(): boolean {
    const currentDate = new Date();
    const selectedDate: Date = this.data.date;

    if (currentDate.getDate() === selectedDate.getDate()
      && currentDate.getMonth() === selectedDate.getMonth()
      && currentDate.getFullYear() === selectedDate.getFullYear()) {
      return true;
    }
    return currentDate < selectedDate;
  }

  disableFormFields() {
    this.productionCalendarForm.controls.customPartsCapacity.disable();
    this.productionCalendarForm.controls.preProductionNotes.disable();
    this.productionCalendarColorCapacityForm.disable();
    this.productionCalendarColorDoorStyleCapacityForm.disable();
  }

  isValid(): boolean {
    return this.productionCalendarFormIsValid()
      && this.productionCalendarColorCapacityFormIsValid()
      && this.productionCalendarColorDoorStyleCapacityFormIsValid()
      && this.productionCalendarShortcutsFormIsValid();
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  productionCalendarFormIsValid(): boolean {
    this.markFormGroupTouched(this.productionCalendarForm);

    if (!this.productionCalendarForm.valid) {
      this.errorMessages.push('You must enter a valid custom parts capacities');
    }
    return this.productionCalendarForm.valid;
  }

  productionCalendarColorCapacityFormIsValid(): boolean {
    const numberOfRequiredFields = 2;
    const formArray = this.productionCalendarColorCapacityForm.get('formGroups') as UntypedFormArray;
    try {
      for (const formGroup of formArray.controls) {
        if (formGroup instanceof UntypedFormGroup) {
          let numberOfValues = 0;

          const color = formGroup.get('color').value as string;
          const allowedCapacity = formGroup.get('allowedCapacity').value as string;

          if (color && color.toString().trim() !== '') {
            numberOfValues++;
          }

          if (allowedCapacity !== null && allowedCapacity.toString().trim() !== '') {
            numberOfValues++;
          }
          // disabled forms are invalid: angular issue
          if (!formGroup.disabled && (!formGroup.valid || (numberOfValues !== 0 && numberOfValues !== numberOfRequiredFields))) {
            this.errorMessages.push('You must enter a valid sheets overrides');
            return false;
          }

          if (formGroup.disabled && numberOfValues !== 0 && numberOfValues !== numberOfRequiredFields) {
            this.errorMessages.push('You must enter a valid sheets overrides');
            return false;
          }
        }
      }
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }

  productionCalendarColorDoorStyleCapacityFormIsValid(): boolean {
    const numberOfRequiredFields = 3;
    const formArray = this.productionCalendarColorDoorStyleCapacityForm.get('formGroups') as UntypedFormArray;
    try {
      for (const formGroup of formArray.controls) {
        if (formGroup instanceof UntypedFormGroup) {

          let numberOfValues = 0;

          const color = formGroup.get('color').value as string;
          const doorStyle = formGroup.get('doorStyle').value as string;
          const allowedCapacity = formGroup.get('allowedCapacity').value as string;

          if (color && color.toString().trim() !== '') {
            numberOfValues++;
          }

          if (doorStyle && doorStyle.toString().trim() !== '') {
            numberOfValues++;
          }

          if (allowedCapacity !== null && allowedCapacity.toString().trim() !== '') {
            numberOfValues++;
          }
          // disabled forms are invalid: angular issue
          if (!formGroup.disabled && (!formGroup.valid || (numberOfValues !== 0 && numberOfValues !== numberOfRequiredFields))) {
            this.errorMessages.push('You must enter a valid molding overrides');
            return false;
          }

          if (formGroup.disabled && numberOfValues !== 0 && numberOfValues !== numberOfRequiredFields) {
            this.errorMessages.push('You must enter a valid molding overrides');
            return false;
          }
        }
      }
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }

  productionCalendarShortcutsFormIsValid(): boolean {
    this.markFormGroupTouched(this.productionCalendarShortcutsForm);

    if (!this.productionCalendarShortcutsForm.valid) {
      this.errorMessages.push('You must enter a valid number of business days');
    }
    return this.productionCalendarShortcutsForm.valid;
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.createProductionCalendar();
    }
  }

  update() {
    this.reset();

    if (this.isValid()) {
      this.updateProductionCalendar();
    }
  }

  getProductionCalendarColorCapacityItems(): ProductionCalendarColorCapacity[] {
    const items: ProductionCalendarColorCapacity[] = [];
    const formArray = this.productionCalendarColorCapacityForm.get('formGroups') as UntypedFormArray;

    try {
      for (const formGroup of formArray.controls) {
        if (formGroup instanceof UntypedFormGroup) {
          const id = formGroup.get('id').value as number;
          const color = formGroup.get('color').value as string;
          const allowedCapacity = formGroup.get('allowedCapacity').value as number;

          if (color.trim() !== '' && allowedCapacity >= 0) {
            const model: ProductionCalendarColorCapacity = {
              id,
              color,
              allowedCapacity,
              productionDate: this.productionDate,
              productionLocationId: this.productionLocationId
            };

            items.push(model);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    return items;
  }

  getProductionCalendarColorDoorStyleCapacityItems(): ProductionCalendarColorDoorStyleCapacity[] {
    const items: ProductionCalendarColorDoorStyleCapacity[] = [];
    const formArray = this.productionCalendarColorDoorStyleCapacityForm.get('formGroups') as UntypedFormArray;

    try {
      for (const formGroup of formArray.controls) {
        if (formGroup instanceof UntypedFormGroup) {
          const id = formGroup.get('id').value as number;
          const color = formGroup.get('color').value as string;
          const doorStyle = formGroup.get('doorStyle').value as string;
          const allowedCapacity = formGroup.get('allowedCapacity').value as number;

          if (color.trim() !== '' && doorStyle.trim() !== '' && allowedCapacity >= 0) {
            const model: ProductionCalendarColorDoorStyleCapacity = {
              id,
              color,
              doorStyle,
              allowedCapacity,
              productionDate: this.productionDate,
              productionLocationId: this.productionLocationId
            };

            items.push(model);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    return items;
  }

  getCustomPartsCapacityErrorMessage() {
    if (this.productionCalendarForm.controls.customPartsCapacity.hasError('required')) {
      return 'You must enter a custom parts capacity.';
    } else if (this.productionCalendarForm.controls.customPartsCapacity.hasError('min')) {
      return 'You must enter a custom parts capacity greater than 0.';
    } else if (this.productionCalendarForm.controls.customPartsCapacity.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getNumberOfBusinessDaysErrorMessage() {
    if (this.productionCalendarShortcutsForm.controls.numberOfBusinessDays.hasError('min')) {
      return 'You must enter a business day greater than 0.';
    } else if (this.productionCalendarShortcutsForm.controls.numberOfBusinessDays.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getPlannedCustomPartsCapacityErrorMessage() {
    if (this.productionCalendarForm.controls.plannedCustomPartsCapacity.hasError('min')) {
      return 'You must enter a planned custom parts capacity equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.plannedCustomPartsCapacity.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getPlannedNumberOfEmployeesErrorMessage() {
    if (this.productionCalendarForm.controls.plannedNumberOfEmployees.hasError('min')) {
      return 'You must enter a planned number of employees equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.plannedNumberOfEmployees.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getActualCustomPartsProducedErrorMessage() {
    if (this.productionCalendarForm.controls.actualCustomPartsProduced.hasError('min')) {
      return 'You must enter a actual custom parts produced equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.actualCustomPartsProduced.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getActualNumberOfEmployeesErrorMessage() {
    if (this.productionCalendarForm.controls.actualNumberOfEmployees.hasError('min')) {
      return 'You must enter a actual number of employees equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.actualNumberOfEmployees.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getHoursWorkedErrorMessage() {
    if (this.productionCalendarForm.controls.hoursWorked.hasError('min')) {
      return 'You must enter a hours worked equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.hoursWorked.hasError('pattern')) {
      return 'You must enter a decimal number.';
    }
    return '';
  }

  getPlannedHoursErrorMessage() {
    if (this.productionCalendarForm.controls.plannedHours.hasError('min')) {
      return 'You must enter a planned hours equal or greater than 0.';
    } else if (this.productionCalendarForm.controls.plannedHours.hasError('pattern')) {
      return 'You must enter a decimal number.';
    }
    return '';
  }

  getAllowedCapacityErrorMessage(allowedCapacity: UntypedFormControl) {
    if (allowedCapacity.hasError('min')) {
      return 'You must enter a value equal or greater than 0.';
    } else if (allowedCapacity.hasError('pattern')) {
      return 'You must enter a integer number.';
    }
    return '';
  }

  getProductionCalendar(productionCalendarId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.productionCalendarService.getProductionCalendar(productionCalendarId).subscribe((productionCalendar: ProductionCalendar) => {
        this.productionCalendar = productionCalendar;
        resolve();
      }, err => {
        console.error(err);
        this.errorMessages = this.getErrorMessages(err);
        if (this.errorMessages.length === 0) {
          this.errorMessages.push('Error getting production calendar information. Please try again.');
          this.disableActions = true;
        }
        this.snackBarService.showError('Error getting production calendar information.');
        reject();
      });
    });
  }

  createProductionCalendar() {
    const productionCalendarColorCapacities = this.getProductionCalendarColorCapacityItems();
    const productionCalendarColorDoorStyleCapacities = this.getProductionCalendarColorDoorStyleCapacityItems();

    const model: ProductionCalendar = {
      productionDate: this.productionDate,
      productionLocationId: this.productionLocationId,
      customPartsCapacity: this.productionCalendarForm.controls.customPartsCapacity.value,
      plannedCustomPartsCapacity: this.productionCalendarForm.controls.plannedCustomPartsCapacity.value,
      plannedNumberOfEmployees: this.productionCalendarForm.controls.plannedNumberOfEmployees.value,
      actualCustomPartsProduced: this.productionCalendarForm.controls.actualCustomPartsProduced.value,
      actualNumberOfEmployees: this.productionCalendarForm.controls.actualNumberOfEmployees.value,
      preProductionNotes: this.productionCalendarForm.controls.preProductionNotes.value,
      postProductionNotes: this.productionCalendarForm.controls.postProductionNotes.value,
      hoursWorked: this.productionCalendarForm.controls.hoursWorked.value,
      plannedHours: this.productionCalendarForm.controls.plannedHours.value,
      productionCalendarColorCapacities,
      productionCalendarColorDoorStyleCapacities
    };

    this.productionCalendarService.createProductionCalendar(model).subscribe((productionCalendar: ProductionCalendar) => {
      this.dialogRef.close(productionCalendar);
      this.snackBarService.showSuccess('The production calendar was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new production calendar.');
    });
  }

  updateProductionCalendar() {
    const productionCalendarColorCapacities = this.getProductionCalendarColorCapacityItems();
    const productionCalendarColorDoorStyleCapacities = this.getProductionCalendarColorDoorStyleCapacityItems();

    const model: ProductionCalendar = {
      id: this.productionCalendarId,
      productionDate: this.productionDate,
      productionLocationId: this.productionLocationId,
      customPartsCapacity: this.productionCalendarForm.controls.customPartsCapacity.value,
      plannedCustomPartsCapacity: this.productionCalendarForm.controls.plannedCustomPartsCapacity.value,
      plannedNumberOfEmployees: this.productionCalendarForm.controls.plannedNumberOfEmployees.value,
      actualCustomPartsProduced: this.productionCalendarForm.controls.actualCustomPartsProduced.value,
      actualNumberOfEmployees: this.productionCalendarForm.controls.actualNumberOfEmployees.value,
      preProductionNotes: this.productionCalendarForm.controls.preProductionNotes.value,
      postProductionNotes: this.productionCalendarForm.controls.postProductionNotes.value,
      hoursWorked: this.productionCalendarForm.controls.hoursWorked.value,
      plannedHours: this.productionCalendarForm.controls.plannedHours.value,
      numberOfBusinessDays: this.productionCalendarShortcutsForm.controls.numberOfBusinessDays.value,
      clone: this.productionCalendarShortcutsForm.controls.clone.value,
      productionCalendarColorCapacities,
      productionCalendarColorDoorStyleCapacities
    };

    this.productionCalendarService.updateProductionCalendar(model).subscribe((productionCalendar: ProductionCalendar) => {
      this.dialogRef.close(productionCalendar);
      this.snackBarService.showSuccess('The production calendar was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating the production calendar.');
    });
  }

  deleteProductionCalendar() {
    const snackBarRef = this.deleteConfirmation.open(
      'Are you sure you want to delete this custom parts capacities?', 'Delete',
      { duration: CONFIRMATION_DURATION }
    );

    snackBarRef.onAction().subscribe(() => {
      this.productionCalendarService.deleteProductionCalendar(this.productionCalendarId).subscribe(() => {
        this.dialogRef.close(true);
        this.snackBarService.showSuccess('The production calendar was deleted successfully.');
      }, err => {
        console.error(err);
        this.errorMessages = this.getErrorMessages(err);
        this.snackBarService.showError('Error deleting the production calendar.');
      });
    });

    this.dialogRef.afterClosed().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }
}
