import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { BaseForm } from '../../../forms/base-form';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Account } from '../../../models/account';
import { AccountsService } from '../../../services/accounts.service';
import { ActivatedRoute } from '@angular/router';
import { ChangePassword } from '../../../models/change-password';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent extends BaseForm implements OnInit {

  account: Account;
  changePasswordForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getAccount();
  }

  createForm() {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });
  }

  isValid(): boolean {
    return this.changePasswordForm.valid;
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.changePassword();
    }
  }

  getCurrentPasswordErrorMessage() {
    return this.changePasswordForm.controls.currentPassword.hasError('required') ? 'You must enter a current password' : '';
  }

  getNewPasswordErrorMessage() {
    return this.changePasswordForm.controls.newPassword.hasError('required') ? 'You must enter a new password' : '';
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getAccount(id).subscribe((account: Account) => {
      this.account = account;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account details.');
      });
  }

  changePassword() {
    const id = this.route.snapshot.paramMap.get('id');

    const model: ChangePassword = {
      userId: id,
      currentPassword: this.changePasswordForm.controls.currentPassword.value,
      newPassword: this.changePasswordForm.controls.newPassword.value
    };

    this.accountsService.changePassword(model).subscribe(_ => {
      let message = 'The password was changed successfully.';

      if (id === this.account.id) {
        message += 'Please sign in.';
        this.authService.signOut();
      }
      this.snackBarService.showSuccess(message);
    },
      err => {
        console.error(err);
        this.errorMessages = this.getErrorMessages(err);
        this.snackBarService.showError('Error changing the user password! Please try again.');
      }
    );
  }
}
