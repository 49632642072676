<mat-label class="matlbl" style="color: grey;font-weight: 450;">Customer Price Group</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.customerPriceGroup}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Customer Role</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.role}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Sales Rep Name</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.salesRepName}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Credit Limit</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{math.trunc(invInfo?.creditLimit) | currency}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Account Balance</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.balance | currency}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Is Blocked</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.blocked}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Order Number</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.salesOrderNumber}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">SFA</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{invInfo?.accountID}}</mat-label>