export enum ExportToProductionStatus {
    Failed = -1,
    Started = 0,
    Ended = 1,
}

export enum ExportToProductionFilter {
    NormalOrders = 1,
    AccessoriesOnly = 2,
    PartsOrders = 3,
    JIT = 4,
    Hot = 5,
    Palletized = 6
}

export interface ExportToProductionAudit {
    id: number;
    startTime: number;
    productionLocationId: number;
    productionDate: Date;
    status: ExportToProductionStatus;
}
