import { Component } from '@angular/core';
import { Account } from '../../../models/account';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountsService } from '../../../services/accounts.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { BaseForm } from '../../../forms/base-form';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.less']
})
export class CreateAccountComponent extends BaseForm {

  createAccountForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  createForm() {
    this.createAccountForm = this.fb.group({
      userName: ['', Validators.required],
      email: ['', Validators.email],
      name: [''],
      location: [''],
    });
  }

  isValid(): boolean {
    return this.createAccountForm.valid;
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.createAccount();
    }
  }

  getUserNameErrorMessage() {
    return this.createAccountForm.controls.userName.hasError('required') ? 'You must enter a username' : '';
  }

  getEmailErrorMessage() {
    return this.createAccountForm.controls.email.hasError('email') ? 'You must enter a valid email address' : '';
  }

  createAccount() {
    const model: Account = {
      userName: this.createAccountForm.controls.userName.value,
      email: this.createAccountForm.controls.email.value,
      name: this.createAccountForm.controls.name.value,
      location: this.createAccountForm.controls.location.value
    };

    this.accountsService.createAccount(model).subscribe(_ => {
      this.router.navigate(['/accounts']);
      this.snackBarService.showSuccess('The account was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new account.');
    });
  }

}
