<ng-container *ngIf="calendarOptions">

  <div class="actions">
    <mat-form-field>
      <mat-label>Production Location</mat-label>
      <mat-select (selectionChange)="onProductionLocationChange($event)" [value]="productionLocationId">
        <mat-option *ngFor="let item of productionLocations" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="loading" *ngIf="loading">
      <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <button mat-flat-button color="accent" (click)="openEditMaterialSuppliers()">
      Edit Material Suppliers
    </button>
  </div>

  <ng-fullcalendar class="production-calendar" (dateClick)="openEditProductionCalendar($event)" #fullcalendar
    [options]="calendarOptions">
  </ng-fullcalendar>
</ng-container>
<mat-card *ngIf="!calendarOptions" class="mat-elevation-z2">
  <h1 class="mat-h1">
    Production Calendar
  </h1>
  <hr />
  <h3>No production calendar to show.</h3>
</mat-card>