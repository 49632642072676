import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MiterSawComponent } from '../miter-saw.component';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { Train } from '../../../models/train';
import { Rdb } from '../../../models/rdb';
import { UserRole } from '../../../models/user-role';
import { TrainsService } from '../../../services/trains.service';
import { ProductionOrderFiles } from 'src/app/models/production-order-file';

const ALLOWED_ROLES = [UserRole.Admin];

@Component({
  selector: 'app-rdb-files',
  templateUrl: './rdb-files.component.html',
  styleUrls: ['./rdb-files.component.less']
})
export class RdbFilesComponent implements OnInit {

  train: Train;
  disableCancelAction = false;
  displayedColumns: string[] = ['id', 'sequence', 'friendlyName', 'fileDownloaded', 'dateTimeDownloaded', 'actions'];

  constructor(
    private authService: AuthService,
    private trainsService: TrainsService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<MiterSawComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { train: Train }) { }

  ngOnInit(): void {
    this.train = this.data.train;
  }

  getRDBFilesDataSource(): Rdb[] {
    return this.train && this.train.rdbFiles ? this.train.rdbFiles : [];
  }

  userIsAllowedToUnlockRDBOrderFiles() {
    return this.authService.isUserInRoles(ALLOWED_ROLES);
  }

  getFile(rdbFile: Rdb) {
    const machine = this.authService.getMachine();
    if (machine) {
      if (!rdbFile.fileDownloaded) {
        this.trainsService.getFile(machine, rdbFile.id).subscribe((file: ProductionOrderFiles) => {
          const url = this.trainsService.getFileUrl(file.url);
          if (!file.downloaded && url) {
            window.open(url);
          } else {
            this.snackBarService.showWarning('The file was downloaded already.');
          }
        }, err => {
          // reload train
          this.getTrain();
          console.error(err);
          this.snackBarService.showError('Error getting rdb file.');
        }, () => {
          this.getTrain();
        });
      } else {
        this.snackBarService.showWarning('The file was downloaded already.');
      }
    } else {
      this.snackBarService.showError('We can\'t download a file without IP address. Please have your supervisor contact IT.');
    }
  }

  getTrain(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainsService.getTrain(this.train.id).subscribe((train: Train) => {
        this.train = train;
        resolve();
      }, err => {
        console.log(err);
        reject();
      });
    });
  }

  unlockRdbFile(id: number) {
    this.trainsService.unlockRdbFile(id).subscribe((rdbFile: Rdb) => {
      if (rdbFile) {
        this.getTrain();
        this.snackBarService.showSuccess('The rdb file was unlocked successfully.');
      } else {
        this.snackBarService.showError('Invalid rdb file. Please reload the page and try again.');
      }
    }, err => {
      console.log(err);
      this.snackBarService.showError('Error unlocking rdb file. Please try again.');
    });
  }

  cancel() {
    this.dialogRef.close(this.train);
  }

}
