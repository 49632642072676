import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { RebateGroup } from '../../../models/rebate-group';
import { AccountingService } from '../../../services/accounting.service';

@Component({
  selector: 'app-rebate-reports',
  templateUrl: './rebate-reports.component.html',
  styleUrls: ['./rebate-reports.component.less']
})
export class RebateReportsComponent implements OnInit {

  startPeriod = new UntypedFormControl();
  endPeriod = new UntypedFormControl();
  rebateGroup = new UntypedFormControl();
  rebateGroups: RebateGroup[] = [];
  enableReport = false;
  csvUrl = '';

  constructor(
    private accountingService: AccountingService,
    public dialogRef: MatDialogRef<RebateReportsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rebateGroups: RebateGroup[] }) { }

  ngOnInit(): void {
    this.rebateGroups = this.data.rebateGroups.filter(r => r.isActive);
  }

  isValid(): boolean {
    this.markAllAsTouched();
    const diff = moment(this.endPeriod.value).diff(moment(this.startPeriod.value), 'days');

    if (diff < 0) {
      this.startPeriod.setErrors({ min: true });
      return false;
    }

    this.startPeriod.setErrors({ min: null });
    this.startPeriod.updateValueAndValidity();

    return this.startPeriod.valid && this.endPeriod.valid && this.rebateGroup.valid;
  }

  markAllAsTouched(): void {
    this.startPeriod.markAsTouched();
    this.endPeriod.markAsTouched();
    this.rebateGroup.markAsTouched();
  }

  generateReportUrl(): void {
    this.enableReport = this.isValid();
    if (this.enableReport) {
      this.csvUrl = this.accountingService.generateRebateReportUrl(this.startPeriod.value, this.endPeriod.value, this.rebateGroup.value);
    }
  }

}
