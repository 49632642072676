import { Component, OnInit } from '@angular/core';
import { BaseForm } from '../../../forms/base-form';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProductionLimitsService } from '../../../services/production-limits.service';
import { Customer } from '../../../models/customer';
import { ApartmentComplex } from '../../../models/apartment-complex';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ProductionLimit } from 'src/app/models/production-limit';
import { GlobalSetting } from 'src/app/models/global-setting';

@Component({
  selector: 'app-create-production-limit',
  templateUrl: './create-production-limit.component.html',
  styleUrls: ['./create-production-limit.component.less']
})
export class CreateProductionLimitComponent extends BaseForm implements OnInit {

  globalSetting: GlobalSetting;
  customers: Customer[] = [];
  selectedCustomer: Customer;
  selectedApartmentComplex: ApartmentComplex;

  filteredCustomers: Observable<Customer[]>;
  apartmentsComplex: ApartmentComplex[] = [];
  filteredApartmentsComplex: Observable<ApartmentComplex[]>;

  createProductionLimitForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private productionLimitsService: ProductionLimitsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getGlobalSetting();
    this.getCustomers();

    this.filteredCustomers = this.createProductionLimitForm.controls.customer.valueChanges
      .pipe(
        startWith(''),
        map(customer => customer ? this.filterCustomers(customer) : this.customers.slice())
      );

    this.filteredApartmentsComplex = this.createProductionLimitForm.controls.apartmentComplex.valueChanges
      .pipe(
        startWith(''),
        map(apt => apt ? this.filterApartmentsComplex(apt) : this.apartmentsComplex.slice())
      );
  }

  createForm() {
    this.createProductionLimitForm = this.fb.group({
      customer: ['', Validators.required],
      apartmentComplex: [''],
      allowedCapacity: ['', [
        Validators.required,
        Validators.min(0),
        Validators.pattern('[0-9]*')]]
    });
  }

  onCustomerOptionSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value as string;
    const [company, name] = value.trim().split(',');

    this.selectedCustomer = this.findCustomer(company, name);

    if (this.selectedCustomer) {
      this.getApartmentsComplex(this.selectedCustomer.id);
    }
  }

  onApartmentComplexOptionSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value as string;
    this.selectedApartmentComplex = this.findApartmentComplex(value);
  }

  isValid(): boolean {
    return this.selectedCustomer && this.createProductionLimitForm.valid;
  }

  submit() {
    this.reset();
    if (this.isValid()) {
      this.createProductionLimit();
    } else {
      this.snackBarService.showError('You must select a valid customer.');
    }
  }

  getCustomerErrorMessage() {
    return this.createProductionLimitForm.controls.customer.hasError('required') ? 'You must enter a customer' : '';
  }

  getAllowedCapacityErrorMessage() {
    if (this.createProductionLimitForm.controls.allowedCapacity.hasError('min')) {
      return `You must enter a valid custom parts capacity greater than ${this.globalSetting.value}`;
    }

    if (this.createProductionLimitForm.controls.allowedCapacity.hasError('pattern')) {
      return `You must enter a valid custom parts capacity integer value.`;
    }

    return this.createProductionLimitForm.valid ? 'You must enter a valid custom parts capacity' : '';
  }

  createProductionLimit() {
    if (this.selectedCustomer) {
      const model: ProductionLimit = {
        customerId: this.selectedCustomer.id,
        customerName: this.createProductionLimitForm.controls.customer.value,
        apartmentComplexId: this.selectedApartmentComplex ? this.selectedApartmentComplex.id : null,
        apartmentComplexName: this.createProductionLimitForm.controls.apartmentComplex.value,
        allowedCustomPartsCapacity: this.createProductionLimitForm.controls.allowedCapacity.value
      };

      this.productionLimitsService.createProductionLimit(model).subscribe(_ => {
        this.router.navigate(['/production-limits']);
        this.snackBarService.showSuccess('The production limit was created successfully.');
      }, err => {
        console.error(err);
        this.errorMessages = this.getErrorMessages(err);
        this.snackBarService.showError('Error creating a new production limit.');
      });
    }
  }

  filterCustomers(value: string): Customer[] {
    const [filterValue] = value.trim().toLowerCase().split(',');
    return this.customers.filter(customer => customer && customer.company && customer.company.toLowerCase().indexOf(filterValue) === 0);
  }

  filterApartmentsComplex(value: string): ApartmentComplex[] {
    const filterValue = value.trim().toLowerCase();
    return this.apartmentsComplex.filter(apt => apt && apt.name && apt.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getCustomerName(customer: Customer): string {
    return `${customer.company}, ${customer.name}`;
  }

  findCustomer(company: string, name: string): Customer {
    return this.customers.find(c => c.company.trim() === company.trim() && c.name.trim() === name.trim());
  }

  findApartmentComplex(name: string): ApartmentComplex {
    return this.apartmentsComplex.find(c => c.name.trim() === name.trim());
  }

  getGlobalSetting() {
    this.productionLimitsService.getGlobalSetting().subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
      const minValue = +this.globalSetting.value;
      this.createProductionLimitForm.controls.allowedCapacity.setValidators([
        Validators.required,
        Validators.min(minValue),
        Validators.pattern('[0-9]*')]);
      this.createProductionLimitForm.controls.allowedCapacity.setValue(minValue);
      this.createProductionLimitForm.updateValueAndValidity();
    });
  }

  getCustomers() {
    this.productionLimitsService.getCustomers().subscribe((customers: Customer[]) => {
      this.customers = customers;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting customers.');
    });
  }

  getApartmentsComplex(customerId: number) {
    this.productionLimitsService.getApartmentsComplex(customerId).subscribe((apartmentsComplex: ApartmentComplex[]) => {
      this.apartmentsComplex = apartmentsComplex;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting apartments complex.');
    });
  }

}





