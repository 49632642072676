<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Edit Account
  </h1>
  <hr />

  <div class="actions">
    <a *ngIf="allowedEditAccount" routerLink="/accounts/roles/{{account?.id}}" mat-raised-button>Edit Roles</a>
    <button mat-raised-button (click)="resetPassword()">Reset Password</button>
    <a routerLink="/account/change-password/{{account?.id}}" mat-raised-button>Change Password</a>
  </div>
  <p><strong>Account:</strong> {{account?.userName}}</p>
  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="editAccountForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="UserName" formControlName="userName" required>
        <mat-error *ngIf="editAccountForm.controls.userName.invalid">{{getUserNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Email" type="email" formControlName="email">
        <mat-error *ngIf="editAccountForm.controls.email.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Name" type="name" formControlName="name">
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Location" type="location" formControlName="location">
      </mat-form-field>
      <div class="mat-form-field">
        <mat-checkbox formControlName="isActive" [disabled]="!allowedEditAccount">Is Active</mat-checkbox>
      </div>
      <button mat-raised-button color="accent">Update</button>
    </form>
  </div>
</mat-card>