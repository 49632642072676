<h1 class="mat-h1">Scan Lookups</h1>
<hr />

<mat-accordion>
  <mat-expansion-panel expanded="true" class="filters-panel">
    <mat-expansion-panel-header>
      <mat-panel-title> Filters </mat-panel-title>
      <mat-panel-description> Filter Scan Lookups </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="filters">
      <div class="filters-container">
        <div>
          <mat-form-field *ngIf="filterBy.value === filterBySalesOrderNumber">
            <input matInput placeholder="Filter by" [formControl]="salesOrderNumberFilter" />
          </mat-form-field>
          <mat-form-field *ngIf="filterBy.value === filterByCartonId">
            <input matInput type="number" placeholder="Filter by" [formControl]="cartonIdFilter" />
          </mat-form-field>
        </div>
        <mat-radio-group aria-label="Select an option" [formControl]="filterBy">
          <mat-radio-button value="salesOrderNumber">SO #</mat-radio-button>
          <mat-radio-button value="cartonId">Carton Id</mat-radio-button>
        </mat-radio-group>
      </div>

      <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<br />

<div class="reset-action-container" *ngIf="filtersApplied">
  <button mat-raised-button (click)="clearFilters()">
    <mat-icon>clear</mat-icon> Reset Scan Lookups Filters
  </button>
</div>

<div class="loading" *ngIf="loading">
  <mat-spinner diameter="40" color="accent"> </mat-spinner>
  <span> Loading</span>
</div>

<h3 *ngIf="!loading && getData().length === 0 && filtersApplied">
  No scan lookups to show.
</h3>

<table *ngIf="!loading && getData().length > 0" mat-table #scanLookupsSort="matSort" matSort
  [dataSource]="getScanLookupsDataSource()" class="mat-elevation-z0 table">
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <ng-container matColumnDef="salesOrderNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      SO #
    </th>
    <td mat-cell *matCellDef="let element">{{ element.salesOrderNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="apartmentNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Apt #</th>
    <td mat-cell *matCellDef="let element">{{ element.apartmentNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="cartonNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Carton #</th>
    <td mat-cell *matCellDef="let element">{{ element.cartonNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="color">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Color</th>
    <td mat-cell *matCellDef="let element">{{ element.color }}</td>
  </ng-container>

  <ng-container matColumnDef="doorStyle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Style</th>
    <td mat-cell *matCellDef="let element">{{ element.doorStyle }}</td>
  </ng-container>

  <ng-container matColumnDef="cartonId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Carton Id</th>
    <td mat-cell *matCellDef="let element">{{ element.cartonId }}</td>
  </ng-container>

  <ng-container matColumnDef="scanType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Scan Type / Is Accessory Match
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.scanType === accessoryMatchType; else noMatchTemplate">
        {{ element.scanType }} / {{ element.isAccessoryMatch }}
      </ng-container>
      <ng-template #noMatchTemplate>
        {{ element.scanType }}
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
    <td mat-cell *matCellDef="let element">{{ element.dateCreated | date : "medium" }}</td>
  </ng-container>

  <ng-container matColumnDef="overrideScan">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Override Scan</th>
    <td mat-cell *matCellDef="let element">{{ element.isOverrideScan | active }}</td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
    <td mat-cell *matCellDef="let element">{{ element.locationName }}</td>
  </ng-container>

  <ng-container matColumnDef="workCenter">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Center</th>
    <td mat-cell *matCellDef="let element">{{ element.workCenterName }}</td>
  </ng-container>
  <ng-container matColumnDef="fedExTrackingNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      FedEx Tracking Number
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.fedExTrackingNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="shipMethod">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Method</th>
    <td mat-cell *matCellDef="let element">{{ element.shippingMethod }}</td>
  </ng-container>
  <ng-container matColumnDef="mdfSupplier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>MDF Supplier</th>
    <td mat-cell *matCellDef="let element">{{ element.moldingSupplier }}</td>
  </ng-container>
</table>

<br />

<h1 class="mat-h1" *ngIf="!loading && getProductionScanLogsData().length > 0">
  Production Scan Logs
</h1>

<table *ngIf="!loading && getProductionScanLogsData().length > 0" mat-table #productionScanLogsSort="matSort" matSort
  [dataSource]="getProductionScanLogsData()" class="mat-elevation-z0 table">
  <tr mat-header-row *matHeaderRowDef="productionScanLogsDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: productionScanLogsDisplayedColumns"></tr>

  <ng-container matColumnDef="employee">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee</th>
    <td mat-cell *matCellDef="let element">{{ element.employeeName }}</td>
  </ng-container>

  <ng-container matColumnDef="workCenter">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Center</th>
    <td mat-cell *matCellDef="let element">{{ element.workCenterName }}</td>
  </ng-container>

  <ng-container matColumnDef="scanType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Type</th>
    <td mat-cell *matCellDef="let element">
      {{ element.scanType }}
    </td>
  </ng-container>

  <ng-container matColumnDef="overrideScan">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Override Scan</th>
    <td mat-cell *matCellDef="let element">
      {{ element.isOverrideScan | active }}
    </td>
  </ng-container>

  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
    <td mat-cell *matCellDef="let element">
      {{ element.dateCreated | date : "medium" }}
    </td>
  </ng-container>
</table>