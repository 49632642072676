import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditForm } from '../../../forms/edit-form';
import { RouterSchedule } from '../../../models/router-schedule';
import { RouterSchedulesService } from '../../../services/router-schedules.service';
import { SnackbarService } from '../../../services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-router-schedule',
  templateUrl: './edit-router-schedule.component.html',
  styleUrls: ['./edit-router-schedule.component.less']
})
export class EditRouterScheduleComponent extends EditForm implements OnInit {

  loading = false;
  routerSchedule: RouterSchedule;
  routerScheduleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private routerSchedulesService: RouterSchedulesService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getRouterSchedule();
  }

  getRouterSchedule(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routerSchedulesService.getRouterSchedule(id).subscribe((routerSchedule: RouterSchedule) => {
      this.routerSchedule = routerSchedule;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router schedule details.');
      });
  }

  createForm(): void {
    this.routerScheduleForm = this.fb.group({
      routerId: ['', Validators.required],
      routerStr: ['', Validators.required],
      productionLocationId: ['', Validators.required],
      date: ['', Validators.required],
      timeStart: ['', Validators.required],
      timeEnd: ['', Validators.required],
    });
  }

  setValues() {
    const date = moment(this.routerSchedule.date).format('MM/DD/YYYY');
    const timeStart = moment(`${date} ${this.routerSchedule.timeStart}`, 'MM/DD/YYYY hh:mm a');
    const timeEnd = moment(`${date} ${this.routerSchedule.timeEnd}`, 'MM/DD/YYYY hh:mm a');
    this.routerScheduleForm.setValue({
      routerId: this.routerSchedule.routerId,
      routerStr: this.routerSchedule.routerStr,
      productionLocationId: this.routerSchedule.productionLocationId,
      date: this.routerSchedule.date,
      timeStart: timeStart.format('HH:mm'),
      timeEnd: timeEnd.format('HH:mm'),
    });
  }

  isValid(): boolean {
    const date = moment(this.routerScheduleForm.get('date').value).format('MM/DD/YYYY');
    const timeStart = this.routerScheduleForm.get('timeStart').value;
    const timeEnd = this.routerScheduleForm.get('timeEnd').value;
    const d1 = moment(`${date} ${timeStart}`, 'MM/DD/YYYY hh:mm a');
    const d2 = moment(`${date} ${timeEnd}`, 'MM/DD/YYYY hh:mm a');
    const validTimeEnd = moment(d2).diff(d1, 'minutes') > 0;

    if (!validTimeEnd) {
      this.routerScheduleForm.get('timeEnd').setErrors({ min: true });
    }

    return this.routerScheduleForm.valid && validTimeEnd;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.editRouterSchedule();
    }
  }

  getData(): RouterSchedule {
    const id = +this.route.snapshot.paramMap.get('id');
    const data: RouterSchedule = {
      id,
      routerId: this.routerScheduleForm.get('routerId').value,
      routerStr: this.routerScheduleForm.get('routerStr').value,
      productionLocationId: this.routerScheduleForm.get('productionLocationId').value,
      date: this.routerScheduleForm.get('date').value,
      timeStart: this.routerScheduleForm.get('timeStart').value,
      timeEnd: this.routerScheduleForm.get('timeEnd').value,
    };
    return data;
  }

  editRouterSchedule() {
    this.loading = true;
    const model = this.getData();

    this.routerSchedulesService.updateRouterSchedule(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/routers']);
      this.snackBarService.showSuccess('The router schedule was updated successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error updating router schedule.');
    });
  }

}
