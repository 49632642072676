import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProductionOrdersService } from '../../../services/production-orders.service';
import { RouterCimComponent } from '../../router-cim/router-cim.component';
import { SnackbarService } from '../../../services/snackbar.service';
import { AuthService } from '../../../services/auth.service';
import { ProductionStatus } from '../../../models/production-status';
import { ScheduleService } from '../../../services/schedule.service';
import { ProductionOrder } from '../../../models/production-order';
import { RouterSchedule } from '../../../models/router-schedule';
import { UserRole } from '../../../models/user-role';

const NONE_PRODUCTION_STATUS = 'None';
const ALLOWED_ROLES = [UserRole.Admin, UserRole.ProductionManager];
const PANELS_CUT_PRODUCTION_STATUS = ProductionStatus[ProductionStatus.PanelsCut];

@Component({
  selector: 'app-edit-production-order',
  templateUrl: './edit-production-order.component.html',
  styleUrls: ['./edit-production-order.component.less']
})
export class EditProductionOrderComponent implements OnInit {

  routerId: number;
  routerScheduleId: number;
  routerSchedules: RouterSchedule[] = [];
  routerModeEnabled = false;
  disabledJITScheduling = false;
  selectedProductionStatus = '';
  rejectionNotes: string = '';
  defaultGibenStartTime: Date = null;
  productionOrder: ProductionOrder;
  productionStatus: string[] = [PANELS_CUT_PRODUCTION_STATUS];

  disableCancelAction = false;
  disableUpdateAction = false;
  unlockProductionOrderFiles = false;
  unlockPrintPaperwork = false;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<RouterCimComponent>,
    private snackBarService: SnackbarService,
    private scheduleService: ScheduleService,
    private productionOrdersService: ProductionOrdersService,
    @Inject(MAT_DIALOG_DATA) public data: { routerId?: number, productionOrder: ProductionOrder },
  ) { }

  async ngOnInit() {
    this.routerId = this.data.routerId;
    this.productionOrder = this.data.productionOrder;

    this.enableRouterMode();

    if (this.routerModeEnabled) {
      await this.getRouterSchedules(this.productionOrder.productionLocationId);
      this.setDefaultRouterSchedule();
    }

    if (!this.productionOrder.filesDownloaded ||
      (this.productionOrder.filesDownloaded || this.productionOrder.paperworkPrinted) && this.authService.isUserInRoles(ALLOWED_ROLES)) {
      this.productionStatus.unshift(NONE_PRODUCTION_STATUS);
    }

    this.selectedProductionStatus = this.productionStatus[0];
  }

  enableRouterMode() {
    this.routerModeEnabled = this.routerId != null;
  }

  needToDisplayMaterialSupplier(e: ProductionOrder): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierId && colors.indexOf(e.color) !== -1;
  }

  onRouterScheduleChange() {
    this.disabledJITScheduling = true;
  }

  async getRouterSchedules(productionLocationId: number) {
    try {
      this.routerSchedules = await this.productionOrdersService.getRouterSchedules(productionLocationId).toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting router schedules.');
    }
  }

  setDefaultRouterSchedule() {
    this.routerScheduleId = this.routerSchedules.find(r => r.routerId === this.routerId)?.id;
  }

  async updateProductionOrder() {
    this.disableCancelAction = true;
    this.disableUpdateAction = true;
    this.disabledJITScheduling = true;

    try {
      const productionOrder = await this.productionOrdersService.updateProductionOrder(
        this.productionOrder,
        NONE_PRODUCTION_STATUS,
        this.routerScheduleId,
        this.defaultGibenStartTime,
        this.unlockProductionOrderFiles,
        this.unlockPrintPaperwork
      ).toPromise();

      this.dialogRef.close(productionOrder);
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error updating production order. Please try again.');
    } finally {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
      this.disabledJITScheduling = false;
    }
  }

  async startJITScheduling() {
    this.disableCancelAction = true;
    this.disableUpdateAction = true;
    this.disabledJITScheduling = true;

    try {
      const result = await this.scheduleService.createProductionOrder(this.routerId, this.productionOrder.id, false, false).toPromise();
      if (!result) {
        this.snackBarService.showWarning('JIT Scheduling has failed. You must check your router configurations and try again. (EN) <br/> JIT Scheduling ha fallado. Debe verificar la configuración de su Router e intentarlo nuevamente. (ES)');
      } else {
        this.productionOrder.gibenStartTime = new Date();
        this.snackBarService.showSuccess('JIT Scheduling executed successfully. A new Production Order will be available.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error executing JIT Scheduling. Please try again.');
    } finally {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
      this.disabledJITScheduling = false;
    }
  }

  async releaseJITScheduling() {
    this.disableCancelAction = true;
    this.disableUpdateAction = true;
    this.disabledJITScheduling = true;

    try {
      const result = await this.scheduleService.releaseProductionOrder(this.routerId, this.productionOrder.id).toPromise();
      if (!result) {
        this.snackBarService.showWarning('Release JIT Scheduling production order has failed. Please try again.');
      } else {
        this.snackBarService.showSuccess('Release JIT Scheduling production order executed successfully.');
        this.dialogRef.close(this.productionOrder);
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error executing release JIT Scheduling production order. Please try again.');
    } finally {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
      this.disabledJITScheduling = false;
    }
  }

  async rollbackJITScheduling() {
    try {
      if (!this.rejectionNotes || this.rejectionNotes.trim() === '') {
        this.snackBarService.showWarning('Rejection notes is required.');
        return;
      }
      this.disableCancelAction = true;
      this.disableUpdateAction = true;
      this.disabledJITScheduling = true;

      const result = await this.scheduleService.rollbackProductionOrder(this.routerId, this.productionOrder.id, this.rejectionNotes).toPromise();

      if (!result) {
        this.snackBarService.showWarning('Rollback JIT Scheduling has failed. Please try again.');
      } else {
        this.snackBarService.showSuccess('Rollback JIT Scheduling executed successfully.');
        this.dialogRef.close(this.productionOrder);
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error executing rollback JIT Scheduling. Please try again.');
    } finally {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
      this.disabledJITScheduling = false;
    }
  }

  async setGibenStartTime() {
    this.disableCancelAction = true;
    this.disableUpdateAction = true;

    try {
      const gibenStartTime = new Date();
      const productionOrder = await this.productionOrdersService.updateProductionOrder(this.productionOrder, NONE_PRODUCTION_STATUS, this.routerScheduleId, gibenStartTime).toPromise();
      if (productionOrder) {
        this.productionOrder.gibenStartTime = productionOrder.gibenStartTime;
        this.snackBarService.showSuccess('Giben start time was updated successfully.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error updating giben start time. Please try again.');
    } finally {
      this.disableCancelAction = false;
      this.disableUpdateAction = false;
    }
  }

  userIsAllowedToRollbackJITScheduling(): boolean {
    const statuses: string[] = [`${ProductionStatus[ProductionStatus.FinalQC]}`, `${ProductionStatus[ProductionStatus.Packaging]}`, `${ProductionStatus[ProductionStatus.PackagingComplete]}`, `${ProductionStatus[ProductionStatus.Shipped]}`, `${ProductionStatus[ProductionStatus.Invoiced]}`];
    return this.routerId && !this.disabledJITScheduling && this.authService.isUserInRoles(ALLOWED_ROLES) && statuses.indexOf(this.productionOrder.status) === -1;
  }

  userIsAllowedToStartJITScheduling(): boolean {
    return this.routerId && !this.disabledJITScheduling && this.productionOrder.filesDownloaded && !this.productionOrder.gibenStartTime;
  }

  userIsAllowedToUnlockProductionOrderFiles() {
    return this.authService.isUserInRoles(ALLOWED_ROLES)
      && this.productionOrder.filesDownloaded
      && this.selectedProductionStatus === NONE_PRODUCTION_STATUS;
  }

  userIsAllowedToUnlockPrintPaperwork() {
    return this.authService.isUserInRoles(ALLOWED_ROLES)
      && this.productionOrder.paperworkPrinted
      && this.selectedProductionStatus === NONE_PRODUCTION_STATUS;
  }
}
