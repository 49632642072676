<h2 mat-dialog-title>Reset Confirmation</h2>
<mat-dialog-content>
    <p> Are you sure you want to reset the production status?</p>
    <ul>
        <li>
            <strong>Cart + Slots:</strong>
            {{item.cartLetter}} {{item.beginSlot}} - {{item.endSlot}}
        </li>
        <li><strong>Train:</strong> {{item.trainNumber}}</li>
        <li><strong>Customer:</strong> {{item.customerName}}</li>
        <li><strong>Project:</strong> {{item.project}}</li>
        <li><strong>Apartment:</strong> {{item.apartmentNumber}}</li>
        <li><strong>PO #:</strong> {{item.productionOrderNumber}}</li>
        <li><strong>Door Style:</strong> {{item.style}}</li>
        <li><strong>Color:</strong> {{item.color}}</li>
        <li><strong>Total Parts:</strong> {{item.totalParts}}</li>
        <li><strong>Friendly Status:</strong> {{item.productionStatus}}</li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
    <button mat-raised-button color="accent" (click)="yes()">Yes</button>
</mat-dialog-actions>