<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Role Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/roles/edit/{{role?.id}}" mat-raised-button>Edit Role</a>
  </div>

  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{role?.id}}</li>
      <li><strong>Name:</strong> {{role?.name}}</li>
      <li><strong>Description:</strong> {{role?.description}}</li>
      <li><strong>Date Created:</strong> {{role?.dateCreated | date}}</li>
    </ul>
  </div>
</mat-card>
