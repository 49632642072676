<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Miter Saw
    <div>
      <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
        matTooltip="Fullscreen Mode">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
        matTooltip="Exit Fullscreen Mode">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
    </div>
  </h1>
  <hr />

  <div class="container">
    <p class="machine">Machine: <span>{{machine}}</span></p>
    <mat-accordion>
      <mat-expansion-panel expanded="true" class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Miter Saw
          </mat-panel-title>
          <mat-panel-description>
            Select a production location
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="actions">
          <mat-form-field>
            <mat-label>Production Location</mat-label>
            <mat-select (selectionChange)="onProductionLocationChange($event)" [value]="productionLocationId">
              <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="filters-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filters
          </mat-panel-title>
          <mat-panel-description>
            Filter trains
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="filters">
          <div class="filters-container">
            <div>
              <mat-form-field>
                <input matInput placeholder="PO #" [formControl]="poNumberFilter">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Train Number" [formControl]="trainNumberFilter">
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Production Date"
                  [formControl]="productionDateFilter" (dateChange)="onProductionDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Colors</mat-label>
                <mat-select [formControl]="colorsFilter" multiple>
                  <mat-option *ngFor="let color of colors" [value]="color.name">{{color.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Door Styles</mat-label>
                <mat-select [formControl]="doorStyleFilter">
                  <mat-option *ngFor="let doorStyle of doorStyles" [value]="doorStyle.name">{{doorStyle.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
  </div>

  <div class="reset-action-container" *ngIf="filtersApplied">
    <button mat-raised-button (click)="clearFilters()">
      <mat-icon>clear</mat-icon> Reset Trains Filters
    </button>
  </div>

  <h4 *ngIf="!loading && !hasTrains()">No trains to show.</h4>

  <table mat-table *ngIf="hasTrains()" [dataSource]="getTrainsDataSource()" #trainsSort="matSort" matSort
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRDBFiles(row.id)"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="productionOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.productionOrderNumber}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="trainNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Train Number </th>
      <td mat-cell *matCellDef="let element"> {{element.trainNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate | date }} </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
      <td mat-cell *matCellDef="let element"> {{element.color}} </td>
    </ng-container>

    <ng-container matColumnDef="doorStyle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style </th>
      <td mat-cell *matCellDef="let element"> {{element.doorStyle}} </td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef> Files </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.rdbFiles.length}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element">
        <a mat-icon-button matTooltip="Edit Train" matTooltipPosition="above"
          (click)="openEditTrain(element.id)">
          <mat-icon color="accent">edit</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>