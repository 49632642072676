import { Component, OnInit } from '@angular/core';
import { EditForm } from '../../../forms/edit-form';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Role } from 'src/app/models/role';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.less']
})
export class EditRoleComponent extends EditForm implements OnInit {

  role: Role;
  editRoleForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getRole();
  }

  createForm() {
    this.editRoleForm = this.fb.group({
      name: ['', Validators.required],
      description: ['']
    });
  }

  isValid(): boolean {
    return this.editRoleForm.valid;
  }

  setValues() {
    this.editRoleForm.setValue({
      name: this.role.name,
      description: this.role.description
    });
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.updateRole();
    }
  }

  getRoleErrorMessage() {
    return this.editRoleForm.controls.name.hasError('required') ? 'You must enter a role name' : '';
  }

  getRole() {
    const id = this.route.snapshot.paramMap.get('id');
    this.rolesService.getRole(id).subscribe((role: Role) => {
      this.role = role;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the role details.');
      });
  }

  updateRole() {
    const id = this.route.snapshot.paramMap.get('id');
    const model: Role = {
      id,
      name: this.editRoleForm.controls.name.value,
      description: this.editRoleForm.controls.description.value,
    };

    this.rolesService.updateRole(model).subscribe((role: Role) => {
      this.role = role;
      this.router.navigate(['/roles']);
      this.snackBarService.showSuccess('The role was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating role.');
    });
  }

}
