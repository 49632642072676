export interface RatesAndTransitTimeDisplay {
    serviceAndTransitTimes: string;
    rate: number;
}

export enum ServiceTypeOvernight {
    STANDARD_OVERNIGHT = "FedEx Standard Overnight",
    PRIORITY_OVERNIGHT = "FedEx Priority Overnight",
    FEDEX_2_DAY = "FedEx 2 Day"
}

export interface RatesAndTransitTime {
    display: RatesAndTransitTimeDisplay;
    Time: Date;
    Rate: number;
    ServiceType: ServiceTypeOvernight;
    select: boolean;
}