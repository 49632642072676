import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../services/accounts.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Account } from '../../models/account';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.less']
})
export class AccountsComponent implements OnInit {

  accounts: Account[] = [];
  displayedColumns: string[] = ['id', 'userName', 'email', 'name', 'location', 'isActive', 'dateCreated', 'actions'];

  constructor(
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getAccounts();
  }

  getAccounts() {
    this.accountsService.getAccounts().subscribe((accounts: Account[]) => {
      this.accounts = accounts;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting accounts.');
      }
    );
  }
}
