<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
          </button>
        <mat-card-header >
            <h1 class="mat-h1">
                Adding account to an existing email
             </h1>
        </mat-card-header>
        <mat-card-content>
            <div class="container">
                <ng-container>
                    <form [formGroup]="addAccountForm" (ngSubmit)="submit()">
                        <mat-form-field class="full-width">
                            <input matInput 
                                placeholder="Email" 
                                formControlName="email" 
                                pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,14}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*"
                                [(ngModel)]="user['userEmail']" required>
                            <mat-error *ngIf="addAccountForm.get('email').hasError('required')">
                                Email is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput 
                                placeholder="Customer Number" 
                                formControlName="customerNumber" 
                                [(ngModel)]="user['customerNumber']" required>
                            <mat-error *ngIf="addAccountForm.get('customerNumber').hasError('required')">
                                Customer Number
                            </mat-error>
                        </mat-form-field>
                        <mat-card-actions>
                            <button mat-raised-button color="accent" class="btn-modal" [disabled]="!addAccountForm.valid" type="submit"> Save </button>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">Cancel</button>
                        </mat-card-actions>
                    </form>
                </ng-container>
            </div>
        </mat-card-content>
        <mat-card-footer>
            
        </mat-card-footer>
    </mat-card>
</mat-dialog-content>
    
