import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterSchedule } from '../../../models/router-schedule';
import { RouterSchedulesService } from '../../../services/router-schedules.service';
import { SnackbarService } from '../../../services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-router-schedule-details',
  templateUrl: './router-schedule-details.component.html',
  styleUrls: ['./router-schedule-details.component.less']
})
export class RouterScheduleDetailsComponent implements OnInit {
  routerSchedule: RouterSchedule;

  constructor(
    private route: ActivatedRoute,
    private routerSchedulesService: RouterSchedulesService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getRouterSchedule();
  }

  getRouterSchedule(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routerSchedulesService.getRouterSchedule(id).subscribe((routerSchedule: RouterSchedule) => {
      this.routerSchedule = routerSchedule;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router schedule details.');
      });
  }

  formatTime(date: Date, time: string): string {
    const d = `${moment(date).format('MM/DD/YYYY')} ${time}`;
    return moment(d, 'MM/DD/YYYY hh:mm a').format('hh:mm a');
  }
}
