import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// Services
import { ReasonCodesService } from '../../../services/reason-codes.service';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/snackbar.service';
// Models
import { Account } from '../../../models/account';
import { ReasonCategory } from '../../../models/reason-category';
// Moment
import * as moment from 'moment';
import { ReasonGroup } from 'src/app/models/reason-group';
import { ReasonGroupsService } from 'src/app/services/reason-groups.service';

@Component({
  selector: 'app-create-reason-category',
  templateUrl: './create-reason-category.component.html',
  styleUrls: ['./create-reason-category.component.less']
})
export class CreateReasonCategoryComponent implements OnInit {
  loading = false;
  user: Account;
  categoryForm: FormGroup;
  groups: ReasonGroup[] = [];

  constructor(
    private auth: AuthService,
    private reasonService: ReasonCodesService,
    private snackService: SnackbarService,
    private navigation: Router,
    private fb: FormBuilder,
    private groupsService: ReasonGroupsService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.createCategoryForm();
    this.user = this.auth.getAccount();
    await this.getReasonGroupsAsync();
  }

  createCategoryForm() {
    this.categoryForm = this.fb.group({
      category: new FormControl('', Validators.required),
      group: new FormControl('', Validators.required)
    });
  }

  goBack() {
    this.navigation.navigate(['reason-codes', { tab: 1 }]);
  }

  async getReasonGroupsAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.groupsService.getReasonGroups().subscribe(
          (response: ReasonGroup[]) => this.groups = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the groups.');
      }
    }).then(() => this.loading = false);
  }

  async createCategory(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonCategory>((resolve, reject) => {
      const group: ReasonGroup = this.categoryForm.get('group').value;
      let newCategory: ReasonCategory = {
        category: this.categoryForm.get('category').value,
        created: new Date(moment().format()),
        createdBy: this.user.userName,
        isActive: true,
        reasonGroupId:  group.id,
        reasonGroup: group.groupName
      };

      try {
        this.reasonService.createReasonCategory(newCategory).subscribe(
          (response: ReasonCategory) => newCategory = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve(newCategory));
      } catch (error) {
        console.error(error);
        reject();
        this.snackService.showError('An error occurred during the save operation.');
      }
    }).then((result: ReasonCategory) => {
      result.categoryId && result.categoryId !== 0 ?
        this.snackService.showSuccess('New category was saved successfully.') :
        this.snackService.showError('An error occurred during the save operation.');
      this.loading = false;
      this.goBack();
    });
  }
}
