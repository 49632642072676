<h1 class="mat-h1">
    Overnight Quote
</h1>
<hr />

<app-order-search (showContentEvent)="showContent($event)"></app-order-search>

<div *ngIf="showResults">
    <div class="container">
        <div class="container-left">
            <app-address-details [type]="'from'"></app-address-details>
        </div>
        <div class="container-right">
            <app-address-details [type]="'to'"></app-address-details>
        </div>
    </div>
    <br />

    <div class="container">
        <div class="container-left">
            <app-package-shipment-details></app-package-shipment-details>
        </div>
        <div class="container-right">
            <app-rates-transit-times></app-rates-transit-times>
        </div>
    </div>
</div>