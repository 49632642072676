<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Edit Roles
  </h1>
  <hr />
  <p><strong>Account:</strong> {{account?.userName}}</p>
  <h3 *ngIf="accountRoles.length === 0">No roles to show.</h3>
  <div class="container">
    <mat-selection-list #list>
      <mat-list-option *ngFor="let accountRole of accountRoles" (click)="onSelectionChanged(list)"
        [selected]="accountRole.isActive" [value]="accountRole.role">
        {{accountRole.role}}
      </mat-list-option>
    </mat-selection-list>
    <div class="actions">
      <button mat-raised-button color="accent" (click)="updateRoles()">Update</button>
    </div>
  </div>
</mat-card>