<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
            <button mat-mini-fab matTooltip="Back to notifications" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
    </div>
</div>
<mat-card>
    <mat-card-content>
        <h1 class="mat-h1">
            New Category
        </h1>
        <hr />
        <ng-container *ngIf="loading; else formTemplate">
            <div class="container-loading">
                <div class="loading">
                    <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
        </ng-container>
        <ng-template #formTemplate>
            <div class="container">
                <form [formGroup]="categoryForm" (ngSubmit)="createCategory()">
                    <mat-form-field class="full-width">
                        <mat-label>Reason Code</mat-label>
                        <input matInput #iptCategory type="text" placeholder="Name of category"  formControlName="category" maxlength="100" minlength="5">
                        <mat-hint align="end">{{iptCategory.value.length}} / 100</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="groups && groups.length > 0">
                        <mat-label>Group</mat-label>
                        <mat-select #groupSelect formControlName="group">
                            <mat-option>- Pick Group -</mat-option>
                            <mat-option *ngFor="let group of groups" [value]="group">
                                {{ group?.groupName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="accent" [disabled]="loading || !categoryForm.valid">Create</button>
                </form>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>