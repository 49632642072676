import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "../../../../services/settings.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { ExpeditedProductionSetting } from "../../../../models/expedited-production-setting";

@Component({
  selector: "app-edit-expedited-production",
  templateUrl: "./edit-expedited-production.component.html",
  styleUrls: ["./edit-expedited-production.component.less"],
})
export class EditExpeditedProductionComponent implements OnInit {
  editExpeditedProductionForm: FormGroup;
  errorMessages: string[] = [];
  expeditedProductionSettingId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {
    this.editExpeditedProductionForm = this.fb.group({
      description: ["", Validators.required],
      minimumFee: [null, [Validators.required, Validators.min(0)]],
      itemNumber:  ["", this.itemNumberValidator],
      feePerPart: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      productionCapacityPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      productionType: ["", Validators.required],
      isActive: [false],
      capacityCheck: [false],
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.expeditedProductionSettingId = params["id"];
      this.loadExpeditedProductionSetting(this.expeditedProductionSettingId);
    });
  }

  loadExpeditedProductionSetting(id: string) {
    // Async operation to fetch the setting
    this.settingsService.getExpeditedProductionSettingById(id).subscribe(
      (setting: ExpeditedProductionSetting) => {
        // Once the setting is retrieved, update the form controls
        this.editExpeditedProductionForm.patchValue(setting);
      },
      (err) => {
        console.error(err);
        this.snackBarService.showError(
          "Error loading expedited production setting."
        );
      }
    );
  }

  submit() {
    if (this.editExpeditedProductionForm.valid) {
      this.updateExpeditedProductionSetting();
    } else {
      this.errorMessages = ["You must enter all required fields correctly."];
      this.snackBarService.showError("Invalid form submission.");
    }
  }

  updateExpeditedProductionSetting() {
    const updatedSetting: ExpeditedProductionSetting = {
      ...this.editExpeditedProductionForm.value,
      id: this.expeditedProductionSettingId,
    };

    this.settingsService
      .updateExpeditedProductionSetting(updatedSetting)
      .subscribe(
        (_) => {
          this.router.navigate(["/settings"]);
          this.snackBarService.showSuccess(
            "Expedited Production Setting updated successfully."
          );
        },
        (err) => {
          console.error(err);
          this.errorMessages = ["Error updating Expedited Production Setting."];
          this.snackBarService.showError(
            "Error updating Expedited Production Setting."
          );
        }
      );
  }

  itemNumberValidator(control: AbstractControl): ValidationErrors | null {
    // Allow letters, numbers, hyphens, and underscores, but no dangerous special characters or only dots
    const pattern = /^[A-Za-z0-9-_]+$/;
  
    // Check if the control value is empty
    if (!control.value) {
      return { 'required': true };
    }
  
    // Check for string containing only dots
    if (control.value.trim() === '.' || control.value.trim().match(/^\.*$/)) {
      return { 'invalidFormat': 'Only dots are not allowed' };
    }
  
    // Check if the control value contains dangerous special characters
    if (/[\>\<\&\%\;\`\/]+/.test(control.value)) {
      return { 'dangerousCharacter': 'Contains dangerous characters' };
    }
  
    // Check if the control value does not match the pattern
    if (!pattern.test(control.value)) {
      return { 'patternMismatch': true };
    }
  
    return null;
  }
}
