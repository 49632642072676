<div class="container">
  <a routerLink="/dashboard">
    <img class="logo" src="/assets/images/logo.svg" alt="Qwikkit">
  </a>

  <mat-nav-list>
    <ng-template ngFor let-item [ngForOf]="navLinks">
      <mat-list-item>
        <a matLine routerLink="{{item.path}}" routerLinkActive="active">
          <span>{{item.title}}</span>
          <button mat-icon-button>
            <mat-icon>{{item.icon}}</mat-icon>
          </button>
        </a>
      </mat-list-item>
    </ng-template>
  </mat-nav-list>
  <br />
</div>