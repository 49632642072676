<div class="container">
  <a routerLink="/"><img src="/assets/images/logo.svg" alt="Cinch"></a>  
  <div class="row">
      <h1 class="mat-display-1">
          <mat-icon>error_outline</mat-icon>
          404 - Page not found!
        </h1>
        <p class="mat-h4"> This is not the web page you are looking for. </p>
  </div>  
</div>
