import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductionLocationsService } from 'src/app/services/production-locations.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProductionLocation } from 'src/app/models/production-location';

@Component({
  selector: 'app-delete-production-location',
  templateUrl: './delete-production-location.component.html',
  styleUrls: ['./delete-production-location.component.less']
})
export class DeleteProductionLocationComponent implements OnInit {

  productionLocation: ProductionLocation;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionLocation();
  }

  getProductionLocation() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLocationsService.getProductionLocation(id).subscribe((productionLocation: ProductionLocation) => {
      this.productionLocation = productionLocation;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production location details.');
      });
  }

  deleteProductionLocation() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLocationsService.deleteProductionLocation(id).subscribe(_ => {
      this.router.navigate([`/locations`]);
      this.snackBarService.showSuccess('The production location was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting production location.');
      });
  }

}
