import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OvernightService } from '../../../services/overnight.service';
import { Package, PackageGroup } from '../../../models/package';

@Component({
  selector: 'app-package-shipment-details',
  templateUrl: './package-shipment-details.component.html',
  styleUrls: ['./package-shipment-details.component.less']
})
export class PackageShipmentDetailsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'quantity', 'weight', 'dimensions', 'declaredValue'];
  serviceTypes: string[];
  packagesToDisplay: Package[];
  packages: Package[];
  packageGroups: PackageGroup[];
  selectedServiceType: string;
  selectedItems = {};
  shipDate: Date;

  constructor(private overnightService: OvernightService) { }

  async ngOnInit() {

    if (this.overnightService.packages) {
      this.shipDate = new Date();
      this.overnightService.shipDate = this.shipDate;

      this.packages = this.overnightService.packages;
      this.packageGroups = this.groupPackages(this.packages);
    }

    this.overnightService.$packagesDetails.subscribe((value: Package[]) => {
      this.shipDate = new Date();
      this.overnightService.shipDate = this.shipDate;

      this.packages = value;
      this.packageGroups = this.groupPackages(this.packages);
    });
  }

  groupPackages(packages: Package[]): PackageGroup[] {

    const groupedPackages: { [key: string]: Package[] } = {};

    for (const pkg of packages) {
      const dimensions = `${pkg.height}-${pkg.width}-${pkg.length}-${pkg.weight}`;

      if (dimensions in groupedPackages) {
        groupedPackages[dimensions].push(pkg);
      } else {
        groupedPackages[dimensions] = [pkg];
      }
    }

    let groupPackages: PackageGroup[] = [];

    for (const group of Object.values(groupedPackages)) {
      let groupPackage: PackageGroup = {
        packages: [],
        packageToDisplay: group[0],
        selected: false,
        id: null
      };

      groupPackage.packageToDisplay.quantity = group.length;

      let dimensions = Object.keys(groupedPackages).filter(x => groupedPackages[x] == group)[0];
      groupPackage.id = dimensions;

      for (let item of group) {
        item.dimensions = dimensions;
        groupPackage.packages.push(item);
      }

      groupPackages.push(groupPackage);
    }

    return groupPackages;
  }

  editQuantity(packageGroup: PackageGroup, quantity: number) {
    const count = packageGroup.packages.length;

    if (quantity > count) {
      packageGroup.packages.push(packageGroup.packageToDisplay);
    } else if (quantity < count) {
      packageGroup.packages.splice(packageGroup.packages.length - 1, 1);
    }

    this.overnightService.packages = this.packageGroups.flatMap(x => x.packages);
  }

  changeShipDate(event: MatDatepickerInputEvent<Date>) {
    this.overnightService.shipDate = event.value;
  }

  toggleSelection(e: MatCheckboxChange): void {
    if (e.checked) {
      for (const item of this.packageGroups) {
        this.selectedItems[item.id] = true;
      }
    } else {
      this.selectedItems = {};
    }
  }

  selectItem(e: MatCheckboxChange, dimensions: string): void {
    this.selectedItems[dimensions] = e.checked;
  }

  isSelected(dimensions: string): boolean {
    return this.selectedItems[dimensions] === true;
  }

  isAllSelected(): boolean {
    for (const item of this.packageGroups) {
      if (this.selectedItems[item.id] !== true) {
        return false;
      }
    }
    return true;
  }

  getSelectedIds(): string[] {
    const ids: string[] = [];

    for (const key in this.selectedItems) {
      if (this.selectedItems.hasOwnProperty(key) && this.selectedItems[key] === true) {
        ids.push(key);
      }
    }

    return ids;
  }

  delete() {
    for (let id of this.getSelectedIds()) {
      this.packageGroups = this.packageGroups.filter(x => x.id != id);
      this.packages = this.packages.filter(x => x.dimensions != id);
      this.overnightService.packages = this.packages;
    }

    this.selectedItems = {};
    this.overnightService.$ratesTransitTimes.next(true);
  }
}
