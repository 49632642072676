import { Component, OnInit, Inject } from '@angular/core';
import { ScheduleManagerComponent } from '../schedule-manager.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ScheduleService } from '../../../services/schedule.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { FormattedScheduledUnit } from '../../../models/formatted-scheduled-unit';

@Component({
  selector: 'app-reset-po-number',
  templateUrl: './reset-po-number.component.html',
  styleUrls: ['./reset-po-number.component.less']
})
export class ResetPoNumberComponent implements OnInit {

  processing = false;
  scheduledUnit: FormattedScheduledUnit;

  constructor(
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<ScheduleManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { scheduledUnit: FormattedScheduledUnit }) { }

  ngOnInit() {
    this.scheduledUnit = this.data.scheduledUnit;
  }

  resetPONumber() {
    this.processing = true;
    this.scheduleService.resetPONumber(this.scheduledUnit).subscribe((scheduledUnits: FormattedScheduledUnit[]) => {
      this.processing = false;
      this.dialogRef.close(scheduledUnits);
    },
      err => {
        console.error(err);
        this.processing = false;
        const errorMessage = this.getErrorMessages(err) || 'Error updating PO #. Please try again.';
        this.snackBarService.showError(errorMessage);
      });
  }

  getErrorMessages(e: { error: { messages: string[] } }): string {
    let errorMessage = '';
    if (e.error && e.error.messages && e.error.messages.length) {
      errorMessage = e.error.messages.join();
    }
    return errorMessage;
  }

}
