<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Delete Production Molding Setting
    </h1>
    <hr />
    <p>Are you sure you want to delete this production molding setting?</p>
    <div class="content">
        <ul>
            <li><strong>Id:</strong> {{productionMoldingSetting?.id}}</li>
            <li><strong>Color:</strong> {{productionMoldingSetting?.color}}</li>
            <li><strong>Door Style:</strong> {{productionMoldingSetting?.doorStyle}}</li>
            <li><strong>Molding Length:</strong> {{productionMoldingSetting?.moldingLength}}</li>
            <li><strong>Friendly Molding Length:</strong> {{productionMoldingSetting?.friendlyMoldingLength}}</li>
            <li><strong>Channel Groove:</strong> {{productionMoldingSetting?.channelGroove}}</li>
            <li><strong>Friendly Channel Groove:</strong> {{productionMoldingSetting?.friendlyChannelGroove}}</li>
            <li><strong>Is Default:</strong> {{productionMoldingSetting?.isDefault | active}}</li>
        </ul>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="deleteProductionMoldingSetting()">Delete</button>
    </div>
</mat-card>