<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Production Molding Setting Details
    </h1>
    <hr />

    <div class="actions">
        <a routerLink="/settings/production-molding/edit/{{productionMoldingSetting?.id}}" mat-raised-button>Edit
            Production Molding Setting
        </a>
    </div>

    <div class="content">
        <ul>
            <li><strong>Id:</strong> {{productionMoldingSetting?.id}}</li>
            <li><strong>Color:</strong> {{productionMoldingSetting?.color}}</li>
            <li><strong>Door Style:</strong> {{productionMoldingSetting?.doorStyle}}</li>
            <li><strong>Molding Length:</strong> {{productionMoldingSetting?.moldingLength}}</li>
            <li><strong>Friendly Molding Length:</strong> {{productionMoldingSetting?.friendlyMoldingLength}}</li>
            <li><strong>Channel Groove:</strong> {{productionMoldingSetting?.channelGroove}}</li>
            <li><strong>Friendly Channel Groove:</strong> {{productionMoldingSetting?.friendlyChannelGroove}}</li>
            <li><strong>Is Default:</strong> {{productionMoldingSetting?.isDefault | active}}</li>
        </ul>
    </div>
</mat-card>