<mat-card>
  <h1 class="mat-h1">
    Schedule
    <div>
      <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
        Auto Refresh
      </mat-slide-toggle>
      <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="openBottomSheetScheduleLegend()" matTooltip="Schedule Legend">
        <mat-icon>subject</mat-icon>
      </button>
      <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
        matTooltip="Fullscreen Mode">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
        matTooltip="Exit Fullscreen Mode">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
    </div>
  </h1>
  <hr />

  <div class="main-bottom-toolbar">
    <div *ngIf="allowedToggleView && suggestScheduleExecuted" class="section selection-summary mat-elevation-z2">
      <p><strong>Slab Parts: </strong> <span (click)="copyToClipboard(totalSlabPartsSelected)">{{totalSlabPartsSelected
          | number}}</span>
      </p>
      <p><strong>Shaker Parts: </strong> <span
          (click)="copyToClipboard(totalShakerPartsSelected)">{{totalShakerPartsSelected | number}}</span>
      </p>
    </div>
    <div *ngIf="allowedToggleView && suggestScheduleExecuted" class="section selection-summary mat-elevation-z2">
      <p><strong>Accessories: </strong> <span
          (click)="copyToClipboard(totalAccessoriesSelected)">{{totalAccessoriesSelected | number}}</span>
      </p>
      <p><strong>Custom Parts: </strong> <span
          (click)="copyToClipboard(totalCustomPartsSelected)">{{totalCustomPartsSelected | number}}</span>
      </p>
    </div>
    <div *ngIf="allowedToggleView && suggestScheduleExecuted" class="section selection-summary mat-elevation-z2">
      <p><strong>Unit Count: </strong>
        <span>
          {{getData().length | number}}
        </span>
      </p>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedule
        </mat-panel-title>
        <mat-panel-description>
          Select
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="actions">
        <mat-form-field>
          <mat-label>Production Location</mat-label>
          <mat-select (selectionChange)="onProductionLocationChange($event)" [value]="productionLocationId">
            <mat-option *ngFor="let item of productionLocations" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="customPartsCapacity!==-1">
          <input matInput readonly placeholder="Custom Parts Capacity" type="number" [value]="customPartsCapacity">
        </mat-form-field>
        <mat-form-field *ngIf="allowedCustomPartsCapacity!==-1">
          <input matInput readonly placeholder="Allowed Custom Parts Capacity" type="number"
            [value]="allowedCustomPartsCapacity">
        </mat-form-field>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="container">
    <mat-accordion>
      <mat-expansion-panel expanded="true" class="legend-panel" (opened)="onLegendPanelOpened()"
        (closed)="onLegendPanelClosed()">
        <mat-expansion-panel-header>
          <mat-panel-title> Legend </mat-panel-title>
          <mat-panel-description>
            Scheduled Units Legend
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="legend">
          <span class="red"> <i>H</i> Hot </span>
          <span class="light-green"> <i>O</i> Overnight</span>
          <span class="purple"> <i>M</i> Multi Color</span>
          <span class="grey"> <i>P</i> Parts</span>
          <span class="orange"> <i>A</i> Accessories</span>
          <span class="transparent"> <i>R</i> Regular</span>
          <span class="green"> <i>L</i> LockedIn</span>
          <span class="pink"> <i>S</i> Sample</span>
          <span class="light-blue"> <i>F</i> Fusion</span>
          <span class="turquoise"> <i>B</i> Bundle / Pallet </span>
          <span class="yellow-green"> <i>E</i> Expedited </span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}"
        (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filters
          </mat-panel-title>
          <mat-panel-description>
            Filter Scheduled Units
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="filters">
          <div class="filters-container">
            <div>
              <mat-form-field>
                <input matInput placeholder="Customer" [formControl]="customerFilter">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="SO #" [formControl]="salesOrderNumberFilter">
              </mat-form-field>
              <mat-form-field>

                <input matInput placeholder="Project" [formControl]="projectFilter">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Apt #" [formControl]="apartmentFilter">
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="Color" [formControl]="colorFilter"
                  (selectionChange)="onMultipleSelectionChange($event)" multiple>
                  <mat-option value="show-all">-- Show All --</mat-option>
                  <mat-option *ngFor="let color of colors" [value]="color.name">
                    {{color.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="Style" [formControl]="doorStyleFilter"
                  (selectionChange)="onMultipleSelectionChange($event)" multiple>
                  <mat-option value="show-all">-- Show All --</mat-option>
                  <mat-option *ngFor="let style of doorStyles" [value]="style.name">
                    {{style.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <app-multi-select-autocomplete class="mat-autocomplete" [placeholder]="'PO #'"
                [options]="productionOrderNumbers" ngDefaultControl [formControl]="productionOrderNumbersFilter"
                [display]="'value'" [value]="'value'" [multiple]='true' [labelCount]="10">
              </app-multi-select-autocomplete>
              <mat-form-field>
                <input matInput [matDatepicker]="picker1" placeholder="Order Date" [formControl]="orderDateFilter"
                  (dateChange)="onOrderDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker2" placeholder="Production Date"
                  [formControl]="productionDateFilter" (dateChange)="onProductionDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker3" [formControl]="requestedShipDateFilter"
                  placeholder="Requested Date" (dateChange)="onRequestedShipDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker4" [formControl]="mustShipDateFilter"
                  placeholder="No Delivery Before Date" (dateChange)="onMustShipDateFilterChange()">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Production Status</mat-label>
                <mat-select [formControl]="productionStatusFilter" (selectionChange)="onMultipleSelectionChange($event)"
                  multiple>
                  <mat-option value="show-all">-- Show All --</mat-option>
                  <mat-option *ngFor="let key of productionStatusKeys" [value]="key">
                    {{productionStatuses.get(key)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>

              <mat-form-field>
                <mat-select placeholder="Ship Method" [formControl]="shipMethodFilter"
                  (selectionChange)="onMultipleSelectionChange($event)" multiple>
                  <mat-option value="show-all">-- Show All --</mat-option>
                  <mat-option *ngFor="let method of shipMethods" [value]="method">
                    {{method}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="checkboxes">
              <mat-checkbox [(ngModel)]="hotFilter" matTooltip="Hot">Hot</mat-checkbox>
              <mat-checkbox [(ngModel)]="overnightFilter" matTooltip="Overnight">Overnight</mat-checkbox>
              <mat-checkbox [(ngModel)]="multicolorFilter" matTooltip="Multi Color">Multi Color</mat-checkbox>
              <mat-checkbox [(ngModel)]="partsFilter" matTooltip="Parts">Parts</mat-checkbox>
              <mat-checkbox [(ngModel)]="accessoriesFilter" (change)="toggleAccessoriesFilter($event)"
                matTooltip="Accessories">Accessories</mat-checkbox>
              <mat-checkbox [(ngModel)]="noAccessoriesFilter" (change)="toggleNoAccessoriesFilter($event)"
                matTooltip="No Accessories">No Accessories</mat-checkbox>
              <mat-checkbox [(ngModel)]="regularFilter" matTooltip="Regular">Regular</mat-checkbox>
              <mat-checkbox [(ngModel)]="lockedInFilter" matTooltip="LockedIn"
                (change)="onLockedInFilterChange($event)">LockedIn
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="noLockedInFilter" matTooltip="No LockedIn"
                (change)="onNoLockedInFilterChange($event)">No LockedIn
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="palletFilter" matTooltip="Pallet">Pallet</mat-checkbox>
              <mat-checkbox [(ngModel)]="fusionFilter" matTooltip="Fusion">Fusion</mat-checkbox>
              <mat-checkbox [(ngModel)]="sampleFilter" matTooltip="Sample">Sample</mat-checkbox>
              <mat-checkbox [(ngModel)]="retailFilter" matTooltip="Retail">Retail</mat-checkbox>
              <mat-checkbox [(ngModel)]="noCapacityFoundFilter" matTooltip="No Capacity Found">NCF</mat-checkbox>
              <mat-checkbox [(ngModel)]="hasPONumberFilter" matTooltip="PO #"
                (change)="onHasPONumberFilterChange($event)"> PO #
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="noHasPONumberFilter" matTooltip="No PO #"
                (change)="onNoHasPONumberFilterChange($event)"> No PO #
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="auditFilter" matTooltip="Audit">Audit</mat-checkbox>
              <mat-checkbox [(ngModel)]="whatsNotFilter" matTooltip="What's Not Production Status">
                What's Not
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="jitFilter" (change)="onJitFilterChange($event)" matTooltip="JIT">
                JIT
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="noJitFilter" (change)="onNoJitFilterChange($event)" matTooltip="No JIT">
                No JIT
              </mat-checkbox>
            </div>
          </div>

          <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="reset-action-container" *ngIf="filtersApplied">
      <button mat-raised-button (click)="clearFilters()">
        <mat-icon>clear</mat-icon> Reset Schedule Filters
      </button>
    </div>

    <div class="loading" *ngIf="loading">
      <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
      <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
      <mat-icon>arrow_upward</mat-icon>
    </button>

    <h3 *ngIf="!loading && scheduledUnitsViewModeActive() && getData().length === 0">
      No scheduled units to show.
    </h3>

    <table *ngIf="scheduledUnitsViewModeActive() && getData().length > 0" mat-table
      [dataSource]="getScheduledUnitsDataSource()" #scheduledUnitsSort="matSort" matSort class="mat-elevation-z0 table"
      (dblclick)="onScheduledUnitsTableDblClick($event)" (matSortChange)="scheduledUnitsSortChange($event)"
      (click)="onScheduledUnitsTableClick($event)">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
        attr.sched-unit-id="{{row.partialUnitId}}" [class.is-retail]="row.isRetail" [class.expedited]="row.isExpedited"
        [ngClass]="{'row-selected': scheduledUnitsSelection.isSelected(row.partialUnitId)}"
        [ngClass]="{'no-capacity-found': !row.productionDate}" (click)="onScheduledUnitsTableRowClick($event, i)"
        (mousedown)="onMousedown($event, i)" (mouseup)="onMouseup($event, i)" (mouseenter)="onMouseenter($event, i)"
        (mouseleave)="onMouseleave($event, i)">
      </tr>

      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
        <td mat-cell *matCellDef="let element"> {{element.project}}</td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
        <td mat-cell *matCellDef="let element">{{element.customer}}</td>
      </ng-container>

      <ng-container matColumnDef="schedulerNotes">
        <th mat-header-cell *matHeaderCellDef> Notes</th>
        <td mat-cell *matCellDef="let element">
          <p *ngIf="element.schedulerNotes" class="notes truncate">{{element.schedulerNotes}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="overnightServiceType">
        <th mat-header-cell *matHeaderCellDef> Overnight Service</th>
        <td mat-cell *matCellDef="let element">
          <span class="truncate">{{element.overnightServiceType}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="apartmentNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apt #</th>
        <td mat-cell *matCellDef="let element"> {{element.apartmentNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="salesOrderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SO #</th>
        <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="partialUnitId">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element">
          <span class="type hot" *ngIf="element.isHot">H</span>
          <span class="type overnight" *ngIf="element.isOvernight">O</span>
          <span class="type multicolor" *ngIf="element.isMultiColor">M</span>
          <span class="type parts" *ngIf="element.isPartsOrder">P</span>
          <span class="type accessories" *ngIf="element.isAccessories">A</span>
          <span class="type regular" *ngIf="element.isRegularOrder">R</span>
          <span class="type locked-in" *ngIf="element.isLockedIn">L</span>
          <span class="type sample" *ngIf="element.isTestUnit">S</span>
          <span class="type fusion" *ngIf="element.isFusion">F</span>
          <span class="type palletized" *ngIf="element.isPalletized">B</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </th>
        <td mat-cell *matCellDef="let element">{{element.orderDateStr}}</td>
      </ng-container>

      <ng-container matColumnDef="productionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
        <td mat-cell *matCellDef="let element" class="left-border {{element.classType}}">
          <span class="productionDate {{element.classType}}">{{element.productionDateStr}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="productionLocationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
        <td mat-cell *matCellDef="let element"> {{element.productionLocationStr}} </td>
      </ng-container>

      <ng-container matColumnDef="sequence">
        <th mat-header-cell *matHeaderCellDef> Seq </th>
        <td mat-cell *matCellDef="let element"> {{element.sequence}} </td>
      </ng-container>

      <ng-container matColumnDef="stackRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stack Rank </th>
        <td mat-cell *matCellDef="let element"> {{element.stackRank}} </td>
      </ng-container>

      <ng-container matColumnDef="requestedDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Requested Del Date </th>
        <td mat-cell *matCellDef="let element">{{element.requestedDeliveryDateStr}}</td>
      </ng-container>

      <ng-container matColumnDef="mustShipDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No Del Before</th>
        <td mat-cell *matCellDef="let element">{{element.mustShipDateStr}}</td>
      </ng-container>

      <ng-container matColumnDef="expedited">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expedited</th>
        <td mat-cell *matCellDef="let element">{{element.isExpedited | active}}</td>
      </ng-container>

      <ng-container matColumnDef="transitDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transit Days</th>
        <td mat-cell *matCellDef="let element">{{element.transitDays}}</td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Color</th>
        <td mat-cell *matCellDef="let element"> {{element.color}} </td>
      </ng-container>

      <ng-container matColumnDef="style">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Style</th>
        <td mat-cell *matCellDef="let element"> {{element.style}} </td>
      </ng-container>

      <ng-container matColumnDef="materialSupplier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Supplier </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="needToDisplayMaterialSupplier(element)" class="material-supplier"
            [ngClass]="element.materialSupplierColor">
            {{element.materialSupplierStr}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfParts">
        <th mat-header-cell *matHeaderCellDef> Parts</th>
        <td mat-cell *matCellDef="let element"> &nbsp; {{element.numberOfParts}} &nbsp; </td>
      </ng-container>

      <ng-container matColumnDef="audit">
        <th mat-header-cell *matHeaderCellDef> Audit</th>
        <td mat-cell *matCellDef="let element"> {{element.isAuditStr}} </td>
      </ng-container>

      <ng-container matColumnDef="friendlyStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Friendly Status</th>
        <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="productionOrderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
        <td mat-cell *matCellDef="let element">
          <span class="poNumber {{element.productionStatusColor}}">{{element.productionOrderNumber}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="moldingLength">
        <th mat-header-cell *matHeaderCellDef> Molding Length </th>
        <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyMoldingLength}} &nbsp;</td>
      </ng-container>

      <ng-container matColumnDef="channelGroove">
        <th mat-header-cell *matHeaderCellDef> Channel Groove </th>
        <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyChannelGroove}} &nbsp;</td>
      </ng-container>

      <ng-container matColumnDef="plannedShipDate">
        <th mat-header-cell *matHeaderCellDef> Expected Delivery Date </th>
        <td mat-cell *matCellDef="let element">{{element.plannedShipDateStr}}</td>
      </ng-container>

      <ng-container matColumnDef="shipMethod">
        <th mat-header-cell *matHeaderCellDef> Ship Method</th>
        <td mat-cell *matCellDef="let element"> {{element.shipMethod}} </td>
      </ng-container>
    </table>
  </div>
</mat-card>