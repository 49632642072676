import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-email-update-modal',
  templateUrl: './email-update-modal.component.html',
  styleUrls: ['./email-update-modal.component.less']
})
export class EmailUpdateModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    invoicingEmail: string
  }, public dialogRef: MatDialogRef<EmailUpdateModalComponent>) {

  }

  updateEmail() {
    this.dialogRef.close(this.data.invoicingEmail);
  }
}
