import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Account } from '../../../../models/account';
import { CartonTypes } from '../../../../models/carton-type';
import { StandardItemShipInSetup } from '../../../../models/standard-item-ship-in-setup';
import { AuthService } from '../../../../services/auth.service';
import { ShippingItemSetupService } from '../../../../services/shipping-item-setup.service';
import { SnackbarService } from '../../../../services/snackbar.service';

enum ShippingStatus {
  MustShipIn = 1,
  CannotShipIn = 2
}

@Component({
  selector: 'app-create-ship-in-setup',
  templateUrl: './create-ship-in-setup.component.html',
  styleUrls: ['./create-ship-in-setup.component.less']
})
export class CreateShipInSetupComponent {

  shippingStatuses = [
    { label: 'Must Ship In', value: ShippingStatus.MustShipIn },
    { label: 'Cannot Ship In', value: ShippingStatus.CannotShipIn }
  ];

  createStandardItemShipInForm: FormGroup;
  errorMessages: string[] = [];
  cartonTypes: CartonTypes[] = [];
  user: Account;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBarService: SnackbarService,
    private shippingSetupService: ShippingItemSetupService,
    private authService: AuthService,
  ) {
    this.createForm();
    this.getCartonTypes();

    this.user = this.authService.getAccount();
  }

  createForm() {
    this.createStandardItemShipInForm = this.fb.group({
      itemNumber: ["", this.itemNumberValidator],
      cartonTypeId: [null, [Validators.required, Validators.min(0)]],
      categoryTypeId: [null, [Validators.required, Validators.min(0)]]
    });
  }

  getCartonTypes(): void {
    this.shippingSetupService.getCartonTypes().subscribe({
      next: (cartonTypes) => {
        this.cartonTypes = cartonTypes;
      },
      error: (error) => {
        console.error('Error getting carton types:', error);
        this.snackBarService.showError('Error getting carton types.');
      }
    });
  }

  async submit(): Promise<void> {
    if (this.createStandardItemShipInForm.valid) {
      const setupData = this.getStandardItemShipInSetup();
      try {
        const isValidItemNumber = await this.shippingSetupService.getValidateStandardItem(setupData.itemNumber).toPromise();
        if (isValidItemNumber) {
          try {
            await this.shippingSetupService.createItemSetup(setupData).toPromise();
            this.snackBarService.showSuccess('Shipping item setup was created successfully.');
            this.router.navigate(["/settings"]);
          } catch (error) {
            this.snackBarService.showError('Error creating shipping item setup.');
          }
        } else {
          this.snackBarService.showError('Error validating item number.');
        }
      } catch (error) {
        this.snackBarService.showError('Error validating item number.');
      }
    }
  }

  getStandardItemShipInSetup(): StandardItemShipInSetup {
    const formModel = this.createStandardItemShipInForm.value;

    const standardItemShipInSetup: StandardItemShipInSetup = {
      itemNumber: formModel.itemNumber,
      cartonTypeId: formModel.cartonTypeId,
      cartonTypeDescription: "",
      categoryTypeId: formModel.categoryTypeId,
      categoryTypeDescription: "",
      createdBy: this.user.userName || 'DefaultUser',
      dateCreated: new Date()
    };

    return standardItemShipInSetup;
  }


  itemNumberValidator(control: AbstractControl): ValidationErrors | null {
    // Allow letters, numbers, hyphens, and underscores, but no dangerous special characters or only dots
    const pattern = /^[A-Za-z0-9-_]+$/;

    // Check if the control value is empty
    if (!control.value) {
      return { 'required': true };
    }

    // Check for string containing only dots
    if (control.value.trim() === '.' || control.value.trim().match(/^\.*$/)) {
      return { 'invalidFormat': 'Only dots are not allowed' };
    }

    // Check if the control value contains dangerous special characters
    if (/[\>\<\&\%\;\`\/]+/.test(control.value)) {
      return { 'dangerousCharacter': 'Contains dangerous characters' };
    }

    // Check if the control value does not match the pattern
    if (!pattern.test(control.value)) {
      return { 'patternMismatch': true };
    }

    return null;
  }
}
