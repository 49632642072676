import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { User } from '../../../models/User';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-cl-delete-user-modal',
  templateUrl: './cl-delete-user-modal.component.html',
  styleUrls: ['./cl-delete-user-modal.component.less']
})
export class ClDeleteUserModalComponent {

  public _customerInfoService: CustomerService;
  public userResponse: any;
  public user: User = {
    userEmail: '',
    customerNumber: '',
    password: ''
  };

  deleteUserForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ClDeleteUserModalComponent>,
    private formBuilder: FormBuilder,
    private customerInfoService: CustomerService,
    private snackBarService: SnackbarService){
      this.createForm();
      this._customerInfoService = customerInfoService;
  }
  
  closeWithOutSaving() {
    this.dialogRef.close();
  }

  async submit(){
    try {
      this.userResponse = await this._customerInfoService.deleteUser(this.user).toPromise();
      if(this.userResponse.isValid){
        this.snackBarService.showSuccess(`User ${this.user.userEmail} disabled.`);
        this.dialogRef.close();
      }else{
        this.snackBarService.showError(this.userResponse.message);
      }
    } catch (error) {
      this.snackBarService.showError(error);
    }

  }

  createForm(){
    this.deleteUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
