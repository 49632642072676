<div class="header-actions">
    <a routerLink="create" mat-flat-button color="accent">Create new notification</a>
</div>
<h1 class="mat-h1">
    Customer Portal Notification
</h1>
<hr />
<mat-accordion>
    <mat-expansion-panel expanded="true" class="">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filters
            </mat-panel-title>
            <mat-panel-description>
                Filter Order Notification
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div [formGroup]="formFiltersNotification">
                    <mat-form-field>
                        <input matInput placeholder="Customer #" formControlName="customerNumber">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer" formControlName="customerName">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Created By" formControlName="createdBy">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerWhen" placeholder="Created date" formControlName="createdDate" (dateChange)="onDateControlChange()">
                        <mat-datepicker-toggle matSuffix [for]="pickerWhen"></mat-datepicker-toggle>
                        <mat-datepicker #pickerWhen></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()"><mat-icon>filter_list</mat-icon></button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="reset-action-container" *ngIf="notificationFilterApply">
    <button mat-raised-button (click)="clearFilters()">
  <mat-icon>clear</mat-icon> Reset Notification Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblNotifications *ngIf="!loading && getData().length > 0" [dataSource]="getNotificationDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef> # Customer </th>
            <td mat-cell *matCellDef="let row"> {{row?.owner}} </td>
        </ng-container>
        <ng-container matColumnDef="ownerName">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let row"> {{row?.ownerName}} </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let row"> {{row?.createdBy}} </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let row"> {{row?.createdDate | date }} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button matTooltip="Reading detail" matTooltipPosition="above" (click)="openedReadingDetail(row)"><mat-icon color="accent">rule</mat-icon></button>
                <button mat-icon-button matTooltip="Delete notification" matTooltipPosition="above" (click)="disableOrderNotification(row)"><mat-icon color="accent">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

</div>