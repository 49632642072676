<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Create Account
  </h1>
  <hr />
  
  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="createAccountForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="UserName" formControlName="userName" required>
        <mat-error *ngIf="createAccountForm.controls.userName.invalid">{{getUserNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Email" type="email" formControlName="email" >
        <mat-error *ngIf="createAccountForm.controls.email.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
          <input matInput placeholder="Name" type="name" formControlName="name" >
      </mat-form-field>
      <mat-form-field class="full-width">
          <input matInput placeholder="Location" type="location" formControlName="location">
      </mat-form-field>
      <button mat-raised-button color="accent">Create</button>
    </form>
  </div>
</mat-card>

