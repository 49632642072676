import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// Material
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
// Models
import { ModalSettingConfig } from 'src/app/models/commission-general';
import { CommissionType } from '../../../models/commission-type';
import { CommissionSalesPerson } from '../../../models/commission-sales-person';
import { CommissionSalesPersonType } from '../../../models/commission-sales-person-type';
import { CommissionTypeAssignments } from '../../../models/commission-type-assignments';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { CommissionService } from '../../../services/commission.service';
// Time
import * as moment from 'moment';
import { CommissionRegion } from 'src/app/models/commission-region';


@Component({
  selector: 'app-settings-commission-modal',
  templateUrl: './settings-commission-modal.component.html',
  styleUrls: ['./settings-commission-modal.component.less'],
})
export class SettingsCommissionModalComponent implements OnInit {
  loading = false;
  settingModal: ModalSettingConfig;
  salesPersonTypes: CommissionSalesPersonType[] = [];
  commissionTypes: CommissionType[] = [];
  commissionRegions: CommissionRegion[] = [];

  commissionTypeForm: UntypedFormGroup;
  commissionSalesForm: UntypedFormGroup;
  commissionSalesPersonTypeForm: UntypedFormGroup;
  commissionTypeAssignmentsForm: UntypedFormGroup;

  commissionAmountType: string[] = ['NewAccountSales', 'ExpansionSales', 'ContinuingSales', 'OtherSales'];

  phoneMask: any = {
    mask: '000-000-0000',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalSettingConfig,
    public dialogRef: MatDialogRef<SettingsCommissionModalComponent>,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private commissionService: CommissionService
  ) {
    this.settingModal = data;
    if (this.settingModal.settingAction === 1 || this.settingModal.settingAction === 2) {
      this.createForm();
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getSalesPersonTypes();
    await this.getCommissionTypes();
    await this.getRegions();
    if (this.settingModal.value !== null) {
      this.setValues();
    }
  }

  getSalesPersonTypes(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.commissionService.getAllSalesPersonsType().subscribe(
        (result: CommissionSalesPersonType[]) => this.salesPersonTypes = result,
        (err) => {
          console.error(err);
          reject();
        }, () => resolve());
    });
  }

  getCommissionTypes(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.commissionService.getAllCommissionType().subscribe(
        (result: CommissionType[]) => this.commissionTypes = result,
        (err) => {
          console.error(err);
          reject();
        },
        () => resolve());
    });
  }

  async getRegions(): Promise<void> {
    await this.commissionService.getCommissionRegions()
      .toPromise()
      .then(result => this.commissionRegions = result)
      .catch(err => console.error(err));
  }

  createForm(): void {
    switch (this.settingModal.settingType) {
      case 1:
        this.commissionTypeForm = this.fb.group({
          name: ['', Validators.required],
          description: ['', Validators.required],
          commissionAmount: ['', Validators.required],
          commissionAmountType: ['', Validators.required]
        });
        break;
      case 2:
        this.commissionSalesForm = this.fb.group({
          salesPersonCode: new UntypedFormControl({ value: '', disabled: true }, Validators.required) ,
          salesPersonName: ['', Validators.required],
          emailAddress: ['', [Validators.required, Validators.email]],
          cellPhone: ['', Validators.required],
          personType: [0],
          region: [0]
        });
        break;
      case 3:
        this.commissionSalesPersonTypeForm = this.fb.group({
          typeName: ['', Validators.required],
          description: ['']
        });
        break;
      case 4:
        this.commissionTypeAssignmentsForm = this.fb.group({
          salesPersonTypeId: [0, [Validators.required, Validators.min(1)]],
          commissionTypeId: [0, [Validators.required, Validators.min(1)]]
        });
        break;
    }
  }

  setValues(): void {
    switch (this.settingModal.settingType) {
      case 1:
        const ctValue: CommissionType = this.settingModal.value;
        this.commissionTypeForm.setValue({
          name: ctValue.name,
          description: ctValue.description,
          commissionAmount: ctValue.commissionAmount,
          commissionAmountType: ctValue.commissionAmountType
        });
        break;
      case 2:
        const rsValue: CommissionSalesPerson = this.settingModal.value;
        if (this.commissionSalesForm) {
          this.commissionSalesForm.setValue({
            salesPersonCode: rsValue.salesPersonCode,
            salesPersonName: rsValue.salesPersonName,
            emailAddress: rsValue.emailAddress,
            cellPhone: rsValue.cellPhone,
            personType: rsValue.salesPersonTypeId === null ? 0 : rsValue.salesPersonTypeId,
            region: rsValue.commissionRegionId && rsValue.commissionRegionId !== 0 ? rsValue.commissionRegionId : 0
          });
        }
        break;
      case 3:
        const sptValue: CommissionSalesPersonType = this.settingModal.value;
        this.commissionSalesPersonTypeForm.setValue({
          typeName: sptValue.name,
          description: sptValue.description
        });
        break;
      case 4:
        const ctaValue: CommissionTypeAssignments = this.settingModal.value;
        this.commissionTypeAssignmentsForm.setValue({
          salesPersonTypeId: ctaValue.salesPersonTypeId,
          commissionTypeId: ctaValue.commissionTypeId
        });
        break;
    }
  }

  getValues(): CommissionType | CommissionSalesPerson | CommissionSalesPersonType | CommissionTypeAssignments  {
    let value: any;
    switch (this.settingModal.settingType) {
      case 1:
        let commissionType: CommissionType;
        if (this.settingModal.value !== null) {
          const currentValue = this.settingModal.value as CommissionType;
          commissionType = {
            id: currentValue.id,
            name: this.commissionTypeForm.get('name').value,
            description: this.commissionTypeForm.get('description').value,
            commissionAmount:
              this.commissionTypeForm.get('commissionAmount').value,
            commissionAmountType: this.commissionTypeForm.get(
              'commissionAmountType'
            ).value,
            dateCreated: currentValue.dateCreated,
            dateModified: new Date(moment().format()),
            modifiedBy: this.authService.getAccount().userName,
            isSpecialProgramType: false,
          };
        } else {
          commissionType = {
            id: 0,
            name: this.commissionTypeForm.get('name').value,
            description: this.commissionTypeForm.get('description').value,
            commissionAmount:
              this.commissionTypeForm.get('commissionAmount').value,
            commissionAmountType: this.commissionTypeForm.get(
              'commissionAmountType'
            ).value,
            dateCreated: new Date(moment().format()),
            dateModified: new Date(moment().format()),
            modifiedBy: this.authService.getAccount().userName,
            isSpecialProgramType: false
          };
        }
        value = commissionType;
        break;
      case 2:
        let commissionSalesPerson: CommissionSalesPerson;
        const salePersonType = this.salesPersonTypes.find(f => f.id === this.commissionSalesForm.get('personType').value);
        const commissionRegion = this.commissionSalesForm.get('region').value;
        if (this.settingModal.value !== null) {
          const currentValue = this.settingModal.value as CommissionSalesPerson;
          commissionSalesPerson = {
            salesPersonId: currentValue.salesPersonId,
            salesPersonCode:
              this.commissionSalesForm.get('salesPersonCode').value,
            salesPersonName:
              this.commissionSalesForm.get('salesPersonName').value,
            emailAddress: this.commissionSalesForm.get('emailAddress').value,
            cellPhone: this.commissionSalesForm.get('cellPhone').value,
            createdDate: currentValue.createdDate,
            modifiedDate: new Date(moment().format()),
            isActive: currentValue.isActive,
            salesPersonTypeId: salePersonType !== undefined ? salePersonType.id === 0 ? null : salePersonType.id : null,
            salesPersonType: salePersonType !== undefined ? salePersonType.name : '',
            commissionRegionId: commissionRegion,
            commissionRegionName: commissionRegion > 0 ?
              this.commissionRegions.find(f => f.regionId === commissionRegion).region : ''
          };
        } else {
          commissionSalesPerson = {
            salesPersonId: 0,
            salesPersonCode:
              this.commissionSalesForm.get('salesPersonCode').value,
            salesPersonName:
              this.commissionSalesForm.get('salesPersonName').value,
            emailAddress: this.commissionSalesForm.get('emailAddress').value,
            cellPhone: this.commissionSalesForm.get('cellPhone').value,
            createdDate: new Date(moment().format()),
            modifiedDate: new Date(moment().format()),
            isActive: true,
            salesPersonTypeId: salePersonType !== undefined ? salePersonType.id === 0 ? null : salePersonType.id : null,
            salesPersonType: salePersonType !== undefined ? salePersonType.name : '',
            commissionRegionId: commissionRegion,
            commissionRegionName: commissionRegion > 0 ?
              this.commissionRegions.find(f => f.regionId === commissionRegion).region : ''
          };
        }
        value = commissionSalesPerson;
        break;
      case 3:
        let commissionSalesPersonType: CommissionSalesPersonType;
        if (this.settingModal.value !== null) {
          const currentValue = this.settingModal.value as CommissionSalesPersonType;
          commissionSalesPersonType = {
            id: currentValue.id,
            name: this.commissionSalesPersonTypeForm.get('typeName').value,
            description: this.commissionSalesPersonTypeForm.get('description').value,
          };
        } else {
          commissionSalesPersonType = {
            id: 0,
            name: this.commissionSalesPersonTypeForm.get('typeName').value,
            description: this.commissionSalesPersonTypeForm.get('description').value,
          };
        }
        value = commissionSalesPersonType;
        break;
      case 4:
         let commissionTypeAssignments: CommissionTypeAssignments;
         const valueCommissionTypeId = this.commissionTypes.find(f => f.id === this.commissionTypeAssignmentsForm.get('commissionTypeId').value);
         const valueSalesPersonTypeId = this.salesPersonTypes.find(f => f.id === this.commissionTypeAssignmentsForm.get('salesPersonTypeId').value);
         if (this.settingModal.value !== null) {
          const currentValue = this.settingModal.value as CommissionTypeAssignments;
          commissionTypeAssignments = {
            commissionTypeAssignmentsId: currentValue.commissionTypeAssignmentsId,
            commissionTypeId: valueCommissionTypeId.id,
            salesPersonTypeId: valueSalesPersonTypeId.id,
            salesPersonName: valueSalesPersonTypeId.name,
            commissionAmount: valueCommissionTypeId.commissionAmount,
            commissionAmountType: valueCommissionTypeId.commissionAmountType,
            commissionDescription: valueCommissionTypeId.description,
            dateCreated: currentValue.dateCreated,
            createdBy: currentValue.createdBy
          };
         } else {
          commissionTypeAssignments = {
            commissionTypeAssignmentsId: 0,
            commissionTypeId: valueCommissionTypeId.id,
            salesPersonTypeId: valueSalesPersonTypeId.id,
            salesPersonName: valueSalesPersonTypeId.name,
            commissionAmount: valueCommissionTypeId.commissionAmount,
            commissionAmountType: valueCommissionTypeId.commissionAmountType,
            commissionDescription: valueCommissionTypeId.description,
            dateCreated: new Date(moment().format()),
            createdBy: this.authService.getAccount().userName
          };
         }
         value = commissionTypeAssignments;
         break;
    }
    return value;
  }

  isValid(): boolean {
    let allValid: boolean;
    switch (this.settingModal.settingType) {
      case 1:
        this.commissionTypeForm.markAllAsTouched();
        this.commissionTypeForm.updateValueAndValidity();
        allValid = this.commissionTypeForm.valid;
        break;
      case 2:
        this.commissionSalesForm.markAllAsTouched();
        this.commissionSalesForm.updateValueAndValidity();
        allValid = this.commissionSalesForm.valid;
        break;
      case 3:
        this.commissionSalesPersonTypeForm.markAllAsTouched();
        this.commissionSalesPersonTypeForm.updateValueAndValidity();
        allValid = this.commissionSalesPersonTypeForm.valid;
        break;
      case 4:
        this.commissionTypeAssignmentsForm.markAllAsTouched();
        this.commissionTypeAssignmentsForm.updateValueAndValidity();
        allValid = this.commissionTypeAssignmentsForm.valid;
        break;
    }
    return allValid;
  }

  closeWithOutSaving() {
    this.data.result = -1;
    this.dialogRef.close(this.data);
  }

  getModalTittle(): string {
    let title = '';
    switch (this.settingModal.settingAction) {
      case 1:
        title = 'Create new';
        break;
      case 2:
        title = 'Edit a ';
        break;
      case 3:
        title = 'Delete';
        break;
      case 4:
        title = 'Disable';
        break;
    }

    switch (this.settingModal.settingType) {
      case 1:
        title += ' Commission Type';
        break;
      case 2:
        title += ' Sales Rep';
        break;
      case 3:
        title += ' Sales Person Type';
        break;
      case 4:
        title += ' Assignments Commission Types';
        break;
    }

    return title;
  }

  getBtnTittle(): string {
    return this.settingModal.settingAction === 1
      ? 'Create'
      : this.settingModal.settingAction === 2
      ? 'Update'
      : this.settingModal.settingAction === 3
      ? 'Delete' : 'Disable';
  }

  async createOrUpdateCommissionType(): Promise<CommissionType> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let commissionTypeResult = this.getValues() as CommissionType;
      if (this.settingModal.value === null) {
        this.commissionService.createCommissionType(commissionTypeResult).subscribe(
          (result: CommissionType) => commissionTypeResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionTypeResult;
            resolve(commissionTypeResult);
          });
      } else {
        this.commissionService.updateCommissionType(commissionTypeResult).subscribe(
          (result: CommissionType) => commissionTypeResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionTypeResult;
            resolve(commissionTypeResult);
          });
      }
    });
  }

  async createOrUpdateSalesPerson(): Promise<CommissionSalesPerson> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let commissionSalesPersonResult = this.getValues() as CommissionSalesPerson;
      if (this.settingModal.value === null) {
        this.commissionService.createSalesPerson(commissionSalesPersonResult).subscribe(
          (result: CommissionSalesPerson) => commissionSalesPersonResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionSalesPersonResult;
            resolve(commissionSalesPersonResult);
          });
      } else {
        this.commissionService.updateSalesPerson(commissionSalesPersonResult).subscribe(
          (result: CommissionSalesPerson) => commissionSalesPersonResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionSalesPersonResult;
            resolve(commissionSalesPersonResult);
          });
      }
    });
  }

  async disableSalesPerson(): Promise<void> {
    this.loading = true;
    return new Promise<void>((resolve, reject) => {
      const currentPerson = this.settingModal.value as CommissionSalesPerson;
      this.commissionService.disableSalesPerson(currentPerson.salesPersonId).subscribe(
        result => result,
        (err) => {
          this.settingModal.result = 2;
          console.error(err);
          reject();
        }, () => {
          this.settingModal.result = 1;
          resolve();
        });
    });
  }

  async createOrUpdateSalesPersonType(): Promise<CommissionSalesPersonType> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let commissionSalesPersonTypeResult = this.getValues() as CommissionSalesPersonType;
      if (this.settingModal.value === null) {
        this.commissionService.createSalePersonType(commissionSalesPersonTypeResult).subscribe(
          (result: CommissionSalesPersonType) => commissionSalesPersonTypeResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionSalesPersonTypeResult;
            resolve(commissionSalesPersonTypeResult);
          });
      } else {
        this.commissionService.updateSalePersonType(commissionSalesPersonTypeResult).subscribe(
          (result: CommissionSalesPersonType) => commissionSalesPersonTypeResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionSalesPersonTypeResult;
            resolve(commissionSalesPersonTypeResult);
          });
      }
    });
  }

  async createOrUpdateCommissionTypeAssignments(): Promise<CommissionTypeAssignments> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let commissionTypeAssignmentsResult = this.getValues() as CommissionTypeAssignments;
      if (this.settingModal.value === null) {
        this.commissionService.createCommissionTypeAssignments(commissionTypeAssignmentsResult).subscribe(
          (result: CommissionTypeAssignments) => commissionTypeAssignmentsResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionTypeAssignmentsResult;
            resolve(commissionTypeAssignmentsResult);
          });
      } else {
        this.commissionService.updateCommissionTypeAssignments(commissionTypeAssignmentsResult).subscribe(
          (result: CommissionTypeAssignments) => commissionTypeAssignmentsResult = result,
          (err) => {
            this.settingModal.result = 2;
            console.error(err);
            reject();
          },
          () => {
            this.settingModal.result = 1;
            this.settingModal.value = commissionTypeAssignmentsResult;
            resolve(commissionTypeAssignmentsResult);
          });
      }

    });
  }


  async submit() {
    if (this.isValid()) {
      switch (this.settingModal.settingType) {
        case 1:
          await this.createOrUpdateCommissionType();
          break;
        case 2:
          await this.createOrUpdateSalesPerson();
          break;
        case 3:
          await this.createOrUpdateSalesPersonType();
          break;
        case 4:
          await this.createOrUpdateCommissionTypeAssignments();
          break;
      }

      this.loading = false;
      this.dialogRef.close(this.settingModal);
    }
  }

  async confirm() {
    const action = this.settingModal.settingAction;
    switch (this.settingModal.settingType) {
      case 2:
        if (action === 4) {
          await this.disableSalesPerson();
        }
        break;
      default:
        break;
    }

    this.loading = false;
    this.dialogRef.close(this.settingModal);
  }
}
