import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CustomerAudit } from '../../models/customer-audit';
import { AuditService } from '../../services/audit.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuditAssignmentsDetailsComponent } from './audit-assignments-details/audit-assignments-details.component';
import { EditAuditAssignmentsComponent } from './edit-audit-assignments/edit-audit-assignments.component';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.less']
})
export class AuditComponent implements OnInit, AfterViewInit {

  loading = false;

  customerFilter = new FormControl();
  customerNumberFilter = new FormControl();
  companyFilter = new FormControl();
  rebateGroupsFiltersApplied = false;
  filtersApplied = false;

  customersDataSource = new MatTableDataSource<CustomerAudit>();
  filteredCustomersDataSource = new MatTableDataSource<CustomerAudit>();

  customersDisplayedColumns: string[] = ['id', 'name', 'customerNumber', 'company', 'communities', 'audit', 'actions'];

  customersSort: MatSort;
  customers: CustomerAudit[] = [];

  @ViewChild('customersSort', { read: MatSort }) set matSortBySONumber(ms: MatSort) {
    this.customersSort = ms;
    this.ngAfterViewInit();
  }

  constructor(private dialog: MatDialog,
    private snackBarService: SnackbarService,
    private auditService: AuditService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {
    // sort options
    this.customersDataSource.sort = this.customersSort;
    this.filteredCustomersDataSource.sort = this.customersSort;
  }

  getCustomers(): void {
    this.showLoading();
    this.auditService.getCustomers().subscribe((customers: CustomerAudit[]) => {
      this.customersDataSource.data = customers;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting customers.');
        this.hideLoading();
      }
      , () => {
        this.hideLoading();
      });
  }

  getCustomersData(): CustomerAudit[] {
    return this.getCustomersDataSource().connect().value;
  }

  getCustomersDataSource(): MatTableDataSource<CustomerAudit> {
    return this.filtersApplied ? this.filteredCustomersDataSource : this.customersDataSource;
  }

  applyFilters(): void {

    this.filteredCustomersDataSource.data = this.customersDataSource.data;

    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCustomer();
      this.filtersApplied = true;
    }
    if (this.customerNumberFilter.valid && this.customerNumberFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCustomerNumber();
      this.filtersApplied = true;
    }
    if (this.companyFilter.valid && this.companyFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCompany();
      this.filtersApplied = true;
    }
  }

  filterByCustomer(): CustomerAudit[] {
    return this.filteredCustomersDataSource.data.filter(r => r.name.toLowerCase().includes(this.customerFilter.value.trim().toLowerCase()));
  }

  filterByCustomerNumber(): CustomerAudit[] {
    return this.filteredCustomersDataSource.data.filter(r => r.customerNumber.toLowerCase().includes(this.customerNumberFilter.value.trim().toLowerCase()));
  }

  filterByCompany(): CustomerAudit[] {
    return this.filteredCustomersDataSource.data.filter(r => r.company?.toLowerCase().includes(this.companyFilter.value.trim().toLowerCase()));
  }

  findCustomer(customerId: number): CustomerAudit {
    return this.customersDataSource.data.find(c => c.id === customerId);
  }

  openAuditAssignments(customerId: number): void {
    const customer = this.findCustomer(customerId);
    if (customer) {
      this.dialog.open(AuditAssignmentsDetailsComponent, { width: '1024px', data: { customer } });
    }
  }

  openEditAuditAssignments(customerId: number): void {
    const customer = this.findCustomer(customerId);
    if (customer) {
      const dialogRef = this.dialog.open(EditAuditAssignmentsComponent, { width: '1024px', data: { customer } });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.getCustomers();
        }
      });
    }
  }

  clearFilters(): void {
    this.customerFilter.setValue('');
    this.customerNumberFilter.setValue('');
    this.companyFilter.setValue('');
    this.filtersApplied = false;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

}
