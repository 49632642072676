import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { GlobalSetting } from '../models/global-setting';
import { ProductionMoldingSetting } from '../models/production-molding-setting';
import { ExpeditedProductionSetting } from '../models/expedited-production-setting';
import { StandardItemShipInSetup } from '../models/standard-item-ship-in-setup';
import { StrategicExpedited } from '../models/strategic-expedited';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getSettings(): Observable<GlobalSetting[]> {
    const url = this.config.buildURL(`settings`);
    return this.http.get<GlobalSetting[]>(url);
  }

  getGroupNames(): Observable<string[]> {
    const url = this.config.buildURL(`settings/groupNames`);
    return this.http.get<string[]>(url);
  }

  getSetting(id: string): Observable<GlobalSetting> {
    const url = this.config.buildURL(`settings/${id}`);
    return this.http.get<GlobalSetting>(url);
  }

  getSettingsByGroupName(groupName: string): Observable<GlobalSetting[]> {
    const url = this.config.buildURL(`settings/byGroupName`);
    const params = new HttpParams().set('groupName', groupName);
    return this.http.get<GlobalSetting[]>(url, { params });
  }

  updateSetting(model: GlobalSetting): Observable<GlobalSetting> {
    const url = this.config.buildURL(`settings/${model.id}`);
    return this.http.put<GlobalSetting>(url, model);
  }

  deleteSetting(id: string) {
    const url = this.config.buildURL(`settings/${id}`);
    return this.http.delete(url);
  }

  getProductionMoldingSettings(): Observable<ProductionMoldingSetting[]> {
    const url = this.config.buildURL(`settings/productionMoldingSettings`);
    return this.http.get<ProductionMoldingSetting[]>(url);
  }

  getProductionMoldingSetting(id: string): Observable<ProductionMoldingSetting> {
    const url = this.config.buildURL(`settings/productionMoldingSetting/${id}`);
    return this.http.get<ProductionMoldingSetting>(url);
  }

  createProductionMoldingSetting(model: ProductionMoldingSetting): Observable<ProductionMoldingSetting> {
    const url = this.config.buildURL(`settings/productionMoldingSetting`);
    return this.http.post<ProductionMoldingSetting>(url, model);
  }

  updateProductionMoldingSetting(model: ProductionMoldingSetting): Observable<ProductionMoldingSetting> {
    const url = this.config.buildURL(`settings/productionMoldingSetting/${model.id}`);
    return this.http.put<ProductionMoldingSetting>(url, model);
  }

  deleteProductionMoldingSetting(id: string) {
    const url = this.config.buildURL(`production/productionMoldingSetting/${id}`);
    return this.http.delete(url);
  }

  createExpeditedProductionSetting(model: ExpeditedProductionSetting): Observable<ExpeditedProductionSetting> {
    const url = this.config.buildURL(`production/expeditedproductionsettings`);
    return this.http.post<ExpeditedProductionSetting>(url, model);
  }

  getExpeditedProductionSetting(): Observable<ExpeditedProductionSetting[]> {
    const url = this.config.buildURL(`production/expeditedproductionsettings`);
    return this.http.get<ExpeditedProductionSetting[]>(url);
  }

  getExpeditedProductionSettingById(id: string) {
    const url = this.config.buildURL(`production/expeditedproductionsettings/${id}`);
    return this.http.get<ExpeditedProductionSetting>(url);
  }

  deleteExpeditedProductionSetting(id: number) {
    const url = this.config.buildURL(`production/expeditedproductionsettings/${id}`);
    return this.http.delete(url);
  }
  
  updateExpeditedProductionSetting(model: ExpeditedProductionSetting): Observable<ExpeditedProductionSetting> {
    const url = this.config.buildURL(`production/expeditedproductionsettings/${model.id}`);
    return this.http.put<ExpeditedProductionSetting>(url, model);
  }

  getStrategicExpedited(): Observable<StrategicExpedited[]> {
    const url = this.config.buildURL(`production/StrategicExpedited`);
    return this.http.get<StrategicExpedited[]>(url);
  }

  getStrategicExpeditedById(id: number): Observable<StrategicExpedited> {
    const url = this.config.buildURL(`production/StrategicExpedited/${id}`);
    return this.http.get<StrategicExpedited>(url);
  }

  createStrategicExpedited(model: StrategicExpedited): Observable<StrategicExpedited> {
    const url = this.config.buildURL(`production/StrategicExpedited`);
    return this.http.post<StrategicExpedited>(url, model);
  }

  deleteStrategicExpedited(id: number): Observable<void> {
    const url = this.config.buildURL(`production/StrategicExpedited/${id}`);
    return this.http.delete<void>(url);
  }

  updateStrategicExpedited(id: number, model: StrategicExpedited): Observable<StrategicExpedited> {
    const url = this.config.buildURL(`production/StrategicExpedited/${id}`);
    return this.http.put<StrategicExpedited>(url, model);
  }
}
