import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Color } from '../../../models/color';
import { RouterSchedule } from '../../../models/router-schedule';
import { RouterSetup } from '../../../models/router-setup';
import { ProductionCalendarService } from '../../../services/production-calendar.service';
import { RouterSchedulesService } from '../../../services/router-schedules.service';
import { RouterSetupsService } from '../../../services/router-setups.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { EditForm } from '../../../forms/edit-form';
import { DoorStyle } from '../../../models/door-style';
import { ProductionOrderStats } from '../../../models/production-order-stats';
import { ProductionOrdersService } from '../../../services/production-orders.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-edit-router-setup',
  templateUrl: './edit-router-setup.component.html',
  styleUrls: ['./edit-router-setup.component.less']
})
export class EditRouterSetupComponent extends EditForm implements OnInit {

  loading = false;
  routerSetup: RouterSetup;
  routerSetupForm: FormGroup;
  defaultMaterial = '';
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  routerSchedules: RouterSchedule[] = [];
  productionOrderStats: ProductionOrderStats;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private routerSetupsService: RouterSetupsService,
    private routerSchedulesService: RouterSchedulesService,
    private productionOrdersService: ProductionOrdersService,
    private productionCalendarService: ProductionCalendarService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  async ngOnInit() {
    await this.getRouterSchedules();
    await this.getColors();
    await this.getDoorStyles();
    await this.getRouterSetup();
    this.onRouterSchedulesChange(this.routerSetup.routerScheduleId);
  }

  createForm(): void {
    this.routerSetupForm = this.fb.group({
      routerScheduleId: ['', Validators.required],
      colors: ['', Validators.required],
      styles: ['', Validators.required],
      maxPartsInBatch: ['', [Validators.required, Validators.min(1), Validators.max(99999)]]
    });
  }

  onRouterSchedulesChange(e: MatSelectChange | number) {
    const id = typeof e === 'number' ? e : e.value;
    const routerSchedule = this.routerSchedules.find(c => c.id === id);
    if (routerSchedule) {
      this.getProductionOrderStats(routerSchedule.productionLocationId);
    }
  }

  async getRouterSchedules() {
    try {
      this.routerSchedules = await this.routerSchedulesService.getRouterSchedules().toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting router schedules.');
    }
  }

  async getColors() {
    try {
      this.colors = await this.productionCalendarService.getColors().toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    }
  }

  async getDoorStyles() {
    try {
      this.doorStyles = await this.productionCalendarService.getDoorStyles().toPromise();
    }
    catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    }
  }

  async getRouterSetup(): Promise<void> {
    try {
      const id = +this.route.snapshot.paramMap.get('id');
      this.routerSetup = await this.routerSetupsService.getRouterSetup(id).toPromise<RouterSetup>();
      this.setValues();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting router setup details.');
    }
  }

  async getProductionOrderStats(productionLocationId: number) {
    try {
      this.loading = true;
      this.productionOrderStats = await this.productionOrdersService.getProductionOrderStats(productionLocationId).toPromise<ProductionOrderStats>();
    } catch (err) {
      console.error(err);
      this.productionOrderStats = null;
      this.snackBarService.showError('Error getting production order stats.');
    } finally {
      this.loading = false;
    }
  }

  formatRouterSchedule(item: RouterSchedule): string {
    return `${item.routerStr} - ${moment(item.date).format('MMM DD, YYYY')} (${item.timeStart} - ${item.timeEnd})`;
  }

  setValues() {
    const colors = this.routerSetup.colors.split(',').filter(c => c).map(c => {
      return this.colors.find(y => y.name === c.trim())
    });
    const styles = this.routerSetup.styles.split(',').filter(c => c).map(c => {
      return this.doorStyles.find(y => y.name === c.trim())
    });
    this.routerSetupForm.setValue({
      routerScheduleId: this.routerSetup.routerScheduleId,
      colors,
      styles,
      maxPartsInBatch: this.routerSetup.maxPartsInBatch
    });
  }

  isValid(): boolean {
    return this.routerSetupForm.valid;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.updateRouterSetup();
    }
  }

  getData(): RouterSetup {
    const id = +this.route.snapshot.paramMap.get('id');
    const colorValues: Color[] = this.routerSetupForm.get('colors').value || [];
    const styleValues: DoorStyle[] = this.routerSetupForm.get('styles').value || [];

    const colors = colorValues.filter(c => c).map(c => c.name).join(', ');
    const styles = styleValues.filter(s => s).map(s => s.name).join(', ');

    const data: RouterSetup = {
      id,
      routerScheduleId: this.routerSetupForm.get('routerScheduleId').value,
      colors,
      styles,
      maxPartsInBatch: this.routerSetupForm.get('maxPartsInBatch').value
    };
    return data;
  }

  updateRouterSetup() {
    this.loading = true;
    const model = this.getData();

    this.routerSetupsService.updateRouterSetup(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/routers']);
      this.snackBarService.showSuccess('The router setup was updated successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error updating router setup.');
    });
  }

}
