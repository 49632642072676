<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Create Router Schedule
    </h1>
    <hr />

    <div class="container">
        <form [formGroup]="routerScheduleForm" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <mat-label>Router</mat-label>
                <mat-select formControlName="routerId" (selectionChange)="onRouterChange($event)" required>
                    <mat-option *ngFor="let item of routers" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="routerScheduleForm.get('routerId').hasError('required')">
                    Router is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput formControlName="date" [matDatepicker]="picker1" placeholder="Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="routerScheduleForm.get('date').hasError('required')">
                    Date is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Time Start</mat-label>
                <input type="time" formControlName="timeStart" matInput>
                <mat-error *ngIf="routerScheduleForm.get('timeStart').hasError('required')">
                    Time Start is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Time End</mat-label>
                <input type="time" formControlName="timeEnd" matInput>
                <mat-error *ngIf="routerScheduleForm.get('timeEnd').hasError('required')">
                    Time End is required
                </mat-error>
                <mat-error *ngIf="routerScheduleForm.get('timeEnd').hasError('min')">
                    Time End must be greater than Time Start
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent">Create</button>
        </form>
    </div>
</mat-card>