import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ProductionCalendarService } from '../..//services/production-calendar.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { EditProductionCalendarComponent } from './edit-production-calendar/edit-production-calendar.component';
import { ProductionLocation } from '../../models/production-location';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { EventInput, EventApi } from '@fullcalendar/core';
import { ProductionCalendarEvent } from '../../models/production-calendar-event';
import { ProductionCalendar } from '../../models/production-calendar';
import { EditMaterialSuppliersComponent } from './edit-material-suppliers/edit-material-suppliers.component';

const INVALID_LOCATION_ID = -1;
const INVALID_PRODUCTION_CALENDAR_ID = -1;
const LOADING_ANIMATION_TIMEOUT = 2000; // ms

enum ProductionCalendarEventTypes {
  CustomPartsCapacity = 0,
  SheetOverrides = 1,
  MoldingOverrides = 2,
  MaterialSupplier = 3,
  AllowedCustomPartsCapacity = 4
};

@Component({
  selector: 'app-production-calendar',
  templateUrl: './production-calendar.component.html',
  styleUrls: ['./production-calendar.component.less']
})
export class ProductionCalendarComponent implements OnInit {

  calendarOptions: any;
  @ViewChild('fullcalendar') fullcalendar: CalendarComponent;

  loading = true;
  currentDate: Date;
  calendarEvents: EventInput[] = [];
  productionLocations: ProductionLocation[] = [];
  productionLocationId = INVALID_LOCATION_ID;

  constructor(
    public dialog: MatDialog,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) { }

  async ngOnInit() {
    await this.getProductionLocations();
    await this.getProductionCalendars();
  }

  getProductionCalendars(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionCalendarService.getDate().subscribe((date: string) => {
        this.currentDate = new Date(date) || new Date();
        this.calendarOptions = {
          defaultDate: this.currentDate,
          editable: false,
          eventLimit: false,
          header: {
            left: '',
            center: 'title',
            right: 'prev,next, today'
          },
          selectable: true,
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          defaultView: 'dayGridMonth',
          height: 750,
          weekends: true,
          minTime: '06:00:00',
          maxTime: '19:00:00',
          slotDuration: '00:15:00',
          eventOrder: 'start',
          eventClick: (data: { event: { start: Date; extendedProps: { type: number }; }; }) => {
            const { start } = data.event;
            const { type } = data.event.extendedProps;
            const dateISOStr = start.toISOString();
            const event = {
              date: start,
              dateStr: dateISOStr.slice(0, dateISOStr.indexOf('T')),
              type
            };
            return this.openEditProductionCalendar(event);
          },
          events: (range: any, callback: CallableFunction) => {
            this.showLoading();
            this.productionCalendarService.getEvents(this.productionLocationId, range)
              .subscribe((calendarEvents: ProductionCalendarEvent[]) => {
                this.calendarEvents = calendarEvents;
                this.hideLoading();
                callback(this.calendarEvents);
              }, err => {
                console.error(err);
                this.snackBarService.showError('Error getting production calendars.');
                this.hideLoading();
              });
          },
        };
        resolve();
      }, err => {
        console.error(err);
        this.snackBarService.showError('Error getting calendar date.');
        reject();
      });
    });
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    this.refresh();
  }

  refresh() {
    this.fullcalendar.calendar.today();
    this.fullcalendar.calendar.refetchEvents();
  }

  openEditProductionCalendar(event: any) {
    const { date, dateStr } = event;
    const events = this.findEvents(date);
    let selectedTabIndex: number = 0;
    let productionCalendarId: number = INVALID_PRODUCTION_CALENDAR_ID;

    if (this.productionLocationId === INVALID_LOCATION_ID) {
      this.snackBarService.showWarning('Please select a production location.');
      return false;
    }

    if (this.previousDate(date) && events.length === 0) {
      this.snackBarService.showWarning('We don\'t have production calendar information for this date.');
      return false;
    }

    if (events.length > 0) {
      productionCalendarId = +events[0].id;
    }

    switch (event.type) {
      case ProductionCalendarEventTypes.SheetOverrides:
        selectedTabIndex = ProductionCalendarEventTypes.SheetOverrides;
        break;
      case ProductionCalendarEventTypes.MoldingOverrides:
        selectedTabIndex = ProductionCalendarEventTypes.MoldingOverrides;
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(EditProductionCalendarComponent, {
      width: '850px',
      position: {
        top: '60px'
      },
      disableClose: true,
      data: {
        date,
        dateStr,
        productionCalendarId,
        productionLocationId: this.productionLocationId,
        selectedTabIndex
      }
    });

    dialogRef.afterClosed().subscribe((result: ProductionCalendar | boolean) => {
      if (result) {
        this.refresh();
      }
    });
  }

  openEditMaterialSuppliers() {
    const dialogRef = this.dialog.open(EditMaterialSuppliersComponent, {
      width: '480px',
      data: {
        productionLocationId: this.productionLocationId,
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.snackBarService.showSuccess('Material suppliers was updated successfully.');
        this.refresh();
      }
    });

    return false;
  }

  previousDate(date: Date): boolean {
    if (this.currentDate.getDate() === date.getDate()
      && this.currentDate.getMonth() === date.getMonth()
      && this.currentDate.getFullYear() === date.getFullYear()) {
      return false;
    }
    return this.currentDate > date;
  }

  findEvents(date: Date): EventApi[] {
    const result: EventApi[] = [];
    const events = this.fullcalendar.calendar.getEvents();

    for (const e of events) {
      if (e.start.getDate() === date.getDate()
        && e.start.getMonth() === date.getMonth()
        && e.start.getFullYear() === date.getFullYear()) {
        result.push(e);
      }
    }

    return result;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    setTimeout(() => {
      this.loading = false;
    }, LOADING_ANIMATION_TIMEOUT);
  }
}
