import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
// Material
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
// Models
import { CommissionCustomer, CommissionCustomerFilter } from 'src/app/models/commission-customer';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommissionsFilters } from 'src/app/models/commissions-filters';
// Services
import { CommissionService } from 'src/app/services/commission.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
// Time
import * as moment from 'moment';
// Components
import { CustomerModalCommissionsComponent } from '../customer-modal-commissions/customer-modal-commissions.component';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-manager-commissions',
  templateUrl: './manager-commissions.component.html',
  styleUrls: ['./manager-commissions.component.less'],
})
export class ManagerCommissionsComponent implements OnInit, AfterViewInit {
  loading = false;
  customersFiltersApplied = false;
  salesPersons: string[] = [];
  assignedRepsFiltersApplied = false;

  // display columns
  displayedColumns: string[] = [
    'number',
    'name',
    'created',
    'activateDate',
    'expandedActiveDate',
    'city',
    'state',
    'assignedRep',
    'splits',
    'noCommunities',
    'actions',
  ];

  commissionCustomersDataSource = new MatTableDataSource<CommissionCustomer>();
  customerSort: MatSort;

  @ViewChild('customerSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.customerSort = ms;
    this.ngAfterViewInit();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) tblCommissionCustomers: MatTable<CommissionCustomer>;
  @ViewChild(MatSelect) dllTeamSales: MatSelect;

  customerFilter = new UntypedFormControl();
  customerNumberFilter = new UntypedFormControl();
  reportFromDateFilter = new UntypedFormControl();
  reportToDateFilter = new UntypedFormControl();
  assignedRepFilter = new UntypedFormControl();

  filterFormPanelDisplaced = true;
  editFormPanelDisplaced = true;

  withSplitCommunities = false;
  withActiveInRange = false;

  constructor(
    private commissionService: CommissionService,
    private snackBarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }


  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      await this.getCommissionCustomers();

    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.commissionCustomersDataSource.sort = this.customerSort;
      this.commissionCustomersDataSource.paginator = this.paginator;
    });
  }

  onSetFiltersTable(predicates: string): void {
    this.commissionCustomersDataSource.filterPredicate = (data: CommissionCustomer, filter: string) => {
      if (filter == '') {
        return true;
      } else {
        let predicates = JSON.parse(filter) as CommissionCustomerFilter[];
        let predicateResult: boolean[] = [];

        predicates.map((predicate) => {
          if (predicate.isString) {
            predicateResult.push(data[predicate.column].toLowerCase().includes(predicate.value.trim().toLowerCase()));
          }

          if (predicate.isBoolean) {
            predicateResult.push(data[predicate.column] == Boolean(predicate.value));
          }

          if (predicate.isDate) {
            let dateRange = predicate.value as string[];
            predicateResult.push(moment(data[predicate.column]) > moment(dateRange[0]) && moment(data[predicate.column]) < moment(dateRange[1]));
          }

          if (predicate.isArray) {
            let repSales = predicate.value as string[];
            for (let rep of repSales) {
              if (data.assignedRep == rep) {
                predicateResult.push(true);
              }
            }
          }
        });
        return predicateResult.filter(f => f).length == predicates.length;
      }
    };

    this.commissionCustomersDataSource.filter = predicates;
    if (this.commissionCustomersDataSource.paginator) {
      this.commissionCustomersDataSource.paginator.firstPage();
    }
  }



  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  onRequestedFromDateFilterChange() {
    if (!this.reportFromDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested From date.');
      this.reportFromDateFilter.setValue('');
    }
  }

  onRequestedToDateFilterChange() {
    if (!this.reportToDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested To date.');
      this.reportToDateFilter.setValue('');
    }
  }

  getData(): Observable<CommissionCustomer[]> {
    return this.commissionCustomersDataSource.connect();
  }

  rangeDatesIsValid(): boolean {
    const startPeriod = this.reportFromDateFilter.value;
    const endPeriod = this.reportToDateFilter.value;
    if (startPeriod && endPeriod) {
      const days = moment(endPeriod)
        .startOf('day')
        .diff(moment(startPeriod).startOf('day'), 'days');

      if (startPeriod && endPeriod && days <= 0) {
        this.reportFromDateFilter.setValue('');
        this.reportToDateFilter.setValue('');
        this.snackBarService.showWarning('Range of dates is not valid.');
      } else if (startPeriod === null || endPeriod === null) {
        this.snackBarService.showWarning('Enter a valid range dates.');
      } else {
        return true;
      }
    }
  }

  applyFilters() {

    var filters: CommissionCustomerFilter[] = [];

    if (this.customerNumberFilter.valid && this.customerNumberFilter.value) {
      filters.push({ column: 'number', value: this.customerNumberFilter.value, isString: true });
    }

    if (this.customerFilter.valid && this.customerFilter.value) {
      filters.push({ column: 'name', value: this.customerFilter.value, isString: true });
    }

    if (this.dllTeamSales && this.dllTeamSales.value && this.dllTeamSales.value.length > 0) {
      filters.push({ column: 'assignedRep', value: this.dllTeamSales.value, isArray: true });
    }

    if (this.withSplitCommunities) {
      filters.push({ column: 'splits', value: true, isBoolean: true });
    }

    if (this.rangeDatesIsValid()) {
      if (this.withActiveInRange) {
        filters.push({ column: 'activateDate', value: [this.reportFromDateFilter.value, this.reportToDateFilter.value], isDate: true });
      } else {
        filters.push({ column: 'created', value: [this.reportFromDateFilter.value, this.reportToDateFilter.value], isDate: true });
      }
    }

    this.customersFiltersApplied = filters.length > 0;

    if (this.customersFiltersApplied) {
      this.onSetFiltersTable(JSON.stringify(filters));
    }
  }

  clearCustomersFilters(): void {
    this.commissionCustomersDataSource.filter = '';
    this.withSplitCommunities = false;
    this.withActiveInRange = false;
    this.customerFilter.setValue('');
    this.customerNumberFilter.setValue('');
    this.customersFiltersApplied = false;
    this.reportFromDateFilter.setValue('');
    this.reportToDateFilter.setValue('');
    this.assignedRepFilter.setValue('');
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async getCommissionCustomers(): Promise<void> {
    if (this.commissionCustomersDataSource.data.length === 0) {
      this.showLoading();
      await this.commissionService.getCommissionCustomers()
        .toPromise()
        .then((commissionCustomer: CommissionCustomer[]) => {
          this.hideLoading();
          if (commissionCustomer && commissionCustomer.length > 0) {
            this.getAssignedReps(commissionCustomer);
            this.commissionCustomersDataSource = new MatTableDataSource<CommissionCustomer>(commissionCustomer);
            this.commissionCustomersDataSource.sort = this.customerSort;
            this.commissionCustomersDataSource.paginator = this.paginator;

            let commissionsFilters = this.commissionService.getCommissionsFilters();
            if (commissionsFilters != null) {
              this.customerFilter.setValue(commissionsFilters.customerFilter);
              this.customerNumberFilter.setValue(commissionsFilters.customerNumberFilter);
              this.reportFromDateFilter.setValue(commissionsFilters.reportFromDateFilter);
              this.reportToDateFilter.setValue(commissionsFilters.reportToDateFilter);
              this.assignedRepFilter.setValue(commissionsFilters.assignedRepFilter);
              this.withSplitCommunities = commissionsFilters.withSplitCommunities;
              this.withActiveInRange = commissionsFilters.withActiveInRange;

              this.applyFilters();
              this.commissionService.updateCommissionsFilters(null);
            }
          } else {
            this.snackBarService.showWarning('No commission customers found.');
          }
        })
        .catch((err) => {
          console.error(err);
          this.hideLoading();
          this.snackBarService.showError('Error getting commission customers.');
        });
    }
  }

  getCommissionCustomersDataSource(): MatTableDataSource<CommissionCustomer> {
    return this.commissionCustomersDataSource;
  }

  getAssignedReps(commissionCustomers: CommissionCustomer[]) {
    commissionCustomers.forEach(customer => {
      if (this.salesPersons.indexOf(customer.assignedRep) === -1) {
        this.salesPersons.push(customer.assignedRep);
      }
    });

    this.salesPersons.sort();
  }

  openCustomerEdit(customer: CommissionCustomer) {
    this.dialog
      .open(CustomerModalCommissionsComponent, {
        width: '40%',
        disableClose: true,
        data: {
          selection: customer,
          customers: this.commissionCustomersDataSource.data.filter(
            (m) => m.activateDate
          ),
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          if (response.state) {
            switch (response.state) {
              case 'ok':
                const newSource = this.commissionCustomersDataSource.data;
                const returnValue = response.result as CommissionCustomer;
                const findIdx = newSource.findIndex(
                  (f) => f.customerID === returnValue.customerID
                );
                if (findIdx !== -1) {
                  newSource[findIdx] = returnValue;
                  this.commissionCustomersDataSource.data = newSource;
                }
                this.snackBarService.showSuccess(
                  'The customer was updated successfully'
                );
                break;
              default:
                this.snackBarService.showError(
                  'An error occurred during the update operation.'
                );
                break;
            }
          }
        }
      });
  }

  onEnterKeyEventHandler(event: KeyboardEvent): void {
    event.preventDefault();
  }

  onActionsClickEventHandler(eventParams: any): void {
    this.commissionService.updateCommissionsFilters(new CommissionsFilters(
      this.customerFilter.value,
      this.customerNumberFilter.value,
      this.reportFromDateFilter.value,
      this.reportToDateFilter.value,
      this.assignedRepFilter.value,
      this.withSplitCommunities,
      this.withActiveInRange
    ));

    this.router.navigate([eventParams.path, eventParams.pathParams], { relativeTo: this.route });
  }

  onAllSelectionChange(option: MatOption) {
    this.dllTeamSales.options.map((item: MatOption) => option.selected ? item.select() : item.deselect());
    this.dllTeamSales.close();
  }
}
