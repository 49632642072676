<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h1 class="mat-h1">
            {{ data.title }}
        </h1>
        <hr />
        <br>
        <div class="container">
            <p>{{ data.message }}</p>
            <p><b>Do you want to continue with the action anyway?</b></p>
            <div class="loading" *ngIf="loading">
                <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Processing</span>
            </div>
        </div>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    <button mat-flat-button color="accent" (click)="disable()">Yes, delete</button>
</mat-dialog-actions>