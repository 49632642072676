<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Delete Global Setting
  </h1>
  <hr />
  <p>Are you sure you want to delete this global settings?</p>
  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{globalSetting?.id}}</li>
      <li><strong>Name:</strong> {{globalSetting?.name}}</li>
      <li><strong>Friendly Name:</strong> {{globalSetting?.friendlyName}}</li>
      <li><strong>Value:</strong> {{globalSetting?.value}}</li>
      <li><strong>Group Name:</strong> {{globalSetting?.groupName}}</li>
      <li><strong>Created By:</strong> {{globalSetting?.createdBy}}</li>
      <li><strong>Date Created:</strong> {{globalSetting?.dateCreated | date}}</li>
      <li><strong>Modified By:</strong> {{globalSetting?.modifiedBy}}</li>
      <li><strong>Date Modified:</strong> {{globalSetting?.dateModified | date}}</li>
    </ul>
  </div>
  <div class="actions">
    <button mat-raised-button color="accent" (click)="deleteSetting()">Delete</button>
  </div>
</mat-card>
