import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import { Account } from '../../../models/account';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.less']
})
export class DeleteAccountComponent implements OnInit {

  account: Account;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getAccount();
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getAccount(id).subscribe((account: Account) => {
      this.account = account;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account details.');
      });
  }

  deleteAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.deleteAccount(id).subscribe(_ => {
      this.router.navigate(['/accounts']);
      this.snackBarService.showSuccess('The account was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting account.');
      });
  }

}
