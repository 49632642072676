<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Create Production Molding Setting
    </h1>
    <hr />

    <div class="container">
        <ul class="errors">
            <li *ngFor="let item of errorMessages">
                {{item}}
            </li>
        </ul>
        <form [formGroup]="createProductionMoldingSettingForm" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <mat-select placeholder="Color" formControlName="color">
                    <mat-option *ngFor="let color of colors" [value]="color.name">
                        {{color.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="createProductionMoldingSettingForm.get('color').hasError('required')">
                    Color is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-select placeholder="Door Style" formControlName="doorStyle">
                    <mat-option *ngFor="let doorStyle of doorStyles" [value]="doorStyle.name">
                        {{doorStyle.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="createProductionMoldingSettingForm.get('doorStyle').hasError('required')">
                    Door Style is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Molding Length" type="number" formControlName="moldingLength">
                <mat-error *ngIf="createProductionMoldingSettingForm.get('moldingLength').hasError('required')">
                    Molding Length is required
                </mat-error>
                <mat-error *ngIf="createProductionMoldingSettingForm.get('moldingLength').hasError('min')">
                    Min value 0 required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Friendly Molding Length" formControlName="friendlyMoldingLength">
                <mat-error *ngIf="createProductionMoldingSettingForm.get('friendlyMoldingLength').hasError('required')">
                    Friendly Molding Length is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Channel Groove" type="number" formControlName="channelGroove">
                <mat-error *ngIf="createProductionMoldingSettingForm.get('channelGroove').hasError('required')">
                    Channel Groove is required
                </mat-error>
                <mat-error *ngIf="createProductionMoldingSettingForm.get('channelGroove').hasError('min')">
                    Min value 0 required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Friendly Channel Groove" formControlName="friendlyChannelGroove">
                <mat-error *ngIf="createProductionMoldingSettingForm.get('friendlyChannelGroove').hasError('required')">
                    Friendly Channel Groove is required
                </mat-error>
            </mat-form-field>
            <div class="mat-form-field">
                <mat-checkbox formControlName="isDefault">Is Default</mat-checkbox>
            </div>
            <button mat-raised-button color="accent">Create</button>
        </form>
    </div>
</mat-card>