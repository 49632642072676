<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content [formGroup]="confirmationForm">
    <p>Are you sure you want to send the following quote to email address "{{data.invoicingEmail}}"?
        <label class="edit-email-link" (click)="openEditEmailModal()">Edit</label>
    </p>
    <div>
        <mat-form-field class="full-width">
            <mat-label>Price</mat-label>
            <input type="number" matInput formControlName="price">
            <mat-error
                *ngIf="confirmationForm.get('price').invalid && (confirmationForm.get('price').dirty || confirmationForm.get('price').touched)">
                Please enter a valid price (non-negative).
            </mat-error>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        [disabled]="confirmationForm.invalid || (!confirmationForm.get('price').value && confirmationForm.get('price').touched)"
        mat-raised-button color="accent" (click)="yes()">&nbsp; Yes &nbsp;</button>
    <button mat-raised-button mat-dialog-close>No</button>
</mat-dialog-actions>