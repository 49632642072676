import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Account } from '../../../models/account';
import { UserRole } from '../../../models/user-role';

@Component({
  selector: 'app-commission-dashboard',
  templateUrl: './commission-dashboard.component.html',
  styleUrls: ['./commission-dashboard.component.less']
})
export class CommissionDashboardComponent implements OnInit {
  isRolOnlyView: boolean;
  tabIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
     this.isRolOnlyView = this.authService.getAccount().roles.find(f => f === UserRole.CommissionViewer.toString()) ? true : false;
  }

  ngOnInit(): void {
    const tabBack = this.route.snapshot.paramMap.get('tab');
    if (tabBack) {
      this.tabIndex = Number(tabBack);
    }
  }

  changeTab() {
    const tabCount = 5;
    this.tabIndex = (this.tabIndex + 1) % tabCount;
  }
}
