<div class="header-actions">
    <a routerLink="create" mat-flat-button color="accent">Create new Rebate Group</a>
    <a mat-flat-button color="accent" (click)="openRebateReports()"> Generate Rebate Reports</a>
</div>
<button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
    <mat-icon>arrow_downward</mat-icon>
</button>

<button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
    <mat-icon>arrow_upward</mat-icon>
</button>

<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Rebate Groups
    </h1>
    <hr />

    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter Rebate Groups
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field class="small">
                            <input matInput placeholder="Name" [formControl]="rebateGroupFilter">
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="activeRebateGroupFilter"
                            (change)="onActiveRebateGroupFilterChange($event)" matTooltip="Active">Active</mat-checkbox>
                        <mat-checkbox [(ngModel)]="noActiveRebateGroupFilter"
                            (change)="onNoActiveRebateGroupFilterChange($event)" matTooltip="No Active">No Active
                        </mat-checkbox>
                    </div>
                </div>
                <button mat-mini-fab matTooltip="Apply filters" (click)="applyRebateGroupsFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="reset-action-container" *ngIf="rebateGroupsFiltersApplied">
        <button mat-raised-button (click)="clearRebateGroupsFilters()">
            <mat-icon>clear</mat-icon> Reset Rebate Groups Filters
        </button>
    </div>

    <h3 *ngIf="getRebateGroupsData().length === 0">No rebate groups to show.</h3>

    <div *ngIf="getRebateGroupsData().length > 0">
        <table mat-table [dataSource]="getRebateGroupsDataSource()" #rebateGroupsSort="matSort" matSort
            class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </th>
                <td mat-cell *matCellDef="let element"><span class="amount">{{element.rebatePercentage}}%</span></td>
            </ng-container>

            <ng-container matColumnDef="startPeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Period </th>
                <td mat-cell *matCellDef="let element">{{element.startPeriod | date}}</td>
            </ng-container>

            <ng-container matColumnDef="endPeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> End Period </th>
                <td mat-cell *matCellDef="let element">{{element.endPeriod | date}}</td>
            </ng-container>

            <ng-container matColumnDef="dateModified">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Modified </th>
                <td mat-cell *matCellDef="let element"> {{element.dateModified | date}} </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                <td mat-cell *matCellDef="let element"> {{element.isActive | active}} </td>
            </ng-container>

            <ng-container matColumnDef="assignments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Assignments </th>
                <td mat-cell *matCellDef="let element"> {{element.assignments}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <a routerLink="details/{{element.id}}" mat-icon-button matTooltip="Rebate Group Details"
                        matTooltipPosition="above">
                        <mat-icon color="accent">notes</mat-icon>
                    </a>
                    <a routerLink="edit/{{element.id}}" mat-icon-button matTooltip="Edit Rebate Group"
                        matTooltipPosition="above">
                        <mat-icon color="accent">edit</mat-icon>
                    </a>
                    <!--
                    <a routerLink="delete/{{element.id}}" mat-icon-button matTooltip="Delete Rebate Group"
                        matTooltipPosition="above">
                        <mat-icon color="accent">delete</mat-icon>
                    </a>-->
                </td>
            </ng-container>
        </table>
    </div>

</mat-card>

<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Customers
    </h1>
    <hr />

    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter Customers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Name" [formControl]="customerFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Customer Number" [formControl]="customerNumberFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Company" [formControl]="companyFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Rebate Group" [formControl]="customerRebateGroupFilter">
                        </mat-form-field>
                    </div>
                </div>
                <button mat-mini-fab matTooltip="Apply filters" (click)="applyCustomersFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="reset-action-container" *ngIf="customersFiltersApplied">
        <button mat-raised-button (click)="clearCustomersFilters()">
            <mat-icon>clear</mat-icon> Reset Customers Filters
        </button>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <h3 *ngIf="!loading && getCustomersData().length === 0">No customers to show.</h3>

    <div *ngIf="getCustomersData().length > 0">
        <table mat-table [dataSource]="getCustomersDataSource()" #customersSort="matSort" matSort
            class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="customersDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: customersDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> <span class="truncate"> {{element.name}} </span> </td>
            </ng-container>

            <ng-container matColumnDef="customerNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Number </th>
                <td mat-cell *matCellDef="let element">{{element.customerNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let element"> <span class="truncate">{{element.company}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="rebateGroup">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rebate Group </th>
                <td mat-cell *matCellDef="let element"> {{element.rebateGroup}} </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.rebatePercentage !== null" class="amount">{{element.rebatePercentage}}%</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="communities">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Communities </th>
                <td mat-cell *matCellDef="let element"> {{element.communities}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openRebateGroupAssignments(element.id)" mat-icon-button matTooltip="Customer Details"
                        matTooltipPosition="above">
                        <mat-icon color="accent">notes</mat-icon>
                    </a>
                    <a (click)="openEditRebateGroupAssignments(element.id)" mat-icon-button matTooltip="Edit Customer"
                        matTooltipPosition="above">
                        <mat-icon color="accent">edit</mat-icon>
                    </a>
                </td>
            </ng-container>
        </table>
    </div>

</mat-card>