<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Rebate Group Details
    </h1>
    <hr />
    <div class="actions">
        <a routerLink="/accounting/edit/{{rebateGroup?.id}}" mat-raised-button>Edit Rebate Group</a>
    </div>
    <div class="content">
        <ul>
            <li><strong>Id:</strong> {{rebateGroup?.id}}</li>
            <li><strong>Name:</strong> {{rebateGroup?.name}}</li>
            <li><strong>Percentage:</strong> <span class="amount">{{rebateGroup?.rebatePercentage}}%</span></li>
            <li><strong>Start Period:</strong> {{rebateGroup?.startPeriod | date}}</li>
            <li><strong>End Period:</strong> {{rebateGroup?.endPeriod | date}}</li>
            <li><strong>Date Created:</strong> {{rebateGroup?.dateCreated | date}}</li>
            <li><strong>Date Modified:</strong> {{rebateGroup?.dateModified | date}}</li>
            <li><strong>Created By:</strong> {{rebateGroup?.createdBy}}</li>
            <li><strong>Modified By:</strong> {{rebateGroup?.modifiedBy}}</li>
            <li><strong>Is Active:</strong> {{rebateGroup?.isActive | active}}</li>
            <li><strong>Assignments:</strong> {{rebateGroup?.assignments}}</li>
        </ul>
    </div>
</mat-card>