<div class="container">
    <mat-tab-group mat-align-tabs="right" color="accent" [(selectedIndex)]="tabIndex">
        <mat-tab (click)="onChangeTab()" label="Reason Codes">
            <ng-template matTabContent>
                <app-list-reason-codes></app-list-reason-codes>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="onChangeTab()" label="Categories">
            <ng-template matTabContent>
                <app-list-reason-categories></app-list-reason-categories>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="onChangeTab()" label="Groups">
            <ng-template matTabContent>
                <app-list-reason-groups></app-list-reason-groups>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>