<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Create Production Location
  </h1>
  <hr />

  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="createProductionLocationForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Name" formControlName="name" required>
        <mat-error *ngIf="createProductionLocationForm.controls.name.invalid">{{getNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Address" formControlName="address" required>
        <mat-error *ngIf="createProductionLocationForm.controls.address.invalid">{{getAddressErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Address2" formControlName="address2">
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="City" formControlName="city">
        <mat-error *ngIf="createProductionLocationForm.controls.city.invalid">{{getCityErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="State" formControlName="state">
        <mat-error *ngIf="createProductionLocationForm.controls.state.invalid">{{getStateErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Zip Code" formControlName="zipCode">
        <mat-error *ngIf="createProductionLocationForm.controls.zipCode.invalid">{{getZipCodeErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Lat" type="number" formControlName="lat">
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Long" type="number" formControlName="long">
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="WIP Hours" type="number" formControlName="wipHours">
        <mat-error *ngIf="createProductionLocationForm.get('wipHours').hasError('required')">
          Wip Hours is required
        </mat-error>
        <mat-error *ngIf="createProductionLocationForm.get('wipHours').hasError('min')">
          Min value {{minWipHours}} required
        </mat-error>
        <mat-error *ngIf="createProductionLocationForm.get('wipHours').hasError('max')">
          Max value {{maxWipHours}} required
        </mat-error>
        <mat-hint>WIP Hours Factor - JIT Scheduling</mat-hint>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="WIP Slab Percentage %" type="number" formControlName="wipSlabPercentage">
        <mat-error *ngIf="createProductionLocationForm.get('wipSlabPercentage').hasError('required')">
          Wip Slab Percentage is required
        </mat-error>
        <mat-error *ngIf="createProductionLocationForm.get('wipSlabPercentage').hasError('min')">
          Min value {{minSlabWipPercentage}} required
        </mat-error>
        <mat-error *ngIf="createProductionLocationForm.get('wipSlabPercentage').hasError('max')">
          Max value {{maxWipSlabPercentage}} required
        </mat-error>
        <mat-hint>WIP Slab Percentage of Total Custom Parts - JIT Scheduling</mat-hint>
      </mat-form-field>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowRetail">Allow Retail</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowFusion">Allow Fusion</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowMultiColorUnit">Allow Multi Color</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowPartsOrder">Allow Parts Order</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowPalletized">Allow Palletized</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowAccessoriesOnly">Allow Accessories Only</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowCPU">Allow CPU</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowFedEx">Allow FedEx</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowDropOff">Allow Drop Off</mat-checkbox>
      </div>
      <div class="mat-form-field">
        <mat-checkbox formControlName="allowOvernight">Allow Overnight</mat-checkbox>
      </div>
      <button mat-raised-button color="accent">Create</button>
    </form>
  </div>
</mat-card>