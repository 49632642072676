import { Component } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet-schedule-legend',
  templateUrl: './bottom-sheet-schedule-legend.component.html',
  styleUrls: ['./bottom-sheet-schedule-legend.component.less']
})
export class BottomSheetScheduleLegendComponent {

  constructor() { }
}
