import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PalletizationEmailUpdateModalComponent } from './palletization-email-update-modal/palletization-email-update-modal.component';
import { PalletizationService } from '../../../services/palletization.service';

@Component({
  selector: 'app-palletization-email-sending',
  templateUrl: './palletization-email-sending.component.html',
  styleUrls: ['./palletization-email-sending.component.less']
})
export class PalletizationEmailSendingComponent {
  confirmationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { invoicingEmail: string },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PalletizationEmailSendingComponent>,
    public palletizationService: PalletizationService
  ) {
    this.confirmationForm = this.fb.group({
      price: [null, [Validators.min(1)]]
    });
  }

  yes() {
    this.palletizationService.invoicingEmail = this.data.invoicingEmail;
    this.palletizationService.price = this.confirmationForm.value.price;
    this.dialogRef.close(true);
  }

  openEditEmailModal() {
    let dialog = this.dialog.open(PalletizationEmailUpdateModalComponent, {
      width: '540px',
      height: '240px',
      disableClose: true,
      data: {
        invoicingEmail: this.data.invoicingEmail
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.data.invoicingEmail = result;
      }
    });
  }
}
