<h2 class="mat-h2">Colors Legend</h2>
<div class="container">
  <ul>
    <li class="monday">Monday</li>
    <li class="tuesday">Tuesday</li>
    <li class="wednesday">Wednesday</li>
    <li class="thursday">Thursday</li>
    <li class="friday">Friday</li>
    <li class="saturday">Saturday</li>
    <li class="sunday">Sunday</li>
  </ul>
  <ul>
    <li><span class="red poNumber">AA001</span> Released to Production</li>
    <li><span class="red poNumber">AA002</span> Production Plan Complete</li>
    <li><span class="red poNumber">AA003</span> Cutting Files Generated</li>
    <li><span class="red poNumber">AA004</span> Cutting Files Available</li>
    <li><span class="red poNumber">AA005</span> Cutting Files at Machine</li>
    <li><span class="black poNumber">AA006</span> Center Panels Cut</li>
    <li><span class="yellow poNumber">AA007</span> Molding Cut Files Downloaded</li>
    <li><span class="yellow poNumber">AA008</span> Assembly Started</li>
    <li><span class="yellow poNumber">AA009</span> Assembly Complete</li>
    <li><span class="yellow poNumber">AA010</span> Quality Control Complete</li>
    <li><span class="orange poNumber">AA011</span> Final Quality Audit Complete</li>
    <li><span class="green poNumber">AA012</span> Packaging</li>
    <li><span class="green poNumber">AA013</span> Packing Complete</li>
    <li><span class="green poNumber">AA014</span> Shipped</li>
    <li><span class="green poNumber">AA015</span> Invoiced</li>
  </ul>
</div>