<h1 class="mat-h1">
    Summary
</h1>
<hr />
<br />
<mat-card class="mat-elevation-z2">
    <mat-card-content>
        <h3>Custom Parts Not Exported</h3>
        <div class="legend">
            <strong>Legend:</strong>
            <ul>
                <li matTooltip="Total Custom Parts ordered without PO#"><span class="orders"></span> Orders</li>
                <li matTooltip="Total Custom Parts LockedIn with Production Date"><span class="scheduled"></span>
                    Scheduled</li>
                <li matTooltip="Total Custom Parts with Production Date but not LockedIn"><span
                        class="unscheduled"></span>
                    Unscheduled</li>
                <li matTooltip="Total Custom Parts with Customer Requested Date"><span class="customerRequested"></span>
                    Customer
                    Requested</li>
                <li matTooltip="Total Custom Parts with Must Ship Date"><span class="mustShip"></span> Must Ship</li>
            </ul>
        </div>
        <hr />
        <div class="loading" *ngIf="loadingProductionSummary">
            <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <p *ngIf="!loadingProductionSummary && !productionSummary">
            No custom parts to show.
        </p>
        <div class="scheduled-parts" *ngIf="productionSummary && productionSummary.scheduledParts">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th *ngFor="let color of productionSummary.colors">{{color}}</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of productionSummary.scheduledParts; let i = index">
                        <tr *ngFor="let line of item.lines; let j = index">
                            <td *ngIf="j === 0; else blankBlock">
                                <strong>{{item.date | date: 'EEE, MMM d'}}</strong>
                            </td>
                            <ng-template #blankBlock>
                                <td class="total" *ngIf="j === item.lines.length-1">Total</td>
                                <td *ngIf="j !== item.lines.length-1">&nbsp;</td>
                            </ng-template>
                            <td [class.bold]="j === 0" [class.total]="j === item.lines.length-1"
                                *ngFor="let val of line">
                                <div
                                    matTooltip="{{val.color}} - Orders: {{val.orders | number}}, Scheduled: {{val.scheduled | number}}, Unscheduled: {{val.unscheduled | number}}, Customer Requested: {{val.customerRequested | number}}, Must Ship: {{val.mustShip | number}}">
                                    <ng-container *ngIf="val.orders">
                                        <span class="orders">{{val.orders | number}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="val.scheduled">
                                        <span class="scheduled">{{val.scheduled | number}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="val.unscheduled">
                                        <span class="unscheduled">{{val.unscheduled | number}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="val.customerRequested">
                                        <span class="customerRequested">{{val.customerRequested | number}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="val.mustShip">
                                        <span class="mustShip">{{val.mustShip | number}}</span>
                                    </ng-container>
                                </div>
                            </td>
                            <td class="total" *ngIf="j === item.lines.length-1">{{item.total | number}} &nbsp;</td>
                            <td *ngIf="j !== item.lines.length-1">&nbsp;</td>
                        </tr>
                        <tr class="blankBlock">
                            <td>&nbsp;</td>
                            <td *ngFor="let c of productionSummary.colors">&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="unscheduled-parts" *ngIf="productionSummary && productionSummary.unScheduledParts">
            <table>
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>All orders</strong></td>
                        <td *ngFor="let val of productionSummary.unScheduledParts">
                            <b>{{val | number}}</b>
                        </td>
                        <td><b>{{ productionSummary.totalUnScheduledParts | number}}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content>
        <h3>Custom Parts Scheduled Details</h3>
        <div class="loading" *ngIf="loading">
            <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <div class="summary" *ngFor="let productionDateSummary of productionDatesSummaries">
            <div *ngIf="productionDateSummary.customPartsScheduled > 0">
                <div class="header">
                    <div>{{productionDateSummary.productionDate}} </div>
                    <div>Custom Parts Scheduled: {{productionDateSummary.customPartsScheduled}}</div>
                    <div>Total Capacity: {{productionDateSummary.totalCapacity}}</div>
                    <div>&nbsp;</div>
                </div>
                <div *ngFor="let productionColorEntry of productionDateSummary.productionColorEntries">
                    <p>
                        <mat-icon>loyalty</mat-icon> <strong>{{productionColorEntry.color}}</strong>
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Style</th>
                                <th>Custom Parts Scheduled</th>
                                <th>Color Override Capacity</th>
                                <th>Color and Style Override Capacity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let productionStyleEntry of productionColorEntry.productionStyleEntries">
                                <td>{{productionStyleEntry.style}}</td>
                                <td>{{productionStyleEntry.customPartsScheduled}}</td>
                                <td>{{productionStyleEntry.colorOverrideCapacity}}</td>
                                <td>{{productionStyleEntry.colorStyleOverrideCapacity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>