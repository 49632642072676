<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h1 class="mat-h1">Edit Customer</h1>
        <hr />
        <br>
        <div class="container">
            <mat-form-field class="full-width">
                <input matInput placeholder="Customer Number" [value]="customer.number" readonly>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Customer Name" [value]="customer.name" readonly>
            </mat-form-field>

            <mat-form-field class="medium-width">
                <input matInput #inputOverride placeholder="Commission Rate Override" [formControl]="rateOverride" maxlength="5" (keypress)="decimalFilter($event)">
                <mat-icon matSuffix *ngIf="inputOverride.value.length > 0">percent</mat-icon>
                <mat-error *ngIf="rateOverride?.errors && rateOverride.hasError('max')"> The max value for rate is 100 </mat-error>
                <mat-error *ngIf="rateOverride?.errors && rateOverride.hasError('min')"> The minimum value for rate is 1 </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <input matInput #inputExpanded placeholder="Expanded Customer Link" [formControl]="expandedCustomer" (click)="hideCustomerGrid = !hideCustomerGrid" readonly>
                <button mat-button *ngIf="inputExpanded.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearCustomerLink()" matTooltip="Clear customer">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="mat-elevation-z8" class="customer-grid" [hidden]="hideCustomerGrid">
                <div class="search-container">
                    <mat-form-field>
                        <mat-label>search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input class="field-search">
                    </mat-form-field>
                </div>
                <table mat-table #tblCustomer [dataSource]="getCustomerList()" class="mat-elevation-z0 table" matSort #dateActiveSort="matSort">
                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef> Customer # </th>
                        <td mat-cell *matCellDef="let row"> {{ row?.number }} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let row"> {{ row?.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="activateDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Active </th>
                        <td mat-cell *matCellDef="let row"> {{ row?.activateDate | date }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="getExpandedCustomer(row)"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="3">No data matching the filter "{{input?.value}}"</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5]"></mat-paginator>
            </div>
            <br>
            <mat-form-field class="full-width">
                <mat-label>Sales Person Type</mat-label>
                <mat-select [formControl]="salePerson">
                    <mat-option [value]="0">- Pick Sale Person -</mat-option>
                    <mat-option *ngFor="let item of salesPersons" [value]="item.salesPersonId">
                        {{ item.salesPersonName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="loading" *ngIf="loading">
                <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
            </div>
        </div>
        <mat-card-actions align="end">
            <button mat-raised-button color="accent" class="btn-modal btn-margin" [disabled]="loading || salePerson.value === 0 || rateOverride?.errors" (click)="UpdateCustomer()"> Update </button>
            <button mat-raised-button color="accent" class="btn-modal" (click)="closeWithOutSaving()" [disabled]="loading">Cancel</button>
        </mat-card-actions>
    </mat-card>
</mat-dialog-content>