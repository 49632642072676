
<mat-card>
  <mat-card-content class="marginTbl">
    <div class="row">
      <div class="columnOrderH">
        <h1 class="mat-h1">
          Customer Logins
        </h1>
      </div>
    </div>
  
  <h3 style="text-align: center;" *ngIf="!loadingCustomerLogins && _customerLogin?.length === 0">No customer logins to show.</h3>
  
  <div class="container header-buttons-actions leftA" *ngIf="_customerLogin?.length > 0">              
    <button  mat-flat-button color="accent" class="margin-btn" (click)="updateUserModal()">UPDATE USER</button>
    <button  mat-flat-button color="accent" class="margin-btn" (click)="deleteUserModal()">DELETE USER</button>
    <button  mat-flat-button color="accent" class="margin-btn" (click)="addAccountModal()">ADD ACCOUNT</button>
  </div>
  
  <div class="loading" *ngIf="loadingCustomerLogins">
      <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Loading Customer Login History...</span>
  </div>
  <br>
  
    <div *ngIf="!loadingCustomerLogins && _customerLogin?.length > 0" class="col-container mat-elevation-z8">
        <table mat-table [dataSource]="_customerLogin" class="mat-elevation-z8 table">
            <tr mat-header-row *matHeaderRowDef="customerLoginDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: customerLoginDisplayedColumns;"></tr>
            
            <ng-container matColumnDef="cinchUserId">
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell *matCellDef="let element">{{element.userId}}</td>
              </ng-container>
  
              <ng-container matColumnDef="customerId">
                <th mat-header-cell *matHeaderCellDef>Customer Id</th>
                <td mat-cell *matCellDef="let element">{{element.customerId}}</td>
              </ng-container>
  
              <ng-container matColumnDef="lastLoginDate">
                <th mat-header-cell *matHeaderCellDef>Last Login Date</th>
                <td mat-cell *matCellDef="let element">{{element.lastLoginDate | date}}</td>
              </ng-container>
        </table>
    </div>
  </mat-card-content>
</mat-card>