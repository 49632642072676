import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { BaseForm } from '../../../../forms/base-form';
import { Color } from '../../../../models/color';
import { DoorStyle } from '../../../../models/door-style';
import { ProductionCalendarService } from '../../../../services/production-calendar.service';
import { ProductionMoldingSetting } from 'src/app/models/production-molding-setting';

@Component({
  selector: 'app-create-production-molding',
  templateUrl: './create-production-molding.component.html',
  styleUrls: ['./create-production-molding.component.less']
})
export class CreateProductionMoldingComponent extends BaseForm implements OnInit {

  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  createProductionMoldingSettingForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private productionCalendarService: ProductionCalendarService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getColors();
    this.getDoorStyles();
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  createForm() {
    this.createProductionMoldingSettingForm = this.fb.group({
      color: ['', Validators.required],
      doorStyle: ['', Validators.required],
      moldingLength: ['', [
        Validators.required,
        Validators.min(0),
      ]],
      friendlyMoldingLength: ['', Validators.required],
      channelGroove: ['', [
        Validators.required,
        Validators.min(0),
      ]],
      friendlyChannelGroove: ['', Validators.required],
      isDefault: [false]
    });
  }

  isValid(): boolean {
    return this.createProductionMoldingSettingForm.valid;
  }

  submit() {
    this.reset();
    if (this.isValid()) {
      this.createProductionMoldingSetting();
    } else {
      this.snackBarService.showError('You must enter a valid production molding setting.');
    }
  }

  createProductionMoldingSetting() {
    const model: ProductionMoldingSetting = {
      color: this.createProductionMoldingSettingForm.controls.color.value,
      doorStyle: this.createProductionMoldingSettingForm.controls.doorStyle.value,
      moldingLength: this.createProductionMoldingSettingForm.controls.moldingLength.value,
      friendlyMoldingLength: this.createProductionMoldingSettingForm.controls.friendlyMoldingLength.value,
      channelGroove: this.createProductionMoldingSettingForm.controls.channelGroove.value,
      friendlyChannelGroove: this.createProductionMoldingSettingForm.controls.friendlyChannelGroove.value,
      isDefault: this.createProductionMoldingSettingForm.controls.isDefault.value,
    };

    this.settingsService.createProductionMoldingSetting(model).subscribe(_ => {
      this.router.navigate(['/settings']);
      this.snackBarService.showSuccess('The production molding setting was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new production molding setting.');
    });
  }
}
