import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalSetting } from 'src/app/models/global-setting';
import { EditForm } from '../../../forms/edit-form';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-setting',
  templateUrl: './edit-setting.component.html',
  styleUrls: ['./edit-setting.component.less']
})
export class EditSettingComponent extends EditForm implements OnInit {

  globalSetting: GlobalSetting;
  editSettingForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getAccount();
  }

  createForm() {
    this.editSettingForm = this.fb.group({
      name: ['', Validators.required],
      friendlyName: ['', Validators.required],
      value: ['',  Validators.required],
      groupName: ['',  Validators.required],
    });
  }

  isValid(): boolean {
    return this.editSettingForm.valid;
  }

  setValues() {
    this.editSettingForm.setValue({
      name: this.globalSetting.name,
      friendlyName: this.globalSetting.friendlyName,
      value: this.globalSetting.value,
      groupName: this.globalSetting.groupName,
    });
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.updateSetting();
    }
  }

  getNameErrorMessage() {
    return this.editSettingForm.controls.name.hasError('required') ? 'You must enter a name' : '';
  }

  getFriendlyNameErrorMessage() {
    return this.editSettingForm.controls.friendlyName.hasError('required') ? 'You must enter a friendly name' : '';
  }

  getValueErrorMessage() {
    return this.editSettingForm.controls.value.hasError('required') ? 'You must enter a value' : '';
  }

  getGroupNameErrorMessage() {
    return this.editSettingForm.controls.groupName.hasError('required') ? 'You must enter a group name' : '';
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.settingsService.getSetting(id).subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the global setting details.');
      });
  }

  updateSetting() {
    const id = +this.route.snapshot.paramMap.get('id');
    const model: GlobalSetting = {
      id,
      name: this.editSettingForm.controls.name.value,
      friendlyName: this.editSettingForm.controls.friendlyName.value,
      value: this.editSettingForm.controls.value.value,
      groupName: this.editSettingForm.controls.groupName.value,
    };

    this.settingsService.updateSetting(model).subscribe((globalSetting: GlobalSetting) => {
      this.globalSetting = globalSetting;
      this.router.navigate([`/settings/group-by/${this.globalSetting.groupName}`]);
      this.snackBarService.showSuccess('The global setting information was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating the global setting information.');
    });
  }

}
