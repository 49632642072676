export enum JobState {
    notRunning = 0,
    running = 1,
}

export interface JobStatus {
    id?: number;
    state: JobState;
    startTime?: Date;
    endTime?: Date;
}
