<mat-label class="matlbl" style="color: grey;font-weight:450;">Company Name </mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.company}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Customer Number</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.customerNumber}}</mat-label> <br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Contact Name</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.contactName}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Customer Email</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.emailAddress}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Address Info</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.addressLine1}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Third Party Invoicing</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.thirdParyInvoicing}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Invoicing Notes</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.invoicingInstructions}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Activate Date</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.activateDate | date}}</mat-label><br><br>
<mat-label class="matlbl" style="color: grey;font-weight: 450;">Service Zone</mat-label> <br>
<mat-label style="color: black; font-weight: 700;">{{custInfo?.serviceZone}}</mat-label><br><br>