import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PalletUnitInfo } from 'src/app/models/pallet-unit-info';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-palletization-unit-info',
  templateUrl: './palletization-unit-info.component.html',
  styleUrls: ['./palletization-unit-info.component.less']
})
export class PalletizationUnitInfoComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<PalletUnitInfo>();
  palletizedOrdersTableSort: MatSort;

  constructor(public dialogRef: MatDialogRef<PalletizationUnitInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PalletUnitInfo[]) {

  }

  ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.palletizedOrdersTableSort;
  }

  @ViewChild('palletizedOrdersTable', { read: MatSort }) set matSort(ms: MatSort) {
    this.palletizedOrdersTableSort = ms;
    this.ngAfterViewInit();
  }

  displayedHeader: string[] = [
    'unitNumber',
    'salesOrderNumber'
  ];
}
