import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterSetup } from '../../../models/router-setup';
import { RouterSetupsService } from '../../../services/router-setups.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-delete-router-setup',
  templateUrl: './delete-router-setup.component.html',
  styleUrls: ['./delete-router-setup.component.less']
})
export class DeleteRouterSetupComponent implements OnInit {
  routerSetup: RouterSetup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routerSetupsService: RouterSetupsService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getRouterSetup();
  }

  getRouterSetup(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routerSetupsService.getRouterSetup(id).subscribe((routerSetup: RouterSetup) => {
      this.routerSetup = routerSetup;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting router setup details.');
      });
  }

  deleteRouterSetup(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.routerSetupsService.deleteRouterSetup(id).subscribe(_ => {
      this.router.navigate([`/routers`]);
      this.snackBarService.showSuccess('The router setup was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting router setup.');
      });
  }

}
