<h2 mat-dialog-title>Audit Assignments Details</h2>
<mat-dialog-content>
    <h3>
        <strong>Id: </strong> <span>{{customer.id}}</span>
        <strong>Customer: </strong> <span>{{customer.name}}</span>
        <strong>Customer Number: </strong> <span>{{customer.customerNumber}}</span>
        <strong>Company: </strong> <span>{{customer.company}}</span>
        <strong>Audit: </strong> <span>{{customer.audit | active}}</span>
    </h3>

    <h3>Communities</h3>

    <div class="filters-container">
        <div>
            <mat-form-field>
                <input matInput placeholder="Filter Communities" [formControl]="communityFilter">
            </mat-form-field>
        </div>

        <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>

    <div class="reset-action-container">
        <button *ngIf="filtersApplied" mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Filters
        </button>
    </div>

    <p *ngIf="getCommunitiesData().length === 0">No communities to show.</p>

    <ng-container *ngIf="getCommunitiesData().length > 0">
        <table mat-table [dataSource]="getCommunitiesData()" class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="communitiesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: communitiesDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Community </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef> Date Created </th>
                <td mat-cell *matCellDef="let element"> {{element.dateCreated | date}} </td>
            </ng-container>

            <ng-container matColumnDef="numberOfUnits">
                <th mat-header-cell *matHeaderCellDef> Number of Units </th>
                <td mat-cell *matCellDef="let element"> {{element.numberOfUnits}} </td>
            </ng-container>

            <ng-container matColumnDef="audit">
                <th mat-header-cell *matHeaderCellDef> Audit </th>
                <td mat-cell *matCellDef="let element"><span>{{element.audit | active}}</span>
                </td>
            </ng-container>
        </table>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>