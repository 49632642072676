import { Component } from '@angular/core';
import { Role } from 'src/app/models/role';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RolesService } from 'src/app/services/roles.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BaseForm } from '../../../forms/base-form';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.less']
})
export class CreateRoleComponent extends BaseForm {

  role: Role;
  createRoleForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private rolesService: RolesService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  createForm() {
    this.createRoleForm = this.fb.group({
      name: ['', Validators.required],
      description: ['']
    });
  }

  isValid(): boolean {
    return this.createRoleForm.valid;
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.createRole();
    }
  }

  getRoleErrorMessage() {
    return this.createRoleForm.controls.name.hasError('required') ? 'You must enter a role name' : '';
  }

  createRole() {
    const model: Role = {
      name: this.createRoleForm.controls.name.value,
      description: this.createRoleForm.controls.description.value,
    };

    this.rolesService.createRole(model).subscribe(_ => {
      this.router.navigate(['/roles']);
      this.snackBarService.showSuccess('The role was created successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error creating a new role.');
    });
  }

}
