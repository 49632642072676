import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalSettingConfig, TypeSettingAction } from 'src/app/models/commission-general';
import { CommissionRegion, CommissionState, CommissionStateItem } from 'src/app/models/commission-region';
import { CommissionService } from 'src/app/services/commission.service';

import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

@Component({
  selector: 'app-commission-region',
  templateUrl: './commission-region.component.html',
  styleUrls: ['./commission-region.component.less']
})
export class CommissionRegionComponent implements OnInit {
  loading = false;
  settingModal: ModalSettingConfig;
  settingCopy: ModalSettingConfig;
  commissionRegionForm: UntypedFormGroup;
  commissionStates: CommissionState[] = [];
  commissionStateItems: CommissionStateItem[] = [];
  allStateValue: Partial<CommissionState> = { id: 0, name: 'All' };
  commissionRegion: CommissionRegion;

  @ViewChild(MatTable) tblState: MatTable<CommissionStateItem>;
  @ViewChild(MatSelect) dllStates: MatSelect;

  displayedColumnsStates: string[] = [
    'number',
    'state',
    'abbreviation',
    'actions',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalSettingConfig,
    public dialogRef: MatDialogRef<CommissionRegionComponent>,
    public fb: UntypedFormBuilder,
    private commissionService: CommissionService) {
    this.settingModal = data;
    this.settingCopy = structuredClone(data);
    this.commissionRegion = this.settingModal.settingAction === 2 ? this.settingModal.value : null;
    this.onInitForm();
  }

  ngOnInit(): void {
    this.onGetCommissionState();
    if (this.commissionRegion !== null) {
      this.commissionRegionForm.get('name').setValue(this.commissionRegion.region)
      this.commissionRegion.stateList.map((item, idx) => item.index = idx + 1);
      this.commissionStateItems = this.commissionRegion.stateList;
    }
  }

  onInitForm() {
    this.commissionRegionForm = this.fb.group({
      'name': ['', Validators.required],
      'state': ['']
    })
  }

  getStateDataSource(): CommissionStateItem[] {
    return this.commissionStateItems.filter(f => !f.delete);
  }

  async onGetCommissionState() {
    await this.commissionService.getCommissionStates()
      .toPromise()
      .then(result => this.commissionStates = result)
      .catch(console.error)
  }


  getTiTleModal(): string {
    return this.settingModal.settingAction === 1 ? 'Create New Region' : 'Edit Region';
  }

  onSelectionChange(option: MatOption) {
    this.dllStates.options.map((item: MatOption) => option.selected ? item.select() : item.deselect())
    this.dllStates.close();
  }

  onValidateStateControl(): boolean {
    return this.commissionRegionForm.get('state').value && (this.commissionRegionForm.get('state').value as CommissionState[]).length > 0;
  }

  onValidateSaveState(): boolean {
    return !this.commissionRegionForm.valid || !(this.commissionStateItems.filter(f => !f.delete).length > 0)
  }

  onAddStates() {
    (this.commissionRegionForm.get('state').value as CommissionState[]).map(
      value => {
        if (value.name !== 'All') {
          const findIdx = this.commissionStateItems.findIndex(f => f.stateId === value.id);
          if (findIdx === -1) {
            this.commissionStateItems.push(
              {
                id: 0,
                stateId: value.id,
                state: value.name,
                abbreviation: value.abbreviation,
                delete: false
              });
          } else {
            this.commissionStateItems[findIdx].delete = false;
          }
        }
      });


    this.commissionStateItems.map((item: CommissionStateItem, index: number) => item.index = index + 1);
    this.commissionRegionForm.get('state').setValue('');
  }

  onDeleteState(row: CommissionStateItem) {
    const findIdx = this.commissionStateItems.findIndex(f => f.stateId === row.stateId);
    if (row.id === 0) {
      this.commissionStateItems.splice(findIdx, 1);
    } else {
      row.delete = true;
    }
    this.tblState.renderRows();
  }

  async submit() {
    switch (this.settingModal.settingAction) {
      case TypeSettingAction.create:
        this.commissionRegion = {
          regionId: 0,
          region: this.commissionRegionForm.get('name').value,
          isActive: true,
          stateList: this.commissionStateItems
        }
        await this.commissionService.createCommissionRegions(this.commissionRegion)
          .toPromise()
          .then(result => this.settingModal.result = result.regionId !== 0 ? 1 : -1)
          .catch(() => this.settingModal.result = -1)
        break;
      case TypeSettingAction.edit:
        this.commissionRegion.region = this.commissionRegionForm.get('name').value;
        this.commissionRegion.stateList = this.commissionStateItems;
        await this.commissionService.updateCommissionRegions(this.commissionRegion)
          .toPromise()
          .then(result => this.settingModal.result = result.regionId !== 0 ? 1 : -1)
          .catch(() => this.settingModal.result = -1)
        break;
    }
    this.dialogRef.close(this.settingModal)
  }

  closeWithOutSaving() {
    this.data = this.settingCopy;
    this.data.result = -1;
    this.dialogRef.close(this.data);
  }

}
