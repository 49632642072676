import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { RebateGroup } from '../models/rebate-group';
import { ConfigService } from './config.service';
import { ApartmentComplex } from '../models/apartment-complex';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getRebateGroups(): Observable<RebateGroup[]> {
    const url = this.config.buildURL('accounting/rebateGroups');
    return this.http.get<RebateGroup[]>(url);
  }

  getRebateGroupsAssignments(customerId: number): Observable<RebateGroup[]> {
    const url = this.config.buildURL('accounting/rebateGroupsAssignments');
    const params = new HttpParams().set('customerId', `${customerId}`);
    return this.http.get<RebateGroup[]>(url, { params });
  }

  getRebateGroupsCommunitiesAssignments(customerId: number): Observable<ApartmentComplex[]> {
    const url = this.config.buildURL('accounting/rebateGroupsCommunitiesAssignments');
    const params = new HttpParams().set('customerId', `${customerId}`);
    return this.http.get<ApartmentComplex[]>(url, { params });
  }

  getCustomers(): Observable<Customer[]> {
    const url = this.config.buildURL('accounting/customers');
    return this.http.get<Customer[]>(url);
  }

  getRebateGroup(rebateGroupId: number): Observable<RebateGroup> {
    const url = this.config.buildURL(`accounting/rebateGroup/${rebateGroupId}`);
    return this.http.get<RebateGroup>(url);
  }

  createRebateGroup(model: RebateGroup): Observable<RebateGroup> {
    const url = this.config.buildURL(`accounting/createRebateGroup`);
    return this.http.post<RebateGroup>(url, model);
  }

  updateRebateGroup(model: RebateGroup): Observable<RebateGroup> {
    const url = this.config.buildURL(`accounting/updateRebateGroup/${model.id}`);
    return this.http.put<RebateGroup>(url, model);
  }

  deleteRebateGroup(rebateGroupId: number) {
    const url = this.config.buildURL(`accounting/deleteRebateGroup/${rebateGroupId}`);
    return this.http.delete(url);
  }

  setRebateGroups(customerId: number, rebateGroupIds: number[]) {
    const model = {
      customerId,
      rebateGroupIds
    };
    const url = this.config.buildURL(`accounting/setRebateGroups`);

    return this.http.put(url, model);
  }

  setRebateGroupsForCommunities(customerId: number, rebateGroupIds: number[], communitiesIds: number[]) {
    const model = {
      customerId,
      rebateGroupIds,
      communitiesIds
    };
    const url = this.config.buildURL(`accounting/setRebateGroupsForCommunities`);

    return this.http.put(url, model);
  }

  unsetRebateGroups(customerId: number, rebateGroupIds: number[]) {
    const model = {
      customerId,
      rebateGroupIds
    };
    const url = this.config.buildURL(`accounting/unsetRebateGroups`);

    return this.http.put(url, model);
  }

  unsetRebateGroupsForCommunities(customerId: number, rebateGroupIds: number[], communitiesIds: number[]) {
    const model = {
      customerId,
      rebateGroupIds,
      communitiesIds
    };
    const url = this.config.buildURL(`accounting/unsetRebateGroupsForCommunities`);

    return this.http.put(url, model);
  }

  generateRebateReport() {
    const url = this.config.buildURL(`accounting/generateRebateReport`);
    return this.http.get(url);
  }

  generateRebateReportUrl(startPeriod: Date, endPeriod: Date, rebateGroupId: number): string {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    const _endPeriod = endPeriod != null ? moment(endPeriod).format('YYYY-MM-DD') : '';
    const url = `${this.config.buildURL('accounting/generateRebateReport')}?startPeriod=${moment(startPeriod).format('YYYY-MM-DD')}&endPeriod=${_endPeriod}&rebateGroupId=${rebateGroupId}`;
    return url;
  }
}
