import { HttpErrorResponse } from '@angular/common/http';

export abstract class BaseForm {
    errorMessages: string[] = [];

    abstract createForm(): void;
    abstract isValid(): boolean;
    abstract submit(): void;

    reset() {
        this.errorMessages = [];
    }

    getErrorMessages(e: Error | HttpErrorResponse): string[] {
        let errorMessages: string[] = [];

        const getErrors = (errors: any): string[] => {
            const result: string[] = [];
            for (const prop in errors) {
                if (errors.hasOwnProperty(prop)) {
                    const values: string[] = errors[prop];
                    for (const message of values) {
                        result.push(message);
                    }
                }
            }
            return result;
        };

        if (e instanceof HttpErrorResponse) {
            const { error } = e;
            if (error && error.errors) {
                errorMessages = errorMessages.concat(getErrors(error.errors));
            } else if (error && error.messages) {
                errorMessages = errorMessages.concat(error.messages);
            }
        }
        return errorMessages;
    }
}
