import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProductionLocation } from 'src/app/models/production-location';
import { ProductionLocationsService } from 'src/app/services/production-locations.service';

@Component({
  selector: 'app-production-locations',
  templateUrl: './production-locations.component.html',
  styleUrls: ['./production-locations.component.less']
})
export class ProductionLocationsComponent implements OnInit {

  productionLocations: ProductionLocation[] = [];
  displayedColumns: string[] = ['id', 'name', 'address', 'isActive', 'modifiedBy', 'dateModified', 'actions'];

  constructor(
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionLocations();
  }

  getProductionLocations() {
    this.productionLocationsService.getProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
      this.productionLocations = productionLocations;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production locations.');
      });
  }
}
