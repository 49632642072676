import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
// Material
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
// Models
import { CommissionSalesPerson } from 'src/app/models/commission-sales-person';
import { CommissionCustomerAccount } from '../../../models/commission-customer-account';
import { GlobalSetting } from '../../../models/global-setting';
// Services
import { CommissionService } from 'src/app/services/commission.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SettingsService } from '../../../services/settings.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/models/user-role';

@Component({
  selector: 'app-commission-customer-account',
  templateUrl: './commission-customer-account.component.html',
  styleUrls: ['./commission-customer-account.component.less']
})
export class CommissionCustomerAccountComponent implements OnInit {
  customersFiltersApplied = false;
  loading = false;
  salesPersonsCharge = false;
  creditLimitRatio = 0;

  filterFormPanelDisplaced = true;
  editFormPanelDisplaced = true;

  salesPersons: CommissionSalesPerson[] = [];

  customerFilter = new UntypedFormControl();
  customerNumberFilter = new UntypedFormControl();
  reportSaleRepFilter = new UntypedFormControl();

  isBlocked = false;
  withHighBalance = false;

  @ViewChild(MatSelect) dllTeamSales: MatSelect;

  @ViewChild(MatTable) tblCommissionCustomers: MatTable<CommissionCustomerAccount>;
  customerAccountDataSource = new MatTableDataSource<CommissionCustomerAccount>();
  filteredCustomerAccountDataSource = new MatTableDataSource<CommissionCustomerAccount>();
  displayedColumns: string[] = [
    'number', 'name', 'salesPerson', 'blocked', 'creditLimit', 'openOrders', 'balance'
  ];

  isRolOnlyView: boolean;
  disableProfileControl = false;

  @ViewChild('filtersButton') filtersButton;

  constructor(
    private commissionService: CommissionService,
    private snackBarService: SnackbarService,
    private settingsService: SettingsService,
    private authService: AuthService
  ) {
    this.isRolOnlyView = this.authService.getAccount().roles.find(f => f === UserRole.CommissionViewer.toString()) ? true : false;
  }

  async ngOnInit(): Promise<void> {
    await this.LoadSettingLimitRatio();
    await this.getAllSalesPerson();
    await this.LoadPermissionsOfUser();
    await this.getCustomersAccounts();
  }

  private async LoadPermissionsOfUser() {
    if (this.isRolOnlyView) {
      const myProfile = this.authService.getAccount();
      const findPerson = this.salesPersons.filter(f => f.emailAddress === myProfile.email);
      if (findPerson.length !== 0) {
        this.reportSaleRepFilter.setValue(findPerson);
        this.reportSaleRepFilter.disable();
      }
    }
  }

  private async LoadSettingLimitRatio() {
    await new Promise<void>((resolve, reject) => {
      this.settingsService.getSetting('96').subscribe((result: GlobalSetting) =>
        this.creditLimitRatio = Number(result.value),
        err => {
          console.error(err);
          reject();
        },
        () => resolve());
    }).then(() => {
      if (this.creditLimitRatio === 0) {
        this.snackBarService.showError('Cannot get the value of the setting for Limit Ratio');
      }
    });
  }

  private filterByCustomer(): CommissionCustomerAccount[] {
    return this.filteredCustomerAccountDataSource.data.filter((r) => {
      if (r.name !== null) {
          return r.name
           .toLowerCase()
           .includes(this.customerFilter.value.trim().toLowerCase());
      }
    });
  }

  private filterByCustomerNumber(): CommissionCustomerAccount[] {
    return this.filteredCustomerAccountDataSource.data.filter((r) => {
      if (r.number !== null) {
        return r.number
          .toLowerCase()
          .includes(this.customerNumberFilter.value.trim().toLowerCase());
      }
    }
    );
  }

  private filterBySalesPersons(): CommissionCustomerAccount[] {
    const selectSalesPerson = (this.reportSaleRepFilter.value as CommissionSalesPerson[]).map((m) => m.salesPersonId);
    return this.filteredCustomerAccountDataSource.data.filter((r) => {
      if (selectSalesPerson.filter(s => s === r.salesPersonId).length !== 0) {
        return r;
      }
    });
  }

   private filterIsBlocked(): CommissionCustomerAccount[] {
    return this.filteredCustomerAccountDataSource.data.filter((r) => r.blocked !== '');
  }

  private filterWithHighBalance(): CommissionCustomerAccount[] {
    return this.filteredCustomerAccountDataSource.data.filter((r) => (((r.openOrders + r.balance) * 100) / r.creditLimit) > this.creditLimitRatio);
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  getCustomersAccounts(): Promise<void> {
    this.showLoading();
    return new Promise<void>((resolve, reject) => {
      if (this.isRolOnlyView) {
         if (this.reportSaleRepFilter.value) {
            const salesPerson = (this.reportSaleRepFilter.value as CommissionSalesPerson[])[0];
            this.commissionService.getCommissionCustomerAccountBySalePerson(salesPerson.emailAddress).subscribe((commissionCustomerAccount: CommissionCustomerAccount[]) => {
              this.customerAccountDataSource.data = commissionCustomerAccount;
          }, err => {
            console.error(err);
            this.snackBarService.showError('Error getting customer account list.');
            reject();
          }, () => {
            this.hideLoading();
            resolve();
          });
         } else {
           this.hideLoading();
           this.reportSaleRepFilter.disable();
           this.customerNumberFilter.disable();
           this.customerFilter.disable();
           this.disableProfileControl = true;
           this.snackBarService.showError('Your email is not registered in the sales team list, you cannot access this report.');
         }
      } else {
        this.commissionService.getAllCommissionCustomerAccount().subscribe((commissionCustomerAccount: CommissionCustomerAccount[]) => {
          this.customerAccountDataSource.data = commissionCustomerAccount;
        }, err => {
          console.error(err);
          this.snackBarService.showError('Error getting customer account list.');
          reject();
        }, () => {
          this.hideLoading();
          resolve();
        });
      }
    });
  }


  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  async getAllSalesPerson(): Promise<void> {
    this.salesPersonsCharge = true;
    await new Promise<void>((resolve, reject) => {
      this.commissionService.getCommissionAllSalesPerson().subscribe(
        (result: CommissionSalesPerson[]) => (this.salesPersons = result),
        (err) => {
          console.error(err);
          this.snackBarService.showError('Error getting sales persons.');
          reject();
        },
        () => {
          this.salesPersonsCharge = false;
          resolve();
        }
      );
    });
  }

  onAllSelectionChange(option: MatOption) {
    if (option.selected) {
      this.dllTeamSales.options.forEach((item: MatOption) => item.select());
    } else {
      this.dllTeamSales.options.forEach((item: MatOption) => item.deselect());
    }
    this.dllTeamSales.close();
  }

  getCustomerAccountDataSource(): MatTableDataSource<CommissionCustomerAccount> {
    return this.customersFiltersApplied ? this.filteredCustomerAccountDataSource : this.customerAccountDataSource;
  }

  getData(): CommissionCustomerAccount[] {
    return this.customerAccountDataSource.connect().value;
  }

  applyFilters() {
    this.filteredCustomerAccountDataSource.data = this.customerAccountDataSource.data;

    if (this.customerNumberFilter.valid && this.customerNumberFilter.value) {
      this.filteredCustomerAccountDataSource.data = this.filterByCustomerNumber();
      this.customersFiltersApplied = true;
    }

    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredCustomerAccountDataSource.data = this.filterByCustomer();
      this.customersFiltersApplied = true;
    }

    if (this.reportSaleRepFilter.value) {
      this.filteredCustomerAccountDataSource.data = this.filterBySalesPersons();
      this.customersFiltersApplied = true;
    }

    if (this.isBlocked) {
      this.filteredCustomerAccountDataSource.data = this.filterIsBlocked();
      this.customersFiltersApplied = true;
    }

    if (this.withHighBalance) {
      this.filteredCustomerAccountDataSource.data = this.filterWithHighBalance();
      this.customersFiltersApplied = true;
    }
  }

  clearCustomersFilters(): void {
    this.isBlocked = false;
    this.withHighBalance = false;
    this.customerFilter.setValue('');
    this.customerNumberFilter.setValue('');
    if (!this.isRolOnlyView) {
      this.reportSaleRepFilter.setValue(null);
    }
    this.customersFiltersApplied = false;
  }

  onEnterKeyEventHandler(event: KeyboardEvent) : void {
    this.dllTeamSales.close();
  }

  onClickEventHandler(event: Event) : void {
    if((event.target as HTMLElement).className.includes("toHandleClickEvent"))
      this.filtersButton.focus();
  }

  stopEventPropagation(event: Event) : void {
    event.stopPropagation();
  }
}
