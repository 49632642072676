import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  getBaseUrl(): string {
    return environment.apiUrl;
  }

  buildURL(path: string): string {
    return `${environment.apiUrl}${path}`;
  }

  buildPackagePlanningURL(path: string): string {
    return `${environment.packagePlanningApiUrl}${path}`;
  }

  buildReportUrl(path: string): string {
    return `${environment.reportUrl}${path}`;
  }

  buildFedexURL(path: string): string {
    return `${environment.fedexUrl}${path}`;
  }

  getAppVersion(): string {
    return environment.appVersion;
  }

  getAppName(): string {
    return environment.appName;
  }

  buildCustomerInfoURL(path: string) : string{
    return `${environment.cinchApiURL}${path}`;
  }

  buildPackagePlanningInfoURL(path: string) : string{
    return `${environment.packagePlanningApiUrl}${path}`;
  }
}
