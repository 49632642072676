import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit} from '@angular/core';
import { Quote } from 'src/app/models/quote';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.less']
})
export class QuoteDetailComponent implements OnInit {

  public details: any;

  constructor(public dialogRef: MatDialogRef<QuoteDetailComponent>,
    private snackBarService: SnackbarService,
    private customerInfoService: CustomerService,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { quoteDetails: Quote[] }){


  }

  ngOnInit() {
    this.details = this.data.quoteDetails;
  }

  closeWithOutSaving() {
    this.dialogRef.close();
  }
}
