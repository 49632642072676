<h2 mat-dialog-title>Edit Material Suppliers</h2>
<mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="materialSuppliersForm" (ngSubmit)="submit()">
        <mat-form-field>
            <input matInput [matDatepicker]="picker1" formControlName="startDate" placeholder="Start Date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="materialSuppliersForm.get('startDate').hasError('required')">
                Start Date is required
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="picker2" formControlName="endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="materialSuppliersForm.get('endDate').hasError('required')">
                End Date is required
            </mat-error>
            <mat-error *ngIf="materialSuppliersForm.get('endDate').hasError('min')">
                End Date must be greater than the Start Date
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Material Supplier</mat-label>
            <mat-select formControlName="materialSupplierId">
                <mat-option *ngFor="let item of materialSuppliers" [value]="item.id">
                    {{item.name}} - ({{item.color}})
                </mat-option>
            </mat-select>
            <mat-error *ngIf="materialSuppliersForm.get('materialSupplierId').hasError('required')">
                Material Supplier is required
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-raised-button color="accent" (click)="submit()">Update</button>
</mat-dialog-actions>