import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OvernightService } from '../../../../services/overnight.service';
import { EmailUpdateModalComponent } from '../email-sending-confirmation/email-update-modal/email-update-modal.component';

@Component({
  selector: 'app-email-sending-confirmation',
  templateUrl: './email-sending-confirmation.component.html',
  styleUrls: ['./email-sending-confirmation.component.less']
})
export class EmailSendingConfirmationComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    invoicingEmail: string,
    serviceAndTransitTimes: string,
    rate: number
  },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EmailSendingConfirmationComponent>,
    private overnightService: OvernightService
  ) {

  }

  yes() {
    this.dialogRef.close(true);
  }

  openEditEmailModal() {
    let dialog = this.dialog.open(EmailUpdateModalComponent, {
      width: '540px',
      height: '240px',
      disableClose: true,
      data: {
        invoicingEmail: this.data.invoicingEmail
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.data.invoicingEmail = result;
        this.overnightService.customerAddress.invoicingEmail = result;
      }
    });
  }
}
