import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { NotificationContact, NotificationContactDetail } from '../../../models/notification-contact';
import { startWith, map } from 'rxjs/operators';
import { OrderNotificationService } from '../../../services/order-notification.service';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { NotificationOrder, NotificationOrderDetail, NotificationOrderOrigen } from '../../../models/notification-order';
import { AuthService } from 'src/app/services/auth.service';
import { Account } from 'src/app/models/account';
import * as moment from 'moment';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-create-order-notification',
  templateUrl: './create-order-notification.component.html',
  styleUrls: ['./create-order-notification.component.less']
})
export class CreateOrderNotificationComponent implements OnInit {

  loading = false;
  contactSpecific = false;
  contactSelect: NotificationContact;
  contactDetailSelect: NotificationContactDetail[] = [];
  contactSelectComplete = false;
  notificationForm: UntypedFormGroup;
  user: Account;

  notificationContacts: NotificationContact[] = [];
  filterContacts: Observable<NotificationContact[]>;
  contactsDetailList: NotificationContactDetail[] = [];

  constructor(
    private navigation: Router,
    private orderNotificationService: OrderNotificationService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    formBuilder: UntypedFormBuilder
  ) {
    this.notificationForm = formBuilder.group({
      inputCustomer: new UntypedFormControl('', Validators.required),
      viewContact: [ this.contactSpecific ],
      inputNotification: new UntypedFormControl('', Validators.required)
    });
    this.user = this.authService.getAccount();
  }

  async ngOnInit(): Promise<void> {
    await this.loadContactInfoAsync();
  }

  async loadContactInfoAsync(): Promise<void> {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.orderNotificationService.getNotificationsContacts().subscribe(
          (result: NotificationContact[]) => this.notificationContacts = result,
          err => console.error(err),
          () => resolve());
      } catch (error) {
        this.loading = false;
        console.error(error);
        reject();
      }

    }).then(() => {
      this.loading = false;
      this.filterContacts = this.notificationForm.get('inputCustomer').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value === '') {
            this.contactsDetailList = [];
            this.notificationForm.get('viewContact').setValue(false, );
            this.contactSpecific = false;
            this.contactSelectComplete = false;
          }
          return typeof value === 'string' ? value : value?.CustomerNumber;
        }),
        map(name => name ? this._filter(name) : this.notificationContacts)
        );
    });
  }

  private _filter(value: string): NotificationContact[] {
    const filterValue = value.toLowerCase();
    return this.notificationContacts.filter(contact =>
      `${contact.customerNumber.toLowerCase()} - ${contact.customer ? contact.customer.toLowerCase() : ''}`.includes(filterValue));
  }

  displayFn(notificationContact: NotificationContact): string {
    return notificationContact && notificationContact.customerNumber ? `${notificationContact.customerNumber} - ${notificationContact.customer}` : '';
  }

  getContactsSelection(option: MatOption) {
    if (option.value) {
      this.contactsDetailList = [];
      this.contactSelect = option.value as NotificationContact;
      this.contactSelectComplete = true;

      this.contactsDetailList = this.contactSelect.contactDetails.filter(e => {
        if (e.name !== this.contactSelect.customerNumber) {
          return e;
        }
      });
    }
  }

  onChangeViewContacts() {
    this.contactSpecific = this.notificationForm.get('viewContact').value;
  }

  onChangeSelectionContact(selection: MatListOption[]) {
    this.contactDetailSelect = selection.map(item => item.value);
  }

  goBack() {
    this.navigation.navigate(['order-notification']);
  }

  async createAlert() {
    const newNotification: NotificationOrder = {
      owner: this.contactSelect.customerNumber,
      ownerName: this.contactSelect.customer,
      origen: NotificationOrderOrigen.Scheduler_Web,
      createdBy: this.user.userName,
      createdDate: new Date(moment().format()),
      notificationsOrderDetail: this.contactSpecific ?
        this.contactDetailSelect.map(i => {
          const detail: NotificationOrderDetail = {
            notificationText: JSON.stringify({
              notify: this.notificationForm.get('inputNotification').value,
              email: i.email
            })
          };
          return detail;
        }) :
        this.contactSelect.contactDetails.map(i => {
          const detail: NotificationOrderDetail = {
            notificationText: JSON.stringify({
              notify: this.notificationForm.get('inputNotification').value,
              email: i.email
            })
          };
          return detail;
        })
    };

    await new Promise<NotificationOrder>((resolve, reject) => {
      let notificationResponse: NotificationOrder;
      try {
        this.orderNotificationService.createNotificationOrder(newNotification).subscribe(
          (response: NotificationOrder) => notificationResponse = response,
          error => console.error(error),
          () => resolve(notificationResponse));
      } catch (error) {
        console.error(error);
        reject();
      }
    }).then((result: NotificationOrder) => {
      (result.notificationId && result.notificationId !== '') ?
      this.snackbarService.showSuccess('New alert was saved successfully.') :
      this.snackbarService.showError('An error occurred during the save operation.') ;
      this.goBack();
    });
  }
}
