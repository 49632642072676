import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WorkCenterComponent } from '../work-center.component';
import { WorkCenterItem } from '../../../models/work-center-item';
import { SnackbarService } from '../../../services/snackbar.service';
import { WorkCentersService } from '../../../services/work-centers.service';

@Component({
  selector: 'app-reset-production-status-confirmation',
  templateUrl: './reset-production-status-confirmation.component.html',
  styleUrls: ['./reset-production-status-confirmation.component.less']
})
export class ResetProductionStatusConfirmationComponent implements OnInit {

  item: WorkCenterItem;

  constructor(
    public dialogRef: MatDialogRef<WorkCenterComponent>,
    private snackBarService: SnackbarService,
    private workCentersService: WorkCentersService,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: WorkCenterItem
    }) { }

  ngOnInit() {
    this.item = this.data.item;
  }

  async yes() {
    try {
      const result = await this.workCentersService.resetProductionStatus(this.item).toPromise();
      this.snackBarService.showSuccess('Production status was successfully reset.');
      this.dialogRef.close(result);
    } catch (err) {
      console.error('Error resetting production status:', err);
      this.snackBarService.showError('Error resetting production status.');
    }
  }
}
