import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomerLogin } from 'src/app/models/customer-login';
import { ClAddAccountModalComponent } from '../cl-add-account-modal/cl-add-account-modal.component';
import { ClArchivedUnitComponent } from '../cl-archived-unit/cl-archived-unit.component';
import { ClDeleteUserModalComponent } from '../cl-delete-user-modal/cl-delete-user-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClUpdateUserModalComponent } from '../cl-update-user-modal/cl-update-user-modal.component';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-cl-tab-customer-logins',
  templateUrl: './cl-tab-customer-logins.component.html',
  styleUrls: ['./cl-tab-customer-logins.component.less']
})
export class ClTabCustomerLoginsComponent implements OnChanges {

  @Input() customerId:number;
  public _customerInfoService: CustomerService;
  public _customerLogin: CustomerLogin[] = [];
  public loadingCustomerLogins: Boolean = false;
  customerLoginDisplayedColumns: string[] = 
  ['cinchUserId'
  , 'customerId'
  , 'lastLoginDate'];

  constructor(
    customerInfoService: CustomerService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog){
      this._customerInfoService = customerInfoService;
    
  }
  ngOnChanges(changes: SimpleChanges){
    this.customerId = changes.customerId.currentValue;
    this._customerLogin = [];
    this.loadingCustomerLogins = true;
    
    this._customerInfoService.getCustomerLogin(this.customerId).subscribe((customerLog: CustomerLogin[]) => {
      this._customerLogin = customerLog;
      this.loadingCustomerLogins = false;
  },
  err => {
    console.error(err);
    this.loadingCustomerLogins = false;
    this.snackBarService.showError('Error getting Customer Logins.');
  });
  }

  updateUserModal(){
    this.dialog
    .open(ClUpdateUserModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
       
      },
    })
    .afterClosed()
    .subscribe((result: any) => {

    });
  }

  deleteUserModal(){
    this.dialog
    .open(ClDeleteUserModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
       
      },
    })
    .afterClosed()
    .subscribe((result: any) => {

    });
  }

  addAccountModal(){
    this.dialog
    .open(ClAddAccountModalComponent, {
      width: '50%',
      disableClose: false,
      data: {
       
      },
    })
    .afterClosed()
    .subscribe((result: any) => {

    });
  }
}
