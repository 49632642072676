import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Account } from 'src/app/models/account';
import { ReasonCategory } from 'src/app/models/reason-category';
import { AuthService } from 'src/app/services/auth.service';
import { ReasonCodesService } from 'src/app/services/reason-codes.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReasonCode } from '../../../models/reason-code';

@Component({
  selector: 'app-edit-reason-code',
  templateUrl: './edit-reason-code.component.html',
  styleUrls: ['./edit-reason-code.component.less']
})
export class EditReasonCodeComponent implements OnInit, OnDestroy {
  loading = false;
  idKey: any;
  reason: ReasonCode;
  user: Account;
  reasonForm: FormGroup;
  categories: ReasonCategory[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private reasonService: ReasonCodesService,
    private snackService: SnackbarService,
    private fb: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.getReason();
    await this.getReasonCategoriesAsync();
    this.user = this.auth.getAccount();
    this.createReasonForm();
  }

  ngOnDestroy(): void {
    this.clearReason();
  }

  private getReason() {
    this.idKey = this.route.snapshot.paramMap.get('id');
    if (this.idKey !== null) {
      this.reason = JSON.parse(localStorage.getItem(`reason-${this.idKey}`));
    } else {
      this.goBack();
    }
  }

  private clearReason() {
    if (this.idKey !== null) {
      localStorage.removeItem(`reason-${this.idKey}`);
    }
  }

  createReasonForm() {
    this.reasonForm = this.fb.group({
      code: new FormControl(this.reason.code, Validators.required),
      description: new FormControl(this.reason.description, Validators.required),
      isNoCharge: new FormControl(this.reason.isNoCharge, Validators.required),
      isfreeInput: new FormControl(this.reason.isFreeFormInput, Validators.required),
      category: new FormControl(this.categories.find(f => f.categoryId === this.reason.categoryId), Validators.required)
    });
  }

  async getReasonCategoriesAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.reasonService.getReasonCategories().subscribe(
          (response: ReasonCategory[]) => this.categories = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the categories.');
      }
    }).then(() => this.loading = false);
  }

  goBack() {
    this.clearReason();
    this.router.navigate(['reason-codes', { tab: 0 }]);
  }

  async updateResonCode() {
    this.loading = true;
    await new Promise((resolve, reject) => {
      try {
        const category: ReasonCategory = this.reasonForm.get('category').value;
        this.reason.code = this.reasonForm.get('code').value;
        this.reason.description = this.reasonForm.get('description').value;
        this.reason.categoryId = category.categoryId;
        this.reason.category = category.category;
        this.reason.isNoCharge = this.reasonForm.get('isNoCharge').value;
        this.reason.isFreeFormInput = this.reasonForm.get('isfreeInput').value;
        this.reason.modified = new Date(moment().format());
        this.reason.modifiedBy = this.user.userName;
        this.reasonService.updateReasonCode(this.reason).subscribe(
          (response: ReasonCode) => this.reason = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve(this.reason));
      } catch (error) {
        console.error(error);
        reject();
        this.snackService.showError('An error occurred during the save operation.');
      }
    }).then((result: ReasonCode) => {
      result.reasonCodeId && result.reasonCodeId !== 0 ?
      this.snackService.showSuccess('New reason code was updated successfully.') :
      this.snackService.showError('An error occurred during the save operation.');
      this.loading = false;
      this.goBack();
    });
  }
}
