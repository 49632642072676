<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Create Rebate Group
    </h1>
    <hr />
    <div class="container">
        <ul class="errors">
            <li *ngFor="let item of errorMessages">
                {{item}}
            </li>
        </ul>
        <form [formGroup]="createRebateGroupForm" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <input matInput placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="createRebateGroupForm.get('name').hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="Percentage" type="number" formControlName="percentage" required>
                <mat-error *ngIf="createRebateGroupForm.get('percentage').hasError('required')">
                    Percentage is required
                </mat-error>
                <mat-error *ngIf="createRebateGroupForm.get('percentage').hasError('min')">
                    Min value 0 required
                </mat-error>
                <mat-error *ngIf="createRebateGroupForm.get('percentage').hasError('max')">
                    Max value 10 required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Start Period</mat-label>
                <input matInput placeholder="Start Period" [matDatepicker]="picker1" formControlName="startPeriod"
                    required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="createRebateGroupForm.get('startPeriod').hasError('required')">
                    Start Period is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>End Period</mat-label>
                <input matInput placeholder="End Period" [matDatepicker]="picker2" formControlName="endPeriod">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="createRebateGroupForm.get('endPeriod').hasError('min')">
                    End Period must be greater than the Start Period
                </mat-error>
            </mat-form-field>

            <button mat-raised-button color="accent">Create</button>
        </form>
    </div>
</mat-card>