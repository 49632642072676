import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reason-codes',
  templateUrl: './reason-codes.component.html',
  styleUrls: ['./reason-codes.component.less']
})
export class ReasonCodesComponent implements OnInit {
  private tabCount = 2;
  tabIndex = 0;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const tabBack = this.route.snapshot.paramMap.get('tab');
    if (tabBack) {
      this.tabIndex = Number(tabBack);
    }
  }

  onChangeTab() {
    this.tabIndex = (this.tabIndex + 1) % this.tabCount;
  }

}
