import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ProductionLocation } from '../models/production-location';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductionLocationsService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getProductionLocations(): Observable<ProductionLocation[]> {
    const url = this.config.buildURL(`productionLocations`);
    return this.http.get<ProductionLocation[]>(url);
  }

  getActiveProductionLocations(): Observable<ProductionLocation[]> {
    const url = this.config.buildURL(`productionLocations/actives`);
    return this.http.get<ProductionLocation[]>(url);
  }

  getProductionLocation(id: string): Observable<ProductionLocation> {
    const url = this.config.buildURL(`productionLocations/${id}`);
    return this.http.get<ProductionLocation>(url);
  }

  createProductionLocation(model: ProductionLocation): Observable<ProductionLocation> {
    const url = this.config.buildURL(`productionLocations`);
    return this.http.post<ProductionLocation>(url, model);
  }

  updateProductionLocation(model: ProductionLocation): Observable<ProductionLocation> {
    const url = this.config.buildURL(`productionLocations/${model.id}`);
    return this.http.put<ProductionLocation>(url, model);
  }

  deleteProductionLocation(id: string) {
    const url = this.config.buildURL(`productionLocations/${id}`);
    return this.http.delete(url);
  }
}
