import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { NotificationOrder } from 'src/app/models/notification-order';
import { OrderNotificationService } from '../../../services/order-notification.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DetailReadingNotificationComponent } from '../detail-reading-notification/detail-reading-notification.component';
import * as moment from 'moment';

@Component({
  selector: 'app-order-notification',
  templateUrl: './order-notification.component.html',
  styleUrls: ['./order-notification.component.less']
})
export class OrderNotificationComponent implements OnInit {
  loading = false;
  notificationFilterApply = false;

  @ViewChild(MatTable) tblNotifications: MatTable<NotificationOrder>;
  notificationDataSource = new MatTableDataSource<NotificationOrder>();
  filteredNotificationDataSource = new MatTableDataSource<NotificationOrder>();
  displayedColumns: string[] = [
    'owner',
    'ownerName',
    'createdBy',
    'createdDate',
    'actions'
  ];

  formFiltersNotification: UntypedFormGroup;

  constructor(
    private orderNotificationService: OrderNotificationService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder) {
    this.createFiltersForm();
  }

  async ngOnInit(): Promise<void> {
    await this.getNotificationOrdersAsync();
  }

  private createFiltersForm() {
    this.formFiltersNotification = this.fb.group({
      customerNumber: new UntypedFormControl(''),
      customerName: new UntypedFormControl(''),
      createdBy: new UntypedFormControl(''),
      createdDate: new UntypedFormControl('')
    });
  }

  private filterByCustomerNumber(): NotificationOrder[] {
    return this.filteredNotificationDataSource.data.filter((r) => {
      if (r.owner !== null) {
        return r.owner
          .toLowerCase()
          .includes(this.formFiltersNotification.get('customerNumber').value.trim().toLowerCase());
      }
    });
  }

  private filterByCustomerName(): NotificationOrder[] {
    return this.filteredNotificationDataSource.data.filter((r) => {
      if (r.owner !== null) {
        return r.ownerName
          .toLowerCase()
          .includes(this.formFiltersNotification.get('customerName').value.trim().toLowerCase());
      }
    });
  }

  private filterByCreatedBy(): NotificationOrder[] {
    return this.filteredNotificationDataSource.data.filter((r) => {
      if (r.createdBy !== null) {
        return r.createdBy
          .toLowerCase()
          .includes(this.formFiltersNotification.get('createdBy').value.trim().toLowerCase());
      }
    });
  }

  private filterByCreatedDate(): NotificationOrder[] {
    return this.filteredNotificationDataSource.data.filter(
      (r) => moment(r.createdDate).format('YYYY-MM-DD') === moment(this.formFiltersNotification.get('createdDate').value).format('YYYY-MM-DD')
    );
  }

  applyFilters(): void {
    this.filteredNotificationDataSource.data = this.notificationDataSource.data;

    const iptNumber = this.formFiltersNotification.get('customerNumber');
    const iptName = this.formFiltersNotification.get('customerName');
    const iptCreate = this.formFiltersNotification.get('createdBy');
    const iptDate = this.formFiltersNotification.get('createdDate');

    if (iptNumber.valid && iptNumber.value) {
      this.filteredNotificationDataSource.data = this.filterByCustomerNumber();
      this.notificationFilterApply = true;
    }

    if (iptName.valid && iptName.value) {
      this.filteredNotificationDataSource.data = this.filterByCustomerName();
      this.notificationFilterApply = true;
    }

    if (iptCreate.valid && iptCreate.value) {
      this.filteredNotificationDataSource.data = this.filterByCreatedBy();
      this.notificationFilterApply = true;
    }

    if (iptDate.valid && iptDate.value) {
      this.filteredNotificationDataSource.data = this.filterByCreatedDate();
      this.notificationFilterApply = true;
    }


  }

  clearFilters(): void {
    this.formFiltersNotification.setValue({
      customerNumber: '',
      customerName: '',
      createdBy: '',
      createdDate: ''
    });
    this.notificationFilterApply = false;
  }

  async getNotificationOrdersAsync(): Promise<void> {
    this.loading = true;
    await new Promise<void>((resolve, reject) =>  {
      try {
        this.orderNotificationService.getNotificationOrders().subscribe(
          (notificationOrders: NotificationOrder[]) => this.notificationDataSource.data = notificationOrders,
          (err) => console.error(err),
          () => resolve());
      } catch (err) {
        console.error(err);
        this.loading = false;
        reject();
      }
    }).then(() => this.loading = false);
  }

  getNotificationDataSource(): MatTableDataSource<NotificationOrder> {
    return this.notificationFilterApply ? this.filteredNotificationDataSource : this.notificationDataSource;
  }

  getData(): NotificationOrder[] {
    return this.notificationFilterApply ? this.filteredNotificationDataSource.connect().value : this.notificationDataSource.connect().value;
  }

  onDateControlChange() {
    if (!this.formFiltersNotification.get('createdDate').valid) {
      this.snackbarService.showWarning('Invalid format created date.');
      this.formFiltersNotification.get('createdDate').setValue('');
    }
  }

  openedReadingDetail(notification: NotificationOrder) {
    this.dialog.open(DetailReadingNotificationComponent, {
      width: '40%',
      disableClose: true,
      data: notification
    });
  }

  async disableOrderNotification(notification: NotificationOrder): Promise<void> {
    await new Promise<boolean>((resolve, reject) => {
      let successDisable = false;
      try {
        this.orderNotificationService.disableNotification(notification).subscribe(
          (isDisable: boolean) => successDisable = isDisable,
          err => console.error(err),
          () => resolve(successDisable));
      } catch (err) {
        console.error(err);
        reject();
      }
    }).then((success: boolean) => {
      if (success) {
        this.snackbarService.showSuccess('Alert delete is success');
        const idx = this.notificationDataSource.data.findIndex(fi => fi.notificationId === notification.notificationId);
        if (idx !== -1) {
          this.notificationDataSource.data.splice(idx, 1);
          this.tblNotifications.renderRows();
          if (this.notificationFilterApply) {
            this.applyFilters();
          }
        }
      } else {
        this.snackbarService.showSuccess('An error occurred during the delete operation.');
      }
    });
  }
}

