<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
          </button>
        <mat-card-header>
            <h1 class="mat-h1">
                Disable an existing user
            </h1>
        </mat-card-header>
        <mat-card-content>
            <div class="container">
                <ng-container>
                    <form [formGroup]="deleteUserForm" (ngSubmit)="submit()">
                        <mat-form-field class="full-width">
                            <input matInput 
                                placeholder="Email" 
                                formControlName="email" 
                                [(ngModel)]="user['userEmail']" required>
                            <mat-error *ngIf="deleteUserForm.get('email').hasError('required')">
                                Email is required
                            </mat-error>
                        </mat-form-field>
                        <mat-card-actions>
                            <button mat-raised-button color="accent" class="btn-modal" type="submit" [disabled]="!deleteUserForm.valid"> Save </button>
                            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">Cancel</button>
                        </mat-card-actions>
                    </form>
                </ng-container>
            </div>
        </mat-card-content>
        <mat-card-footer>

        </mat-card-footer>
    </mat-card>
</mat-dialog-content>
    
