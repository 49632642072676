import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodeType, PromoCode } from '../models/promo-code';
import { PromoCodeCriteriaType } from '../models/promo-code-criteria-type';
import { PromoCodePositiveWord } from '../models/promo-code-positive-word';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PromoCodesService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getWebsites(): string[] {
    return ['CinchKit', 'KitchenMakeover'];
  }

  getPromoCodes(): Observable<PromoCode[]> {
    const url = this.config.buildURL(`promoCodes`);
    return this.http.get<PromoCode[]>(url);
  }

  getPromoCode(promoCodeId: number): Observable<PromoCode> {
    const url = this.config.buildURL(`promoCodes/${promoCodeId}`);
    return this.http.get<PromoCode>(url);
  }

  getCodeTypes(): Observable<CodeType[]> {
    const url = this.config.buildURL(`promoCodes/getCodeTypes`);
    return this.http.get<CodeType[]>(url);
  }

  getCriteriaTypes(): Observable<PromoCodeCriteriaType[]> {
    const url = this.config.buildURL(`promoCodes/getCriteriaTypes`);
    return this.http.get<PromoCodeCriteriaType[]>(url);
  }

  getPositiveWords(): Observable<PromoCodePositiveWord[]> {
    const url = this.config.buildURL(`promoCodes/getPositiveWords`);
    return this.http.get<PromoCodePositiveWord[]>(url);
  }

  createPromoCode(model: PromoCode): Observable<PromoCode> {
    const url = this.config.buildURL(`promoCodes`);
    return this.http.post<PromoCode>(url, model);
  }

  updatePromoCode(model: PromoCode): Observable<PromoCode> {
    const url = this.config.buildURL(`promoCodes/${model.id}`);
    return this.http.put<PromoCode>(url, model);
  }

  deletePromoCode(promoCodeId: number) {
    const url = this.config.buildURL(`promoCodes/${promoCodeId}`);
    return this.http.delete(url);
  }
}
