<h2 mat-dialog-title class="center">Palletize Information</h2>
<mat-dialog-content class="mat-typography">
    <div>
        <div class="section">
            <h3 class="center">Shipping Address</h3>
            <div class="left">
                <div>
                    <span class="bold">Ship To:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressName }}</label>
                </div>
                <div>
                    <span class="bold">Address Line 1:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressLine1 }}</label>
                </div>
                <div>
                    <span class="bold">Address Line 2:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressLine2 }}</label>
                </div>
                <div>
                    <span class="bold">Zipcode:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressZipCode }}</label>
                </div>
            </div>
            <div class="right">
                <div>
                    <span class="bold">City:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressCity }}</label>
                </div>
                <div>
                    <span class="bold">State:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressStateId }}</label>
                </div>
                <div>
                    <span class="bold">Phone Number:</span>
                    &nbsp;
                    <label>{{ data.shippingAddressPhone }}</label>
                </div>
                <div>
                    <span class="bold">Special Shipping Instruction or Notes:</span>
                    &nbsp;
                    <label>{{ data.shippingInstructions }}</label>
                </div>
            </div>
        </div>
        <div class="section" *ngIf="data.contactList[0]">
            <h3 class="center">Contacts Information</h3>
            <div class="left">
                <p class="bold">For delivery at install location</p>
                <div>{{ data.contactList[0].fullName }}</div>
                <div>{{ data.contactList[0].phoneNumber }}</div>
            </div>
            <div class="right">
                <div>{{ data.contactList[0].emailAddres }}</div>
            </div>
        </div>
        <div class="section" *ngIf="data.contactList[1]">
            <p class="bold">Authorization to make delivery changes</p>
            <div class="left">
                <div>{{ data.contactList[1].fullName }}</div>
                <div>{{ data.contactList[1].phoneNumber }}</div>
                <div>{{ data.entranceCode }}</div>
            </div>
            <div class="right">
                <div>{{ data.contactList[1].emailAddres }}</div>
                <div class="input-container">
                    <span class="bold">Delivery date:</span><span> {{ data.deliveryDate | date }}</span>
                </div>
            </div>
        </div>
        <div class="section-question">
            <div>
                <div class="question">
                    <span class="bold">Will you require a liftgate at the time of delivery?</span>
                </div>
                <div class="response">
                    <span>{{ data.isLiftgateRequired ? 'Yes' : 'No' }}</span>
                </div>
            </div>
            <div>
                <div class="question">
                    <span class="bold">At the time of delivery, will there be access to the dock and can it be accessed
                        with a 52 ft. trailer?</span>
                </div>
                <div class="response">
                    <span>{{ data.isLimitedAccessToAddress ? 'Yes' : 'No' }}</span>
                </div>
            </div>
            <div>
                <div class="question">
                    <span class="bold">Can the delivery arrive early if kits are finished ahead of schedule?</span>
                </div>
                <div class="response">
                    <span>{{ data.canDeliverEarly ? 'Yes' : 'No' }}</span>
                </div>
            </div>
        </div>
        <div class="section no-margin">
            <h3 class="center">Palletization Type</h3>
            <div>
                <span>{{ data.palletizeOption }}</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>