import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RebateGroup } from '../../models/rebate-group';
import { Customer } from '../../models/customer';
import { FormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { DOCUMENT } from '@angular/common';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { RebateGroupAssignmentsDetailsComponent } from './rebate-group-assignments-details/rebate-group-assignments-details.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditRebateGroupAssignmentsComponent } from './edit-rebate-group-assignments/edit-rebate-group-assignments.component';
import { AccountingService } from '../../services/accounting.service';
import { SnackbarService } from '../../services/snackbar.service';
import { RebateReportsComponent } from './rebate-reports/rebate-reports.component';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.less']
})
export class AccountingComponent implements OnInit, AfterViewInit {

  loading = false;
  rebateGroupFilter = new FormControl();
  activeRebateGroupFilter = false;
  noActiveRebateGroupFilter = false;

  customerFilter = new FormControl();
  customerNumberFilter = new FormControl();
  companyFilter = new FormControl();
  customerRebateGroupFilter = new FormControl();
  rebateGroupsFiltersApplied = false;
  customersFiltersApplied = false;

  rebateGroupsDataSource = new MatTableDataSource<RebateGroup>();
  filteredRebateGroupsDataSource = new MatTableDataSource<RebateGroup>();
  customersDataSource = new MatTableDataSource<Customer>();
  filteredCustomersDataSource = new MatTableDataSource<Customer>();

  displayedColumns: string[] = ['id', 'name', 'percentage', 'startPeriod', 'endPeriod', 'dateModified', 'isActive', 'assignments', 'actions'];
  customersDisplayedColumns: string[] = ['id', 'name', 'customerNumber', 'company', 'rebateGroup', 'percentage', 'communities', 'actions'];

  rebateGroupsSort: MatSort;
  customersSort: MatSort;

  customers: Customer[] = [];

  @ViewChild('rebateGroupsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.rebateGroupsSort = ms;
    this.ngAfterViewInit();
  }

  @ViewChild('customersSort', { read: MatSort }) set matSortBySONumber(ms: MatSort) {
    this.customersSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackbarService,
    private accountingService: AccountingService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.getRebateGroups();
    this.getCustomers();
  }

  ngAfterViewInit() {
    // sort options
    this.rebateGroupsDataSource.sort = this.rebateGroupsSort;
    this.filteredRebateGroupsDataSource.sort = this.rebateGroupsSort;
    this.customersDataSource.sort = this.customersSort;
    this.filteredCustomersDataSource.sort = this.customersSort;
  }

  getRebateGroups(): void {
    this.accountingService.getRebateGroups().subscribe((rebateGroups: RebateGroup[]) => {
      this.rebateGroupsDataSource.data = rebateGroups;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting rebate groups.');
      }
    );
  }

  getCustomers(): void {
    this.showLoading();
    this.accountingService.getCustomers().subscribe((customers: Customer[]) => {
      this.customersDataSource.data = customers;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting customers.');
        this.hideLoading();
      }
      , () => {
        this.hideLoading();
      });
  }

  getRebateGroupsData(): RebateGroup[] {
    return this.getRebateGroupsDataSource().connect().value;
  }

  getCustomersData(): Customer[] {
    return this.getCustomersDataSource().connect().value;
  }

  getRebateGroupsDataSource(): MatTableDataSource<RebateGroup> {
    return this.rebateGroupsFiltersApplied ? this.filteredRebateGroupsDataSource : this.rebateGroupsDataSource;
  }

  getCustomersDataSource(): MatTableDataSource<Customer> {
    return this.customersFiltersApplied ? this.filteredCustomersDataSource : this.customersDataSource;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  applyRebateGroupsFilters(): void {

    this.filteredRebateGroupsDataSource.data = this.rebateGroupsDataSource.data;

    if (this.rebateGroupFilter.valid && this.rebateGroupFilter.value) {
      this.filteredRebateGroupsDataSource.data = this.filterByRebateGroup();
      this.rebateGroupsFiltersApplied = true;
    }
    if (this.activeRebateGroupFilter) {
      this.filteredRebateGroupsDataSource.data = this.filterByActiveRebateGroup();
      this.rebateGroupsFiltersApplied = true;
    }
    if (this.noActiveRebateGroupFilter) {
      this.filteredRebateGroupsDataSource.data = this.filterByNoActiveRebateGroup();
      this.rebateGroupsFiltersApplied = true;
    }
  }

  applyCustomersFilters(): void {

    this.filteredCustomersDataSource.data = this.customersDataSource.data;

    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCustomer();
      this.customersFiltersApplied = true;
    }
    if (this.customerNumberFilter.valid && this.customerNumberFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCustomerNumber();
      this.customersFiltersApplied = true;
    }
    if (this.companyFilter.valid && this.companyFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCompany();
      this.customersFiltersApplied = true;
    }
    if (this.customerRebateGroupFilter.valid && this.customerRebateGroupFilter.value) {
      this.filteredCustomersDataSource.data = this.filterByCustomerRebateGroup();
      this.customersFiltersApplied = true;
    }
  }

  filterByRebateGroup(): RebateGroup[] {
    return this.filteredRebateGroupsDataSource.data.filter(r => r.name.toLowerCase().includes(this.rebateGroupFilter.value.trim().toLowerCase()));
  }

  filterByActiveRebateGroup(): RebateGroup[] {
    return this.filteredRebateGroupsDataSource.data.filter(r => r.isActive);
  }

  filterByNoActiveRebateGroup(): RebateGroup[] {
    return this.filteredRebateGroupsDataSource.data.filter(r => !r.isActive);
  }

  filterByCustomer(): Customer[] {
    return this.filteredCustomersDataSource.data.filter(r => r.name.toLowerCase().includes(this.customerFilter.value.trim().toLowerCase()));
  }

  filterByCustomerNumber(): Customer[] {
    return this.filteredCustomersDataSource.data.filter(r => r.customerNumber.toLowerCase().includes(this.customerNumberFilter.value.trim().toLowerCase()));
  }

  filterByCompany(): Customer[] {
    return this.filteredCustomersDataSource.data.filter(r => r.company?.toLowerCase().includes(this.companyFilter.value.trim().toLowerCase()));
  }

  filterByCustomerRebateGroup(): Customer[] {
    return this.filteredCustomersDataSource.data.filter(c => c.rebateGroup?.toLowerCase().includes(this.customerRebateGroupFilter.value.trim().toLowerCase()));
  }

  onActiveRebateGroupFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noActiveRebateGroupFilter) {
      this.noActiveRebateGroupFilter = false;
    }
  }

  onNoActiveRebateGroupFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.activeRebateGroupFilter) {
      this.activeRebateGroupFilter = false;
    }
  }

  findCustomer(customerId: number): Customer {
    return this.customersDataSource.data.find(c => c.id === customerId);
  }

  openRebateGroupAssignments(customerId: number): void {
    const customer = this.findCustomer(customerId);
    const rebateGroups = this.rebateGroupsDataSource.data;
    if (customer) {
      this.dialog.open(RebateGroupAssignmentsDetailsComponent, {
        width: '1024px',
        data: {
          customer,
          rebateGroups
        }
      });
    }
  }

  openEditRebateGroupAssignments(customerId: number): void {
    const customer = this.findCustomer(customerId);
    const rebateGroups = this.rebateGroupsDataSource.data;
    if (customer) {
      const dialogRef = this.dialog.open(EditRebateGroupAssignmentsComponent, {
        width: '1024px',
        data: {
          customer,
          rebateGroups
        }
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.getRebateGroups();
          this.getCustomers();
        }
      });

    }
  }

  openRebateReports(): void {
    const rebateGroups = this.rebateGroupsDataSource.data;
    const dialogRef = this.dialog.open(RebateReportsComponent, {
      width: '680px',
      data: {
        rebateGroups
      }
    });
  }

  clearRebateGroupsFilters(): void {
    this.rebateGroupFilter.setValue('');
    this.activeRebateGroupFilter = false;
    this.noActiveRebateGroupFilter = false;
    this.rebateGroupsFiltersApplied = false;
  }

  clearCustomersFilters(): void {
    this.customerFilter.setValue('');
    this.customerNumberFilter.setValue('');
    this.companyFilter.setValue('');
    this.customerRebateGroupFilter.setValue('');
    this.customersFiltersApplied = false;
  }
}
