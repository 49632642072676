<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
          </button>
          <mat-card-header >
                <h1>Quote Details</h1>
        </mat-card-header>
        <mat-card-content>
            <mat-grid-list cols="2" rowHeight="3:1">
                <mat-grid-tile style="left:-65px; top:-90px; height: 100%;">
                    <div>
                        <input disabled placeholder="Project Name:" class="green-label">
                        <input disabled [(ngModel)]="details.customerNumber" class="gray-label"> <br>
                        <input disabled placeholder="Address:" class="green-label">
                        <input disabled [(ngModel)]="details.data.address" class="gray-label"> <br>
                        <input disabled placeholder="City, State, Zip:" class="green-label">
                        <input disabled [(ngModel)]="details.data.cityStateZip" class="gray-label"> <br>
                        <input disabled placeholder="Rep Name:" class="green-label" >
                        <input disabled [(ngModel)]="details.data.repName" class="gray-label"> <br>
                        <input disabled placeholder="Rep Email:" class="green-label" >
                        <input disabled [(ngModel)]="details.data.repEmail" class="gray-label"> <br>
                        <input disabled placeholder="Account Number:" class="green-label">
                        <input disabled [(ngModel)]="details.data.accountNumber" class="gray-label"> <br>
                        <input disabled placeholder="Unit #:" class="green-label">
                        <input disabled [(ngModel)]="details.data.unitNumber" class="gray-label"> <br>
                        <input disabled placeholder="Unit Type:" class="green-label">
                        <input disabled [(ngModel)]="details.data.unitType" class="gray-label"> <br>
                        <input disabled placeholder="Rep Phone:" class="green-label">
                        <input disabled [(ngModel)]="details.data.repPhoneNumber" class="gray-label"> <br>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="left:-65px; top:-90px; height: 100%;">
                    <div>
                        <input disabled placeholder="Style:" class="green-label">
                        <input disabled [(ngModel)]="details.data.pullStyleId" class="gray-label"> <br>
                        <input disabled placeholder="Hinge type:" class="green-label">
                        <input disabled [(ngModel)]="details.data.hingeType" class="gray-label"> <br>
                        <input disabled placeholder="Pull color:" class="green-label">
                        <input disabled [(ngModel)]="details.data.pullColor" class="gray-label"> <br>
                        <input disabled placeholder="Pull size:" class="green-label">
                        <input disabled [(ngModel)]="details.data.pullSize" class="gray-label"><br>
                        <input disabled placeholder="Color:" class="green-label">
                        <input disabled [(ngModel)]="details.data.color" class="gray-label"><br>
                        <input disabled placeholder="Paint:" class="green-label">
                        <input disabled [(ngModel)]="details.data.hasPaint" class="gray-label"><br>
                        <input disabled placeholder="Pull Style:" class="green-label">
                        <input disabled [(ngModel)]="details.data.pullStyle" class="gray-label"><br>
                        <input disabled placeholder="Cabinet Fronts:" class="green-label">
                        <input disabled [(ngModel)]="details.data.cabinetFronts" class="gray-label"><br>
                        <input disabled placeholder="Drawer Fronts:" class="green-label">
                        <input disabled [(ngModel)]="details.data.drawerFronts" class="gray-label"><br>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="left:-65px; top:65px; width: 100%;">
                    <div>
                        <br>
                        <input disabled placeholder="Total Cost:" class="green-label">
                        <input disabled [(ngModel)]="details.data.totalCost" class="gray-label"><br>
                        <input disabled placeholder="Margin:" class="green-label">
                        <input disabled [(ngModel)]="details.data.profitMarginPercentage" class="gray-label"><br>
                        <input disabled placeholder="Discount:" class="green-label">
                        <input disabled [(ngModel)]="details.data.discountPercentage" class="gray-label"><br>
                        <input disabled placeholder="Selling Price w/o Taxes:" class="green-label">
                        <input disabled [(ngModel)]="details.data.sellingPrice" class="gray-label"><br>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="left:-65px; top: 50px;">
                    <div>
                        <br>
                        <input disabled placeholder="Notes:" class="green-label">
                        <input disabled [(ngModel)]="details.data.notes" class="gray-label"> <br>
                    </div>
                </mat-grid-tile>
              </mat-grid-list>
                      
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">Close</button>
        </mat-card-actions>
    </mat-card>

</mat-dialog-content>