<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
            <button mat-mini-fab matTooltip="Back to notifications" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
    </div>
</div>
<mat-card>
    <mat-card-content>
        <h1 class="mat-h1">
            Edit Reson Code
        </h1>
        <hr />
        <ng-container *ngIf="loading; else formTemplate">
            <div class="container-loading">
                <div class="loading">
                    <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
        </ng-container>
        <ng-template #formTemplate>
            <div class="container">
                <form [formGroup]="reasonForm" (ngSubmit)="updateResonCode()">
                    <mat-form-field class="full-width">
                        <mat-label>Reason Code</mat-label>
                        <input type="text" placeholder="Reason Code" matInput formControlName="code" maxlength="50" minlength="5">
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Description</mat-label>
                        <textarea matInput #message minlength="5" maxlength="250" placeholder="Description" formControlName="description" rows="5"></textarea>
                        <mat-hint align="end">{{message.value.length}} / 250</mat-hint>
                    </mat-form-field>
                    <div class="mat-form-field full-width">
                        <mat-slide-toggle name="noCharge" formControlName="isNoCharge">Appy for no charge</mat-slide-toggle>
                    </div>
                    <div class="mat-form-field full-width">
                        <mat-slide-toggle name="freeInput" formControlName="isfreeInput">Appy for free form input</mat-slide-toggle>
                    </div>
                    <mat-form-field class="full-width" *ngIf="categories && categories.length > 0">
                        <mat-label>Category</mat-label>
                        <mat-select #categorySelect formControlName="category">
                            <mat-option>- Pick Category -</mat-option>
                            <mat-option *ngFor="let category of categories" [value]="category">
                                {{ category?.category }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="accent" [disabled]="loading || !reasonForm.valid">Updated</button>
                </form>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>