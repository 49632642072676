import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PromoCodesService } from '../../../services/promo-codes.service';
import { CodeType, PromoCode } from '../../../models/promo-code';
import { CriteriaType, PromoCodeCriteriaType } from '../../../models/promo-code-criteria-type';
import { SnackbarService } from '../../../services/snackbar.service';
import { PromoCodeCriteria } from '../../../models/promo-code-criteria';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-create-promo-code',
  templateUrl: './create-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.less']
})
export class CreatePromoCodeComponent implements OnInit {

  loading = false;
  websites: string[] = [];
  promoCodeTypes: CodeType[] = [];
  promoCodeCriteriaTypes: PromoCodeCriteriaType[] = [];
  positiveWords: string[] = [];
  filteredPositiveWords: Observable<string[]>;
  promoCodesForm: FormGroup;
  promoCodesCriteriaForm: FormGroup;
  promoCodesCriteriaFormItems: FormArray;
  minAmount = 1;
  maxAmount = 9999;
  maxNumberOfPromoCodes = 36000;
  maxNumberOfRandomPromoCodes = 999999;
  noChargePartsCodeType: CodeType = CodeType.NoChargeParts;
  amountMask: any = {
    mask: Number,
    min: this.minAmount,
    max: this.maxAmount,
    radix: '.',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private promoCodesService: PromoCodesService,
    private snackBarService: SnackbarService
  ) {
    this.createPromoCodesForm();
    this.createPromoCodesCriteriaForm();
  }

  async ngOnInit(): Promise<void> {
    await this.getPositiveWords();
    this.getFilteredPositiveWords();
    this.getWebsites();
    this.getPromoCodeTypes();
    this.getPromoCodeCriteriaTypes();
  }

  createPromoCodesForm() {
    this.promoCodesForm = this.fb.group({
      random: [false],
      positiveWord: ['', Validators.required],
      type: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(this.minAmount), Validators.max(this.maxAmount)]],
      maxDiscountAmount: ['', [Validators.min(this.minAmount), Validators.max(this.maxAmount)]],
      startDate: ['', Validators.required],
      endDate: [''],
      allowedUses: ['', [Validators.required, Validators.min(1), Validators.max(99999)]],
      numberOfUsesPerCustomer: ['', [Validators.min(1), Validators.max(99999)]],
      numberOfPromoCodes: ['', [Validators.required, Validators.min(1), Validators.max(this.maxNumberOfPromoCodes)]],
      websites: ['', Validators.required]
    });
  }

  createPromoCodesCriteriaForm(): void {
    const promoCodesCriteriaFormItems: FormGroup[] = [];
    this.promoCodesCriteriaForm = this.fb.group({
      formGroups: this.fb.array(promoCodesCriteriaFormItems)
    });
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
  }

  createPromoCodesCriteriaFormItem(): FormGroup {
    return this.fb.group({
      type: ['', [Validators.required]],
      value: [''],
    });
  }

  setPromoCodesFormValidators() {
    const random = this.promoCodesForm.get('random').value;

    if (random) {
      this.promoCodesForm.get('positiveWord').clearValidators();
      this.promoCodesForm.get('numberOfPromoCodes').setValidators(
        [
          Validators.required,
          Validators.min(1),
          Validators.max(this.maxNumberOfRandomPromoCodes)
        ]);
    } else {
      this.promoCodesForm.get('positiveWord').setValidators([Validators.required]);
      this.promoCodesForm.get('numberOfPromoCodes').setValidators(
        [
          Validators.required,
          Validators.min(1),
          Validators.max(this.maxNumberOfPromoCodes)
        ]);
    }

    const type = this.promoCodesForm.get('type').value as CodeType;

    if (type !== CodeType.NoChargeParts) {
      this.promoCodesForm.get('amount').setValidators(
        [
          Validators.required,
          Validators.min(this.minAmount),
          Validators.max(this.maxAmount)
        ]);
    } else {
      this.promoCodesForm.get('amount').clearValidators();
    }

    this.promoCodesForm.get('amount').updateValueAndValidity();
    this.promoCodesForm.get('positiveWord').updateValueAndValidity();
    this.promoCodesForm.get('numberOfPromoCodes').updateValueAndValidity();
  }

  addCriteria(): void {
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
    this.promoCodesCriteriaFormItems.push(this.createPromoCodesCriteriaFormItem());
  }

  removeCriteria(index: number) {
    this.promoCodesCriteriaFormItems = this.promoCodesCriteriaForm.get('formGroups') as FormArray;
    this.promoCodesCriteriaFormItems.removeAt(index);
  }

  getFilteredPositiveWords(): void {
    this.filteredPositiveWords = this.promoCodesForm.get('positiveWord').valueChanges.pipe(
      startWith(''),
      map(value => this.filterPositiveWords(value))
    );
  }

  filterPositiveWords(value: string): string[] {
    return this.positiveWords.filter(w => w.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  getWebsites(): void {
    this.websites = this.promoCodesService.getWebsites();
  }

  async getPositiveWords(): Promise<void> {
    try {
      const positiveWords = await this.promoCodesService.getPositiveWords().toPromise();
      this.positiveWords = positiveWords.map(p => p.word.toUpperCase());
    } catch (err) {
      console.error('Error getting positive words:', err);
      this.snackBarService.showError('Error getting positive words.');
    }
  }
  getPromoCodeTypes(): void {
    this.promoCodesService.getCodeTypes().subscribe((codeTypes: CodeType[]) => {
      this.promoCodeTypes = codeTypes;
    }, err => {
      console.error('Error getting code types:', err);
      this.snackBarService.showError('Error getting code types.');
    });
  }

  getPromoCodeCriteriaTypes(): void {
    this.promoCodesService.getCriteriaTypes().subscribe((promoCodeCriteriaTypes: PromoCodeCriteriaType[]) => {
      this.promoCodeCriteriaTypes = promoCodeCriteriaTypes;
    }, err => {
      console.error('Error getting promo code criteria types:', err);
      this.snackBarService.showError('Error getting promo code criteria types.');
    });
  }

  format(word: string, delimiter: string = ' '): string {
    let result = '';
    if (word) {
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        const prevChar = word[i - 1];
        const tokenDelimiter = !isNaN(+char) && !isNaN(+prevChar) ? '' : delimiter;
        result += char === char.toUpperCase() ? `${tokenDelimiter}${char}` : char;
      }
    }
    return result.trim();
  }

  formatCodeType(type: CodeType): string {
    return this.format(CodeType[type]) || 'Unknown';
  }

  formatCriteriaType(type: CriteriaType): string {
    return this.format(CriteriaType[type]) || 'Unknown';
  }

  formatDate(date: Date): string {
    return date ? moment(date).format('l') : '';
  }

  isValid(): boolean {
    this.promoCodesCriteriaFormItems.markAllAsTouched();
    this.promoCodesCriteriaFormItems.updateValueAndValidity();
    this.promoCodesForm.updateValueAndValidity();

    const startDate = this.promoCodesForm.get('startDate').value;
    const endDate = this.promoCodesForm.get('endDate').value;
    const allowedUses = this.promoCodesForm.get('allowedUses').value;
    const numberOfUsesPerCustomer = this.promoCodesForm.get('numberOfUsesPerCustomer').value;

    if (startDate && endDate && moment(startDate).isAfter(endDate)) {
      this.promoCodesForm.get('endDate').setErrors({ invalid: true });
    }

    if (numberOfUsesPerCustomer && numberOfUsesPerCustomer > allowedUses) {
      this.promoCodesForm.get('numberOfUsesPerCustomer').setErrors({ max: true });
    }

    const formArray = this.promoCodesCriteriaFormItems as FormArray;

    for (const formGroup of formArray.controls) {
      const value = formGroup.get('value').value as string;
      const type = formGroup.get('type').value as PromoCodeCriteriaType;

      if (type && type.requiresValue && !value.trim()) {
        formGroup.get('value').setErrors({ required: true });
      }
    }

    return this.promoCodesForm.valid && this.promoCodesCriteriaFormItems.valid;
  }

  getData(): PromoCode {
    const type = this.promoCodesForm.get('type').value as CodeType;
    const amount = type === CodeType.NoChargeParts ? 0 : this.promoCodesForm.get('amount').value;
    const maxDiscountAmount = type === CodeType.NoChargeParts ? 0 : this.promoCodesForm.get('maxDiscountAmount').value;

    const promoCode: PromoCode = {
      isRandom: this.promoCodesForm.get('random').value,
      positiveWord: this.promoCodesForm.get('positiveWord').value,
      type,
      amount,
      maxDiscountAmount,
      startDate: this.promoCodesForm.get('startDate').value,
      endDate: this.promoCodesForm.get('endDate').value,
      numberOfAllowedUses: this.promoCodesForm.get('allowedUses').value,
      numberOfUsesPerCustomer: this.promoCodesForm.get('numberOfUsesPerCustomer').value,
      numberOfPromoCodes: this.promoCodesForm.get('numberOfPromoCodes').value,
      websites: this.promoCodesForm.get('websites').value,
      criteria: []
    };

    const formArray = this.promoCodesCriteriaFormItems as FormArray;

    for (const formGroup of formArray.controls) {
      const value = formGroup.get('value').value as string;
      const criteriaType = formGroup.get('type').value as PromoCodeCriteriaType;
      const codeCriteria: PromoCodeCriteria = {
        value,
        promoCodeCriteriaTypeId: criteriaType?.id
      };
      promoCode.criteria.push(codeCriteria);
    }

    return promoCode;
  }

  submit(): void {
    if (this.isValid()) {
      this.createPromoCode();
    }
  }

  createPromoCode(): void {
    this.loading = true;
    const model = this.getData();

    this.promoCodesService.createPromoCode(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/promo-codes']);
      this.snackBarService.showSuccess('The promo codes was created successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error creating promo codes.');
    });
  }

}
