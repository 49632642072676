<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Delete Router
    </h1>
    <hr />
    <div *ngIf="routerDTO" class="content">
        <ul>
            <li><strong>Id:</strong> {{routerDTO?.id}}</li>
            <li><strong>Name:</strong> {{routerDTO?.name}}</li>
            <li><strong>Production Location:</strong> {{routerDTO?.productionLocationStr}}</li>
            <li><strong>IP Address:</strong> {{routerDTO?.ipAddress}}</li>
            <li><strong>Is Active:</strong> {{routerDTO?.isActive | active}}</li>
            <li><strong>Date Created:</strong> {{routerDTO?.dateCreated | date}}</li>
            <li><strong>Created By:</strong> {{routerDTO?.createdByUserId}}</li>
            <li><strong>Date Modified:</strong> {{routerDTO?.dateModified | date}}</li>
            <li><strong>Modified By:</strong> {{routerDTO?.modifiedByUserId}}</li>
        </ul>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="deleteRouter()">Delete</button>
    </div>
</mat-card>