import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Quote, QuoteDetails } from 'src/app/models/quote';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';

@Component({
  selector: 'app-qwikquote-search',
  templateUrl: './qwikquote-search.component.html',
  styleUrls: ['./qwikquote-search.component.less']
})
export class QwikquoteSearchComponent implements OnChanges, AfterViewInit{

  @Input() customerNumber:string;
  public quotesData: Quote[] = [];
  public customerInfoService: CustomerService;
  public data = {} as Quote;
  public quoteDetails = {} as QuoteDetails ;
  public loadingQuoteHistory: boolean = false;
  formControl: any;
  private dataSource = new MatTableDataSource<Quote>();

  tblSort: MatSort;
  @ViewChild('tblSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.tblSort = ms;
    this.ngAfterViewInit();
  }

  customerQwitQuoteHistoryDisplayedColumns: string[] = 
  [ 'customerNumber'
    , 'quoteNumber'
    , 'amount'
    , 'customerPONumber'
    , 'projectName'
    , 'unitNumber'
    , 'dateCreated'];

  constructor(customerInfoService: CustomerService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder){
      this.customerInfoService = customerInfoService;

      this.dataSource.filterPredicate = ((data, filter) => {

        
        const a = !filter.word || data.quoteNumber.toString().includes(filter.word);
       
        return a ;
      }) as (PeriodicElement, string) => boolean;
  
      this.formControl = formBuilder.group({
        word: ''
      })
      
      this.formControl.valueChanges.subscribe(value => {
        const filter = {...value, name: value?.name?.trim().toLowerCase()} as string;
        this.dataSource.filter = filter;
      });
      
  }

  ngOnChanges(changes: SimpleChanges){
    this.customerNumber = changes.customerNumber.currentValue;
    this.loadingQuoteHistory = true;
    this.quotesData = [];
    this.customerInfoService.getCustomerQuote(this.customerNumber).subscribe((quotes) =>{
  
      this.loadingQuoteHistory = false;

      quotes.forEach((value, index) => {

        this.data = {} as Quote;

        this.data.quoteDetails = JSON.parse(value.data);
        this.data.customerNumber = value.customerNumber
        this.data.quoteNumber = value.quoteNumber;
        this.data.dateCreated = value.dateCreated;
        this.data.customerPONumber = value.customerPONumber;
        this.data.data = JSON.parse(value.data);
        this.data.source = value.source;

        this.quotesData.push(this.data);
        
     
      }, err => {
        console.error(err);
        this.loadingQuoteHistory = false;
        this.snackBarService.showError('Error loading Quotes.');
      })

      this.dataSource.data = this.quotesData;
      this.dataSource.sort = this.tblSort;

    }); 
  }

  ngAfterViewInit() {    
    this.dataSource.sort = this.tblSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayQuoteDetails(details: any) {
    let quoteDetails = details;
      this.dialog.open(QuoteDetailComponent, {
        width: '60%',
        height:'50%',
        disableClose: false,
          data: {
            quoteDetails
      },
      }).afterClosed().subscribe((result: any) => {

      this.dialog.closeAll();

      });
  }
}
