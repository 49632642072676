import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { Credentials } from '../models/cinch-credentials';
import { ConfigService } from './config.service';

const ACCESS_TOKEN_KEY_CINCH = 'access_token_cinch';
const MACHINE_KEY = 'machine';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthService {
  
  private isLogged = false;
  private authenticate = new BehaviorSubject(false);
  private params: URLSearchParams = new URLSearchParams();

  constructor(
    private router: Router,
    private http: HttpClient,
    private config: ConfigService,
    private storage: SessionStorageService,
  ) { }

  signIn(credentials: Credentials) {
    for (const key in credentials) {
          if (credentials.hasOwnProperty(key)) {        
            this.params.set(key, credentials[key]);
          }
        }
        
      return this.http.post(this.config.buildCustomerInfoURL('token'), this.params.toString(), httpOptions);
  }
  
  signOut() {
    this.clearAccessToken();
  }

  setMachine(machine: string) {
    this.storage.store(MACHINE_KEY, machine);
  }

  getMachine(): string {
    return this.storage.retrieve(MACHINE_KEY);
  }

  setAccessToken(token: any) {
    this.storage.store(ACCESS_TOKEN_KEY_CINCH, token.access_token);
  }

  getAccessToken(): string {
    return this.storage.retrieve(ACCESS_TOKEN_KEY_CINCH);
  }

  clearAccessToken() {
    this.storage.clear(ACCESS_TOKEN_KEY_CINCH);
  }
}
