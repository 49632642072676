import { ArchivedUnit } from 'src/app/models/archived-unit';
import { CustomerService } from 'src/app/services/customer.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit} from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-unarchive-unit-confirmation',
  templateUrl: './unarchive-unit-confirmation.component.html',
  styleUrls: ['./unarchive-unit-confirmation.component.less']
})
export class UnarchiveUnitConfirmationComponent implements OnInit {

  public _customerInfoService: CustomerService;
  public archivedUnit: ArchivedUnit[] = [];
  private onSuccessDelete: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<UnarchiveUnitConfirmationComponent>,
    private snackBarService: SnackbarService,
    private customerInfoService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: { archivedUnit: ArchivedUnit[] }){
      this._customerInfoService = customerInfoService;
    }

  ngOnInit() {
    this.archivedUnit = this.data.archivedUnit;
  }

  closeWithOutSaving() {
    this.dialogRef.close({success: this.onSuccessDelete});
  }

  unArchiveSelectedUnits(){
    this._customerInfoService.unArchiveUnits(this.archivedUnit)
      .subscribe((response) => {
        if(response){
          this.snackBarService.showSuccess('Unit(s) succesfully UnArchived.');
          this.onSuccessDelete = true;
          this.dialogRef.close({success: this.onSuccessDelete});
        }else{
          this.snackBarService.showError('Error UnArchiving Units.');
        }
    }, err => {
       console.error(err);
       this.snackBarService.showError('Error UnArchiving Units.');
       this.dialogRef.close({success: this.onSuccessDelete});
     })
  }
}
