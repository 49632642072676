<mat-card>
    <h1 class="mat-h1">Commission Report By Region</h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}" (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
            <mat-expansion-panel-header>
                <mat-panel-title>Filters</mat-panel-title>
                <mat-panel-description>Filters commission report</mat-panel-description>
            </mat-expansion-panel-header>
             <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerFrom" [formControl]="reportFromDateFilter" placeholder="From" (dateChange)="onRequestedFromDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerTo" [formControl]="reportToDateFilter" placeholder="To" (dateChange)="onRequestedToDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field *ngIf="!salesPersonsCharge">
                            <mat-label>Select individuals</mat-label>
                            <mat-select #dllTeamSales [formControl]="reportSaleRepFilter" multiple>
                                <mat-select-trigger>
                                    {{reportSaleRepFilter.value ? reportSaleRepFilter.value[0]?.salesPersonName : ''}}
                                    <span *ngIf="reportSaleRepFilter.value?.length > 1" class="additional-selection">
                                      (+{{reportSaleRepFilter.value.length - 1}} {{reportSaleRepFilter.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [value]="{ salesPersonId: 0, salesPersonName: 'All' }" (onSelectionChange)="onAllSelectionChange($event.source)">All</mat-option>
                                <mat-option *ngFor="let item of salesPersons" [value]="item">
                                    {{ item.salesPersonName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="header-buttons-actions">
                        <button mat-flat-button color="accent" (click)="runReport()" [disabled]="loading">Run Calculate</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table *ngIf="reportDataSource.length > 0">
        <thead>
            <tr>
                <th *ngFor="let header of displayedHeader" class="width-10">
                    {{ header }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of reportDataSource">
                <td>{{ item.salesPerson }}</td>
                <td>{{ item.month }}</td>
                <td>{{ item.year }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.totalRevenue | currency }}</td>
            </tr>
        </tbody>
    </table>
</div>