import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StandardItemShipInSetup } from '../../../../models/standard-item-ship-in-setup';
import { ShippingItemSetupService } from '../../../../services/shipping-item-setup.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-delete-ship-in-setup',
  templateUrl: './delete-ship-in-setup.component.html',
  styleUrls: ['./delete-ship-in-setup.component.less']
})
export class DeleteShipInSetupComponent implements OnInit {
  standardItemShipInSetup: StandardItemShipInSetup;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private shippingSetupService: ShippingItemSetupService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit() {
    this.getStandardItemShipInSetupById();
  }

  async getStandardItemShipInSetupById(): Promise<void> {
    const id = this.route.snapshot.paramMap.get("id");
    try {
      this.standardItemShipInSetup = await this.shippingSetupService.getStandardItemShipInSetupById(id).toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError("Error getting shipping item setup.");
    }
  }

  async deleteStandardItemShipInSetup(): Promise<void> {
    try {
      await this.shippingSetupService.deleteStandardItemShipInSetup(this.standardItemShipInSetup.id).toPromise();
      this.router.navigate([`/settings`]);
      this.snackBarService.showSuccess("Shipping item setup was deleted successfully.");
    } catch (err) {
      console.error(err);
      this.snackBarService.showError("Error deleting shipping item setup.");
    }
  }

}
