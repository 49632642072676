<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Create Router
    </h1>
    <hr />

    <div class="container">
        <form [formGroup]="routerForm" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <input matInput placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="routerForm.get('name').hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput placeholder="IP Address" formControlName="ipAddress" required>
                <mat-error *ngIf="routerForm.get('ipAddress').hasError('required')">
                    IP Address is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Production Location</mat-label>
                <mat-select formControlName="productionLocationId" required>
                    <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="routerForm.get('productionLocationId').hasError('required')">
                    Production Location is required
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent">Create</button>
        </form>
    </div>
</mat-card>