<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Create Production Limit
  </h1>
  <hr />

  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="createProductionLimitForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Customer" aria-label="Customer" [matAutocomplete]="customersAutocomplete"
          formControlName="customer" required>
        <mat-autocomplete #customersAutocomplete="matAutocomplete" (optionSelected)="onCustomerOptionSelected($event)">
          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="getCustomerName(customer)">
            {{customer.company}}, {{customer.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="createProductionLimitForm.controls.customer.invalid">{{getCustomerErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Apartment Complex" aria-label="Apartment Complex"
          [matAutocomplete]="apartmentsComplexAutocomplete" formControlName="apartmentComplex">
        <mat-autocomplete #apartmentsComplexAutocomplete="matAutocomplete" (optionSelected)="onApartmentComplexOptionSelected($event)">
          <mat-option *ngFor="let apt of filteredApartmentsComplex | async" [value]="apt.name">
            {{apt.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Allowed Custom Parts Capacity" formControlName="allowedCapacity" required>
        <mat-error *ngIf="createProductionLimitForm.controls.allowedCapacity.invalid">
          {{getAllowedCapacityErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="accent">Create</button>
    </form>
  </div>
</mat-card>
