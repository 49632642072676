import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExpeditedProductionSetting } from "src/app/models/expedited-production-setting";
import { SettingsService } from "src/app/services/settings.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
  selector: "app-delete-expedited-production",
  templateUrl: "./delete-expedited-production.component.html",
  styleUrls: ["./delete-expedited-production.component.less"],
})
export class DeleteExpeditedProductionComponent implements OnInit {
  expeditedProductionSettings: ExpeditedProductionSetting;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getExpeditedProductionSettingById();
  }

  getExpeditedProductionSettingById() {
    const id = this.route.snapshot.paramMap.get("id");
    this.settingsService.getExpeditedProductionSettingById(id).subscribe(
      (expeditedProductionSettings: ExpeditedProductionSetting) => {
        this.expeditedProductionSettings = expeditedProductionSettings;
      },
      (err) => {
        console.error(err);
        this.snackBarService.showError(
          "Error getting expedited production setting details."
        );
      }
    );
  }

  deleteExpeditedProductionSetting() {
    this.settingsService
      .deleteExpeditedProductionSetting(this.expeditedProductionSettings.id)
      .subscribe(
        () => {
          this.router.navigate([`/settings`]);
          this.snackBarService.showSuccess(
            "Expedited production setting deleted successfully."
          );
        },
        (err) => {
          console.error(err);
          this.snackBarService.showError(
            "Error deleting expedited production setting."
          );
        }
      );
  }
  formatPercentage(value: number): string {
    return `${(value * 1).toFixed(2)}%`;
  }
}
