import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role';
import { RolesService } from 'src/app/services/roles.service';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.less']
})
export class RoleDetailsComponent implements OnInit {

  role: Role;

  constructor(
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getRole();
  }

  getRole() {
    const id = this.route.snapshot.paramMap.get('id');
    this.rolesService.getRole(id).subscribe((role: Role) => {
      this.role = role;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the role details.');
      });
  }
}
