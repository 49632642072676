import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
// Services
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CommissionService } from '../../../services/commission.service';
import { AuthService } from 'src/app/services/auth.service';
import { BlobService } from '../../../services/blob.service';
// Models
import { CommissionSalesPerson } from '../../../models/commission-sales-person';
import { CommissionLedgers } from 'src/app/models/commission-ledgers';
import { CommissionResponse, StateCommissionPlanEnum } from '../../../models/commission-request';
import { UserRole } from 'src/app/models/user-role';
// DateTime
import * as moment from 'moment';
// Material
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// Components
import { PreventRecalculateCommissionComponent } from '../prevent-recalculate-commissions/prevent-recalculate-commission.component';


@Component({
  selector: 'app-resume-commissions',
  templateUrl: './resume-commissions.component.html',
  styleUrls: ['./resume-commissions.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ResumeCommissionsComponent implements OnInit {
  salesPersons: CommissionSalesPerson[] = [];
  salesPersonsCharge = false;
  loading = false;
  isRolOnlyView: boolean;
  disableRunFunction = false;
  currentProcess: CommissionResponse[] = [];

  filterFormPanelDisplaced = true;
  editFormPanelDisplaced = true;

  @ViewChild(MatTable) tblReport: MatTable<CommissionLedgers>;
  reportDataSource: CommissionLedgers[] = [];
  displayedColumnsHeaders: string[] = [
    'Name',
    'Email',
    'Phone',
    'Total accumulated',
  ];
  displayedColumnsDetails: string[] = [
    'Customer',
    'Community',
    'Apartment #',
    'Invoice #',
    'Invoice date',
    'Pre Tax Sale Amount',
    `Comm'n Type`,
    `Comm'n %`,
    'Split %',
    `Comm'n Amount`,
  ];

  reportFromDateFilter = new UntypedFormControl();
  reportToDateFilter = new UntypedFormControl();
  reportSaleRepFilter = new UntypedFormControl();

  @ViewChild(MatSelect) dllTeamSales: MatSelect;

  constructor(
    private commissionService: CommissionService,
    private blobService: BlobService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.isRolOnlyView = this.authService.getAccount().roles.find(f => f === UserRole.CommissionViewer.toString()) ? true : false;
  }

  async ngOnInit(): Promise<void> {
    await this.getAllSalesPerson();
    await this.LoadPermissionsOfUser();
  }

  async getAllSalesPerson(): Promise<void> {
    this.salesPersonsCharge = true;
    await this.commissionService.getCommissionAllSalesPerson()
      .toPromise()
      .then((result: CommissionSalesPerson[]) => this.salesPersons = result.filter(f => f.commissionRegionId === 0 && f.salesPersonName !== ''))
      .catch((err: any) => {
        console.error(err);
        this.snackBarService.showError('Error getting sales persons.');
      });
    this.salesPersonsCharge = false;
  }

  private async LoadPermissionsOfUser() {
    if (this.isRolOnlyView) {
      const myProfile = this.authService.getAccount();
      const findPerson = this.salesPersons.filter(f => f.emailAddress === myProfile.email);
      if (findPerson.length !== 0) {
        this.reportSaleRepFilter.setValue(findPerson);
        this.reportSaleRepFilter.disable();
      } else {
        this.reportSaleRepFilter.disable();
        this.disableRunFunction = true;
        this.snackBarService.showError('Your email is not registered in the sales team list, you cannot access this report.');
      }

    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  onRequestedFromDateFilterChange() {
    if (!this.reportFromDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested From date.');
      this.reportFromDateFilter.setValue('');
    }
  }

  onRequestedToDateFilterChange() {
    if (!this.reportToDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested To date.');
      this.reportToDateFilter.setValue('');
    }
  }

  onAllSelectionChange(option: MatOption) {
    this.dllTeamSales.options.map((item: MatOption) => option.selected ? item.select() : item.deselect());
    this.dllTeamSales.close();
  }

  rangeDatesIsValid(isRecalculate: boolean): boolean {
    const startPeriod = this.reportFromDateFilter.value;
    const endPeriod = this.reportToDateFilter.value;
    const days = moment(endPeriod)
      .startOf('day')
      .diff(moment(startPeriod).startOf('day'), 'days');

    if (startPeriod && endPeriod && days <= 0) {
      this.reportFromDateFilter.setValue('');
      this.reportToDateFilter.setValue('');
      this.snackBarService.showWarning('Range of dates is not valid.');
    } else if (startPeriod === null || endPeriod === null) {
      this.snackBarService.showWarning('Enter a valid range dates.');
    } else if (isRecalculate && days > 60) {
      this.snackBarService.showWarning('The recalculate execution only allows a range no greater than 60 days.');
    } else if (!isRecalculate && days > 365) {
      this.snackBarService.showWarning('The commission report only allows a range no greater than one year.');
    } else {
      return true;
    }
  }

  getReportDataSource(): CommissionLedgers[] {
    return this.reportDataSource;
  }

  async getDataReport(): Promise<void> {
    this.showLoading();
    await this.commissionService.getAllSalesCommissionLedgerByFilters(
      this.reportFromDateFilter.value, this.reportToDateFilter.value, this.reportSaleRepFilter.value ? (this.reportSaleRepFilter.value as CommissionSalesPerson[]).map((m) => m.salesPersonId) : [])
      .toPromise()
      .then((result: CommissionLedgers[]) => {
        this.reportDataSource = result;
        if (this.reportDataSource.length === 0) {
          this.snackBarService.showWarning(`Your parameters don't get any record, try again.`);
        }
        this.hideLoading();
      })
      .catch((err: any) => {
        console.error(err);
        this.hideLoading();
        this.snackBarService.showError('Error getting commission report.');
      });
  }

  async runReport(): Promise<void> {
    if (this.rangeDatesIsValid(false)) {
      this.reportDataSource = [];
      await this.getDataReport();
    }
  }

  async preventCalculateCommission() {
    if (this.rangeDatesIsValid(true)) {
      await this.commissionService.getStatesOfCommissionProcess()
        .toPromise()
        .then((result: CommissionResponse[]) => this.currentProcess = result);

      this.dialog
      .open(PreventRecalculateCommissionComponent, {
        width: '40%',
        disableClose: true,
        data: {
          process: this.currentProcess,
          legendDates: `${moment(this.reportFromDateFilter.value).format('L')} to ${moment(this.reportToDateFilter.value).format('L')}`,
          user: this.authService.getAccount().userName,
          start: moment(this.reportFromDateFilter.value).format(),
          end: moment(this.reportToDateFilter.value).format()
        },
      })
      .afterClosed()
      .toPromise()
      .then((response: CommissionResponse) => {
        const stateProcess = StateCommissionPlanEnum[StateCommissionPlanEnum[response.state] as keyof typeof StateCommissionPlanEnum];
        if (stateProcess) {
          switch (stateProcess) {
            case StateCommissionPlanEnum.Complete:
              this.snackBarService.showSuccess('The recalculation process was completed successfully');
              break;
            default:
              this.snackBarService.showError(`Code: ${stateProcess} Message: ${ response.runException && response.runException !== '' ? response.runException : 'An error occurred during the selected operation.' }`);
              break;
          }
        }
      });
    }
  }

  async getCommissionReport(): Promise<void> {
    this.showLoading();
    await this.commissionService.createCommissionReport(
      this.reportFromDateFilter.value,
      this.reportToDateFilter.value,
      this.getReportDataSource().map((m) => m.salesPersonMail.toLowerCase()))
      .then(buffer => {
        this.blobService.downloadFile(`Commissions_${moment().format('MDYYYY_ss')}.zip`, 'application/zip', buffer);
        this.hideLoading();
      })
      .catch(() => {
        this.hideLoading();
        this.snackBarService.showError('Error download commission report.');
      });

  }

  async getXlsxCommissionReport(): Promise<void> {
    this.showLoading();
    const buffer = await this.commissionService.createXlsxCommissionReport(
      this.reportFromDateFilter.value,
      this.reportToDateFilter.value,
      this.reportSaleRepFilter.value ? (this.reportSaleRepFilter.value as CommissionSalesPerson[]).map((m) => m.salesPersonId) : []);
    this.blobService.downloadFile(`Commissions_${moment().format('MDYYYY_ss')}.xlsx`, 'application/vnd.ms-excel', buffer);
    this.hideLoading();
  }
}
