import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductionOrdersService } from 'src/app/services/production-orders.service';
import { PalletizationFormComponent } from './palletization-form/palletization-form.component';
import { PalletizationEmailSendingComponent } from './palletization-email-sending/palletization-email-sending.component';
import { PalletizationService } from 'src/app/services/palletization.service';
import { PalletizationQuote } from '../../models/palletization-quote';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PalletizedOrder, PalletizedOrderGrouped } from 'src/app/models/palletized-order';
import { PalletizationUnitInfoComponent } from './palletization-unit-info/palletization-unit-info.component';
import { PalletUnitInfo } from 'src/app/models/pallet-unit-info';

@Component({
  selector: 'app-palletization',
  templateUrl: './palletization.component.html',
  styleUrls: ['./palletization.component.less']
})
export class PalletizationComponent implements AfterViewInit, OnInit {

  filter: string = "";
  dataSource = new MatTableDataSource();
  loading = false;

  displayedHeader: string[] = [
    'dateOrder',
    'customerName',
    'customerNumber',
    'locationName',
    'approved',
    'pickupDate',
    'actions'
  ];

  constructor(
    private service: ProductionOrdersService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService,
    private palletizationService: PalletizationService) {

  }

  async ngOnInit() {
    await this.search();
  }

  palletizationTableSort: MatSort;

  @ViewChild('palletizedOrdersTbl', { read: MatSort }) set matSort(ms: MatSort) {
    this.palletizationTableSort = ms;
    this.ngAfterViewInit();
  }

  async ngAfterViewInit() {
    this.dataSource.sort = this.palletizationTableSort;
    this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];
  }

  async search() {
    this.showLoading();

    this.service.getPalletizedOrders(this.filter).subscribe((data: PalletizedOrderGrouped[]) => {
      this.dataSource.data = data.map(x => ({
        approved:
          !x.palletizedOrders[0].isPerPartCustomer
            ?
            x.palletizedOrders[0].approved !== null
              ?
              x.palletizedOrders[0].approved
                ? 1
                : 0
              : -1
            : 1,
        customerId: x.palletizedOrders[0].customerId,
        customerName: x.palletizedOrders[0].customerName,
        customerNumber: x.palletizedOrders[0].customerNumber,
        dateOrder: x.palletizedOrders[0].dateOrder,
        invoicingEmail: x.palletizedOrders[0].invoicingEmail,
        isPerPartCustomer: x.palletizedOrders[0].isPerPartCustomer,
        isPalletForm: x.palletizedOrders[0].isPalletForm,
        locationId: x.palletizedOrders[0].locationId,
        locationName: x.palletizedOrders[0].locationName,
        orderTotal: x.palletizedOrders[0].orderTotal,
        palletFormId: x.palletizedOrders[0].palletFormId,
        pickupDate: x.palletizedOrders[0].pickupDate,
        salesOrderNumber: x.palletizedOrders[0].salesOrderNumber,
        orderId: x.palletizedOrders[0].orderId,
        palletizedOrders: x.palletizedOrders
      }));

      this.hideLoading();
    });
  }

  viewDetails(id: number) {
    this.service.getPalletizeInfo(id).subscribe(result => {

      if (result) {
        this.dialog.open(PalletizationFormComponent,
          {
            height: "800px",
            width: "600px",
            disableClose: true,
            data: result
          }).beforeClosed()
          .subscribe(() => {

          });
      }
    });
  }

  viewUnits(palletizedOrders: PalletizedOrder[]) {
    let result: PalletUnitInfo[];
    result = palletizedOrders.map(x => ({
      unitNumber: x.apartmentNumber,
      salesOrderNumber: x.salesOrderNumber
    }));
    this.dialog.open(PalletizationUnitInfoComponent,
      {
        height: "500px",
        width: "600px",
        disableClose: true,
        data: result
      }).beforeClosed()
      .subscribe(() => {

      });
  }

  openConfirmationModal(invoicingEmail: string, orderNumber: string, customerId: number, palletFormId: number, orderId: number) {
    let dialog = this.dialog.open(PalletizationEmailSendingComponent, {
      width: '540px',
      height: '280px',
      disableClose: true,
      data: {
        invoicingEmail: invoicingEmail
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.sendEmail(orderNumber, customerId, palletFormId, orderId);
      }
    });
  }

  sendEmail(orderNumber: string, customerId: number, palletFormId: number, orderId: number) {
    let model: PalletizationQuote = {
      orderNumber: orderNumber,
      customerId: customerId,
      price: this.palletizationService.price,
      palletFormId: palletFormId,
      orderId: orderId,
      customerEmail: this.palletizationService.invoicingEmail
    };

    this.palletizationService.createPalletizationQuote(model).subscribe(() => {
      this.snackBarService.showSuccess("Email sent successfully!");
    });
  }

  onPickupDateChange(date: Date, locationId: number, palletFormId: number) {
    this.palletizationService.updatePickupDate(date.toDateString(), locationId, palletFormId).subscribe(() => {

    });
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
}
