<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content>
    <p>Are you sure you want to send the following quote to email address "{{data.invoicingEmail}}"?
        <label class="edit-email-link" (click)="openEditEmailModal()">Edit</label>
    </p>
    <div>
        {{data.serviceAndTransitTimes}}
    </div>
    <div>
        {{data.rate | currency}}
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="yes()">&nbsp; Yes &nbsp;</button>
    <button mat-raised-button mat-dialog-close>No</button>
</mat-dialog-actions>