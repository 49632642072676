<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">Delete Shipping Item Setup</h1>
    <hr />
    <p>Are you sure you want to delete this shipping item setup?</p>
    <div class="content">
      <ul>
        <li>
          <strong>Item Number:</strong>
          {{ standardItemShipInSetup?.itemNumber }}
        </li>
        <li>
          <strong>Carton Type:</strong>
          {{ standardItemShipInSetup?.cartonTypeDescription }}
        </li>
        <li>
          <strong>Category Type:</strong>
          {{ standardItemShipInSetup?.categoryTypeDescription }}
        </li>
      </ul>
    </div>
    <div class="actions">
      <button
        mat-raised-button
        color="accent"
        (click)="deleteStandardItemShipInSetup()"
      >
        Delete
      </button>
    </div>
  </mat-card>
  