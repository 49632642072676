import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { ReasonGroup } from '../models/reason-group';

@Injectable({
  providedIn: 'root',
})
export class ReasonGroupsService {
  constructor(
    private config: ConfigService,
    private http: HttpClient) {
    }

  getReasonGroups(): Observable<ReasonGroup[]> {
    return this.http.get<ReasonGroup[]>(this.config.buildURL('reasonGroups'));
  }

  getReasonGroupById(id: number): Observable<ReasonGroup[]> {
    return this.http.get<ReasonGroup[]>(this.config.buildURL(`reasonGroups/${id}`));
  }

  createReasonGroup(model: ReasonGroup): Observable<ReasonGroup> {
    return this.http.post<ReasonGroup>(this.config.buildURL('reasonGroups/createReasonGroup'), model);
  }

  updateReasonGroup(model: ReasonGroup): Observable<ReasonGroup> {
    return this.http.put<ReasonGroup>(this.config.buildURL('reasonGroups/updateReasonGroup'), model);
  }

  deleteReasonGroup(id: number): Observable<any> {
    return this.http.delete(this.config.buildURL(`reasonGroups/deleteReasonGroup/${id}`));
  }
}
