<div class="header-actions">
    <a routerLink="create" mat-flat-button color="accent">Create new Router</a>
</div>
<button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
    <mat-icon>arrow_downward</mat-icon>
</button>

<button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
    <mat-icon>arrow_upward</mat-icon>
</button>

<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Routers
    </h1>
    <hr />

    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter Routers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field class="small">
                            <input matInput placeholder="Name" [formControl]="nameFilter">
                        </mat-form-field>
                        <mat-form-field class="small">
                            <input matInput placeholder="IP Address" [formControl]="ipAddressFilter">
                        </mat-form-field>
                        <mat-form-field class="small">
                            <mat-label>Production Location</mat-label>
                            <mat-select [formControl]="productionLocationFilter">
                                <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="activeRoutersFilter" matTooltip="Active">Active</mat-checkbox>
                    </div>
                </div>
                <button mat-mini-fab matTooltip="Apply filters" (click)="applyRoutersFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="reset-action-container" *ngIf="routersFiltersApplied">
        <button mat-raised-button (click)="clearRoutersFilters()">
            <mat-icon>clear</mat-icon> Reset Routers Filters
        </button>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <h3 *ngIf="!loading && getRoutersData().data.length === 0">No routers to show.</h3>

    <div *ngIf="!loading && getRoutersData().data.length > 0">
        <table mat-table #routersSort="matSort" [dataSource]="getRoutersData()" matSort class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="productionLocationId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Location </th>
                <td mat-cell *matCellDef="let element">{{element.productionLocationStr}} </td>
            </ng-container>

            <ng-container matColumnDef="ipAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IP Address </th>
                <td mat-cell *matCellDef="let element">{{element.ipAddress}} </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                <td mat-cell *matCellDef="let element"> {{element.isActive | active}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <button color="accent" [disabled]="element.jitSchedulingStarted"
                        (click)="startJITScheduling(element)" mat-icon-button matTooltip="Start JIT Scheduling"
                        matTooltipPosition="above">
                        <mat-icon>play_circle</mat-icon>
                    </button>
                    <a color="accent" routerLink="details/{{element.id}}" mat-icon-button matTooltip="Router Details"
                        matTooltipPosition="above">
                        <mat-icon>notes</mat-icon>
                    </a>
                    <a color="accent" routerLink="edit/{{element.id}}" mat-icon-button matTooltip="Edit Router"
                        matTooltipPosition="above">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a color="accent" routerLink="delete/{{element.id}}" mat-icon-button matTooltip="Delete Router"
                        matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                    </a>
                </td>
            </ng-container>
        </table>
    </div>
</mat-card>

<h1>Routers Calendar / <small>Starting at {{firstDay}}</small></h1>
<hr /> <br />

<div class="header-actions">
    <a routerLink="/router-schedules/create" mat-flat-button color="accent">Create new Router Schedule</a>
</div>
<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Router Schedules
    </h1>
    <hr />

    <div class="loading" *ngIf="loadingRouterSchedules">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <h3 *ngIf="!loadingRouterSchedules && getRouterSchedulesData().data.length === 0">
        No router schedules to show for this week.
    </h3>

    <div *ngIf="!loadingRouterSchedules && getRouterSchedulesData().data.length > 0">
        <table mat-table #routerSchedulesSort="matSort" [dataSource]="getRouterSchedulesData()" matSort
            class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="routerSchedulesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: routerSchedulesDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="routerId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Router </th>
                <td mat-cell *matCellDef="let element">{{element.routerStr}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">{{element.date | date}} </td>
            </ng-container>

            <ng-container matColumnDef="timeStart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Start </th>
                <td mat-cell *matCellDef="let element">{{formatTime(element.date, element.timeStart)}} </td>
            </ng-container>

            <ng-container matColumnDef="timeEnd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Time End </th>
                <td mat-cell *matCellDef="let element"> {{formatTime(element.date, element.timeEnd)}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <a color="accent" routerLink="/router-schedules/details/{{element.id}}" mat-icon-button
                        matTooltip="Router Schedule Details" matTooltipPosition="above">
                        <mat-icon>notes</mat-icon>
                    </a>
                    <a color="accent" routerLink="/router-schedules/edit/{{element.id}}" mat-icon-button
                        matTooltip="Edit Router Schedule" matTooltipPosition="above">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a color="accent" routerLink="/router-schedules/delete/{{element.id}}" mat-icon-button
                        matTooltip="Delete Router Schedule" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                    </a>
                </td>
            </ng-container>
        </table>
    </div>
</mat-card>

<div class="header-actions">
    <a routerLink="/router-setups/create" mat-flat-button color="accent">Create new Router Setup</a>
</div>
<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Router Setups
    </h1>
    <hr />

    <div class="loading" *ngIf="loadingRouterSetups">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <p class="alert">
        <mat-icon aria-hidden="false" aria-label="warning" fontIcon="warning"></mat-icon>
        <span>
            Fusion/ Multicolor JIT per production location needs a default router setup with all colors and styles.
        </span>
    </p>

    <h3 *ngIf="!loadingRouterSetups && getRouterSetupsData().data.length === 0">
        No router setups to show for this week.
    </h3>

    <div *ngIf="!loadingRouterSetups && getRouterSetupsData().data.length > 0">
        <table mat-table #routerSetupsSort="matSort" [dataSource]="getRouterSetupsData()" matSort
            class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="routerSetupsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: routerSetupsDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="routerStr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Router</th>
                <td mat-cell *matCellDef="let element">{{element.routerStr}} </td>
            </ng-container>

            <ng-container matColumnDef="routerScheduleStr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Router Schedule</th>
                <td mat-cell *matCellDef="let element">{{element.routerScheduleStr}} </td>
            </ng-container>

            <ng-container matColumnDef="productionLocationStr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Location</th>
                <td mat-cell *matCellDef="let element">{{element.productionLocationStr}} </td>
            </ng-container>

            <ng-container matColumnDef="colors">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Colors </th>
                <td mat-cell *matCellDef="let element">{{element.colors}} </td>
            </ng-container>

            <ng-container matColumnDef="styles">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Styles </th>
                <td mat-cell *matCellDef="let element">{{element.styles}} </td>
            </ng-container>

            <ng-container matColumnDef="maxPartsInBatch">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Parts in Batch </th>
                <td mat-cell *matCellDef="let element">{{element.maxPartsInBatch}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                    <a color="accent" routerLink="/router-setups/details/{{element.id}}" mat-icon-button
                        matTooltip="Router Setup Details" matTooltipPosition="above">
                        <mat-icon>notes</mat-icon>
                    </a>
                    <a color="accent" routerLink="/router-setups/edit/{{element.id}}" mat-icon-button
                        matTooltip="Edit Router Setup" matTooltipPosition="above">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a color="accent" routerLink="/router-setups/delete/{{element.id}}" mat-icon-button
                        matTooltip="Delete Router Setup" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                    </a>
                </td>
            </ng-container>
        </table>
    </div>
</mat-card>