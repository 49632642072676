import { Component, Inject, OnInit, inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ProductionLocation } from '../../../models/production-location';
import { SnackbarService } from '../../../services/snackbar.service';
import { ScheduleService } from '../../../services/schedule.service';
import { FormattedScheduledUnit } from '../../../models/formatted-scheduled-unit';
import { ExportToProductionFilter } from '../../../models/export-to-production-audit';
import { SettingsService } from '../../../services/settings.service';
import { ProductionMoldingSetting } from '../../../models/production-molding-setting';
import { ProductionLocationsService } from '../../../services/production-locations.service';
import { OrderFreshDesk, OrderStateFreshDesk } from '../../../models/fresh-desk';
import * as moment from 'moment';
import { MatSelectChange } from '@angular/material/select';
import { ProductionCalendarService } from '../../../services/production-calendar.service';

const INVALID_LOCATION_ID = -1;

enum Steps {
  ExportToProduction = 0,
  ExportToProductionExecuted = 1,
  FinalizeExportToProductionExecuted = 2,
  CancelExportToProductionExecuted = 3
}

@Component({
  selector: 'app-export-to-production-expanded',
  templateUrl: './export-to-production-expanded.component.html',
  styleUrls: ['./export-to-production-expanded.component.less']
})
export class ExportToProductionExpandedComponent implements OnInit {

  csvUrl: string;
  processing = false;
  enableExport = false;
  exportToProductionExecuted = false;
  customPartsCapacity = -1;
  allowedCustomPartsCapacity = -1;
  startTime: number;
  productionDate = new FormControl();
  filter: ExportToProductionFilter;
  productionLocationId: number = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  productionMoldingSettings = [];
  productionMoldingSettingSelected = new FormControl();
  totalCustomPartsScheduled: number;
  totalCustomPartsExported: number;
  scheduledUnits: FormattedScheduledUnit[] = [];
  scheduledUnitsSelection = new SelectionModel<number>(true, []);
  displayedColumns: string[] = ['select', 'poNumber', 'productionDate', 'customer', 'salesOrderNumber', 'project', 'apartmentNumber', 'color', 'style', 'numberOfParts', 'pallet', 'fusion', 'moldingLength', 'channelGroove'];
  exportToProductionFilters: string[] = ['Normal Orders', 'Accessories Only', 'Parts Orders', 'Palletized'];
  selectedExportToProductionFilter: string = this.exportToProductionFilters[0];
  Steps: typeof Steps = Steps;
  currentStep: Steps = Steps.ExportToProduction;

  // Fresh Desk variables
  syncFreshDeskState = '';
  isEnabledFreshDesk = false;
  syncFreshDeskReport: OrderStateFreshDesk[] = [];

  // Services
  settingsService = inject(SettingsService);
  scheduleService = inject(ScheduleService);
  snackBarService = inject(SnackbarService);

  constructor(
    @Inject(DOCUMENT) private document: any,
    private productionLocationsService: ProductionLocationsService,
    private productionCalendarService: ProductionCalendarService
  ) { }

  async ngOnInit() {
    this.productionDate.setValue(new Date());
    await this.getProductionLocations();
    this.onProductionDateChange();
    await this.getProductionMoldingSettings();
    await this.getFreshDeskSyncSetting();
    await this.getUnfinalizedExportedScheduledUnits();
  }

  async getFreshDeskSyncSetting() {
    try {
      const setting = await this.scheduleService.getFreshDeskSyncSetting().toPromise();
      this.isEnabledFreshDesk = setting && setting.value && setting.value !== '' ? Number(setting.value) === 1 : false;
    } catch (err) {
      this.snackBarService.showError('Error getting Fresh Desk setting.');
    }
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  fullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onProductionDateChange() {
    if (!this.productionDate.valid || this.productionDate.value === '') {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDate.setValue('');
      this.enableExport = false;
    } else {
      this.setProductionCalendarCustomPartsCapacity();
      this.enableExport = true;
    }
    this.clearExportedScheduledUnits();
  }

  onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    if (this.productionLocationId !== INVALID_LOCATION_ID) {
      this.setProductionCalendarCustomPartsCapacity();
      this.getUnfinalizedExportedScheduledUnits();
    }
  }

  async setProductionCalendarCustomPartsCapacity() {
    try {
      const result = await this.productionCalendarService.getProductionCalendarBy(this.productionLocationId, this.productionDate.value).toPromise();
      this.customPartsCapacity = result.customPartsCapacity;
      this.allowedCustomPartsCapacity = result.allowedCustomPartsCapacity;
    } catch (err) {
      this.customPartsCapacity = -1;
      this.allowedCustomPartsCapacity = -1;
      console.error('Error getting production calendar custom parts capacity.', err);
      this.snackBarService.showError('Error getting production calendar custom parts capacity.');
    }
  }

  formDisabled(): boolean {
    return this.processing || this.currentStep === Steps.ExportToProductionExecuted;
  }

  isAllSelected() {
    const numSelected = this.scheduledUnitsSelection.selected.length;
    const numRows = this.scheduledUnits.length;
    return numSelected >= numRows;
  }

  masterToggle() {
    if (!this.isAllSelected()) {
      this.scheduledUnits.forEach(row => {
        this.scheduledUnitsSelection.select(row.partialUnitId);
      });
    } else {
      this.scheduledUnitsSelection.clear();
    }
  }

  toggleScheduledUnitsSelection(event: MatCheckboxChange, element: FormattedScheduledUnit) {
    if (event.checked) {
      this.scheduledUnitsSelection.select(element.partialUnitId);
    } else {
      this.scheduledUnitsSelection.deselect(element.partialUnitId);
    }
  }

  async getProductionLocations(): Promise<void> {
    try {
      this.productionLocations = await this.productionLocationsService.getActiveProductionLocations().toPromise();
      const defaultLocation = this.productionLocations.find(p => p.isDefault);
      this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
    } catch (err) {
      console.error('Error getting production locations.', err);
      this.snackBarService.showError('Error getting production locations.');
    }
  }

  getScheduledUnitsSelected(): FormattedScheduledUnit[] {
    const result: FormattedScheduledUnit[] = [];
    const partialUnitIds = this.scheduledUnitsSelection.selected;
    for (const partialUnitId of partialUnitIds) {
      const scheduledUnit = this.scheduledUnits.find(c => c.partialUnitId === partialUnitId);
      if (scheduledUnit) {
        result.push(scheduledUnit);
      }
    }
    return result;
  }

  getSelectedFilter(): ExportToProductionFilter {
    const filter = this.selectedExportToProductionFilter.replace(/ /g, '');
    switch (filter) {
      case ExportToProductionFilter[ExportToProductionFilter.NormalOrders]:
        return ExportToProductionFilter.NormalOrders;
      case ExportToProductionFilter[ExportToProductionFilter.AccessoriesOnly]:
        return ExportToProductionFilter.AccessoriesOnly;
      case ExportToProductionFilter[ExportToProductionFilter.PartsOrders]:
        return ExportToProductionFilter.PartsOrders;
      case ExportToProductionFilter[ExportToProductionFilter.Palletized]:
        return ExportToProductionFilter.Palletized;
      default:
        return ExportToProductionFilter.NormalOrders;
    }
  }

  async getProductionMoldingSettings() {
    try {
      const settings = await this.settingsService.getProductionMoldingSettings().toPromise();
      this.productionMoldingSettings = settings.map(s => { return { value: s, display: this.format(s) } });
    } catch (err) {
      console.error('Error getting production molding settings.', err);
      this.snackBarService.showError('Error getting production molding settings.');
    }
  }

  format(setting: ProductionMoldingSetting): string {
    return `Color: ${setting.color} - Door Style: ${setting.doorStyle} - Molding Length: ${setting.friendlyMoldingLength} - Channel Groove: ${setting.friendlyChannelGroove}`;
  }

  setProductionMoldingSetting() {
    if (this.productionMoldingSettingSelected.valid && this.productionMoldingSettingSelected.value) {
      const { color, doorStyle, moldingLength, channelGroove, friendlyMoldingLength, friendlyChannelGroove } = this.productionMoldingSettingSelected.value;
      const scheduledUnits = this.getScheduledUnitsSelected();
      for (const item of scheduledUnits) {
        if (item.color === color && item.style === doorStyle) {
          item.moldingLength = moldingLength;
          item.channelGroove = channelGroove;
          item.friendlyMoldingLength = friendlyMoldingLength;
          item.friendlyChannelGroove = friendlyChannelGroove;
        }
      }
      this.scheduledUnitsSelection.clear();
    }
  }

  finalizeExportEnabled(): boolean {
    if (this.scheduledUnits.every(c => c.isAccessories)) return true;
    return this.scheduledUnits.every(c => c.moldingLength > 0 && c.channelGroove > 0);
  }

  async exportToProduction(): Promise<void> {
    this.processing = true;
    this.startTime = new Date().getTime();
    this.filter = this.getSelectedFilter();
    try {
      if (this.productionLocationId === INVALID_LOCATION_ID) {
        this.snackBarService.showWarning('Invalid production location.');
        this.processing = !this.processing;
        return;
      }
      const result = await this.scheduleService.exportToProduction(this.startTime, this.productionLocationId, this.productionDate.value, this.filter).toPromise();

      this.totalCustomPartsScheduled = result.totalCustomPartsScheduled;
      this.totalCustomPartsExported = result.totalCustomPartsExported;
      this.scheduledUnits = result.scheduledUnits;

      if (this.scheduledUnits.length > 0) {
        this.currentStep = Steps.ExportToProductionExecuted;
        this.snackBarService.showSuccess('The scheduled units was exported successfully.');
      } else {
        this.currentStep = Steps.ExportToProduction;
        this.snackBarService.showWarning('No scheduled units were exported. Please try again, if the problem persists review the production calendar custom parts capacity or contact IT support.');
      }
    } catch (err) {
      this.scheduledUnits = [];
      this.currentStep = Steps.ExportToProduction;
      console.error('Error exporting to production.', err);
      this.snackBarService.showError('Error exporting to production. A rollback was applied. Please try again.');
    } finally {
      this.processing = false;
    }
  }

  async finalizeExportToProduction(): Promise<void> {
    this.processing = true;
    try {
      this.scheduledUnits = await this.scheduleService.finalizeExportToProduction(this.startTime, this.productionLocationId, this.productionDate.value, this.filter, this.scheduledUnits).toPromise();
      // send result to fresh desk if sync is enabled
      const result = await this.onExportProductionResultToFreshDesk(this.scheduledUnits).toPromise();
      if (result && result.length > 0) {
        this.syncFreshDeskReport = result;
        const syncSuccess = this.syncFreshDeskReport.filter(f => f.syncSuccess).length;
        const syncFail = this.syncFreshDeskReport.filter(f => !f.syncSuccess).length;
        this.syncFreshDeskState = this.syncFreshDeskReport.length === syncSuccess ? 'AllSyncComplete' : this.syncFreshDeskReport.length === syncFail ? 'AllSyncFail' : 'SyncWithErrors';
      }
      this.csvUrl = this.getCSVUrl();
      this.currentStep = Steps.FinalizeExportToProductionExecuted;
      this.showFinalizeExportToProductionMessage();

    } catch (err) {
      this.scheduledUnits = [];
      this.currentStep = Steps.ExportToProductionExecuted;
      console.error('Error finalizing export to production.', err);
      this.snackBarService.showError('Error finalizing export to production. A rollback was applied. Please try again.');
    } finally {
      this.processing = false;
    }
  }

  showFinalizeExportToProductionMessage() {
    if (this.isEnabledFreshDesk) {
      switch (this.syncFreshDeskState) {
        case 'AllSyncComplete':
          this.snackBarService.showSuccess('The production export was finalized successfully. The FreshDesk sync is enabled all items is now exported successfully.');
          break;
        case 'AllSyncFail':
          this.snackBarService.showWarning('The production export was finalized successfully. The FreshDesk sync is enabled an error has occurred in sync process.');
          break;
        case 'SyncWithErrors':
          this.snackBarService.showWarning('The production export was finalized successfully. The FreshDesk sync is enabled some items has not able to export.');
          break;
      }
    }
    else {
      this.snackBarService.showSuccess('The production export was finalized successfully.');
    }
  }

  async cancelExportToProduction(): Promise<void> {
    this.processing = true;
    try {
      await this.scheduleService.cancelExportToProduction(this.startTime, this.productionLocationId, this.productionDate.value, this.filter, this.scheduledUnits).toPromise();
      // reset PO#
      for (const u of this.scheduledUnits) {
        u.productionOrderNumber = '';
      }
      this.currentStep = Steps.CancelExportToProductionExecuted;
      this.snackBarService.showSuccess('The cancel export was applied successfully.');
    } catch (err) {
      this.currentStep = Steps.ExportToProductionExecuted;
      console.error('Error cancelling export to production.', err);
      this.snackBarService.showError('Error cancelling export to production. Please try again.');
    } finally {
      this.processing = false;
    }
  }

  async getUnfinalizedExportedScheduledUnits() {
    try {
      this.processing = true;
      this.scheduledUnits = await this.scheduleService.getUnfinalizedExportedScheduledUnits(this.productionLocationId).toPromise();
      if (this.scheduledUnits.length > 0) {
        this.currentStep = Steps.ExportToProductionExecuted;
        this.snackBarService.showWarning('You must finalize a pending production export.');
      }
    } catch (err) {
      console.error('Error getting unfinalized exported scheduled units.', err);
      this.snackBarService.showError('Error getting unfinalized exported scheduled units.');
    } finally {
      this.processing = false;
    }
  }

  onExportProductionResultToFreshDesk(exportUnits: FormattedScheduledUnit[]): Observable<OrderStateFreshDesk[]> {
    if (this.isEnabledFreshDesk && exportUnits.length > 0) {
      const orders: OrderFreshDesk[] = exportUnits.map(unit => ({
        sales_order: String(unit.partialUnitId),
        color: unit.color,
        invoice_amount: unit.apartmentNumber,
        invoice_amount_w_tax: String(unit.numberOfParts),
        invoice_date: unit.project,
        parts_order: unit.isPartsOrder ? 'TRUE' : 'FALSE',
        sales_order_1: moment(unit.productionDate).format('YYYY-MM-DD'),
        production_number: unit.productionOrderNumber,
        style: unit.style,
        sales_order_number2: unit.salesOrderNumber,
        customer_name: moment(unit.orderDate).format('YYYY-MM-DD'),
        accessory_only_order: unit.isAccessories ? 'TRUE' : 'FALSE',
        email: unit.customer
      }));

      if (orders.length > 0) {
        return this.scheduleService.syncNewOrdersToFreshDesk(orders);
      }
    }
    return of([]);
  }

  onSelectedFilterChange() {
    this.csvUrl = this.getCSVUrl();
  }

  getCSVUrl(): string {
    const filter = this.getSelectedFilter();
    return this.scheduleService.getExportedScheduleUrl(this.productionLocationId, this.productionDate.value, filter);
  }

  clearExportedScheduledUnits() {
    this.scheduledUnits = [];
    this.exportToProductionExecuted = false;
  }
}
