<div class="container">
    <mat-tab-group mat-align-tabs="right" color="accent" [(selectedIndex)]="tabIndex">
        <mat-tab (click)="changeTab()" label="Dashboard">
            <ng-template matTabContent>
                <app-resume-commissions></app-resume-commissions>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="changeTab()" label="Dashboard Region">
            <ng-template matTabContent>
                <app-resume-commission-regions></app-resume-commission-regions>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="changeTab()" label="Account Assignments" *ngIf="!isRolOnlyView">
            <ng-template matTabContent>
                <app-manager-commissions></app-manager-commissions>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="changeTab()" label="Customer Account Visibility">
            <ng-template matTabContent>
                <app-commission-customer-account></app-commission-customer-account>
            </ng-template>
        </mat-tab>
        <mat-tab (click)="changeTab()" label="Settings" *ngIf="!isRolOnlyView">
            <ng-template matTabContent>
                <app-settings-commissions></app-settings-commissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>