<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Production Limit Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/production-limits/edit/{{productionLimit?.id}}" mat-raised-button>Edit Production Limit</a>
  </div>

  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{productionLimit?.id}}</li>
      <li><strong>Customer:</strong> {{productionLimit?.customerName}}</li>
      <li *ngIf="productionLimit?.apartmentComplexName"><strong>Apartment Complex:</strong>
        {{productionLimit?.apartmentComplexName}}</li>
      <li><strong>Allowed Custom Parts Capacity:</strong> {{productionLimit?.allowedCustomPartsCapacity}}</li>
    </ul>
  </div>
</mat-card>
