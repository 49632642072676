import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { CustomerAudit } from '../models/customer-audit';
import { ApartmentComplexAudit } from '../models/apartment-complex-audit';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getCustomers(): Observable<CustomerAudit[]> {
    const url = this.config.buildURL('audit/customers');
    return this.http.get<CustomerAudit[]>(url);
  }

  getCommunities(customerId: number): Observable<ApartmentComplexAudit[]> {
    const url = this.config.buildURL('audit/communities');
    const params = new HttpParams().set('customerId', `${customerId}`);

    return this.http.get<ApartmentComplexAudit[]>(url, { params });
  }

  setCustomerAudit(model: CustomerAudit): Observable<void> {
    const url = this.config.buildURL('audit/setCustomerAudit');
    return this.http.post<void>(url, model);
  }

  setCommunitiesAudit(model: ApartmentComplexAudit[]): Observable<void> {
    const url = this.config.buildURL('audit/setCommunitiesAudit');
    return this.http.post<void>(url, model);
  }
}
