<div class="header-actions">
    <a routerLink="reason-create" mat-flat-button color="accent">Create new Rason Code</a>
</div>
<h1 class="mat-h1">
    Reason Codes
</h1>
<hr />
<mat-accordion>
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filters
            </mat-panel-title>
            <mat-panel-description>
                Filters Reason Codes
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div [formGroup]="filterForm">
                    <mat-form-field>
                        <input matInput placeholder="Code" formControlName="code">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Description" formControlName="desc">
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="categories && categories.length > 0">
                        <mat-label>Category</mat-label>
                        <mat-select #categorySelect formControlName="category">
                            <mat-option>- Pick Category -</mat-option>
                            <mat-option *ngFor="let category of categories" [value]="category">
                                {{ category?.category }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="checkboxes">
                    <mat-checkbox [(ngModel)]="filterApplyCost">Include apply for cost</mat-checkbox>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()"><mat-icon>filter_list</mat-icon></button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="reset-action-container" *ngIf="reasonsFilterApply">
    <button mat-raised-button (click)="clearFilters()">
  <mat-icon>clear</mat-icon> Reset Filters
</button>
</div>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblReasonCodes *ngIf="!loading && getData().length > 0" [dataSource]="getCategoryDataSource()" class="mat-elevation-z0 table">
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Reason Code </th>
            <td mat-cell *matCellDef="let row"> {{row?.code}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let row"> {{row?.description}} </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let row"> {{row?.createdBy}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let row"> {{row?.created | date }} </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let row"> {{ row?.category }} </td>
        </ng-container>
        <ng-container matColumnDef="isNoCharge">
            <th mat-header-cell *matHeaderCellDef> Apply For Charge </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.isNoCharge" [disableRipple]="true" (click)="$event.preventDefault()"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button matTooltip="Edit Reason Code" matTooltipPosition="above" (click)="editReasonCode(row)"><mat-icon color="accent">edit</mat-icon></button>
                <button mat-icon-button matTooltip="Delete Reason Code" matTooltipPosition="above"><mat-icon color="accent" (click)="disableReasonCode(row)">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>


</div>