<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Edit Role
  </h1>
  <hr />
  <p><strong>Role:</strong> {{role?.name}}</p>
  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="editRoleForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Name" formControlName="name" required>
        <mat-error *ngIf="editRoleForm.controls.name.invalid">{{getRoleErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Description" formControlName="description"></textarea>
      </mat-form-field>

      <button mat-raised-button color="accent">Update</button>
    </form>
  </div>
</mat-card>
