<div class="header-actions">
  <a routerLink="create" mat-flat-button color="accent">Create new Production Limit</a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Production Limits
  </h1>
  <hr />

  <h3 *ngIf="productionLimits.length === 0">No production limits to show.</h3>

  <table *ngIf="productionLimits.length > 0" mat-table [dataSource]="productionLimits" class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef> Customer </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.customerName}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="apartmentComplexName">
      <th mat-header-cell *matHeaderCellDef> Apartment Complex </th>
      <td mat-cell *matCellDef="let element"> {{element.apartmentComplexName}} </td>
    </ng-container>

    <ng-container matColumnDef="allowedCustomPartCapacity">
      <th mat-header-cell *matHeaderCellDef> Allowed Custom Parts Capacity </th>
      <td mat-cell *matCellDef="let element"> {{element.allowedCustomPartsCapacity}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="details/{{element.id}}" mat-icon-button matTooltip="Location Details" matTooltipPosition="above">
          <mat-icon color="accent">notes</mat-icon>
        </a>
        <a routerLink="edit/{{element.id}}" mat-icon-button matTooltip="Edit Location" matTooltipPosition="above">
          <mat-icon color="accent">edit</mat-icon>
        </a>
        <a routerLink="delete/{{element.id}}" mat-icon-button matTooltip="Delete Location" matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>