import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ArchivedLocation } from 'src/app/models/archived-location';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UnarchiveLocationConfirmationComponent } from './unarchive-location-confirmation/unarchive-location-confirmation.component';

@Component({
  selector: 'app-cl-archived-location',
  templateUrl: './cl-archived-location.component.html',
  styleUrls: ['./cl-archived-location.component.less']
})
export class ClArchivedLocationComponent implements OnInit, AfterViewInit {

  public _customerInfoService: CustomerService;
  public archivedLocation: ArchivedLocation[] = [];
  public locationsToUnArchice: ArchivedLocation[] = [];
  public dataSource = new MatTableDataSource<ArchivedLocation>();

  public archivedLocationDisplayedColumns: string[] =
    [
      'locationName'
      , 'locationID'
      , 'unArchive'];

    formControl: any;

    constructor(public dialogRef: MatDialogRef<ClArchivedLocationComponent>,
      private customerInfoService: CustomerService,
      private snackBarService: SnackbarService,
      public dialog: MatDialog,
      public formBuilder: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: { locations: ArchivedLocation[] }){
        this._customerInfoService = customerInfoService;
  
      this.dataSource.filterPredicate = ((data, filter) => {
        const a = !filter.word || data.locationName?.toLowerCase().includes(filter.word);
        return a;
      }) as (PeriodicElement, string) => boolean;
  
      this.formControl = formBuilder.group({
        word: ''
      });

      this.formControl.valueChanges.subscribe(value => {
        const filter = {...value, name: value?.name?.trim().toLowerCase()} as string;
        this.dataSource.filter = filter;
      });
    }

    tblSort: MatSort;
  @ViewChild('tblSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.tblSort = ms;
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.tblSort;
  }

  ngOnInit() {
    this.archivedLocation = this.data.locations;
    this.dataSource.data = this.archivedLocation;
  }

  closeWithOutSaving() {
    this.locationsToUnArchice = [];
    this.dialogRef.close();
  }

  addLocationToUnArchive(i: number, row: any, $event: any){
    if($event.checked){
      this.locationsToUnArchice.push(row);
    }else{
      this.locationsToUnArchice.splice(this.locationsToUnArchice.findIndex(item => item.locationID === row.locationID), 1)
    }
  }

  unArchiveSelectedLocations(){
    this.confirmUnArchivingModal();
  }

  confirmUnArchivingModal(){
    let locations = this.locationsToUnArchice;

    this.dialog
    .open(UnarchiveLocationConfirmationComponent, {
      width: '40%',
      height:'30%',
      disableClose: false,
      data: {
        locations
      },
    })
    .afterClosed()
    .subscribe((result: any) => {
      if(result.success){
        this.archivedLocation.forEach((value, index) =>{
          this.locationsToUnArchice.forEach((value2, index2) =>{
            if(value.id === value2.id){
              this.archivedLocation.splice(index,1);
              this.locationsToUnArchice.splice(index2,1);
            }
          });
        });
        this.archivedLocation = [...this.archivedLocation];
        this.locationsToUnArchice = [...this.locationsToUnArchice];
        this.dataSource.data = this.archivedLocation;
      }
      this.dialog.closeAll();
    });
  }

  closeXWithoutSaving(){
 this.locationsToUnArchice = [];
  }
}
