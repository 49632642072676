<div class="container">
    <mat-accordion class="accordion-headers-align mat-elevation-z2" multi>
        <mat-expansion-panel (opened)="onSalesPersonTypesOpened()">
            <mat-expansion-panel-header>
                <strong>Sales Person Types</strong>
            </mat-expansion-panel-header>
            <div class="container">
                <div class="loading" *ngIf="loading">
                    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
            <div class="header-actions">
                <button mat-flat-button color="accent" (click)="openSettingsModal(settingConfig.commissionPersonType, actionConfig.create, null)">Create new Sales Person Type</button>
            </div>
            <table mat-table #tblCommissionSalesPersonType *ngIf="!loading && getDataSalesPersonTypes().length > 0" [dataSource]="getSalesPersonTypesDataSource()" class="mat-elevation-z0 table">
                <!-- <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Id </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                </ng-container> -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button matTooltip="Edit" matTooltipPosition="right" (click)="openSettingsModal(settingConfig.commissionPersonType, actionConfig.edit, row)">
                            <mat-icon color="accent">edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsPersonType"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPersonType;"></tr>
            </table>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="onRegionsOpened()">
            <mat-expansion-panel-header>
                <strong>Regions</strong>
            </mat-expansion-panel-header>
            <div class="container">
                <div class="loading" *ngIf="loading">
                    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
                <div class="header-actions">
                    <button mat-flat-button color="accent" (click)="openRegionModal(actionConfig.create, null)">Create new Region</button>
                </div>
                <table mat-table #tblRegions *ngIf="!loading && getDataRegion().length > 0" [dataSource]="getRegionsDataSource()" class="mat-elevation-z0 table">
                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef> Region </th>
                        <td mat-cell *matCellDef="let row"> {{row?.region}} </td>
                    </ng-container>
                    <ng-container matColumnDef="noStates">
                        <th mat-header-cell *matHeaderCellDef> No Associate States </th>
                        <td mat-cell *matCellDef="let row"> {{ row.stateList ? row.stateList.length : '-' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="isActive">
                        <th mat-header-cell *matHeaderCellDef> Active </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox [checked]="row.isActive" class="checkbox-readonly" [disableRipple]="true" (click)="$event.preventDefault()"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Edit" matTooltipPosition="right" (click)="openRegionModal(actionConfig.edit, row)">
                                <mat-icon color="accent">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRegions"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRegions;"></tr>
                </table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="onSalesRepOpened()">
            <mat-expansion-panel-header>
                <strong>Sales Team</strong>
            </mat-expansion-panel-header>
            <div class="container">
                <div class="loading" *ngIf="loading">
                    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
                <div class="header-actions">
                    <!-- <button mat-flat-button color="accent" (click)="openSettingsModal(settingConfig.commissionSales, actionConfig.create, null)">Create new Sales Rep</button> -->
                </div>
                <table mat-table #tblSalesRep *ngIf="!loading && getDataSalesReps().length > 0" [dataSource]="getSalesRepDataSource()" class="mat-elevation-z0 table">
                    <!-- <ng-container matColumnDef="salesPersonId">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let row"> {{row.salesPersonId}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="salesPersonName">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let row"> {{row?.salesPersonName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="emailAddress">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let row"> {{row.emailAddress}} </td>
                    </ng-container>
                    <ng-container matColumnDef="column">
                        <th mat-header-cell *matHeaderCellDef> header </th>
                        <td mat-cell *matCellDef="let row"> {{row.column}} </td>
                    </ng-container>
                    <ng-container matColumnDef="cellPhone">
                        <th mat-header-cell *matHeaderCellDef> Phone </th>
                        <td mat-cell *matCellDef="let row"> {{row.cellPhone}} </td>
                    </ng-container>
                    <ng-container matColumnDef="salesPersonType">
                        <th mat-header-cell *matHeaderCellDef> Person Type </th>
                        <td mat-cell *matCellDef="let row"> {{row?.salesPersonType}} </td>
                    </ng-container>
                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef> Region </th>
                        <td mat-cell *matCellDef="let row"> {{ row?.commissionRegionName  }} </td>
                    </ng-container>
                    <ng-container matColumnDef="isActive">
                        <th mat-header-cell *matHeaderCellDef> Active </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox [checked]="row.isActive" class="checkbox-readonly" [disableRipple]="true" (click)="$event.preventDefault()"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Edit" matTooltipPosition="left" (click)="openSettingsModal(settingConfig.commissionSales, actionConfig.edit, row)">
                                <mat-icon color="accent">edit</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Disable" matTooltipPosition="right" (click)="openSettingsModal(settingConfig.commissionSales, actionConfig.disable, row)">
                                <mat-icon color="accent">person_off</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsSalesRep"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsSalesRep;"></tr>
                </table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="onDataCommissionTypesOpened()">
            <mat-expansion-panel-header>
                <strong>Commission Types</strong>
            </mat-expansion-panel-header>
            <div class="container">
                <div class="loading" *ngIf="loading">
                    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
                <div class="header-actions">
                    <button mat-flat-button color="accent" (click)="openSettingsModal(settingConfig.commissionType, actionConfig.create, null)">Create new Commission Type</button>
                </div>
                <table mat-table #tblCommissionType *ngIf="!loading && getDataCommissionTypes().length > 0" [dataSource]="getCommissionTypeDataSource()" class="mat-elevation-z0 table">
                    <!-- <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="commissionAmount">
                        <th mat-header-cell *matHeaderCellDef> Commission Amount </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionAmount}} </td>
                    </ng-container>
                    <ng-container matColumnDef="commissionAmountType">
                        <th mat-header-cell *matHeaderCellDef> Commission Amount Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionAmountType}} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Edit" matTooltipPosition="right" (click)="openSettingsModal(settingConfig.commissionType, actionConfig.edit, row)">
                                <mat-icon color="accent">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCommissionType"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCommissionType"></tr>
                </table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="onCommissionTypeAssignmentsOpened()">
            <mat-expansion-panel-header>
                <strong>Assign Commission Types</strong>
            </mat-expansion-panel-header>
            <div class="container">
                <div class="loading" *ngIf="loading">
                    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
                <div class="header-actions">
                    <button mat-flat-button color="accent" (click)="openSettingsModal(settingConfig.commissionTypeAssignments, actionConfig.create, null)">Create new Assignment Commission Type</button>
                </div>
                <table mat-table #tblCommissionTypeAssignments *ngIf="!loading && getDataCommissionTypeAssignments().length > 0" [dataSource]="getCommissionTypeAssignmentsDataSource()" class="mat-elevation-z0 table">
                    <!-- <ng-container matColumnDef="commissionTypeAssignmentsId">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionTypeAssignmentsId}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="commissionDescription">
                        <th mat-header-cell *matHeaderCellDef> Commission Description </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionDescription}} </td>
                    </ng-container>
                    <ng-container matColumnDef="commissionAmount">
                        <th mat-header-cell *matHeaderCellDef> Commission Amount </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionAmount}} </td>
                    </ng-container>
                    <ng-container matColumnDef="salesPersonName">
                        <th mat-header-cell *matHeaderCellDef> Sale Person Type Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.salesPersonName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="commissionAmountType">
                        <th mat-header-cell *matHeaderCellDef> Commission Amount Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.commissionAmountType}} </td>
                    </ng-container>


                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Edit" matTooltipPosition="right" (click)="openSettingsModal(settingConfig.commissionTypeAssignments, actionConfig.edit, row)">
                                <mat-icon color="accent">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAssignments"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAssignments;"></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>