import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ReasonCategory } from 'src/app/models/reason-category';
import { ReasonCodesService } from '../../../services/reason-codes.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReasonCodesModalComponent } from '../reason-codes-modal/reason-codes-modal.component';
import { ReasonPrevent } from 'src/app/models/reason-code-config-modal';
import { ReasonCodeConfigModal, ReasonModalState } from '../../../models/reason-code-config-modal';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ReasonGroupsService } from 'src/app/services/reason-groups.service';
import { ReasonGroup } from 'src/app/models/reason-group';

@Component({
  selector: 'app-list-reason-categories',
  templateUrl: './list-reason-categories.component.html',
  styleUrls: ['./list-reason-categories.component.less']
})
export class ListReasonCategoriesComponent implements OnInit {
  loading = false;
  categoryFilterApply = false;
  formFilter: FormGroup;
  groups: ReasonGroup[] = [];

  @ViewChild(MatTable) tblCategories: MatTable<ReasonCategory>;
  categoriesDataSource = new MatTableDataSource<ReasonCategory>();
  filtercategoriesDataSource = new MatTableDataSource<ReasonCategory>();
  displayedColumns: string[] = [
    'categoryId',
    'category',
    'group',
    'createdBy',
    'created',
    'actions'
  ];

  constructor(
    private reasonService: ReasonCodesService,
    private snackService: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private groupsService: ReasonGroupsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.createCategoryForm();
    this.getReasonCategoriesAsync();
    this.getReasonGroupsAsync();
  }

  private createCategoryForm() {
    this.formFilter = this.fb.group({
      category: new FormControl(''),
      created: new FormControl(''),
      group: new FormControl('')
    });
  }

  async getReasonGroupsAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.groupsService.getReasonGroups().subscribe(
          (response: ReasonGroup[]) => this.groups = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the groups.');
      }
    }).then(() => this.loading = false);
  }

  async getReasonCategoriesAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.reasonService.getReasonCategories().subscribe(
          (response: ReasonCategory[]) => this.categoriesDataSource.data = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the categories.');
      }
    }).then(() => this.loading = false);
  }

  getCategoryDataSource(): MatTableDataSource<ReasonCategory> {
    return this.categoryFilterApply ? this.filtercategoriesDataSource : this.categoriesDataSource;
  }

  getData(): ReasonCategory[] {
    return this.categoryFilterApply ? this.filtercategoriesDataSource.connect().value : this.categoriesDataSource.connect().value;
  }

  applyFilters() {
    this.filtercategoriesDataSource.data = this.categoriesDataSource.data;

    const category = this.formFilter.get('category');
    const created = this.formFilter.get('created');
    const group = this.formFilter.get('group');

    if (category && category.value) {
      this.filtercategoriesDataSource.data = this.filterByCategory();
      this.categoryFilterApply = true;
    }

    if (created && created.value) {
      this.filtercategoriesDataSource.data = this.filterByCreatedBy();
      this.categoryFilterApply = true;
    }

    if (group && group.value) {
      this.filtercategoriesDataSource.data = this.filterByGroup();
      this.categoryFilterApply = true;
    }

  }

  private filterByCategory(): ReasonCategory[] {
    return this.filtercategoriesDataSource.data.filter((r) => {
      if (r.category !== null) {
        return r.category
          .toLowerCase()
          .includes(this.formFilter.get('category').value.trim().toLowerCase());
      }
    });
  }
  
  private filterByGroup(): ReasonCategory[] {
    return this.filtercategoriesDataSource.data.filter((r) => {
      if (r.reasonGroupId !== null) {
        return r.reasonGroupId === (this.formFilter.get('group').value as ReasonGroup).id;
      }
    });
  }

  private filterByCreatedBy(): ReasonCategory[] {
    return this.filtercategoriesDataSource.data.filter((r) => {
      if (r.createdBy !== null) {
        return r.createdBy
          .toLowerCase()
          .includes(this.formFilter.get('created').value.trim().toLowerCase());
      }
    });
  }

  clearFilters() {
    this.formFilter.setValue({
      category: '',
      created: '',
      group:''
    });

    this.categoryFilterApply = false;
  }

  editCategory(category: ReasonCategory) {
    if (category) {
      localStorage.setItem(`category-${category.categoryId}`, JSON.stringify(category));
      this.router.navigate([`reason-codes/category-edit/${category.categoryId}`]);
    }
  }

  disableCategory(category: ReasonCategory) {
    this.dialog.open(ReasonCodesModalComponent, {
      width: '40%',
      disableClose: true,
      data: {
        title: `Delete Categoy / ${category.category.toUpperCase()}`,
        message: 'The Delete action cannot be undone, please note that once this action is executed, all reason codes associated with this category will be automatically deleted and cannot be recovered.',
        typePrevent: ReasonPrevent.category,
        payload: category
      }
    }).afterClosed().subscribe((result: ReasonCodeConfigModal) => {
      switch (result.state) {
        case ReasonModalState.success:
          this.snackService.showSuccess('Category delete is successfully');
          const idx = this.categoriesDataSource.data.findIndex(fi => fi.categoryId === category.categoryId);
          if (idx !== -1) {
              this.categoriesDataSource.data.splice(idx, 1);
              this.tblCategories.renderRows();
              if (this.categoryFilterApply) {
                this.applyFilters();
              }
            }
          break;
        case ReasonModalState.error:
          this.snackService.showError('An error occurred during the delete operation.');
          break;
      }
    });

  }
}
