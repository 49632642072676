import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { ConfigService } from './config.service';
// Models
import { CommissionCustomer } from '../models/commission-customer';
import { CommissionCommunities } from '../models/commission-communities';
import { CommissionCommunitySalesSplit } from '../models/commission-community-sales-split';
import { CommissionSalesPerson } from '../models/commission-sales-person';
import { CommissionType } from '../models/commission-type';
import { CommissionTypeAssignments } from '../models/commission-type-assignments';
import { CommissionSalesPersonType } from '../models/commission-sales-person-type';
import { CommissionLedgers } from '../models/commission-ledgers';
import { ICommissionsFilters } from '../models/commissions-filters';
// Time
import * as moment from 'moment';
import { CommissionRequest, CommissionResponse } from '../models/commission-request';
import { environment } from 'src/environments/environment';
import { CommissionCustomerAccount } from '../models/commission-customer-account';
import { CommissionRegion, CommissionReportRegion, CommissionState } from '../models/commission-region';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  private managerCommissionsFilters : ICommissionsFilters = null;

  constructor(private config: ConfigService, private http: HttpClient) { }

  getCommissionCustomers(): Observable<CommissionCustomer[]> {
    return this.http.get<CommissionCustomer[]>(this.config.buildURL('commission/CommissionCustomers'));
  }

  updateCommissionCustomer(model: CommissionCustomer): Observable<CommissionCustomer> {
    return this.http.post<CommissionCustomer>(this.config.buildURL('commission/UpdateCommissionCustomer'), model);
  }

  getCommissionCommunitiesById(customerID: number): Observable<CommissionCommunities[]> {
    return this.http.get<CommissionCommunities[]>(this.config.buildURL(`commission/CommissionCommunitiesById/${customerID}`));
  }

  updateCommunity(customer: CommissionCommunities): Observable<CommissionCommunities> {
    return this.http.post<CommissionCommunities>(this.config.buildURL('commission/UpdateCommissionCommunity'), customer);
  }

  getCommissionAllSalesPerson(): Observable<CommissionSalesPerson[]> {
    return this.http.get<CommissionSalesPerson[]>(this.config.buildURL('commission/CommissionAllSalesPerson'));
  }

  createOrUpdateSalesSplits(model: CommissionCommunitySalesSplit[]): Observable<CommissionCommunitySalesSplit[]> {
    return this.http.post<CommissionCommunitySalesSplit[]>(this.config.buildURL('commission/CreateOrUpdateSalesSplits'), model);
  }

  deleteSalesSplit(saleSplitID: number) {
    return this.http.delete(this.config.buildURL(`commission/DeleteSalesSplit/${saleSplitID}`));
  }

  getAllSalesPersonsType(): Observable<CommissionSalesPersonType[]> {
    return this.http.get<CommissionSalesPersonType[]>(this.config.buildURL('commission/SalesPersonsType'));
  }

  createSalePersonType(model: CommissionSalesPersonType): Observable<CommissionSalesPersonType> {
    return this.http.post<CommissionSalesPersonType>(this.config.buildURL('commission/CreateSalePersonType'), model);
  }

  updateSalePersonType(model: CommissionSalesPersonType): Observable<CommissionSalesPersonType> {
    return this.http.put<CommissionSalesPersonType>(this.config.buildURL('commission/UpdateSalePersonType'), model);
  }

  getAllCommissionType(): Observable<CommissionType[]> {
    return this.http.get<CommissionType[]>(this.config.buildURL('commission/GetAllCommissionType'));
  }

  createCommissionType(model: CommissionType): Observable<CommissionType> {
    return this.http.post<CommissionType>(this.config.buildURL('commission/CreateCommissionType'), model);
  }

  updateCommissionType(model: CommissionType): Observable<CommissionType> {
    return this.http.put<CommissionType>(this.config.buildURL('commission/UpdateCommissionType'), model);
  }

  createSalesPerson(model: CommissionSalesPerson): Observable<CommissionSalesPerson> {
    return this.http.post<CommissionSalesPerson>(this.config.buildURL('commission/CreateSalesPerson'), model);
  }

  updateSalesPerson(model: CommissionSalesPerson): Observable<CommissionSalesPerson> {
    return this.http.put<CommissionSalesPerson>(this.config.buildURL('commission/UpdateSalesPerson'), model);
  }

  disableSalesPerson(salesPersonId: number): Observable<void> {
    return this.http.put<void>(`${this.config.buildURL('commission/DisableSalesPerson')}/${salesPersonId}`, {});
  }

  getAllCommissionTypeAssignments(): Observable<CommissionTypeAssignments[]> {
    return this.http.get<CommissionTypeAssignments[]>(this.config.buildURL('commission/GetAllCommissionTypeAssignments'));
  }

  createCommissionTypeAssignments(model: CommissionTypeAssignments): Observable<CommissionTypeAssignments> {
    return this.http.post<CommissionTypeAssignments>(this.config.buildURL('commission/CreateCommissionTypeAssignments'), model);
  }

  updateCommissionTypeAssignments(model: CommissionTypeAssignments): Observable<CommissionTypeAssignments> {
    return this.http.put<CommissionTypeAssignments>(this.config.buildURL('commission/UpdateCommissionTypeAssignments'), model);
  }

  getAllSalesCommissionLedger(): Observable<CommissionLedgers[]> {
    return this.http.get<CommissionLedgers[]>(this.config.buildURL('commission/SalesCommissionLedger'));
  }

  getAllSalesCommissionLedgerByFilters(from: Date, to: Date, salesPersonIds: number[]): Observable<CommissionLedgers[]> {
    return this.http.post<CommissionLedgers[]>(this.config.buildURL('commission/SalesCommissionLedgerByFilters'), {
      fromDate: moment(from).format(),
      toDate: moment(to).format(),
      salesPersonIds
    });
  }

  createCommissionReport(from: Date, to: Date, salesPersonsMails: string[]): Promise<any> {
    return this.http.post(this.config.buildURL('commission/DownloadCommissionReport'), {
      fromDate: moment(from).format(),
      toDate: moment(to).format(),
      salesPersonsMails
    }, { responseType: 'arraybuffer' }).toPromise();
  }

  createXlsxCommissionReport(from: Date, to: Date, salesPersonIds: number[]): Promise<any> {
    return this.http.post(this.config.buildURL('commission/SalesCommissionXlsxReport'), {
      fromDate: moment(from).format(),
      toDate: moment(to).format(),
      salesPersonIds
    }, { responseType: 'arraybuffer' }).toPromise();
  }

  getStatesOfCommissionProcess(): Observable<CommissionResponse[]> {
    return this.http.post<CommissionResponse[]>(`${environment.ledgerFunction}`, {
      userExecute: 'system',
      startRange: moment().format(),
      endRange: moment().format(),
      onlyGetListProcess: true
    });
  }

  recalculateCommissionPlan(body: CommissionRequest): Observable<CommissionResponse> {
    return this.http.post<CommissionResponse>(`${environment.ledgerFunction}`, body);
  }

  getAllCommissionCustomerAccount(): Observable<CommissionCustomerAccount[]> {
    return this.http.get<CommissionCustomerAccount[]>(this.config.buildURL('commission/CommissionCustomerAccount'));
  }

  getCommissionCustomerAccountBySalePerson(salePersonMail: string): Observable<CommissionCustomerAccount[]> {
    return this.http.get<CommissionCustomerAccount[]>(this.config.buildURL( `commission/CommissionCustomerAccountBySalePerson/${salePersonMail}`));
  }

  updateCommissionsFilters(commissionsFilters: ICommissionsFilters) : void {
    this.managerCommissionsFilters = commissionsFilters;
  }

  getCommissionsFilters() : ICommissionsFilters {
    return this.managerCommissionsFilters;
  }

  getCommissionRegions(): Observable<CommissionRegion[]> {
    return this.http.get<CommissionRegion[]>(this.config.buildURL('commission/GetCommissionRegions'));
  }

  getCommissionStates(): Observable<CommissionState[]> {
    return this.http.get<CommissionState[]>(this.config.buildURL('commission/GetCommissionStates'));
  }

  createCommissionRegions(model: CommissionRegion): Observable<CommissionRegion> {
    return this.http.post<CommissionRegion>(this.config.buildURL('commission/CreateCommissionRegions'), model);
  }

  updateCommissionRegions(model: CommissionRegion): Observable<CommissionRegion> {
    return this.http.put<CommissionRegion>(this.config.buildURL('commission/UpdateCommissionRegions'), model);
  }

  getCommissionRegionReportByFilters(from: Date, to: Date, salesPersonIds: number[]): Observable<CommissionReportRegion[]> {
    return this.http.post<CommissionReportRegion[]>(this.config.buildURL('commission/ReportCommissionPerRegions'), {
      fromDate: moment(from).format(),
      toDate: moment(to).format(),
      salesPersonIds
    });
  }
}
