<mat-accordion>
    <mat-expansion-panel expanded="true" class="" [hideToggle]="true">
        <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
            <mat-panel-title>
                {{ title }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="details">
            <div class="content">
                <ul *ngIf="type === 'from' && originAddress">
                    <li>
                        <div class="details-label">Name:</div>
                        <div class="details-label-value">{{originAddress.name}}</div>
                    </li>
                    <li>
                        <div class="details-label">Address 1:</div>
                        <div class="details-label-value">{{originAddress.address1}}</div>
                    </li>
                    <li>
                        <div class="details-label">Address 2:</div>
                        <div class="details-label-value">{{originAddress.address2}}</div>
                    </li>
                    <li>
                        <div class="details-label">Zip:</div>
                        <div class="details-label-value">{{originAddress.zip}}</div>
                    </li>
                    <li>
                        <div class="details-label">City:</div>
                        <div class="details-label-value">{{originAddress.city}}</div>
                    </li>
                    <li>
                        <div class="details-label">State:</div>
                        <div class="details-label-value">{{originAddress.state}}</div>
                    </li>
                    <li>
                        <div class="details-label">Country:</div>
                        <div class="details-label-value">{{originAddress.country}}</div>
                    </li>
                </ul>

                <ul *ngIf="type === 'to' && customerAddress">
                    <li>
                        <div class="details-label">Name:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.customerName">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">Address 1:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.address1">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">Address 2:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.address2">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">Zip:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.zip">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">City:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.city">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">State:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.state">
                        </div>
                    </li>
                    <li>
                        <div class="details-label">Country:</div>
                        <div class="details-label-value">{{customerAddress.country}}</div>
                    </li>
                    <li>
                        <div class="details-label">Phone Number:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.phoneNumber">
                        </div>

                        <div class="second-label">Ext:</div>
                        <div class="details-label-value">
                            <input matInput [(ngModel)]="customerAddress.phoneNumberExt">
                        </div>
                    </li>
                    <li>
                        <mat-checkbox [(ngModel)]="customerAddress.isResidential" class="details-label"></mat-checkbox>
                        <div class="details-label-value">: This is a residential address</div>
                    </li>
                </ul>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>