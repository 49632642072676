<mat-accordion>
    <mat-expansion-panel expanded="true" class="" [hideToggle]="true">
        <mat-expansion-panel-header #panel (click)="panel._toggle()">
            <mat-panel-title>
                4. Rates & Transit Times
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="details">
            <div class="content">
                <div class="tblContainer">
                    <div *ngIf="error">
                        <span>{{error}}</span>
                    </div>
                    <div class="loading" *ngIf="loading">
                        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
                    </div>
                    <table *ngIf="!loading && !error" mat-table #tblPackages [dataSource]="source"
                        class="mat-elevation-z0 table" matSort #dateActiveSort="matSort">
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns">
                        </tr>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>Select</th>
                            <td mat-cell *matCellDef="let row">
                                <mat-radio-button [value]="row" (change)="radioSelected($event)" name="selectedRate">
                                </mat-radio-button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="serviceAndTransitTimes">
                            <th mat-header-cell *matHeaderCellDef>Service and Transit Times</th>
                            <td mat-cell *matCellDef="let row"> {{ row?.display.serviceAndTransitTimes }} </td>
                        </ng-container>
                        <ng-container matColumnDef="rate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Your Rate</th>
                            <td mat-cell *matCellDef="let row" style="text-align: left; padding-right: 0;"> {{ row?.display.rate | currency }} </td>
                        </ng-container>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="3">No data</td>
                        </tr>
                    </table>
                </div>
                <div>
                </div>
            </div>
        </div>
        <mat-action-row>
            <button mat-raised-button color="accent"
                [disabled]="!overnightService.packages || overnightService.packages.length === 0 || loading || overnightService.existingPaintPrimer || overnightService.packagingStatus"
                (click)="getRates()">Get Rates</button>
            <button mat-raised-button color="accent" [disabled]="!rowSelected" (click)="openConfirmationModal()">Send
                Rates</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>