<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Delete Account
  </h1>
  <hr />
  <p>Are you sure you want to delete this account?</p>
  <div class="content">
    <ul>
        <li><strong>Id:</strong> {{account?.id}}</li>
        <li><strong>UserName:</strong> {{account?.userName}}</li>
        <li><strong>Email:</strong> {{account?.email}}</li>
        <li><strong>Name:</strong> {{account?.name}}</li>
        <li><strong>Location:</strong> {{account?.location}}</li>
        <li><strong>Is Active:</strong> {{account?.isActive | active}}</li>
        <li><strong>Date Created:</strong> {{account?.dateCreated | date}}</li>
        <li><strong>Roles:</strong> {{account?.roles?.join(', ')}}</li>
    </ul>
  </div>
  <div class="actions">
    <button mat-raised-button color="accent" (click)="deleteAccount()">Delete</button>
  </div>
</mat-card>
