import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SettingsService } from "../../../../services/settings.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { ExpeditedProductionSetting } from "../../../../models/expedited-production-setting";

@Component({
  selector: "app-create-expedited-production",
  templateUrl: "./create-expedited-production.component.html",
  styleUrls: ["./create-expedited-production.component.less"],
})
export class CreateExpeditedProductionComponent {
  createExpeditedProductionForm: FormGroup;
  errorMessages: string[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {
    this.createForm();
  }

  createForm() {
    this.createExpeditedProductionForm = this.fb.group({
      description: ["", Validators.required],
      itemNumber: ["", this.itemNumberValidator],
      minimumFee: [null, [Validators.required, Validators.min(0)]],
      feePerPart: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      productionCapacityPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      productionType: ["", Validators.required],
      capacityCheck: [false]
    });
  }

  submit() {
    if (this.createExpeditedProductionForm.valid) {
      this.createExpeditedProductionSetting();
    }
  }

  createExpeditedProductionSetting() {
    const expeditedProductionSetting: ExpeditedProductionSetting =
      this.createExpeditedProductionForm.value;

    this.settingsService
      .createExpeditedProductionSetting(expeditedProductionSetting)
      .subscribe(
        (_) => {
          this.router.navigate(["/settings"]);
          this.snackBarService.showSuccess(
            "Expedited Production Setting created successfully."
          );
        },
        (err) => {
          console.error(err);
          this.errorMessages = ["Error creating Expedited Production Setting."];
          this.snackBarService.showError(
            "Error creating Expedited Production Setting."
          );
        }
      );
  }

  itemNumberValidator(control: AbstractControl): ValidationErrors | null {
    // Allow letters, numbers, hyphens, and underscores, but no dangerous special characters or only dots
    const pattern = /^[A-Za-z0-9-_]+$/;
  
    // Check if the control value is empty
    if (!control.value) {
      return { 'required': true };
    }
  
    // Check for string containing only dots
    if (control.value.trim() === '.' || control.value.trim().match(/^\.*$/)) {
      return { 'invalidFormat': 'Only dots are not allowed' };
    }
  
    // Check if the control value contains dangerous special characters
    if (/[\>\<\&\%\;\`\/]+/.test(control.value)) {
      return { 'dangerousCharacter': 'Contains dangerous characters' };
    }
  
    // Check if the control value does not match the pattern
    if (!pattern.test(control.value)) {
      return { 'patternMismatch': true };
    }
  
    return null;
  }
}
