import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '../models/router';

@Injectable({
  providedIn: 'root'
})
export class RoutersService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getRouters(): Observable<Router[]> {
    const url = this.config.buildURL('routers');
    return this.http.get<Router[]>(url);
  }

  getRoutersByProductionLocation(productionLocationId: number): Observable<Router[]> {
    const url = this.config.buildURL('routers/routersByProductionLocation');
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`)
    return this.http.get<Router[]>(url, { params });
  }

  getRouter(routerId: number): Observable<Router> {
    const url = this.config.buildURL(`routers/${routerId}`);
    return this.http.get<Router>(url);
  }

  createRouter(model: Router): Observable<Router> {
    const url = this.config.buildURL(`routers`);
    return this.http.post<Router>(url, model);
  }

  updateRouter(model: Router): Observable<Router> {
    const url = this.config.buildURL(`routers/${model.id}`);
    return this.http.put<Router>(url, model);
  }

  deleteRouter(routerId: number) {
    const url = this.config.buildURL(`routers/${routerId}`);
    return this.http.delete(url);
  }
}
