import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ReasonCode } from '../../../models/reason-code';
import { SnackbarService } from '../../../services/snackbar.service';
import { ReasonCodesService } from '../../../services/reason-codes.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReasonCodesModalComponent } from '../reason-codes-modal/reason-codes-modal.component';
import { ReasonModalState, ReasonPrevent } from 'src/app/models/reason-code-config-modal';
import { ModalSettingConfig } from '../../../models/commission-general';
import { ReasonCodeConfigModal } from '../../../models/reason-code-config-modal';
import { ReasonCategory } from '../../../models/reason-category';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-list-reason-codes',
  templateUrl: './list-reason-codes.component.html',
  styleUrls: ['./list-reason-codes.component.less']
})
export class ListReasonCodesComponent implements OnInit {
  loading = false;
  reasonsFilterApply = false;
  categories: ReasonCategory[] = [];
  filterForm: FormGroup;
  filterApplyCost = false;

  @ViewChild(MatTable) tblReasonCodes: MatTable<ReasonCode>;
  codesDataSource = new MatTableDataSource<ReasonCode>();
  filterCodesDataSource = new MatTableDataSource<ReasonCode>();
  displayedColumns: string[] = [
    'code',
    'description',
    'createdBy',
    'created',
    'category',
    'isNoCharge',
    'actions'
  ];

  constructor(
    private snackService: SnackbarService,
    private reasonService: ReasonCodesService,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.createFilterForm();
    await this.getReasonCodesAsync();
    await this.getReasonCategoriesAsync();
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      code: new FormControl(''),
      desc: new FormControl(''),
      category: new FormControl('')
    });
  }

  private async getReasonCategoriesAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.reasonService.getReasonCategories().subscribe(
          (response: ReasonCategory[]) => this.categories = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the categories.');
      }
    }).then(() => this.loading = false);
  }

  private async getReasonCodesAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) =>  {
      try {
       this.reasonService.getReasonCodes().subscribe(
         (response: ReasonCode[]) => this.codesDataSource.data = response,
         (err) => {
           console.error(err);
           reject();
         },
         () => resolve());
      } catch (error) {
        console.error(error);
        reject();
        this.snackService.showError('An error occurred during try to get reason codes.');
      }
    }).then(() => this.loading = false);
  }

  applyFilters() {
    this.filterCodesDataSource.data = this.codesDataSource.data;
    const code = this.filterForm.get('code');
    const desc = this.filterForm.get('desc');
    const category = this.filterForm.get('category');

    if (code && code.value) {
      this.filterCodesDataSource.data = this.filterByCode();
      this.reasonsFilterApply = true;
    }
    if (desc && desc.value) {
      this.filterCodesDataSource.data = this.filterByDescription();
      this.reasonsFilterApply = true;
    }
    if (category && category.value) {
      this.filterCodesDataSource.data = this.filterByCategory();
      this.reasonsFilterApply = true;
    }
    if (this.filterApplyCost) {
      this.filterCodesDataSource.data = this.filterByApplyCost();
      this.reasonsFilterApply = true;
    }
  }

  private filterByCode(): ReasonCode[] {
    return this.filterCodesDataSource.data.filter((r) => {
      if (r.code !== null) {
        return r.code
          .toLowerCase()
          .includes(this.filterForm.get('code').value.trim().toLowerCase());
      }
    });
  }

  private filterByDescription(): ReasonCode[] {
    return this.filterCodesDataSource.data.filter((r) => {
      if (r.description !== null) {
        return r.description
          .toLowerCase()
          .includes(this.filterForm.get('desc').value.trim().toLowerCase());
      }
    });
  }

  private filterByCategory(): ReasonCode[] {
    return this.filterCodesDataSource.data.filter((r) => {
      if (r.categoryId !== null) {
        return r.categoryId === (this.filterForm.get('category').value as ReasonCategory).categoryId;
      }
    });
  }

  private filterByApplyCost(): ReasonCode[] {
    return this.filterCodesDataSource.data.filter((r) => {
      if (r.isNoCharge !== null) {
        return r.isNoCharge === this.filterApplyCost;
      }
    });
  }

  clearFilters() {
    this.filterForm.setValue({
      code: '',
      desc: '',
      category: ''
    });
    this.reasonsFilterApply = false;
  }


  getData(): ReasonCode[] {
    return this.reasonsFilterApply ? this.filterCodesDataSource.connect().value : this.codesDataSource.connect().value;
  }

  getCategoryDataSource(): MatTableDataSource<ReasonCode> {
    return this.reasonsFilterApply ? this.filterCodesDataSource : this.codesDataSource;
  }

  editReasonCode(reasonCode: ReasonCode) {
    if (reasonCode) {
      localStorage.setItem(`reason-${reasonCode.reasonCodeId}`, JSON.stringify(reasonCode));
      this.router.navigate([`reason-codes/reason-edit/${reasonCode.reasonCodeId}`]);
    }
  }

  disableReasonCode(reasonCode: ReasonCode) {
    this.dialog.open(ReasonCodesModalComponent, {
      width: '40%',
      disableClose: true,
      data: {
        title: `Delete Reason Code - ${reasonCode.code.toUpperCase()}`,
        message: 'The Delete action cannot be undone, consider that once this action is executed, the code will no longer be available in the current list of codes for the selection of clients',
        typePrevent: ReasonPrevent.reason,
        payload: reasonCode
      }
    }).afterClosed().subscribe(
      (result: ReasonCodeConfigModal) => {
        switch (result.state) {
          case ReasonModalState.success:
            this.snackService.showSuccess('Reason Code delete is successfully');
            const idx = this.codesDataSource.data.findIndex(fi => fi.reasonCodeId === reasonCode.reasonCodeId);
            if (idx !== -1) {
              this.codesDataSource.data.splice(idx, 1);
              this.tblReasonCodes.renderRows();
              if (this.reasonsFilterApply) {
                this.applyFilters();
              }
            }
            break;
          case ReasonModalState.error:
            this.snackService.showError('An error occurred during the delete operation.');
            break;
        }
      }
    );
  }

}
