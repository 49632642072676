import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// Base configuration
import { ConfigService } from './config.service';
// Models
import { NotificationContact } from '../models/notification-contact';
import { NotificationOrder } from '../models/notification-order';

@Injectable({
  providedIn: 'root'
})
export class OrderNotificationService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getNotificationsContacts(): Observable<NotificationContact[]> {
    return this.http.get<NotificationContact[]>(this.config.buildURL('OrderNotification/NotificationsContacts'));
  }

  getNotificationOrders(): Observable<NotificationOrder[]> {
    return this.http.get<NotificationOrder[]>(this.config.buildURL('OrderNotification/NotificationOrders'));
  }

  createNotificationOrder(notificationOrder: NotificationOrder): Observable<NotificationOrder> {
     return this.http.post<NotificationOrder>(this.config.buildURL('OrderNotification/CreateNotificationOrder'), notificationOrder);
  }

  disableNotification(notificationOrder: NotificationOrder): Observable<any> {
    return this.http.put<any>(this.config.buildURL('OrderNotification/DisableNotification'), notificationOrder);

  }
}
