import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductionCalendar } from '../models/production-calendar';
import { ProductionCalendarEvent } from '../models/production-calendar-event';
import { Color } from '../models/color';
import { DoorStyle } from '../models/door-style';
import { MaterialSupplier } from '../models/material-supplier';
import * as moment from 'moment';
import { MaterialSupplierConfiguration } from '../models/material-supplier-configuration';

@Injectable({
  providedIn: 'root'
})
export class ProductionCalendarService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getDate(): Observable<string> {
    const url = this.config.buildURL('productionCalendar/getDate');
    return this.http.get<string>(url);
  }

  getProductionCalendar(id: number): Observable<ProductionCalendar> {
    const url = this.config.buildURL(`productionCalendar/${id}`);
    return this.http.get<ProductionCalendar>(url);
  }

  getColors(): Observable<Color[]> {
    const url = this.config.buildURL(`productionCalendar/getColors`);
    return this.http.get<Color[]>(url);
  }

  getDoorStyles(): Observable<DoorStyle[]> {
    const url = this.config.buildURL(`productionCalendar/getDoorStyles`);
    return this.http.get<DoorStyle[]>(url);
  }

  getProductionCalendarBy(productionLocationId: number, date: Date): Observable<ProductionCalendar> {
    const url = this.config.buildURL('productionCalendar/getBy');
    const params = new HttpParams()
      .set('productionLocationId', `${productionLocationId}`)
      .set('date', moment(date).format('YYYY-MM-DD'));

    return this.http.get<ProductionCalendar>(url, { params });
  }

  getEvents(productionLocationId: number, range: any): Observable<ProductionCalendarEvent[]> {
    const url = this.config.buildURL('productionCalendar/getEvents');
    const params = new HttpParams()
      .set('productionLocationId', `${productionLocationId}`)
      .set('start', moment(range.startStr).format('YYYY-MM-DD'))
      .set('end', moment(range.endStr).format('YYYY-MM-DD'));
    return this.http.get<ProductionCalendarEvent[]>(url, { params });
  }

  getMaterialSuppliers(): Observable<MaterialSupplier[]> {
    const url = this.config.buildURL('productionCalendar/getMaterialSuppliers');
    return this.http.get<MaterialSupplier[]>(url);
  }

  updateMaterialSuppliers(model: MaterialSupplierConfiguration): Observable<MaterialSupplierConfiguration> {
    const url = this.config.buildURL('productionCalendar/updateMaterialSuppliers');
    return this.http.put<MaterialSupplierConfiguration>(url, model);
  }

  createProductionCalendar(model: ProductionCalendar): Observable<ProductionCalendar> {
    const url = this.config.buildURL(`productionCalendar`);
    return this.http.post<ProductionCalendar>(url, model);
  }

  updateProductionCalendar(model: ProductionCalendar): Observable<ProductionCalendar> {
    const url = this.config.buildURL(`productionCalendar/${model.id}`);
    return this.http.put<ProductionCalendar>(url, model);
  }

  deleteProductionCalendar(id: number) {
    const url = this.config.buildURL(`productionCalendar/${id}`);
    return this.http.delete(url);
  }
}
