import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SnackbarType } from 'src/app/models/snackbar-type';

@Component({
  selector: 'app-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.less']
})
export class SnackbarMessageComponent implements OnInit {

  icon = 'info';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
    this.setIcon();
  }

  setIcon() {
    if (this.data.type !== SnackbarType.Info && this.data.type !== SnackbarType.Success) {
      this.icon = `${this.data.type}`;
    }
  }

}
