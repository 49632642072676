import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductionOrder } from '../models/production-order';
import { ProductionOrderStats } from '../models/production-order-stats';
import { ProductionOrderFiles } from '../models/production-order-file';
import { ProductionPartsNotCartoned } from '../models/production-parts-not-cartoned';
import { AddressDetail } from '../models/address-detail';
import { PalletizedOrder, PalletizedOrderGrouped } from '../models/palletized-order';
import { PalletInfo } from '../models/pallet-info';
import { RouterSchedule } from '../models/router-schedule';
import { PalletUnitInfo } from '../models/pallet-unit-info';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrdersService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getProductionOrder(id: number): Observable<ProductionOrder> {
    const url = this.config.buildURL(`productionOrders/getProductionOrder`);
    const params = new HttpParams().set('id', `${id}`);
    return this.http.get<ProductionOrder>(url, { params });
  }

  getProductionOrdersByLocation(productionLocationId: number): Observable<ProductionOrder[]> {
    const url = this.config.buildURL(`productionOrders`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);
    return this.http.get<ProductionOrder[]>(url, { params });
  }

  getProductionOrdersByRouter(routerId: number): Observable<ProductionOrder[]> {
    const url = this.config.buildURL(`productionOrders/productionOrdersByRouter`);
    const params = new HttpParams().set('routerId', `${routerId}`);
    return this.http.get<ProductionOrder[]>(url, { params });
  }

  getProductionOrderStats(productionLocationId: number): Observable<ProductionOrderStats> {
    const url = this.config.buildURL(`productionOrders/getProductionOrderStats`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);

    return this.http.get<ProductionOrderStats>(url, { params });
  }

  getProductionOrderStatsByRouterId(productionLocationId: number, routerId?: number): Observable<ProductionOrderStats> {
    const url = this.config.buildURL(`productionOrders/GetProductionOrderStatsByRouterId`);
    let params = new HttpParams().set('productionLocationId', `${productionLocationId}`);
    if (routerId) {
      params = params.set('routerId', `${routerId}`);
    }
    return this.http.get<ProductionOrderStats>(url, { params });
  }

  getFiles(id: number, machine: string): Observable<ProductionOrderFiles> {
    const url = this.config.buildURL(`productionOrders/getFiles`);
    const params = new HttpParams().set('id', `${id}`).set('machine', machine);
    return this.http.get<ProductionOrderFiles>(url, { params });
  }

  printPaperwork(id: number, machine: string): Observable<boolean> {
    const url = this.config.buildURL(`productionOrders/printPaperwork`);
    const params = new HttpParams().set('id', `${id}`).set('machine', machine);
    return this.http.get<boolean>(url, { params });
  }

  getIPAddress(): Observable<string> {
    const url = this.config.buildURL(`productionOrders/getIPAddress`);
    return this.http.get<string>(url);
  }

  updateProductionOrder(model: ProductionOrder, status: string, routerScheduleId?: number, startTime?: Date, unlockProductionOrderFiles: boolean = false, unlockPrintPaperwork: boolean = false): Observable<ProductionOrder> {
    const url = this.config.buildURL(`productionOrders/${model.id}`);
    const gibenStartTime = startTime ? startTime.toLocaleString() : startTime;

    return this.http.put<ProductionOrder>(url, { status, routerScheduleId, gibenStartTime, unlockProductionOrderFiles, unlockPrintPaperwork, lotNumber: model.lotNumber, gibenNotes: model.gibenNotes });
  }

  getProductionOrdersNotCartoned(): Observable<ProductionPartsNotCartoned[]> {
    const url = this.config.buildURL(`production/getProductionPartsLockedInNotCartonedReport`);
    return this.http.get<ProductionPartsNotCartoned[]>(url);
  }

  getCustomerInfo(salesOrderNumber: string): Observable<AddressDetail> {
    const url = this.config.buildURL(`productionOrders/customerInfo`);
    const params = new HttpParams().set('salesOrderNumber', `${salesOrderNumber}`);
    return this.http.get<AddressDetail>(url, { params });
  }

  getIsRetail(salesOrderNumber: string): Observable<boolean> {
    const url = this.config.buildURL(`productionOrders/isRetail`);
    const params = new HttpParams().set('salesOrderNumber', `${salesOrderNumber}`);
    return this.http.get<boolean>(url, { params });
  }

  getPalletizedOrders(filter: string = null): Observable<PalletizedOrderGrouped[]> {
    const url = this.config.buildURL(`productionOrders/palletized-orders`);
    const params = new HttpParams().set('filter', `${filter}`);
    return this.http.get<PalletizedOrderGrouped[]>(url, { params });
  }

  getPalletizeInfo(id: number): Observable<PalletInfo> {
    const url = this.config.buildURL(`productionOrders/pallet-form`);
    const params = new HttpParams().set('id', `${id}`);
    return this.http.get<PalletInfo>(url, { params });
  }

  getRouterSchedules(productionLocationId: number): Observable<RouterSchedule[]> {
    const url = this.config.buildURL('productionOrders/RouterSchedules');
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);
    return this.http.get<RouterSchedule[]>(url, { params });
  }
}
