import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Account } from 'src/app/models/account';
import { AuthService } from 'src/app/services/auth.service';
import { ReasonCodesService } from 'src/app/services/reason-codes.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReasonCategory } from '../../../models/reason-category';
import { ReasonGroupsService } from 'src/app/services/reason-groups.service';
import { ReasonGroup } from 'src/app/models/reason-group';

@Component({
  selector: 'app-edit-reason-category',
  templateUrl: './edit-reason-category.component.html',
  styleUrls: ['./edit-reason-category.component.less']
})
export class EditReasonCategoryComponent implements OnInit {
  idKey: any;
  loading = false;
  category: ReasonCategory;
  user: Account;
  categoryForm: FormGroup;
  groups: ReasonGroup[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private reasonService: ReasonCodesService,
    private snackService: SnackbarService,
    private fb: FormBuilder,
    private groupsService: ReasonGroupsService
    ) { }

  async ngOnInit(): Promise<void> {
    this.getCategory();
    await this.getReasonGroupsAsync();
    this.createCategoryForm();
    this.user = this.auth.getAccount();
  }

  private createCategoryForm() {
    this.categoryForm = this.fb.group({
      category: new FormControl(this.category.category, Validators.required),
      group: new FormControl(this.groups.find(f => f.id === this.category.reasonGroupId), Validators.required)
    });
  }

  async getReasonGroupsAsync() {
    this.loading = true;
    await new Promise<void>((resolve, reject) => {
      try {
        this.groupsService.getReasonGroups().subscribe(
          (response: ReasonGroup[]) => this.groups = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve());
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during try to get the groups.');
      }
    }).then(() => this.loading = false);
  }

  private getCategory() {
    this.idKey = this.route.snapshot.paramMap.get('id');
    if (this.idKey !== null) {
      this.category = JSON.parse(localStorage.getItem(`category-${this.idKey}`));
    } else {
      this.goBack();
    }
  }

  private clearCategory() {
    if (this.idKey !== null) {
      localStorage.removeItem(`category-${this.idKey}`);
    }
  }

  goBack() {
    this.clearCategory();
    this.router.navigate(['reason-codes', { tab: 1 }]);
  }

  async updateCategory(): Promise<void> {
    this.loading = true;
    await new Promise<ReasonCategory>((resolve, reject) => {
      const group: ReasonGroup = this.categoryForm.get('group').value;
      this.category.category = this.categoryForm.get('category').value;
      this.category.modified = new Date(moment().format());
      this.category.modifiedBy = this.user.userName;
      this.category.reasonGroup = group.groupName;
      this.category.reasonGroupId = group.id;
      try {
        this.reasonService.updateReasonCategory(this.category).subscribe(
          (response: ReasonCategory) => this.category = response,
          err => {
            console.error(err);
            reject();
          },
          () => resolve(this.category));
      } catch (error) {
        console.error(error);
        this.snackService.showError('An error occurred during the update operation.');
      }
    }).then((result: ReasonCategory) => {
      result.categoryId && result.categoryId !== 0 ?
        this.snackService.showSuccess('The category was updated successfully.') :
        this.snackService.showError('An error occurred during the update operation.');
      this.clearCategory();
      this.loading = false;
      this.router.navigate(['reason-codes', { tab: 1 }]);
    });
  }


}
