<mat-card>
    <h1 class="mat-h1">
        Export to Production
        <div>
            <button *ngIf="!fullScreenMode()" mat-mini-fab color="primary" (click)="setFullScreenMode()"
                matTooltip="Fullscreen Mode">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button *ngIf="fullScreenMode()" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
                matTooltip="Exit Fullscreen Mode">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
        </div>
    </h1>
    <hr />

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
        <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <div class="container">
        <mat-accordion>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title> Filters </mat-panel-title>
                    <mat-panel-description>
                        Filter Scheduled Units
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="filters">
                    <mat-form-field>
                        <mat-label>Production Location</mat-label>
                        <mat-select [value]="productionLocationId" [disabled]="formDisabled()"
                            (selectionChange)="onProductionLocationChange($event)" required>
                            <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="productionLocationId < 0">
                            You must select a production location
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput [disabled]="formDisabled()" [matDatepicker]="picker"
                            placeholder="Production Date" [formControl]="productionDate"
                            (dateChange)="onProductionDateChange()" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngIf="customPartsCapacity!==-1">
                        <input matInput readonly [disabled]="formDisabled()" placeholder="Custom Parts Capacity"
                            type="number" [value]="customPartsCapacity">
                    </mat-form-field>
                    <mat-form-field *ngIf="allowedCustomPartsCapacity!==-1">
                        <input matInput readonly [disabled]="formDisabled()" placeholder="Allowed Custom Parts Capacity"
                            type="number" [value]="allowedCustomPartsCapacity">
                    </mat-form-field>
                </div>
                <div>
                    <label>Export Type</label>
                    <mat-radio-group [disabled]="formDisabled()" (change)="onSelectedFilterChange()"
                        aria-label="Select an option" [(ngModel)]="selectedExportToProductionFilter">
                        <mat-radio-button *ngFor="let filter of exportToProductionFilters" [value]="filter">
                            {{filter}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="actions">
            <a routerLink="/schedule-manager"
                *ngIf="currentStep === Steps.ExportToProduction || currentStep === Steps.FinalizeExportToProductionExecuted || currentStep === Steps.CancelExportToProductionExecuted"
                mat-raised-button [disabled]="processing">
                Back
            </a>

            <button
                *ngIf="currentStep === Steps.ExportToProduction || currentStep === Steps.FinalizeExportToProductionExecuted || currentStep === Steps.CancelExportToProductionExecuted"
                mat-raised-button color="accent" [disabled]="processing || !enableExport"
                (click)="exportToProduction()">
                Export
            </button>

            <button *ngIf="currentStep === Steps.ExportToProductionExecuted" mat-raised-button [disabled]="processing"
                (click)="cancelExportToProduction()">
                Cancel Export
            </button>

            <button *ngIf="currentStep === Steps.ExportToProductionExecuted" mat-raised-button color="accent"
                [disabled]="processing || !finalizeExportEnabled()" (click)="finalizeExportToProduction()">
                Finalize Export
            </button>

            <a *ngIf="currentStep === Steps.FinalizeExportToProductionExecuted" mat-raised-button color="accent"
                [href]="csvUrl" [disabled]="processing" target="_blank">
                Download CSV
            </a>
        </div>
        <div>
            <div class="loading" *ngIf="processing">
                <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Processing</span>
            </div>

            <div *ngIf="!processing && currentStep > Steps.ExportToProduction">
                <h3>Export Preview</h3>
                <div class="preview-results">
                    <p *ngIf="totalCustomPartsScheduled">
                        <strong>Custom Parts Scheduled:</strong> {{totalCustomPartsScheduled}}
                    </p>
                    <p *ngIf="totalCustomPartsExported">
                        <strong>Custom Parts Exported:</strong> {{totalCustomPartsExported}}
                    </p>
                    <p
                        *ngIf="totalCustomPartsScheduled && totalCustomPartsExported && totalCustomPartsScheduled > totalCustomPartsExported">
                        <strong>Custom Parts Remaining:</strong>
                        {{totalCustomPartsScheduled - totalCustomPartsExported}}
                    </p>
                </div>

                <p *ngIf="totalCustomPartsScheduled && totalCustomPartsExported && totalCustomPartsScheduled > totalCustomPartsExported && currentStep === Steps.ExportToProductionExecuted"
                    class="warning">
                    *Please export all custom parts scheduled before finalizing the export. You can cancel the export if
                    needed and increase the production calendar custom parts capacity.
                </p>

                <p *ngIf="scheduledUnits.length === 0">
                    No scheduled units to show.
                </p>

                <div class="molding-actions" *ngIf="currentStep === Steps.ExportToProductionExecuted">
                    <div>
                        <app-multi-select-autocomplete class="mat-autocomplete"
                            [formControl]="productionMoldingSettingSelected"
                            [placeholder]="'Production Molding Setting'" [options]="productionMoldingSettings"
                            ngDefaultControl [display]="'display'" [value]="'value'" [multiple]='false'>
                        </app-multi-select-autocomplete>
                    </div>
                    <div>
                        <button mat-raised-button color="accent" [disabled]="processing"
                            (click)="setProductionMoldingSetting()">
                            Set Molding Setting
                        </button>
                    </div>
                </div>

                <table *ngIf="scheduledUnits.length > 0" mat-table [dataSource]="scheduledUnits">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="scheduledUnitsSelection.hasValue() && isAllSelected()"
                                [indeterminate]="scheduledUnitsSelection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? toggleScheduledUnitsSelection($event, element) : null"
                                [checked]="scheduledUnitsSelection.isSelected(element.partialUnitId)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="poNumber">
                        <th mat-header-cell *matHeaderCellDef> PO # </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.productionOrderNumber" class="poNumber">
                                {{element.productionOrderNumber}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="productionDate">
                        <th mat-header-cell *matHeaderCellDef> Production Date </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="productionDate {{element.classType}}">{{element.productionDateStr}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="color">
                        <th mat-header-cell *matHeaderCellDef> Color </th>
                        <td mat-cell *matCellDef="let element"> {{element.color}} </td>
                    </ng-container>

                    <ng-container matColumnDef="style">
                        <th mat-header-cell *matHeaderCellDef> Style </th>
                        <td mat-cell *matCellDef="let element"> {{element.style}} </td>
                    </ng-container>

                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef> Customer </th>
                        <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
                    </ng-container>

                    <ng-container matColumnDef="project">
                        <th mat-header-cell *matHeaderCellDef> Project </th>
                        <td mat-cell *matCellDef="let element"> {{element.project}} </td>
                    </ng-container>

                    <ng-container matColumnDef="apartmentNumber">
                        <th mat-header-cell *matHeaderCellDef> Apt </th>
                        <td mat-cell *matCellDef="let element"> {{element.apartmentNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="salesOrderNumber">
                        <th mat-header-cell *matHeaderCellDef> SO #</th>
                        <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="numberOfParts">
                        <th mat-header-cell *matHeaderCellDef> Parts </th>
                        <td mat-cell *matCellDef="let element"> {{element.numberOfParts}} </td>
                    </ng-container>

                    <ng-container matColumnDef="pallet">
                        <th mat-header-cell *matHeaderCellDef> Pallet </th>
                        <td mat-cell *matCellDef="let element"> {{element.isPalletized | active}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fusion">
                        <th mat-header-cell *matHeaderCellDef> Fusion </th>
                        <td mat-cell *matCellDef="let element"> {{element.isFusion | active}} </td>
                    </ng-container>

                    <ng-container matColumnDef="moldingLength">
                        <th mat-header-cell *matHeaderCellDef> Molding Length </th>
                        <td mat-cell *matCellDef="let element"> {{element.friendlyMoldingLength}} </td>
                    </ng-container>

                    <ng-container matColumnDef="channelGroove">
                        <th mat-header-cell *matHeaderCellDef> Channel Groove </th>
                        <td mat-cell *matCellDef="let element"> {{element.friendlyChannelGroove}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</mat-card>