import { Component, OnInit } from '@angular/core';
import { CriteriaType } from '../../../models/promo-code-criteria-type';
import { CodeType, PromoCode } from '../../../models/promo-code';
import { ActivatedRoute, Router } from '@angular/router';
import { PromoCodesService } from '../../../services/promo-codes.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-delete-promo-code',
  templateUrl: './delete-promo-code.component.html',
  styleUrls: ['./delete-promo-code.component.less']
})
export class DeletePromoCodeComponent implements OnInit {

  promoCode: PromoCode;
  noChargePartsCodeType: CodeType = CodeType.NoChargeParts;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private promoCodesService: PromoCodesService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getPromoCode();
  }

  getPromoCode(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.promoCodesService.getPromoCode(id).subscribe((promoCode: PromoCode) => {
      this.promoCode = promoCode;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting promo code details.');
      });
  }

  format(word: string, delimiter: string = ' '): string {
    let result = '';
    if (word) {
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        const prevChar = word[i - 1];
        const tokenDelimiter = !isNaN(+char) && !isNaN(+prevChar) ? '' : delimiter;
        result += char === char.toUpperCase() ? `${tokenDelimiter}${char}` : char;
      }
    }
    return result.trim();
  }

  formatCriteriaType(type: CriteriaType): string {
    return this.format(CriteriaType[type]) || 'Unknown';
  }

  formatCodeType(type: CodeType): string {
    return this.format(CodeType[type]) || 'Unknown';
  }

  formatAmount(type: CodeType, value: number): string {
    switch (type) {
      case CodeType.FlatAmountOffPurchase:
        return `$${value}`;
      case CodeType.PercentAmountOffPurchase:
        return `${value}%`;
      case CodeType.ShippingDiscount:
        return `$${value}`;
      case CodeType.SomeNumberOfFreeCustomItems:
        return `${value}`;
      case CodeType.NoChargeParts:
        return 'No charge';
      default:
        return `${value}`;
    }
  }

  deletePromoCode(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.promoCodesService.deletePromoCode(id).subscribe(_ => {
      this.router.navigate([`/promo-codes`]);
      this.snackBarService.showSuccess('The promo code was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting promo code.');
      });
  }
}
