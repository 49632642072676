import { Component, OnInit } from '@angular/core';
import { AccountsService } from 'src/app/services/accounts.service';
import { Account } from '../../../models/account';
import { AccountRole } from 'src/app/models/account-role';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';

@Component({
  selector: 'app-account-roles',
  templateUrl: './account-roles.component.html',
  styleUrls: ['./account-roles.component.less']
})
export class AccountRolesComponent implements OnInit {

  account: Account;
  accountRoles: AccountRole[] = [];
  selectedOptions: string[];

  constructor(
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getAccount();
    this.getRoles();
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getAccount(id).subscribe((account: Account) => {
      this.account = account;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account details.');
      });
  }

  getRoles() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getRoles(id).subscribe((accountRoles: AccountRole[]) => {
      this.accountRoles = accountRoles;
      this.selectedOptions = this.accountRoles
        .filter(item => item.isActive)
        .map(item => item.role);
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account roles.');
      });
  }

  onSelectionChanged(list: MatSelectionList) {
    // determine selected options
    this.selectedOptions = list.selectedOptions.selected.map(item => item.value);
  }

  updateRoles() {
    const id = this.route.snapshot.paramMap.get('id');
    const getAccountRoles = () => {
      for (const accountRole of this.accountRoles) {
        accountRole.isActive = (this.selectedOptions.indexOf(accountRole.role) !== -1);
      }
      return this.accountRoles;
    };
    const accountRoles = getAccountRoles();
    this.accountsService.updateRoles(id, accountRoles).subscribe((result: AccountRole[]) => {
      this.accountRoles = result;
      this.selectedOptions = this.accountRoles
        .filter(item => item.isActive)
        .map(item => item.role);
      this.snackBarService.showSuccess('The account roles was updated successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error updating the account roles.');
      });
  }

}
