import { Injectable } from '@angular/core';
import { SnackbarType } from '../models/snackbar-type';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackbarMessageComponent } from '../components/snackbar-message/snackbar-message.component';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar, ) { }

  show(type: SnackbarType, message: string) {
    this.snackBar.openFromComponent(SnackbarMessageComponent,
      {
        data: { type, message },
        panelClass: this.getPanelClass(type)
      }
    );
  }

  showInfo(message: string) {
    this.show(SnackbarType.Info, message);
  }

  showError(message: string) {
    this.show(SnackbarType.Error, message);
  }

  showWarning(message: string) {
    this.show(SnackbarType.Warning, message);
  }

  showSuccess(message: string) {
    this.show(SnackbarType.Success, message);
  }

  private getPanelClass(type: SnackbarType): string {
    let panelClass = '';

    if (type === SnackbarType.Info) {
      panelClass = 'snackbar-info';
    } else if (type === SnackbarType.Warning) {
      panelClass = 'snackbar-warning';
    } else if (type === SnackbarType.Success) {
      panelClass = 'snackbar-success';
    } else if (type === SnackbarType.Error) {
      panelClass = 'snackbar-error';
    }

    return panelClass;
  }

}
