import { Component, OnInit } from '@angular/core';
import { Account } from '../../../models/account';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { AccountsService } from '../../../services/accounts.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EditForm } from '../../../forms/edit-form';
import { UserRole } from '../../../models/user-role';

const MAIN_ROLES = [UserRole.Admin];

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.less']
})
export class EditAccountComponent extends EditForm implements OnInit {

  account: Account;
  editAccountForm: UntypedFormGroup;
  allowedEditAccount = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private accountsService: AccountsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getAccount();
    this.allowedEditAccount = this.authService.isUserInRoles(MAIN_ROLES);
  }

  createForm() {
    this.editAccountForm = this.fb.group({
      userName: ['', Validators.required],
      email: ['', Validators.email],
      name: [''],
      location: [''],
      isActive: false
    });
  }

  isValid(): boolean {
    return this.editAccountForm.valid;
  }

  setValues() {
    this.editAccountForm.setValue({
      userName: this.account.userName,
      email: this.account.email,
      name: this.account.name,
      location: this.account.location,
      isActive: this.account.isActive
    });
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.updateAccount();
    }
  }

  getUserNameErrorMessage() {
    return this.editAccountForm.controls.userName.hasError('required') ? 'You must enter a username' : '';
  }

  getEmailErrorMessage() {
    return this.editAccountForm.controls.email.hasError('email') ? 'You must enter a valid email address' : '';
  }

  getAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.getAccount(id).subscribe((account: Account) => {
      this.account = account;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting the account details.');
      });
  }

  updateAccount() {
    const id = this.route.snapshot.paramMap.get('id');
    const model: Account = {
      id,
      userName: this.editAccountForm.controls.userName.value,
      email: this.editAccountForm.controls.email.value,
      name: this.editAccountForm.controls.name.value,
      location: this.editAccountForm.controls.location.value,
      isActive: this.editAccountForm.controls.isActive.value
    };

    this.accountsService.updateAccount(model).subscribe((account: Account) => {
      this.account = account;
      this.router.navigate(['/accounts']);
      this.snackBarService.showSuccess('The account information was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating the account information.');
    });
  }

  resetPassword() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountsService.resetPassword(id).subscribe(_ => {
      let message = 'The password was successfully reset.';

      const account = this.authService.getAccount();

      if (id === account.id) {
        message += 'Please sign in.';
        this.authService.signOut();
      }
      this.snackBarService.showSuccess(message);
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error resetting the user password.');
      });
  }
}
