<h2 mat-dialog-title>RDB order files</h2>
<mat-dialog-content class="mat-dialog-content">
    <p *ngIf="getRDBFilesDataSource().length === 0">No rdb files to show.</p>
    <table mat-table *ngIf="getRDBFilesDataSource().length" [dataSource]="getRDBFilesDataSource()"
        class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="sequence">
            <th mat-header-cell *matHeaderCellDef> Sequence </th>
            <td mat-cell *matCellDef="let element"> {{element.sequence}} </td>
        </ng-container>
        <ng-container matColumnDef="friendlyName">
            <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
            <td mat-cell *matCellDef="let element"> {{element.friendlyName}} </td>
        </ng-container>
        <ng-container matColumnDef="fileDownloaded">
            <th mat-header-cell *matHeaderCellDef> File Downloaded </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.fileDownloaded" class="downloaded">{{element.fileDownloaded | active}} </span>
                <span *ngIf="!element.fileDownloaded">{{element.fileDownloaded | active}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="dateTimeDownloaded">
            <th mat-header-cell *matHeaderCellDef> Downloaded Date </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.dateTimeDownloaded"> {{element.dateTimeDownloaded | date}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <a mat-icon-button *ngIf="element.fileDownloaded">
                    <mat-icon color="accent">check_circle</mat-icon>
                </a>
                <button *ngIf="!element.fileDownloaded" mat-icon-button matTooltip="Download file"
                    (click)="getFile(element)" matTooltipPosition="above">
                    <mat-icon color="accent">attach_file</mat-icon>
                </button>

                <button *ngIf="userIsAllowedToUnlockRDBOrderFiles()" mat-icon-button
                    matTooltip="Unlock file to download" (click)="unlockRdbFile(element.id)" matTooltipPosition="above">
                    <mat-icon color="accent">lock_open</mat-icon>
                </button>
            </td>
        </ng-container>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button [disabled]="disableCancelAction" mat-raised-button mat-dialog-close cdkFocusInitial (click)="cancel()">
        Cancel
    </button>
</mat-dialog-actions>