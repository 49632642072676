import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { CartonTypes } from '../models/carton-type';
import { Observable } from 'rxjs';
import { StandardItemShipInSetup } from '../models/standard-item-ship-in-setup';

@Injectable({
  providedIn: 'root'
})
export class ShippingItemSetupService {
  constructor(private config: ConfigService, private http: HttpClient) { }

  getCartonTypes(): Observable<CartonTypes[]> {
    const url = this.config.buildURL(`ShippingItemSetup/cartonTypes`);
    return this.http.get<CartonTypes[]>(url);
  }

  createItemSetup(itemSetup: StandardItemShipInSetup): Observable<string> {
    const url = this.config.buildURL('ShippingItemSetup/create');
    return this.http.post<string>(url, itemSetup);
  }

  getStandardItemShipInSetup(): Observable<StandardItemShipInSetup[]> {
    const url = this.config.buildURL('ShippingItemSetup');
    return this.http.get<StandardItemShipInSetup[]>(url);
  }

  getStandardItemShipInSetupById(id: string): Observable<StandardItemShipInSetup> {
    const url = this.config.buildURL(`ShippingItemSetup/${id}`);
    return this.http.get<StandardItemShipInSetup>(url);
  }

  deleteStandardItemShipInSetup(id: number): Observable<any> {
    const url = this.config.buildURL(`ShippingItemSetup/delete/${id}`);
    return this.http.delete(url);
  }

  getValidateStandardItem(itemNumber: string): Observable<boolean> {
    const encodedItemNumber = encodeURIComponent(itemNumber);
    const url = this.config.buildURL(`ShippingItemSetup/validateItemNumber/${encodedItemNumber}`);
    return this.http.get<boolean>(url);
  }

}
