import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { User } from '../../../models/User';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-cl-add-account-modal',
  templateUrl: './cl-add-account-modal.component.html',
  styleUrls: ['./cl-add-account-modal.component.less']
})
export class ClAddAccountModalComponent {

  public _customerInfoService: CustomerService;
  public userResponse: any;
  public user: User = {
    userEmail: '',
    customerNumber: '',
    password: ''
  };

  addAccountForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ClAddAccountModalComponent>,
     private formBuilder: FormBuilder,
    private customerInfoService: CustomerService,
    private snackBarService: SnackbarService){
      this.createForm();
      this._customerInfoService = customerInfoService;
  }

  closeWithOutSaving() {
    this.dialogRef.close();
  }

  async submit(){
    try {
      this.userResponse =  await this.customerInfoService.addAccount(this.user).toPromise();
      if(this.userResponse.isValid){
        this.snackBarService.showInfo(this.userResponse.message);
      }else{
      this.snackBarService.showError(this.userResponse.message);
      }
    } catch (error) {
      this.snackBarService.showError(error);
    }
  }

  createForm(){
    this.addAccountForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      customerNumber: ['', Validators.required]
    });
  }
}
