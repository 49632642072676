import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { ScanLookup, ScanLookupsExtended } from '../models/scan-lookup';

@Injectable({
  providedIn: 'root'
})
export class ScanLookupsService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getScanLookups(filterBy: string, filter: string): Observable<ScanLookupsExtended> {
    const url = this.config.buildURL(`scanLookups`);
    const params = new HttpParams().set('filterBy', filterBy).set('filter', filter);

    return this.http.get<ScanLookupsExtended>(url, { params });
  }
}
