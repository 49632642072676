import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Train } from '../models/train';
import { Rdb } from '../models/rdb';
import { ProductionOrderFiles } from '../models/production-order-file';

@Injectable({
  providedIn: 'root'
})
export class TrainsService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getTrain(id: number): Observable<Train> {
    const url = this.config.buildURL(`trains/${id}`);
    return this.http.get<Train>(url);
  }

  getTrains(productionLocationId: number): Observable<Train[]> {
    const url = this.config.buildURL(`trains/getTrains`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);
    return this.http.get<Train[]>(url, { params });
  }

  updateTrain(model: Train, status: string, unlock: boolean = false): Observable<Train> {
    const url = this.config.buildURL(`trains/${model.id}`);
    return this.http.put<Train>(url, { status, unlock });
  }

  unlockRdbFile(id): Observable<Rdb> {
    const url = this.config.buildURL(`trains/unlockRdbFile/${id}`);
    return this.http.put<Rdb>(url, { id });
  }

  getFile(machine: string, id: number): Observable<ProductionOrderFiles> {
    const url = this.config.buildURL(`trains/getFile`);
    const params = new HttpParams().set('machine', machine).set('id', `${id}`);
    return this.http.get<ProductionOrderFiles>(url, { params });
  }

  getFileUrl(path: string): string {
    return this.config.buildURL(`trains/${path}`);
  }

}
