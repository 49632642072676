import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExpeditedProductionSetting } from "src/app/models/expedited-production-setting";
import { SettingsService } from "src/app/services/settings.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
  selector: "app-detail-expedited-production",
  templateUrl: "./detail-expedited-production.component.html",
  styleUrls: ["./detail-expedited-production.component.less"],
})
export class DetailExpeditedProductionComponent implements OnInit {
  expeditedProductionSettings: ExpeditedProductionSetting;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getExpeditedProductionSettingById();
  }

  getExpeditedProductionSettingById() {
    const id = this.route.snapshot.paramMap.get("id");
    this.settingsService.getExpeditedProductionSettingById(id).subscribe(
      (expeditedProductionSettings: ExpeditedProductionSetting) => {
        this.expeditedProductionSettings = expeditedProductionSettings;
      },
      (err) => {
        console.error(err);
        this.snackBarService.showError(
          "Error getting expedited production setting details."
        );
      }
    );
  }
  formatPercentage(value: number): string {
    return `${(value * 1).toFixed(2)}%`;
  }
}
