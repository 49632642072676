import { Component, Inject, OnInit, ViewChild } from '@angular/core';
// Material
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// Models
import { CommissionCommunities } from 'src/app/models/commission-communities';
import { CommissionCommunitySalesSplit } from '../../../models/commission-community-sales-split';
import { CommissionSalesPerson } from '../../../models/commission-sales-person';
// Service
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CommissionService } from '../../../services/commission.service';
import { AuthService } from '../../../services/auth.service';
// DateTime
import * as moment from 'moment';


@Component({
  selector: 'app-community-modal-commissions',
  templateUrl: './community-modal-commissions.component.html',
  styleUrls: ['./community-modal-commissions.component.less'],
})
export class CommunityModalCommissionsComponent implements OnInit {
  @ViewChild(MatTable) tblSalesSplits: MatTable<CommissionCommunitySalesSplit>;

  communitiesSalesSplitsDataSource =
    new MatTableDataSource<CommissionCommunitySalesSplit>();

  displayedColumns: string[] = [
    'salesCode',
    'salesRepName',
    'percent',
    'actions',
  ];

  availableSalesPersons: CommissionSalesPerson[] = [];

  communitySelect: CommissionCommunities;
  loading = false;

  showWarnMsj = false;
  warnMsj = '';

  newSaleOverrideDate = new UntypedFormControl();
  rateOverride = new FormControl<number | null >(null, [
    Validators.min(1),
    Validators.max(100)
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commissionService: CommissionService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<CommunityModalCommissionsComponent>
  ) {
    this.communitySelect = data.selection;

    if (this.communitySelect.communitiesSalesSplits.length !== 0) {
      this.communitiesSalesSplitsDataSource.data =
        this.communitySelect.communitiesSalesSplits;
    }


  }

  async ngOnInit(): Promise<void> {
    await this.getAllSalesPersonAsync();
    this.setInitialValuesControls();
  }

  setInitialValuesControls() {
    this.rateOverride.valueChanges.subscribe((value: any) => {
      if (value !== null && value !== '' && value >= 0) {
        this.newSaleOverrideDate.disable();
      } else {
        this.newSaleOverrideDate.enable();
      }
    });

    if (this.communitySelect.newSalesOverride && this.communitySelect.newSalesOverride !== null) {
      this.newSaleOverrideDate.setValue(this.communitySelect.newSalesOverride);
      this.rateOverride.disable();
    }

    if (this.communitySelect.commissionRateOverride && this.communitySelect.commissionRateOverride !== null) {
      this.rateOverride.setValue(this.communitySelect.commissionRateOverride);
      this.newSaleOverrideDate.disable();
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
        event.preventDefault();
    }
 }

 onDatePickerChange(event: MatDatepickerInputEvent<Date>) {
  if (event.value && event.value !== null) {
    this.rateOverride.disable();
  }
 }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async getAllSalesPersonAsync(): Promise<void> {
    this.showLoading();
    await this.commissionService.getCommissionAllSalesPerson()
      .toPromise()
      .then((salesPersons: CommissionSalesPerson[]) => this.availableSalesPersons = salesPersons)
      .catch(err => {
        console.error(err);
        this.snackBarService.showError('Error getting commission communities for this customer.');
      })
      .finally(() => this.hideLoading());
  }

  getCommunitiesSalesSplitsDataSource(): MatTableDataSource<CommissionCommunitySalesSplit> {
    return this.communitiesSalesSplitsDataSource;
  }

  changeSalesPerson(value: number, row: CommissionCommunitySalesSplit) {
      const findRow = this.communitiesSalesSplitsDataSource.data.find((f) => f === row);
      if (findRow) {
        if (value !== 0) {
          const newRep = this.availableSalesPersons.find((f) => f.salesPersonId === value);
          if (newRep) {
            const filterSource = this.communitiesSalesSplitsDataSource.data.filter(f => f.salesRepId === newRep.salesPersonId);
            if (filterSource.length > 1) {
              this.showWarnMsj = true;
              this.warnMsj = `Cannot add a representative more than once`;
              findRow.salesRepId = 0;
              findRow.salesRepName = '-';
              findRow.salesCode = '-';
              return;
            } else {
              this.showWarnMsj = false;
              this.warnMsj = ``;
            }
            findRow.salesRepId = newRep.salesPersonId;
            findRow.salesRepName = newRep.salesPersonName;
            findRow.salesCode = newRep.salesPersonCode;
            findRow.modifiedBy = this.authService.getAccount().userName;
            findRow.dateModified = new Date();
          }
        } else {
          if (findRow) {
            findRow.salesRepId = 0;
            findRow.salesRepName = '-';
            findRow.salesCode = '-';
          }
        }
      }

  }

  allowSaveSplits(): boolean {
    if (this.communitiesSalesSplitsDataSource.data.length !== 0) {
      let extraValidation:boolean;
      const selectRepSales = this.communitiesSalesSplitsDataSource.data.filter((f) => f.salesRepId === 0).length;
      const percentWithZero = this.communitiesSalesSplitsDataSource.data.filter((f) => f.percent === 0 || f.percent === null).length;
      const percentExcess = this.communitiesSalesSplitsDataSource.data.filter((f) => f.percent > 100).length;

      if (this.newSaleOverrideDate.value !== null) {
        extraValidation = this.validateDatePicker();
      }
      if (this.rateOverride.value !== null) {
        extraValidation = this.rateOverride?.errors ? false : true;
      }

      return extraValidation !== undefined ?
        selectRepSales === 0 && percentWithZero === 0 && percentExcess === 0 && extraValidation :
        selectRepSales === 0 && percentWithZero === 0 && percentExcess === 0;
    } else {
      if (this.newSaleOverrideDate.value !== null) {
        return this.validateDatePicker();
      }
      if (this.rateOverride.value !== null) {
        return this.rateOverride?.errors ? false : true;
      }
    }
  }

  addNewRepSale() {
    this.communitiesSalesSplitsDataSource.data.push({
      id: 0,
      apartmentComplexId: this.communitySelect.communityID,
      salesRepId: 0,
      salesRepName: '-',
      salesCode: '-',
      percent: 0,
      dateCreated: new Date(),
      modifiedBy: this.authService.getAccount().userName,
    });
    if (this.getData().length !== 1) {
      this.tblSalesSplits.renderRows();
    }
  }

  getData(): CommissionCommunitySalesSplit[] {
    return this.communitiesSalesSplitsDataSource.connect().value;
  }

  validateDatePicker(): boolean {
    return this.newSaleOverrideDate.value && this.newSaleOverrideDate.value !== null;
  }

  async clearNewSalesOverride() {
    this.newSaleOverrideDate.setValue(null);
    this.rateOverride.enable();
    if (this.communitySelect.newSalesOverride !== null) {
      this.showLoading();
      await this.UpdateCommunity().then(() => this.hideLoading());
    }
  }

  async removeRepSale(index: number, row: CommissionCommunitySalesSplit) {
    if (row.id !== 0) {
      await this.commissionService.deleteSalesSplit(row.id).toPromise();
    }
    this.communitiesSalesSplitsDataSource.data.splice(index, 1);
    this.tblSalesSplits.renderRows();

    if (this.communitySelect.communitiesSalesSplits.length === 0) {
      this.communitySelect.assignedReps = '';
      this.communitySelect.splitType = '';
    }
  }

  async UpdateCommunity(): Promise<void> {
    this.communitySelect.newSalesOverride = this.newSaleOverrideDate.value !== null ? moment(this.newSaleOverrideDate.value).toDate() : null;
    this.communitySelect.commissionRateOverride = this.rateOverride.value !== null ? this.rateOverride.value : null;
    await this.commissionService.updateCommunity(this.communitySelect)
      .toPromise()
      .then((result: CommissionCommunities) => {
        this.communitySelect = result;
        this.data.selection = this.communitySelect;
      });
  }

  async saveSalesSplits() {
    this.showLoading();

    if (this.communitiesSalesSplitsDataSource.data.length !== 0) {
      this.showWarnMsj = this.communitiesSalesSplitsDataSource.data
        .map((a) => a.percent)
        .reduce((accumulator, current) => accumulator + current) < 100;
    }

    if (this.showWarnMsj) {
      this.warnMsj = `The splits entered do not total 100%. Please check that the information is correct`;
      this.hideLoading();
      return;
    }


    if (this.communitiesSalesSplitsDataSource.data.length > 0) {
      if (this.newSaleOverrideDate.value !== null || this.rateOverride.value !== null) {
        await this.UpdateCommunity();
      }
      await this.commissionService.createOrUpdateSalesSplits(this.communitiesSalesSplitsDataSource.data)
        .toPromise()
        .then((resultCommunitiesSalesSplits: CommissionCommunitySalesSplit[]) => {
          if (resultCommunitiesSalesSplits.length !== 0) {
            this.data.selection.assignedReps = resultCommunitiesSalesSplits.map((m) => m.salesRepName).join(' | ');
            this.data.selection.splitType = resultCommunitiesSalesSplits
              .map((m) => Intl.NumberFormat('en-us', { minimumFractionDigits: 1, maximumFractionDigits: 1, }).format(m.percent))
              .join('/');
            this.data.selection.communitiesSalesSplits = resultCommunitiesSalesSplits;
            this.dialogRef.close({
              request: this.data.selection,
              responseState: 1
            });
          }})
        .catch(() => this.dialogRef.close({
          request: this.data.selection,
          responseState: 3
        }));
    } else {
      await this.UpdateCommunity()
      .then(() => this.dialogRef.close({
        request: this.data.selection,
        responseState: this.communitySelect ? 1 : 3
      }))
      .catch(() => this.dialogRef.close({
        request: this.data.selection,
        responseState: 3
      }));
    }
  }

  closeWithOutSaving() {
    this.dialogRef.close({
      request: this.data.selection,
      responseState: -1
    });
  }
}
