<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Account Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/account/edit/{{account?.id}}" mat-raised-button>Edit Account</a>
  </div>

  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{account?.id}}</li>
      <li><strong>UserName:</strong> {{account?.userName}}</li>
      <li><strong>Email:</strong> {{account?.email}}</li>
      <li><strong>Name:</strong> {{account?.name}}</li>
      <li><strong>Location:</strong> {{account?.location}}</li>
      <li><strong>Is Active:</strong> {{account?.isActive | active}}</li>
      <li><strong>Date Created:</strong> {{account?.dateCreated | date}}</li>
      <li><strong>Roles:</strong> {{account?.roles?.join(', ')}}</li>
    </ul>
  </div>
</mat-card>