<mat-card>
  <mat-card-title>
    
  </mat-card-title>
  <mat-card-content class="marginTbl">
    <div class="row">
        <div class="columnOrderH">
          <h1>
              Quotes
          </h1>
        </div>
      </div>
      <div class="loading" *ngIf="loadingQuoteHistory">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span>Loading QwitQuote History...</span>
      </div>
      <h3 style="text-align: center;" 
          *ngIf="!loadingQuoteHistory && quotesData.length === 0">
          No QwitQuote history to show.
      </h3>

      <div class="filters" *ngIf="!loadingQuoteHistory&& quotesData.length > 0">
        <div class="filters-container">
          <div>
            <form style="display: flex;" [formGroup]="formControl">
              <mat-form-field>
                <mat-icon matSuffix>search</mat-icon>
                <input matInput formControlName="word" placeholder="Quote Number" >
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="!loadingQuoteHistory && quotesData.length > 0" class="coh-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource"  class="mat-elevation-z8 table" matSort #tblSort="matSort">
            
          <tr mat-header-row *matHeaderRowDef="customerQwitQuoteHistoryDisplayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: customerQwitQuoteHistoryDisplayedColumns;"></tr>

          <ng-container matColumnDef="customerNumber">
            <th mat-header-cell *matHeaderCellDef >Customer Number</th>
            <td mat-cell  *matCellDef="let element"> {{element?.customerNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="quoteNumber">
            <th mat-header-cell *matHeaderCellDef>Quote Number</th>
            <td mat-cell  *matCellDef="let element" (click)="displayQuoteDetails(element)">
              <span class="link">{{element.quoteNumber}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell  *matCellDef="let element" (click)="displayQuoteDetails(element)">
              {{ element.quoteDetails.totalCost | currency: 'USD'}} 
            </td>
          </ng-container>

          <ng-container matColumnDef="customerPONumber">
            <th mat-header-cell *matHeaderCellDef>PO Number</th>
            <td mat-cell  *matCellDef="let element" (click)="displayQuoteDetails(element)">
              {{ element.customerPONumber }} 
            </td>
          </ng-container>

          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>Project Name</th>
            <td mat-cell  *matCellDef="let element" (click)="displayQuoteDetails(element)">
              {{ element.quoteDetails.customerName }} 
            </td>
          </ng-container>

          <ng-container matColumnDef="unitNumber">
            <th mat-header-cell *matHeaderCellDef>Unit Number</th>
            <td mat-cell  *matCellDef="let element" (click)="displayQuoteDetails(element)">
              {{ element.quoteDetails.unitNumber }} 
            </td>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef>Date Created</th>
            <td mat-cell  *matCellDef="let element">{{element.dateCreated | date}}</td>
          </ng-container>
      
      </table>
      </div>
  </mat-card-content>
</mat-card>