<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
            <button mat-mini-fab matTooltip="Back to notifications" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
    </div>
</div>
<mat-card>
    <mat-card-content>
        <h1 class="mat-h1">
            Edit Reson Group
        </h1>
        <hr />
        <ng-container *ngIf="loading; else formTemplate">
            <div class="container-loading">
                <div class="loading">
                    <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
        </ng-container>
        <ng-template #formTemplate>
            <div class="container">
                <form [formGroup]="reasonForm" (ngSubmit)="updateReasonGroup()">
                    <mat-form-field class="full-width">
                        <mat-label>Reason Group</mat-label>
                        <input type="text" placeholder="Reason Group" matInput formControlName="groupName" maxlength="50" minlength="1">
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Description</mat-label>
                        <textarea matInput #message minlength="5" maxlength="250" placeholder="Description" formControlName="description" rows="5"></textarea>
                        <mat-hint align="end">{{message.value.length}} / 250</mat-hint>
                    </mat-form-field>                    
                    <button mat-raised-button color="accent" [disabled]="loading || !reasonForm.valid">Update</button>
                </form>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>