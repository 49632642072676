import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseForm } from '../../../forms/base-form';
import { RouterSchedule } from '../../../models/router-schedule';
import { Router as RouterDTO } from '../../../models/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { RoutersService } from '../../../services/routers.service';
import { RouterSchedulesService } from '../../../services/router-schedules.service';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import * as moment from 'moment';

@Component({
  selector: 'app-create-router-schedule',
  templateUrl: './create-router-schedule.component.html',
  styleUrls: ['./create-router-schedule.component.less']
})
export class CreateRouterScheduleComponent extends BaseForm implements OnInit {

  loading = false;
  routers: RouterDTO[] = [];
  routerSchedule: RouterSchedule;
  routerScheduleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routersService: RoutersService,
    private routerSchedulesService: RouterSchedulesService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getRouters();
  }

  createForm(): void {
    this.routerScheduleForm = this.fb.group({
      routerId: ['', Validators.required],
      routerStr: ['', Validators.required],
      productionLocationId: ['', Validators.required],
      date: ['', Validators.required],
      timeStart: ['', Validators.required],
      timeEnd: ['', Validators.required],
    });
  }

  onRouterChange(e: MatSelectChange) {
    const router = this.routers.find(c => c.id === e.value);
    if (router) {
      this.routerScheduleForm.get('routerStr').setValue(router.name);
      this.routerScheduleForm.get('productionLocationId').setValue(router.productionLocationId);
    }
  }

  getRouters() {
    this.routersService.getRouters().subscribe((routers: RouterDTO[]) => {
      this.routers = routers;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting routers.');
      });
  }

  isValid(): boolean {
    const date = moment(this.routerScheduleForm.get('date').value).format('MM/DD/YYYY');
    const timeStart = this.routerScheduleForm.get('timeStart').value;
    const timeEnd = this.routerScheduleForm.get('timeEnd').value;
    const d1 = moment(`${date} ${timeStart}`, 'MM/DD/YYYY hh:mm a');
    const d2 = moment(`${date} ${timeEnd}`, 'MM/DD/YYYY hh:mm a');
    const validTimeEnd = moment(d2).diff(d1, 'minutes') > 0;

    if (!validTimeEnd) {
      this.routerScheduleForm.get('timeEnd').setErrors({ min: true });
    }

    return this.routerScheduleForm.valid && validTimeEnd;
  }

  submit(): void {
    this.reset();

    if (this.isValid()) {
      this.createRouterSchedule();
    }
  }

  getData(): RouterSchedule {
    const data: RouterSchedule = {
      routerId: this.routerScheduleForm.get('routerId').value,
      routerStr: this.routerScheduleForm.get('routerStr').value,
      productionLocationId: this.routerScheduleForm.get('productionLocationId').value,
      date: this.routerScheduleForm.get('date').value,
      timeStart: this.routerScheduleForm.get('timeStart').value,
      timeEnd: this.routerScheduleForm.get('timeEnd').value,
    };
    return data;
  }

  createRouterSchedule() {
    this.loading = true;
    const model = this.getData();

    this.routerSchedulesService.createRouterSchedule(model).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['/routers']);
      this.snackBarService.showSuccess('The router schedule was created successfully.');
    }, err => {
      this.loading = false;
      console.error(err);
      this.snackBarService.showError('Error creating router schedule.');
    });
  }
}
