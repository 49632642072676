import { Component, OnInit } from '@angular/core';
import { ProductionLimit } from '../../../models/production-limit';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProductionLimitsService } from '../../../services/production-limits.service';

@Component({
  selector: 'app-delete-production-limit',
  templateUrl: './delete-production-limit.component.html',
  styleUrls: ['./delete-production-limit.component.less']
})
export class DeleteProductionLimitComponent implements OnInit {

  productionLimit: ProductionLimit;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productionLimitsService: ProductionLimitsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionLimit();
  }

  getProductionLimit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLimitsService.getProductionLimit(id).subscribe((productionLimit: ProductionLimit) => {
      this.productionLimit = productionLimit;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production limit details.');
      });
  }

  deleteProductionLimit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLimitsService.deleteProductionLimit(id).subscribe(_ => {
      this.router.navigate([`/production-limits`]);
      this.snackBarService.showSuccess('The production limit was deleted successfully.');
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error deleting production limit.');
      });
  }

}
