import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Role } from 'src/app/models/role';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.less']
})
export class RolesComponent implements OnInit {

  roles: Role[] = [];
  displayedColumns: string[] = ['id', 'name', 'description', 'dateCreated', 'actions'];

  constructor(private roleService: RolesService, private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.roleService.getRoles().subscribe((roles: Role[]) => {
      this.roles = roles;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting roles.');
      }
    );
  }
}
