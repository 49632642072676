import { ReasonCategory } from './reason-category';
import { ReasonCode } from './reason-code';
export enum ReasonPrevent {
    category = 1,
    reason = 2,
    group = 3
}
export enum ReasonModalState {
    success,
    error,
    cancel,
}

export interface ReasonCodeConfigModal {
    title: string;
    message: string;
    typePrevent: ReasonPrevent;
    payload: any;
    state?: ReasonModalState;
}
