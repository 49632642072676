import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { manifest } from '../../routes/routes-manifest';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  navLinks = [];
  level = 1;
  today = new Date();

  constructor(
    private router: Router,
    private auth: AuthService) { }

  ngOnInit() {
    this.navLinks = this.getNavLinks();
  }

  getNavLinks(): any[] {
    const dashboard = 'dashboard';
    return Object.entries(manifest)
      .filter(([k, v]) => k !== dashboard && this.auth.isUserInRoles(v.roles) && v.active && v.level === this.level)
      .map(([_, v]) => v)
      .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
