import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApartmentComplexAudit } from '../../../models/apartment-complex-audit';
import { SnackbarService } from '../../../services/snackbar.service';
import { AuditService } from 'src/app/services/audit.service';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { CustomerAudit } from 'src/app/models/customer-audit';

@Component({
  selector: 'app-edit-audit-assignments',
  templateUrl: './edit-audit-assignments.component.html',
  styleUrls: ['./edit-audit-assignments.component.less']
})
export class EditAuditAssignmentsComponent implements OnInit {

  customer: CustomerAudit;
  selectedCommunities = {};
  communityFilter = new FormControl();
  communities: ApartmentComplexAudit[] = [];
  filteredCommunities: ApartmentComplexAudit[] = [];
  disableActions = false;
  filtersApplied = false;
  changesApplied = false;

  communitiesDisplayedColumns: string[] = ['select', 'id', 'name', 'dateCreated', 'numberOfUnits', 'audit'];

  constructor(
    private auditService: AuditService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<EditAuditAssignmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: CustomerAudit }) { }

  ngOnInit(): void {
    this.customer = this.data.customer;
    this.getCommunities();
  }

  getCommunitiesData(): ApartmentComplexAudit[] {
    return this.filtersApplied ? this.filteredCommunities : this.communities;
  }

  getCommunities(): void {
    const customerId = this.customer.id;
    this.auditService.getCommunities(customerId).subscribe((communities: ApartmentComplexAudit[]) => {
      this.communities = communities;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting communities.');
    });
  }

  toggleSelection(e: MatCheckboxChange): void {
    if (e.checked) {
      for (const item of this.communities) {
        this.selectedCommunities[item.id] = true;
      }
    } else {
      this.selectedCommunities = {};
    }
  }

  selectItem(e: MatCheckboxChange, id: number): void {
    this.selectedCommunities[id] = e.checked;
  }

  isSelected(id: number): boolean {
    return this.selectedCommunities[id] === true;
  }

  isAllSelected(): boolean {
    for (const item of this.communities) {
      if (this.selectedCommunities[item.id] !== true) {
        return false;
      }
    }
    return true;
  }

  clearSelection() {
    this.selectedCommunities = {};
  }

  getSelectedCommunitiesIds(): number[] {
    const communitiesIds: number[] = [];

    for (const key in this.selectedCommunities) {
      if (this.selectedCommunities.hasOwnProperty(key) && this.selectedCommunities[key] === true) {
        communitiesIds.push(+key);
      }
    }

    return communitiesIds;
  }

  applyFilters(): void {
    this.filteredCommunities = this.communities;

    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredCommunities = this.filterByCommunity();
      this.filtersApplied = true;
    }
  }

  filterByCommunity(): ApartmentComplexAudit[] {
    return this.filteredCommunities.filter(c => c.name.toLowerCase().includes(this.communityFilter.value.trim().toLowerCase()));
  }

  setCustomerAudit(audit: boolean) {
    this.disableActions = true;
    const data: CustomerAudit = { id: this.customer.id, audit };
    this.auditService.setCustomerAudit(data).subscribe(() => {
      this.customer.audit = audit;
      this.changesApplied = true;
      this.disableActions = false;
      this.snackBarService.showSuccess('Customer audit was updated successfully.');
    }, err => {
      console.error(err);
      this.disableActions = false;
      this.snackBarService.showError('Error setting customer audit.');
    });
  }

  setCommunitiesAudit(audit: boolean) {
    this.disableActions = true;
    const communities = this.getSelectedCommunitiesIds();
    const data: ApartmentComplexAudit[] = [];
    for (const id of communities) {
      data.push({ id, audit });
    }
    this.auditService.setCommunitiesAudit(data).subscribe(() => {
      // set audit flag
      for (const id of communities) {
        const community = this.communities.find(c => c.id === id);
        if (community) {
          community.audit = audit;
        }
      }
      this.clearSelection();
      this.changesApplied = true;
      this.disableActions = false;
      this.snackBarService.showSuccess('Communities audit was updated successfully.');
    }, err => {
      console.error(err);
      this.disableActions = false;
      this.snackBarService.showError('Error setting communities audit.');
    });
  }

  clearFilters(): void {
    this.filtersApplied = false;
    this.communityFilter.setValue('');
    this.filteredCommunities = [];
  }

  close(): void {
    this.dialogRef.close(this.changesApplied);
  }

}
