<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Create Shipping Item Setup</h1>
  <hr />

  <div class="container">
    <form [formGroup]="createStandardItemShipInForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Item Number" formControlName="itemNumber" />
        <mat-error *ngIf="createStandardItemShipInForm.get('itemNumber')?.hasError('required')">
          Item Number is required
        </mat-error>
        <mat-error *ngIf="createStandardItemShipInForm.get('itemNumber')?.hasError('patternMismatch')">
          Item number format is invalid (only letters, numbers, hyphens, underscores allowed)
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Select Carton Type</mat-label>
        <mat-select formControlName="cartonTypeId">
          <mat-option *ngFor="let cartonType of cartonTypes" [value]="cartonType.id">
            {{ cartonType.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="createStandardItemShipInForm.get('cartonTypeId')?.hasError('required')">
          Carton Type
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Select Shipping Status</mat-label>
        <mat-select formControlName="categoryTypeId">
          <mat-option *ngFor="let status of shippingStatuses" [value]="status.value">
            {{ status.label }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="createStandardItemShipInForm.get('categoryTypeId')?.hasError('required')">
          Category Type
        </mat-error>
      </mat-form-field>

      <div class="actions">
        <button mat-raised-button color="primary" type="submit">Create</button>
      </div>
    </form>
  </div>
</mat-card>