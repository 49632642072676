import { Component, Input, OnInit } from '@angular/core';
import { OvernightService } from '../../../services/overnight.service';
import { AddressDetail, FedexAddress } from '../../../models/address-detail';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.less']
})
export class AddressDetailsComponent implements OnInit {

  @Input() type: string;
  @Input() customerAddres: AddressDetail;

  title: string;
  customerAddress: AddressDetail;
  originAddress: AddressDetail;

  constructor(private overnightService: OvernightService) { }

  async ngOnInit() {

    switch (this.type) {
      case "from":
        this.title = "1. From Address Details";

        let cacheOriginAddress = localStorage.getItem("originAddress");
        if (cacheOriginAddress) {
          this.originAddress = JSON.parse(cacheOriginAddress);
        }

        this.overnightService.$isRetail.subscribe((value: boolean) => {
          this.originAddress.name = value ? "Kitchen Makeover" : "Qwikkit";
        });

        this.overnightService.$originAddress.subscribe((value: FedexAddress) => {
          this.originAddress = {
            id: null,
            apartmentId: null,
            name: this.overnightService.isRetail ? "Kitchen Makeover" : "Qwikkit",
            address1: value.StreetLines[0],
            address2: value.StreetLines[1],
            zip: value.PostalCode,
            city: value.City,
            state: value.StateOrProvince,
            country: value.CountryCode,
            customerId: null
          };

          localStorage.setItem("originAddress", JSON.stringify(this.originAddress));
        });

        break;

      case "to":
        this.title = "2. To Address Details";
        this.customerAddress = this.overnightService.customerAddress;

        this.overnightService.$customerAddress.subscribe((value: AddressDetail) => {
          this.customerAddress = value;
        });
        break;
    }
  }
}
