<mat-accordion>
    <mat-expansion-panel expanded="true" class="">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Order Search
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filters">
            <div class="filters-container">
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Sales Order #" [(ngModel)]="salesOrderNumber">
                    </mat-form-field>
                </div>
            </div>
            <button mat-mini-fab matTooltip="Search" (click)="search()">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
    </mat-expansion-panel>
</mat-accordion>