import { Component, OnInit } from '@angular/core';
import { EditForm } from '../../../forms/edit-form';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProductionLocation } from '../../../models/production-location';
import { ProductionLocationsService } from '../../../services/production-locations.service';

@Component({
  selector: 'app-edit-production-location',
  templateUrl: './edit-production-location.component.html',
  styleUrls: ['./edit-production-location.component.less']
})
export class EditProductionLocationComponent extends EditForm implements OnInit {

  minWipHours = 0;
  maxWipHours = 8;
  minSlabWipPercentage = 0;
  maxWipSlabPercentage = 100;
  productionLocation: ProductionLocation;
  editProductionLocationForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private productionLocationsService: ProductionLocationsService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.getProductionLocation();
  }

  createForm() {
    this.editProductionLocationForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      lat: [''],
      long: [''],
      wipHours: ['0', [Validators.required, Validators.min(this.minWipHours), Validators.max(this.maxWipHours)]],
      wipSlabPercentage: ['0', [Validators.required, Validators.min(this.minSlabWipPercentage), Validators.max(this.maxWipSlabPercentage)]],
      isActive: [false],
      isDefault: [false],
      allowRetail: [false],
      allowFusion: [false],
      allowMultiColorUnit: [false],
      allowPartsOrder: [false],
      allowPalletized: [false],
      allowAccessoriesOnly: [false],
      allowCPU: [false],
      allowFedEx: [false],
      allowDropOff: [false],
      allowOvernight: [false]
    });
  }

  isValid(): boolean {
    return this.editProductionLocationForm.valid;
  }

  setValues() {
    this.editProductionLocationForm.setValue({
      name: this.productionLocation.name,
      address: this.productionLocation.address,
      address2: this.productionLocation.address2,
      city: this.productionLocation.city,
      state: this.productionLocation.state,
      zipCode: this.productionLocation.zipCode,
      lat: this.productionLocation.lat,
      long: this.productionLocation.long,
      wipHours: this.productionLocation.wipHours,
      wipSlabPercentage: this.productionLocation.wipSlabPercentage,
      isActive: this.productionLocation.isActive,
      isDefault: this.productionLocation.isDefault,
      allowRetail: this.productionLocation.allowRetail,
      allowFusion: this.productionLocation.allowFusion,
      allowMultiColorUnit: this.productionLocation.allowMultiColorUnit,
      allowPartsOrder: this.productionLocation.allowPartsOrder,
      allowPalletized: this.productionLocation.allowPalletized,
      allowAccessoriesOnly: this.productionLocation.allowAccessoriesOnly,
      allowCPU: this.productionLocation.allowCPU,
      allowFedEx: this.productionLocation.allowFedEx,
      allowDropOff: this.productionLocation.allowDropOff,
      allowOvernight: this.productionLocation.allowOvernight
    });
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      this.updateProductionLocation();
    }
  }

  getNameErrorMessage() {
    return this.editProductionLocationForm.controls.name.hasError('required') ? 'You must enter a name' : '';
  }

  getAddressErrorMessage() {
    return this.editProductionLocationForm.controls.address.hasError('required') ? 'You must enter a address' : '';
  }

  getCityErrorMessage() {
    return this.editProductionLocationForm.controls.city.hasError('required') ? 'You must enter a city' : '';
  }

  getStateErrorMessage() {
    return this.editProductionLocationForm.controls.state.hasError('required') ? 'You must enter a state' : '';
  }

  getZipCodeErrorMessage() {
    return this.editProductionLocationForm.controls.zipCode.hasError('required') ? 'You must enter a zip code' : '';
  }

  getProductionLocation() {
    const id = this.route.snapshot.paramMap.get('id');
    this.productionLocationsService.getProductionLocation(id).subscribe((productionLocation: ProductionLocation) => {
      this.productionLocation = productionLocation;
      this.setValues();
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production location details.');
      });
  }

  updateProductionLocation() {
    const id = +this.route.snapshot.paramMap.get('id');
    const model: ProductionLocation = {
      id,
      name: this.editProductionLocationForm.controls.name.value,
      address: this.editProductionLocationForm.controls.address.value,
      address2: this.editProductionLocationForm.controls.address2.value,
      city: this.editProductionLocationForm.controls.city.value,
      state: this.editProductionLocationForm.controls.state.value,
      zipCode: this.editProductionLocationForm.controls.zipCode.value,
      lat: this.editProductionLocationForm.controls.lat.value,
      long: this.editProductionLocationForm.controls.long.value,
      wipHours: this.editProductionLocationForm.controls.wipHours.value,
      wipSlabPercentage: this.editProductionLocationForm.controls.wipSlabPercentage.value,
      isActive: this.editProductionLocationForm.controls.isActive.value,
      isDefault: this.editProductionLocationForm.controls.isDefault.value,
      allowRetail: this.editProductionLocationForm.controls.allowRetail.value,
      allowFusion: this.editProductionLocationForm.controls.allowFusion.value,
      allowMultiColorUnit: this.editProductionLocationForm.controls.allowMultiColorUnit.value,
      allowPartsOrder: this.editProductionLocationForm.controls.allowPartsOrder.value,
      allowPalletized: this.editProductionLocationForm.controls.allowPalletized.value,
      allowAccessoriesOnly: this.editProductionLocationForm.controls.allowAccessoriesOnly.value,
      allowCPU: this.editProductionLocationForm.controls.allowCPU.value,
      allowFedEx: this.editProductionLocationForm.controls.allowFedEx.value,
      allowDropOff: this.editProductionLocationForm.controls.allowDropOff.value,
      allowOvernight: this.editProductionLocationForm.controls.allowOvernight.value
    };

    this.productionLocationsService.updateProductionLocation(model).subscribe((productionLocation: ProductionLocation) => {
      this.productionLocation = productionLocation;
      this.router.navigate(['/locations']);
      this.snackBarService.showSuccess('The production location information was updated successfully.');
    }, err => {
      console.error(err);
      this.errorMessages = this.getErrorMessages(err);
      this.snackBarService.showError('Error updating the production location information.');
    });
  }
}
