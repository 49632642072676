import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalSetting } from 'src/app/models/global-setting';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-settings-by-group-name',
  templateUrl: './settings-by-group-name.component.html',
  styleUrls: ['./settings-by-group-name.component.less']
})
export class SettingsByGroupNameComponent implements OnInit {

  groupName: string;
  globalSettings: GlobalSetting[] = [];
  displayedColumns: string[] = ['id', 'name', 'friendlyName', 'value', 'dateModified', 'modifiedBy', 'actions'];

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getGroupName();
    this.getGlobalSettings();
  }

  getGroupName() {
    this.groupName = this.route.snapshot.paramMap.get('name');
  }

  getGlobalSettings() {
    this.settingsService.getSettingsByGroupName(this.groupName).subscribe((globalSettings: GlobalSetting[]) => {
      this.globalSettings = globalSettings;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting global settings.');
    });
  }
}
