import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionStatusesService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getProductionStatuses(): Observable<any> {
    const url = this.config.buildURL(`schedule/productionStatuses`);
    return this.http.get<any>(url);
  }
}
