<mat-dialog-content class="mat-typography">
    <mat-card>
        <div>
            <h1 class="mat-h1">Important</h1>
            <hr />
            <br>
            <ng-container [ngSwitch]="currentState">
                <div *ngSwitchCase="enumState.InProgress">
                    <p><b>Sorry, we have another process running, here are the details of it:</b></p>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th>User to Execute</th>
                                <th>Range of Calculation</th>
                                <th>State</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ processInProgress.userExecute }}</td>
                                <td>{{ processInProgress.rangeStart | date }} from {{ processInProgress.rangeEnd | date }}</td>
                                <td>{{ processInProgress.state }}</td>
                            </tr>

                        </tbody>
                    </table>
                    <br />
                    <p><b>Please wait for it to finish and try again later</b></p>
                </div>
                <div *ngSwitchCase="enumState.PreviousExecutions">
                    <ng-container *ngIf="!loading; else execution">
                        <p>We have found previous runs for this process, accordingly, please review the following
                            details to
                            make sure you don't overwrite the previous calculation process.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date of Execute</th>
                                    <th>User to Execute</th>
                                    <th>Range of Calculation</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let process of listProcess">
                                    <td>{{ process.runEndTime | date }}</td>
                                    <td>{{ process.userExecute }}</td>
                                    <td>{{ process.rangeStart | date }} to {{ process.rangeEnd | date }}</td>
                                    <td>{{ process.state }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <p><b>Do you want to continue running the recalculation anyway?</b></p>
                    </ng-container>
                </div>
                <div *ngSwitchCase="enumState.readyForExecution">
                    <ng-container *ngIf="!loading; else execution">
                        <p>When executing this process, the ledger entries that are in the selected range <b>({{
                                legendDates }})</b>
                            will be permanently deleted, additionally the process will change the totals in the
                            commissions generated in this same period.</p>
                        <br>
                        <p><b>Do you want to continue running the recalculation anyway?</b></p>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <ng-template #execution>
            <div fxLayout="column" fxLayoutAlign="space-around center">
                <div>
                    <mat-spinner diameter="80" color="accent"></mat-spinner>
                </div>
                <div class="loading-leyend">
                    <span><strong>{{ progressText }}</strong></span>
                </div>
            </div>
        </ng-template>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="closePreventDialog()" [disabled]="loading">Cancel</button>
    <button mat-flat-button color="accent" (click)="reCalculateCommissions()" [disabled]="loading || blockRun">Yes,
        run</button>
</mat-dialog-actions>