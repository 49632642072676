<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Delete Rebate Group
    </h1>
    <hr />
    <p>Are you sure you want to delete this rebate group?</p>
    <div class="content">
        <ul>
            <li><strong>Id:</strong> {{rebateGroup?.id}}</li>
            <li><strong>Name:</strong> {{rebateGroup?.name}}</li>
            <li><strong>Percentage:</strong> <span class="amount">{{rebateGroup?.rebatePercentage}}%</span></li>
            <li><strong>Start Period:</strong> {{rebateGroup?.startPeriod | date}}</li>
            <li><strong>End Period:</strong> {{rebateGroup?.endPeriod | date}}</li>
            <li><strong>Date Created:</strong> {{rebateGroup?.dateCreated | date}}</li>
            <li><strong>Date Modified:</strong> {{rebateGroup?.dateModified | date}}</li>
            <li><strong>Created By:</strong> {{rebateGroup?.createdBy}}</li>
            <li><strong>Modified By:</strong> {{rebateGroup?.modifiedBy}}</li>
            <li><strong>Is Active:</strong> {{rebateGroup?.isActive | active}}</li>
            <li><strong>Assignments:</strong> {{rebateGroup?.assignments}}</li>
        </ul>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="deleteRebateGroup()">Delete</button>
    </div>
</mat-card>