import { ArchivedUnit } from 'src/app/models/archived-unit';
import { CustomerService } from 'src/app/services/customer.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit} from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ArchivedLocation } from 'src/app/models/archived-location';


@Component({
  selector: 'app-unarchive-location-confirmation',
  templateUrl: './unarchive-location-confirmation.component.html',
  styleUrls: ['./unarchive-location-confirmation.component.less']
})
export class UnarchiveLocationConfirmationComponent implements OnInit{

  public _customerInfoService: CustomerService;
  public archivedLocation: ArchivedLocation[] = [];
  private onSuccessDelete: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<UnarchiveLocationConfirmationComponent>,
    private snackBarService: SnackbarService,
    private customerInfoService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: { locations: ArchivedLocation[] }){
      this._customerInfoService = customerInfoService;
    }

  ngOnInit() {
    this.archivedLocation = this.data.locations;
  }

  closeWithOutSaving() {
    this.dialogRef.close({success: this.onSuccessDelete});
  }

  unArchiveSelectedLocations(){

    this._customerInfoService.unArchiveLocations(this.archivedLocation)
      .subscribe((response) => {
        if(response){
          this.snackBarService.showSuccess('Locations succesfully UnArchived.');
          this.onSuccessDelete = true;
          this.dialogRef.close({success: this.onSuccessDelete});
        }else{
          this.snackBarService.showError('Error UnArchiving Locations.');
        }
    }, err => {
       console.error(err);
       this.snackBarService.showError('Error UnArchiving Locations.');
       this.dialogRef.close({success: this.onSuccessDelete});
     })
  }
}
