<mat-card>
    <h1 class="mat-h1">
        Customers
    </h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}"
            (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filters customers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters" (keyup.enter)="applyFilters()">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Customer #" [formControl]="customerNumberFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Customer" [formControl]="customerFilter">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Select Assigned Reps</mat-label>
                            <mat-select #dllTeamSales [formControl]="assignedRepFilter" multiple>
                                <mat-select-trigger>
                                    {{assignedRepFilter.value ? assignedRepFilter.value[0] ?? 'No Assigned Rep' : ''}}
                                    <span *ngIf="assignedRepFilter.value?.length > 1" class="additional-selection">
                                        (+{{assignedRepFilter.value.length - 1}} {{assignedRepFilter.value?.length === 2
                                        ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [value]="'All'"
                                    (onSelectionChange)="onAllSelectionChange($event.source)">All</mat-option>
                                <mat-option *ngFor="let item of salesPersons" [value]="item">
                                    {{ item ?? "No Assigned Rep" }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerFrom" [formControl]="reportFromDateFilter"
                                placeholder="Created Date From" (dateChange)="onRequestedFromDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"
                                (keydown)="onEnterKeyEventHandler($event)"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerTo" [formControl]="reportToDateFilter"
                                placeholder="Created Date To" (dateChange)="onRequestedToDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo"
                                (keydown)="onEnterKeyEventHandler($event)"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="checkboxes">
                        <mat-checkbox [(ngModel)]="withActiveInRange">Include accounts that become active in this
                            range</mat-checkbox>
                        <mat-checkbox [(ngModel)]="withSplitCommunities" matTooltip="Split communities">Split
                            communities</mat-checkbox>
                    </div>
                </div>

                <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
<div class="reset-action-container" *ngIf="customersFiltersApplied">
    <button mat-raised-button (click)="clearCustomersFilters()">
        <mat-icon>clear</mat-icon> Reset Customers Filters
    </button>
</div>

<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <table mat-table #tblCommissionCustomers *ngIf="(getData() | async).length > 0"
        [dataSource]="getCommissionCustomersDataSource()" class="mat-elevation-z0 table"
        matSort
        #customerSort="matSort"
        matSortActive="activateDate"
        matSortDirection="desc"
        >
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Number </th>
            <td mat-cell *matCellDef="let row">
                <a mat-button (click)="openCustomerEdit(row)" color="accent"> <u>{{row.number}}</u> </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let row"> {{row.created | date }} </td>
        </ng-container>
        <ng-container matColumnDef="activateDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Date Active </th>
            <td mat-cell *matCellDef="let row"> {{ row?.activateDate | date }} </td>
        </ng-container>
        <ng-container matColumnDef="expandedActiveDate">
            <th mat-header-cell *matHeaderCellDef> Specifying Account Active Date </th>
            <td mat-cell *matCellDef="let row"> {{ row?.expandedActiveDate | date }} </td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> City </th>
            <td mat-cell *matCellDef="let row"> {{row.city}} </td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef> State </th>
            <td mat-cell *matCellDef="let row"> {{row.state}} </td>
        </ng-container>
        <ng-container matColumnDef="assignedRep">
            <th mat-header-cell *matHeaderCellDef> Assigned Rep </th>
            <td mat-cell *matCellDef="let row"> {{row?.assignedRep }} </td>
        </ng-container>
        <ng-container matColumnDef="splits">
            <th mat-header-cell *matHeaderCellDef> Splits </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.splits" [disableRipple]="true"
                    (click)="$event.preventDefault()"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="noCommunities">
            <th mat-header-cell *matHeaderCellDef> Communities </th>
            <td mat-cell *matCellDef="let row"> {{row.noCommunities}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button
                    (click)="onActionsClickEventHandler({ path: 'communities/' + row.customerID, pathParams: { customer: row.name, rol: row?.customerRoleId } })"
                    mat-icon-button matTooltip="Edit" matTooltipPosition="above"
                    [disabled]="!(row.noCommunities !== 0)">
                    <mat-icon [color]="row.noCommunities !== 0 ? 'accent' : ''">edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[50, 100]"
        showFirstLastButtons></mat-paginator>
</div>
