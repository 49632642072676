import { Component, OnInit } from '@angular/core';
import { ProductionLimit } from 'src/app/models/production-limit';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProductionLimitsService } from 'src/app/services/production-limits.service';

@Component({
  selector: 'app-production-limits',
  templateUrl: './production-limits.component.html',
  styleUrls: ['./production-limits.component.less']
})
export class ProductionLimitsComponent implements OnInit {

  productionLimits: ProductionLimit[] = [];
  displayedColumns: string[] = ['id', 'customerName', 'apartmentComplexName', 'allowedCustomPartCapacity', 'actions'];

  constructor(
    private productionLimitsService: ProductionLimitsService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getProductionLimits();
  }

  getProductionLimits() {
    this.productionLimitsService.getProductionLimits().subscribe((productionLimits: ProductionLimit[]) => {
      this.productionLimits = productionLimits;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production limits.');
      });
  }

}
