<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <h3 class="mat-h1">Reading detail</h3>
        <hr />
        <mat-list>
            <div mat-subheader>Message</div>
            <mat-list-item>
              <mat-icon mat-list-icon>mode_comment</mat-icon>
              <div mat-line> {{ data.notificationsOrderDetail[0].notificationJson.text }} </div>
              <div mat-line> Created: {{ data.createdDate | date }} </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Recipients</div>
            <mat-list-item *ngFor="let user of data.notificationsOrderDetail">
                <mat-icon mat-list-icon [color]="!user.isRead ? '' : 'accent'"> {{ !user.isRead ? 'notifications' : 'done_all' }} </mat-icon>
                <div mat-line> {{ user.notificationJson.from }} </div>
                <div mat-line> {{ !user.isRead ? 'Pending for view' : 'Read date ' + (user.readDate | date) }} </div>
            </mat-list-item>
        </mat-list>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" class="btn-modal" (click)="closeModal()">Close</button>
</mat-dialog-actions>