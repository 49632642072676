<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
          </button>
          <mat-card-header >

        </mat-card-header>
        <mat-card-content>
            <h1 class="mat-h1">
                Are you sure you want to Unarchive?
            </h1>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">No</button>
            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="unArchiveSelectedUnits()">Yes</button>
        </mat-card-actions>
    </mat-card>

</mat-dialog-content>