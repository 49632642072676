<div fxlayout="column" class="header-actions">
    <div fxflex="" fxlayout="" class="container-general">
        <div fxflex="20" fxlayoutalign="left left" class="container-back">
            <button mat-mini-fab matTooltip="Back to notifications" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
    </div>
</div>
<mat-card>
    <mat-card-content>
        <h1 class="mat-h1">
            New Customer Portal Notification
        </h1>
        <hr />
        <ng-container *ngIf="loading; else formTemplate">
            <div class="container-loading">
                <div class="loading">
                    <mat-spinner diameter="30" color="accent"> </mat-spinner> <span> Loading</span>
                </div>
            </div>
        </ng-container>
        <ng-template #formTemplate>
            <div class="container">
                <form [formGroup]="notificationForm" (ngSubmit)="createAlert()">
                    <mat-form-field class="full-width">
                        <mat-label>1.- Select customer for you alert</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Customer" matInput formControlName="inputCustomer" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete" (optionSelected)="getContactsSelection($event.option)">
                            <mat-option *ngFor="let option of filterContacts | async" [value]="option">
                                {{ option.customerNumber }} - {{ option.customer }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="contactSelectComplete">
                        <div class="mat-form-field full-width">
                            <mat-slide-toggle name="viewContact" formControlName="viewContact" (change)="onChangeViewContacts()">Specific Login</mat-slide-toggle>
                        </div>
                        <div class="mat-form-field full-width" *ngIf="contactSpecific && contactsDetailList.length > 0">
                            <mat-selection-list #contactsList (selectionChange)="onChangeSelectionContact(contactsList.selectedOptions.selected)">
                                <mat-list-option *ngFor="let contact of contactsDetailList" [value]="contact">
                                    {{ contact?.name }}
                                </mat-list-option>
                            </mat-selection-list>
                            <br />
                            <mat-hint *ngIf="contactsDetailList.length === 0"> * The customer don't have specific contacts</mat-hint>
                        </div>
                        <mat-form-field class="full-width">
                            <mat-label>2.- Describe your notification</mat-label>
                            <textarea matInput #message maxlength="250" placeholder="Ex. Dear client you ..." formControlName="inputNotification" rows="5"></textarea>
                            <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint>
                            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="!contactSpecific; else submitTemplate">
                        <button mat-raised-button color="accent" [disabled]="loading || !notificationForm.valid">Create</button>
                    </ng-container>
                    <ng-template #submitTemplate>
                        <button mat-raised-button color="accent" [disabled]="loading || !(notificationForm.valid && contactDetailSelect.length > 0)" >Create</button>
                    </ng-template>
                </form>
            </div>
        </ng-template>



    </mat-card-content>
</mat-card>