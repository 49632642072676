import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  getRoles(): Observable<Role[]> {
    const url = this.config.buildURL('roles');
    return this.http.get<Role[]>(url);
  }

  getRole(roleId: string): Observable<Role> {
    const url = this.config.buildURL(`roles/${roleId}`);
    return this.http.get<Role>(url);
  }

  createRole(model: Role): Observable<Role> {
    const url = this.config.buildURL(`roles`);
    return this.http.post<Role>(url, model);
  }

  updateRole(model: Role): Observable<Role> {
    const url = this.config.buildURL(`roles/${model.id}`);
    return this.http.put<Role>(url, model);
  }

  deleteRole(roleId: string) {
    const url = this.config.buildURL(`roles/${roleId}`);
    return this.http.delete(url);
  }
}
