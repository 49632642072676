import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommissionService } from '../../../services/commission.service';
import { CommissionRequest, CommissionResponse, StateCommissionPlanEnum} from '../../../models/commission-request';

@Component({
  selector: 'app-prevent-recalculate-commissions',
  templateUrl: './prevent-recalculate-commission.component.html',
  styleUrls: ['./prevent-recalculate-commission.component.less'],
})
export class PreventRecalculateCommissionComponent implements OnInit {
  loading = false;
  legendDates: string;
  progressText = `Wait for process it's complete`;
  processInProgress: CommissionResponse;
  listProcess: CommissionResponse[] = [];
  enumState = StateCommissionPlanEnum;
  currentState: StateCommissionPlanEnum;
  isSameUser = false;
  blockRun = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreventRecalculateCommissionComponent>,
    private commissionService: CommissionService
  ) {
    this.legendDates = data.legendDates;
    this.listProcess = data.process;
  }

  ngOnInit(): void {
    this.onValidateCurrentState();
  }

  onValidateCurrentState() {
    if (this.listProcess && this.listProcess.length > 0) {
      const haveInProgress = this.listProcess.find(f => f.state === StateCommissionPlanEnum.InProgress);
      if (haveInProgress) {
        this.processInProgress = haveInProgress;
        this.isSameUser = haveInProgress.userExecute === this.data.userExecute;
        this.currentState = StateCommissionPlanEnum.InProgress;
        this.blockRun = true;
        return;
      }
      const previousProcess = this.listProcess.filter(f => f.state === StateCommissionPlanEnum.Complete);
      if (previousProcess && previousProcess.length > 0) {
        this.currentState = StateCommissionPlanEnum.PreviousExecutions; return;
      } else {
        this.currentState = StateCommissionPlanEnum.readyForExecution;
      }
    } else {
      this.currentState = StateCommissionPlanEnum.readyForExecution;
     }
  }

  async reCalculateCommissions(): Promise<void> {
    this.loading = true;

    const calculateBody: CommissionRequest = {
      userExecute: this.data.user,
      startRange: this.data.start,
      endRange: this.data.end,
      onlyGetListProcess: false
    };

    await this.commissionService
      .recalculateCommissionPlan(calculateBody)
      .toPromise()
      .then((result: CommissionResponse) => this.dialogRef.close(result))
      .catch((err) => this.dialogRef.close({ state: 'exception', result: err }));
  }

  closePreventDialog() {
    this.dialogRef.close({ state: 'close-prevent', result: '' });
  }
}
