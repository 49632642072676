import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationOrder, NotificationOrderDetail } from '../../../models/notification-order';

@Component({
  selector: 'app-detail-reading-notification',
  templateUrl: './detail-reading-notification.component.html',
  styleUrls: ['./detail-reading-notification.component.less']
})
export class DetailReadingNotificationComponent {
  readingList: NotificationOrderDetail[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotificationOrder,
    public dialogRef: MatDialogRef<DetailReadingNotificationComponent>
  ) {
    this.readingList = this.data.notificationsOrderDetail.map(item => {
      const jsonText = JSON.parse(item.notificationText);
      item.notificationJson = {
        from: jsonText.email,
        text: jsonText.notify
      };
      return item;
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
