<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Global Settings</h1>
  <hr />

  <h3 class="mat-h3">Group Names</h3>
  <h3 *ngIf="groupNames.length === 0">No global settings to show.</h3>

  <mat-nav-list *ngIf="groupNames.length > 0">
    <mat-list-item *ngFor="let groupName of groupNames">
      <a routerLink="group-by/{{ groupName }}">
        {{ groupName }}
        <mat-icon>chevron_right</mat-icon>
      </a>
    </mat-list-item>
  </mat-nav-list>
</mat-card>
<br /><br />

<div class="header-actions">
  <a routerLink="/settings/create-strategic-expedited/create" mat-flat-button color="accent">
    Create new Strategic Expedited Setting
  </a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Strategic Expedited</h1>
  <hr />

  <h3 class="mat-h3">Strategic Expedited Settings</h3>
  <h3 *ngIf="standardItemShipInSetup.length === 0">
    No shipping item setups to show.
  </h3>

  <table *ngIf="strategicExpedited.length >0"  mat-table [dataSource]="strategicExpedited"
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="strategicExpeditedSettingsDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: strategicExpeditedSettingsDisplayedColumns"></tr>

    
    <ng-container matColumnDef="transitDays">
      <th mat-header-cell *matHeaderCellDef>Transit Days</th>
      <td mat-cell *matCellDef="let element">{{ element.transitDays }}</td>
    </ng-container>

    
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>States</th>
      <td mat-cell *matCellDef="let element">{{ element.state }}</td>
    </ng-container>
    
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let element">{{ element.isActive | active }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/settings/delete-strategic-expedited/delete/{{ element.id }}" mat-icon-button
          matTooltip="Delete Setting" matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
        <a routerLink="/settings/edit-strategic-expedited/edit/{{ element.id }}" mat-icon-button matTooltip="Edit Setting"
        matTooltipPosition="above">
        <mat-icon color="accent">edit</mat-icon>
      </a>
      </td>
    </ng-container>

  </table>

</mat-card>
<br /><br />

<div class="header-actions">
  <a routerLink="/settings/standard-item-shipping/create" mat-flat-button color="accent">
    Create new Shipping Item Setup
  </a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Shipping Item Setups</h1>
  <hr />

  <h3 class="mat-h3">Package Planning Shipping Item Setups</h3>
  <h3 *ngIf="standardItemShipInSetup.length === 0">
    No shipping item setups to show.
  </h3>

  <table *ngIf="standardItemShipInSetup.length > 0" mat-table [dataSource]="standardItemShipInSetup"
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="standardItemShipInSetupDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: standardItemShipInSetupDisplayedColumns"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="itemNumber">
      <th mat-header-cell *matHeaderCellDef>Item Number</th>
      <td mat-cell *matCellDef="let element">{{ element.itemNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="cartonTypeId">
      <th mat-header-cell *matHeaderCellDef>Carton Type</th>
      <td mat-cell *matCellDef="let element">{{ element.cartonTypeDescription }}</td>
    </ng-container>

    <ng-container matColumnDef="categoryTypeId">
      <th mat-header-cell *matHeaderCellDef>Category Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.categoryTypeDescription }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef>Date Created</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateCreated | date : "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created By</th>
      <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/settings/standard-item-shipping/delete/{{ element.id }}" mat-icon-button
          matTooltip="Delete Setting" matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>
<br /><br />

<div class="header-actions">
  <a routerLink="/settings/expedited-production/create" mat-flat-button color="accent">
    Create new Expedited Production Setting
  </a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Expedited Production Settings</h1>
  <hr />

  <h3 *ngIf="expeditedProductionSettings.length === 0">
    No expedited settings to show.
  </h3>

  <table *ngIf="expeditedProductionSettings.length > 0" mat-table [dataSource]="expeditedProductionSettings"
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="expeditedDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: expeditedDisplayedColumns"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="productionType">
      <th mat-header-cell *matHeaderCellDef>Production Type</th>
      <td mat-cell *matCellDef="let element">{{ element.productionType }}</td>
    </ng-container>

    <ng-container matColumnDef="feePerPart">
      <th mat-header-cell *matHeaderCellDef>Fee Per Part</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.feePerPart | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="minimumFee">
      <th mat-header-cell *matHeaderCellDef>Minimum Fee</th>
      <td mat-cell *matCellDef="let element">
        {{ element.minimumFee | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let element">{{ element.isActive  | active }}</td>
    </ng-container>

        
    <ng-container matColumnDef="dateModified">
      <th mat-header-cell *matHeaderCellDef>Date Modified</th>
      <td mat-cell *matCellDef="let element">{{ element.dateModified  | date : "medium"}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/settings/expedited-production/delete/{{ element.id }}" mat-icon-button
          matTooltip="Delete Setting" matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>
<br /><br />

<div class="header-actions">
  <a routerLink="/settings/production-molding/create" mat-flat-button color="accent">
    Create new Production Molding Setting
  </a>
</div>
<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Production Molding Settings</h1>
  <hr />

  <h3 class="mat-h3">Production Molding Settings for Production Export</h3>
  <h3 *ngIf="productionMoldingSettings.length === 0">
    No production molding settings to show.
  </h3>

  <table *ngIf="productionMoldingSettings.length > 0" mat-table [dataSource]="productionMoldingSettings"
    class="mat-elevation-z0 table">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef>Color</th>
      <td mat-cell *matCellDef="let element">{{ element.color }}</td>
    </ng-container>

    <ng-container matColumnDef="doorStyle">
      <th mat-header-cell *matHeaderCellDef>Door Style</th>
      <td mat-cell *matCellDef="let element">{{ element.doorStyle }}</td>
    </ng-container>

    <ng-container matColumnDef="moldingLength">
      <th mat-header-cell *matHeaderCellDef>Molding Length</th>
      <td mat-cell *matCellDef="let element">{{ element.moldingLength }}</td>
    </ng-container>

    <ng-container matColumnDef="friendlyMoldingLength">
      <th mat-header-cell *matHeaderCellDef>Friendly Molding Length</th>
      <td mat-cell *matCellDef="let element">
        {{ element.friendlyMoldingLength }}
      </td>
    </ng-container>

    <ng-container matColumnDef="channelGroove">
      <th mat-header-cell *matHeaderCellDef>Channel Groove</th>
      <td mat-cell *matCellDef="let element">{{ element.channelGroove }}</td>
    </ng-container>

    <ng-container matColumnDef="friendlyChannelGroove">
      <th mat-header-cell *matHeaderCellDef>Friendly Channel Groove</th>
      <td mat-cell *matCellDef="let element">
        {{ element.friendlyChannelGroove }}
      </td>
    </ng-container>

    <ng-container matColumnDef="isDefault">
      <th mat-header-cell *matHeaderCellDef>Is Default</th>
      <td mat-cell *matCellDef="let element">
        {{ element.isDefault | active }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/settings/production-molding/details/{{ element.id }}" mat-icon-button
          matTooltip="Setting Details" matTooltipPosition="above">
          <mat-icon color="accent">notes</mat-icon>
        </a>
        <a routerLink="/settings/production-molding/edit/{{ element.id }}" mat-icon-button matTooltip="Edit Setting"
          matTooltipPosition="above">
          <mat-icon color="accent">edit</mat-icon>
        </a>
        <a routerLink="/settings/production-molding/delete/{{ element.id }}" mat-icon-button matTooltip="Delete Setting"
          matTooltipPosition="above">
          <mat-icon color="accent">delete</mat-icon>
        </a>
      </td>
    </ng-container>
  </table>
</mat-card>