<h2 mat-dialog-title>Edit Train</h2>
<mat-dialog-content class="mat-dialog-content">
  <div class="group">
    <p><strong>PO #:</strong> {{train.productionOrderNumber}}</p>
    <p><strong>Train Number:</strong> {{train.trainNumber}}</p>
  </div>
  <div class="group">
    <p><strong>Production Date:</strong> {{train.productionDate | date}}</p>
    <p><strong>Files:</strong> {{train.rdbFiles.length}}</p>
  </div>
  <div class="group">
    <p><strong>Color:</strong> {{train.color}}</p>
    <p><strong>Style:</strong> {{train.doorStyle}}</p>
  </div>
  <p><strong>Carts:</strong>
    <span *ngIf="train.cartLetters">&nbsp; {{train.cartLetters}}</span>
    <span *ngIf="!train.cartLetters">&nbsp; (No cart letters to show)</span>
  </p>
  <p class="warning">
    Warning, you will not be able to undo this action. Only a production supervisor can undo this update.
  </p>
  <mat-form-field>
    <mat-label>Production Status:</mat-label>
    <mat-select [(ngModel)]="selectedProductionStatus">
      <mat-option *ngFor="let status of productionStatus" [value]="status">
        {{status}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox *ngIf="userIsAllowedToUnlockRDBOrderFiles()" [(ngModel)]="unlockRDBOrderFiles">Unlock RDB order
    files</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="disableCancelAction" mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button [disabled]="disableUpdateAction" mat-raised-button color="accent" (click)="updateTrain()">Update</button>
</mat-dialog-actions>