<div class="header-actions">
  <a routerLink="create" mat-flat-button color="accent">Create new Promo Code</a>
  <a mat-flat-button color="accent" (click)="downloadCSV()">Download CSV</a>
</div>
<h1 class="mat-h1">
  Promo Codes
</h1>
<hr />

<mat-accordion>
  <mat-expansion-panel expanded="true" class="filters-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filters
      </mat-panel-title>
      <mat-panel-description>
        Filter Promo Codes
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="filters">
      <div class="filters-container">
        <div>
          <mat-form-field>
            <input matInput placeholder="Code" [formControl]="codeFilter">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [formControl]="typeFilter">
              <mat-option *ngFor="let item of promoCodeTypes" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="startDateFilter">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="endDateFilter">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Created By" [formControl]="createdByFilter">
          </mat-form-field>
        </div>
        <div class="checkboxes">
          <mat-checkbox [(ngModel)]="isActiveFilter">Active</mat-checkbox>
        </div>
      </div>

      <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<br />

<div class="reset-action-container" *ngIf="filtersApplied">
  <button mat-raised-button (click)="clearFilters()">
    <mat-icon>clear</mat-icon> Reset Promo Codes Filters
  </button>
</div>

<div class="loading" *ngIf="loading">
  <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
</div>

<h3 *ngIf="!loading && getData().length === 0"> No promo codes to show.</h3>

<table *ngIf="!loading && getData().length > 0" mat-table #promoCodesSort="matSort" matSort
  [dataSource]="getPromoCodesDataSource()" class="mat-elevation-z0 table">
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
    <td mat-cell *matCellDef="let element">
      {{element.code}}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
    <td mat-cell *matCellDef="let element"> {{formatCodeType(element.type)}} </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">
      <span class="amount">{{formatAmount(element.type, element.amount)}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
    <td mat-cell *matCellDef="let element"> {{element.startDate | date}} </td>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date</th>
    <td mat-cell *matCellDef="let element"> {{element.endDate | date}} </td>
  </ng-container>

  <ng-container matColumnDef="numberOfAllowedUses">
    <th mat-header-cell *matHeaderCellDef>Number of Allowed Uses</th>
    <td mat-cell *matCellDef="let element"> {{element.numberOfAllowedUses}} </td>
  </ng-container>

  <ng-container matColumnDef="numberOfUsesPerCustomer">
    <th mat-header-cell *matHeaderCellDef>Number of Uses Per Customer</th>
    <td mat-cell *matCellDef="let element"> {{element.numberOfUsesPerCustomer}} </td>
  </ng-container>

  <ng-container matColumnDef="criteria">
    <th mat-header-cell *matHeaderCellDef>Criteria</th>
    <td mat-cell *matCellDef="let element"> {{element.criteria?.length}} </td>
  </ng-container>

  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef> Active</th>
    <td mat-cell *matCellDef="let element"> {{element.isActive | active}} </td>
  </ng-container>

  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created</th>
    <td mat-cell *matCellDef="let element"> {{element.dateCreated | date}} </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By</th>
    <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions</th>
    <td mat-cell *matCellDef="let element">
      <a mat-icon-button matTooltip="Copy Promo Code" (click)="copyToClipboard(element.code)"
        matTooltipPosition="above">
        <mat-icon color="accent">content_copy</mat-icon>
      </a>
      <a routerLink="details/{{element.id}}" mat-icon-button matTooltip="Promo Code Details" matTooltipPosition="above">
        <mat-icon color="accent">notes</mat-icon>
      </a>
      <a routerLink="edit/{{element.id}}" mat-icon-button matTooltip="Edit Promo Code" matTooltipPosition="above">
        <mat-icon color="accent">edit</mat-icon>
      </a>
      <a routerLink="delete/{{element.id}}" mat-icon-button matTooltip="Delete Promo Code" matTooltipPosition="above">
        <mat-icon color="accent">delete</mat-icon>
      </a>
    </td>
  </ng-container>
</table>