<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Production Location Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/locations/edit/{{productionLocation?.id}}" mat-raised-button>Edit Production Location</a>
  </div>

  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{productionLocation?.id}}</li>
      <li><strong>Name:</strong> {{productionLocation?.name}}</li>
      <li><strong>Address:</strong> {{productionLocation?.address}}</li>
      <li><strong>Address2:</strong> {{productionLocation?.address2}}</li>
      <li><strong>City:</strong> {{productionLocation?.city}}</li>
      <li><strong>State:</strong> {{productionLocation?.state}}</li>
      <li><strong>ZipCode:</strong> {{productionLocation?.zipCode}}</li>
      <li><strong>Latitude:</strong> {{productionLocation?.lat}}</li>
      <li><strong>Longitude:</strong> {{productionLocation?.long}}</li>
      <li><strong>Wip Hours:</strong> {{productionLocation?.wipHours}}</li>
      <li><strong>Wip Slab Percentage:</strong> {{productionLocation?.wipSlabPercentage || 0}}%</li>
      <li><strong>Is Active:</strong> {{productionLocation?.isActive | active}}</li>
      <li><strong>Is Default:</strong> {{productionLocation?.isDefault | active}}</li>
      <li><strong>Allow Retail:</strong> {{productionLocation?.allowRetail | active}}</li>
      <li><strong>Allow Fusion:</strong> {{productionLocation?.allowFusion | active}}</li>
      <li><strong>Allow Multi Color:</strong> {{productionLocation?.allowMultiColorUnit | active}}</li>
      <li><strong>Allow Parts Order:</strong> {{productionLocation?.allowPartsOrder | active}}</li>
      <li><strong>Allow Palletized:</strong> {{productionLocation?.allowPalletized | active}}</li>
      <li><strong>Allow Accessories Only:</strong> {{productionLocation?.allowAccessoriesOnly | active}}</li>
      <li><strong>Allow CPU:</strong> {{productionLocation?.allowCPU | active}}</li>
      <li><strong>Allow FedEx:</strong> {{productionLocation?.allowFedEx | active}}</li>
      <li><strong>Allow Drop Off:</strong> {{productionLocation?.allowDropOff | active}}</li>
      <li><strong>Allow Overnight:</strong> {{productionLocation?.allowOvernight | active}}</li>
      <li><strong>Created By:</strong> {{productionLocation?.createdBy}}</li>
      <li><strong>Date Created:</strong> {{productionLocation?.dateCreated | date}}</li>
      <li><strong>Modified By:</strong> {{productionLocation?.modifiedBy}}</li>
      <li><strong>Date Modified:</strong> {{productionLocation?.dateModified | date}}</li>
    </ul>
  </div>
</mat-card>