<h2 mat-dialog-title>Apartment Details</h2>
<mat-dialog-content class="mat-dialog-content">
  <div class="header">
    <h4>Apartment Number: Ap100 <button mat-button><i class="material-icons">print</i> Print Packing List</button></h4>
    <div>
      <ul>
        <li><strong>Customer:</strong> Customer A </li>
        <li><strong>Production Order Number:</strong> </li>
        <li><strong>Ship To:</strong> Address to ship </li>
      </ul>
      <ul>
        <li><strong>Customer Number:</strong> C-00000 </li>
        <li><strong>Production Date:</strong> 8/14/2019</li>
        <li><strong>Total Unit Weight:</strong> 22.6 lb</li>
        <li><strong>Sales Order Number:</strong> 1243</li>
      </ul>
      <ul>
        <li><strong>Project:</strong>Project 1</li>
        <li><strong>Order Date:</strong> 4/8/2019</li>
        <li><strong>Order Number:</strong> SO'2343</li>
        <li><strong>Order By:</strong>jhondoe</li>
      </ul>
    </div>
  </div>

  <table>
    <thead>
      <tr>
        <th>Carton Number</th>
        <th>Carton Id</th>
        <th>Carton Type</th>
        <th>Pack Seq</th>
        <th>Lay</th>
        <th>Slv</th>
        <th>Ord Line</th>
        <th>Item</th>
        <th>Location</th>
        <th>Color</th>
        <th>Style</th>
        <th>HW</th>
        <th>Assy Seq</th>
        <th>W</th>
        <th>H</th>
        <th>LB</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
        <td>
         1
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
