import { Component } from '@angular/core';

@Component({
  selector: 'app-overnight-delivery',
  templateUrl: './overnight-delivery.component.html',
  styleUrls: ['./overnight-delivery.component.less']
})
export class OvernightDeliveryComponent {
  showResults: boolean = false;

  constructor() {
  }

  showContent(value: boolean) {
    this.showResults = value;
  }
}
