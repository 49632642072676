import { Injectable } from '@angular/core';
import { PalletizationQuote } from '../models/palletization-quote';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PalletizationService {

  public invoicingEmail: string;
  public price: number;

  constructor(private config: ConfigService, private http: HttpClient) { }

  createPalletizationQuote(model: PalletizationQuote) {
    return this.http.post<PalletizationQuote>(this.config.buildURL(`palletization/new-palletization-quote`), model);
  }

  updatePickupDate(pickupDate: string, locationId: number, palletFormId: number) {
    return this.http.put(this.config.buildURL(`palletization/pickup-date`), {}, { headers: {}, params: { pickupDate, locationId, palletFormId } });
  }
}
