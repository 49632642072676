<div mat-dialog-content>
  <mat-tab-group mat-align-tabs="center" backgroundColor="primary" animationDuration="0ms"
    [selectedIndex]="selectedTabIndex">
    <mat-tab color="accent" label="Custom Parts Capacities">
      <div class="actions" *ngIf="allFormFieldsAreAvailable() && productionCalendarEditMode">
        <button mat-mini-fab (click)="deleteProductionCalendar()" [disabled]="disableActions">
          <mat-icon>remove</mat-icon>
        </button>
      </div>
      <div class="container" [ngClass]="{'mt-46': allFormFieldsAreAvailable() && productionCalendarEditMode}">
        <div class="loading" *ngIf="loading">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <ul class="errors">
          <li *ngFor="let item of errorMessages">
            {{item}}
          </li>
        </ul>
        <form *ngIf="productionCalendarForm" [formGroup]="productionCalendarForm">
          <mat-form-field class="full-width">
            <input matInput placeholder="Production Date" formControlName="productionDate">
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Custom Parts Capacity" type="number" formControlName="customPartsCapacity"
              required>
            <mat-error *ngIf="productionCalendarForm.controls.customPartsCapacity.invalid">
              {{getCustomPartsCapacityErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Allowed Custom Parts Capacity" type="number"
              formControlName="allowedCustomPartsCapacity">
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Planned Custom Parts Capacity" type="number"
              formControlName="plannedCustomPartsCapacity">
            <mat-error *ngIf="productionCalendarForm.controls.plannedCustomPartsCapacity.invalid">
              {{getPlannedCustomPartsCapacityErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Planned Number Of Employees" type="number"
              formControlName="plannedNumberOfEmployees">
            <mat-error *ngIf="productionCalendarForm.controls.plannedNumberOfEmployees.invalid">
              {{getPlannedNumberOfEmployeesErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Actual Custom Parts Produced" type="number"
              formControlName="actualCustomPartsProduced">
            <mat-error *ngIf="productionCalendarForm.controls.actualCustomPartsProduced.invalid">
              {{getActualCustomPartsProducedErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Actual Number Of Employees" type="number"
              formControlName="actualNumberOfEmployees">
            <mat-error *ngIf="productionCalendarForm.controls.actualNumberOfEmployees.invalid">
              {{getActualNumberOfEmployeesErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Hours Worked" formControlName="hoursWorked">
            <mat-error *ngIf="productionCalendarForm.controls.hoursWorked.invalid">
              {{getHoursWorkedErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Planned Hours" formControlName="plannedHours">
            <mat-error *ngIf="productionCalendarForm.controls.plannedHours.invalid">
              {{getPlannedHoursErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Pre Production Notes" rows="6"
              formControlName="preProductionNotes"></textarea>
          </mat-form-field>
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Post Production Notes" rows="6"
              formControlName="postProductionNotes"></textarea>
          </mat-form-field>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Sheet Overrides">
      <div class="actions">
        <button *ngIf="allFormFieldsAreAvailable()" mat-mini-fab [disabled]="disableActions"
          (click)="addProductionCalendarColorCapacityFormItem()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="container" [ngClass]="{'mt-46': allFormFieldsAreAvailable()}">
        <div class="loading" *ngIf="loading">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <ul class="errors">
          <li *ngFor="let item of errorMessages">
            {{item}}
          </li>
        </ul>

        <div *ngIf="productionCalendarColorCapacityForm" [formGroup]="productionCalendarColorCapacityForm">
          <h4 *ngIf="productionCalendarColorCapacityForm.get('formGroups').controls.length === 0">No sheet overrides to
            show. You can add more sheet overrides using the add button.</h4>
          <div formArrayName="formGroups" class="formGroup"
            *ngFor="let formGroup of productionCalendarColorCapacityForm.get('formGroups').controls; let i = index;">
            <div [formGroupName]="i">
              <input type="hidden" formControlName="id">
              <mat-form-field class="full-width">
                <mat-select placeholder="Color" formControlName="color">
                  <mat-option *ngFor="let color of colors" [value]="color.name">
                    {{color.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Allowed Custom Part Capacity" type="number"
                  formControlName="allowedCapacity">
                <mat-error *ngIf="formGroup.get('allowedCapacity').invalid">
                  {{getAllowedCapacityErrorMessage(formGroup.get('allowedCapacity'))}}
                </mat-error>
              </mat-form-field>
            </div>
            <button *ngIf="allFormFieldsAreAvailable() && (i!==0 || productionCalendarEditMode)" mat-button
              color="accent" [disabled]="disableActions" (click)="removeColorCapacityForm(i)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Molding Overrides">
      <div class="actions">
        <button *ngIf="allFormFieldsAreAvailable()" mat-mini-fab [disabled]="disableActions"
          (click)="addProductionCalendarColorDoorStyleCapacityFormItem()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="container" [ngClass]="{'mt-46': allFormFieldsAreAvailable()}">
        <div class="loading" *ngIf="loading">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <ul class="errors">
          <li *ngFor="let item of errorMessages">
            {{item}}
          </li>
        </ul>

        <div *ngIf="productionCalendarColorDoorStyleCapacityForm"
          [formGroup]="productionCalendarColorDoorStyleCapacityForm">
          <h4 *ngIf="productionCalendarColorDoorStyleCapacityForm.get('formGroups').controls.length === 0">No molding
            overrides to show. You can add more molding overrides using the add button.</h4>
          <div formArrayName="formGroups" class="formGroup"
            *ngFor="let formGroup of productionCalendarColorDoorStyleCapacityForm.get('formGroups').controls; let i = index;">
            <div [formGroupName]="i">
              <input type="hidden" formControlName="id">
              <mat-form-field class="full-width">
                <mat-select placeholder="Color" formControlName="color">
                  <mat-option *ngFor="let color of colors" [value]="color.name">
                    {{color.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-select placeholder="Door Style" formControlName="doorStyle">
                  <mat-option *ngFor="let doorStyle of doorStyles" [value]="doorStyle.name">
                    {{doorStyle.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Allowed Custom Part Capacity" type="number"
                  formControlName="allowedCapacity">
                <mat-error *ngIf="formGroup.get('allowedCapacity').invalid">
                  {{getAllowedCapacityErrorMessage(formGroup.get('allowedCapacity'))}}
                </mat-error>
              </mat-form-field>
            </div>
            <button *ngIf="allFormFieldsAreAvailable() && (i!==0 || productionCalendarEditMode)" mat-button
              color="accent" [disabled]="disableActions" (click)="removeColorDoorStyleCapacityForm(i)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Shortcuts" *ngIf="productionCalendarEditMode && productionCalendarShortcutsForm">
      <div class="container">
        <div class="loading" *ngIf="loading">
          <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
        </div>
        <ul class="errors">
          <li *ngFor="let item of errorMessages">
            {{item}}
          </li>
        </ul>
        <form class="shortcuts" [formGroup]="productionCalendarShortcutsForm">
          <mat-form-field class="full-width">
            <input matInput placeholder="Number of business days" type="number" formControlName="numberOfBusinessDays">
            <mat-error *ngIf="productionCalendarShortcutsForm.controls.numberOfBusinessDays.invalid">
              {{getNumberOfBusinessDaysErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-checkbox formControlName="clone">Copy / Clone the capacity settings to the next business days.
          </mat-checkbox>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button *ngIf="!productionCalendarEditMode" mat-raised-button color="accent" [disabled]="disableActions"
    (click)="submit()">Create</button>
  <button *ngIf="productionCalendarEditMode" mat-raised-button color="accent" [disabled]="disableActions"
    (click)="update()">Update</button>
</div>