<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Edit Global Setting
  </h1>
  <hr />

  <p><strong>Global Setting:</strong> {{globalSetting?.friendlyName}}</p>
  <div class="container">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>
    <form [formGroup]="editSettingForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Name" formControlName="name" required>
        <mat-error *ngIf="editSettingForm.controls.name.invalid">{{getNameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Friendly Name" formControlName="friendlyName" required>
        <mat-error *ngIf="editSettingForm.controls.friendlyName.invalid">{{getFriendlyNameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Value" formControlName="value" required>
        <mat-error *ngIf="editSettingForm.controls.value.invalid">{{getValueErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Group Name" formControlName="groupName" required>
        <mat-error *ngIf="editSettingForm.controls.groupName.invalid">{{getGroupNameErrorMessage()}}</mat-error>
      </mat-form-field>
      <button mat-raised-button color="accent">Update</button>
    </form>
  </div>
</mat-card>
