<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Global Setting Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/settings/edit/{{globalSetting?.id}}" mat-raised-button>Edit Setting</a>
  </div>

  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{globalSetting?.id}}</li>
      <li><strong>Name:</strong> {{globalSetting?.name}}</li>
      <li><strong>Friendly Name:</strong> {{globalSetting?.friendlyName}}</li>
      <li><strong>Value:</strong> {{globalSetting?.value}}</li>
      <li><strong>Group Name:</strong> {{globalSetting?.groupName}}</li>
      <li><strong>Created By:</strong> {{globalSetting?.createdBy}}</li>
      <li><strong>Date Created:</strong> {{globalSetting?.dateCreated | date}}</li>
      <li><strong>Modified By:</strong> {{globalSetting?.modifiedBy}}</li>
      <li><strong>Date Modified:</strong> {{globalSetting?.dateModified | date}}</li>
    </ul>
  </div>
</mat-card>

