import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { manifest } from '../../routes/routes-manifest';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.less']
})
export class SidenavComponent implements OnInit {

  navLinks = [];
  appVersion = '0.0.0';
  level = 1;

  constructor(
    private auth: AuthService,
    private config: ConfigService) { }

  ngOnInit() {
    this.navLinks = this.getNavLinks();
    this.appVersion = this.config.getAppVersion();
  }

  getNavLinks(): any[] {
    return Object.entries(manifest)
      .filter(([_, v]) => this.auth.isUserInRoles(v.roles) && v.active && v.level === this.level)
      .map(([_, v]) => v)
      .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
  }
}
