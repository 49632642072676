import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { StrategicExpedited } from 'src/app/models/strategic-expedited';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-strategic-expedited',
  templateUrl: './edit-strategic-expedited.component.html',
  styleUrls: ['./edit-strategic-expedited.component.less']
})
export class EditStrategicExpeditedComponent implements OnInit {
  strategicExpeditedForm: FormGroup;
  strategicExpeditedSettingId: number;
  errorMessages: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {
    this.strategicExpeditedForm = this.fb.group({
      transitDays: ['', [Validators.required, Validators.min(0)]],
      state: ['', [Validators.required, this.commaSeparatedUppercaseValidator]],
      isActive: [false]
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.strategicExpeditedSettingId = +params['id']; // Convert param to number
      this.loadStrategicExpeditedSettingById(this.strategicExpeditedSettingId);
    });
  }

  loadStrategicExpeditedSettingById(id: number) {
    this.settingsService.getStrategicExpeditedById(id).subscribe(
      (data: StrategicExpedited) => {
        this.strategicExpeditedForm.patchValue(data);
      },
      err => {
        console.error('Error loading strategic expedited setting by id', err);
        this.snackBarService.showError('Error loading strategic expedited setting by id.');
      }
    );
  }

  submit() {
    if (this.strategicExpeditedForm.valid) {
      const updatedSetting: StrategicExpedited = this.strategicExpeditedForm.value;
      this.settingsService.updateStrategicExpedited(this.strategicExpeditedSettingId, updatedSetting).subscribe(
        () => {
          this.router.navigate(["/settings"]);
          this.snackBarService.showSuccess('Strategic expedited setting updated successfully.');
        },
        err => {
          console.error('Error updating strategic expedited setting', err);
          this.snackBarService.showError('Error updating strategic expedited setting.');
        }
      );
    } else {
      this.errorMessages = [];
      if (this.strategicExpeditedForm.get('state').hasError('invalidCommaSeparatedUppercase')) {
        this.errorMessages.push('States should be uppercase letters separated by commas.');
      }
    }
  }

  commaSeparatedUppercaseValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value ? control.value.trim() : '';
    if (!value) {
      return null;
    }
    const isValid = /^[a-zA-Z]{2}(,[a-zA-Z]{2})*$/.test(value);
    return isValid ? null : { invalidCommaSeparatedState: true };
  }
}
