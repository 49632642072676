<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Promo Code Details
  </h1>
  <hr />

  <div class="actions">
    <a routerLink="/promo-codes/edit/{{promoCode?.id}}" mat-raised-button>Edit Promo Code</a>
  </div>

  <div *ngIf="promoCode" class="content">
    <ul>
      <li><strong>Id:</strong> {{promoCode?.id}}</li>
      <li><strong>Code:</strong> {{promoCode?.code}}</li>
      <li><strong>Type:</strong> {{formatCodeType(promoCode?.type)}}</li>
      <li><strong>Amount:</strong> <span>{{formatAmount(promoCode?.type, promoCode?.amount)}}</span> </li>
      <li><strong>Max Discount Amount:</strong>
        <ng-container *ngIf="promoCode?.type && promoCode?.type !== noChargePartsCodeType">
          {{promoCode?.maxDiscountAmount}}
        </ng-container>
      </li>
      <li><strong>Start Date:</strong> {{promoCode?.startDate | date}}</li>
      <li><strong>End Date:</strong> {{promoCode?.endDate | date}}</li>
      <li><strong>Number of Allowed Uses:</strong> {{promoCode?.numberOfAllowedUses}}</li>
      <li><strong>Number of Uses Per Customer:</strong> {{promoCode?.numberOfUsesPerCustomer}}</li>
      <li><strong>Date Created:</strong> {{promoCode?.dateCreated | date}}</li>
      <li><strong>Created By:</strong> {{promoCode?.createdBy}}</li>
      <li><strong>Websites:</strong> {{promoCode?.websites.join(', ') }}</li>
      <li><strong>Active:</strong> {{promoCode?.isActive | active }}</li>
    </ul>
    <div class="criteria">
      <ng-container *ngIf="promoCode?.criteria.length > 0">
        <h3>Promo Code Criteria:</h3>
        <p *ngFor="let c of promoCode.criteria">
          <mat-icon color="accent">done</mat-icon> {{formatCriteriaType(c.promoCodeCriteriaType?.type)}}.
          <ng-container *ngIf="c.value">
            Value: {{c.value}}
          </ng-container>
        </p>
      </ng-container>
    </div>
  </div>
</mat-card>