<h2 mat-dialog-title>Rebate Group Assignments Details</h2>
<mat-dialog-content>
    <h3>
        <strong>Id: </strong> <span>{{customer.id}}</span>
        <strong>Customer: </strong> <span>{{customer.name}}</span>
        <strong>Customer Number: </strong> <span>{{customer.customerNumber}}</span>
        <strong>Company: </strong> <span>{{customer.company}}</span>
    </h3>

    <p *ngIf="rebateGroupsAssignments.length === 0">No rebate groups to show.</p>

    <ng-container *ngIf="rebateGroupsAssignments.length > 0">
        <table mat-table [dataSource]="rebateGroupsAssignments" class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="rebateGroupsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: rebateGroupsDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Rebate Group </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
                <th mat-header-cell *matHeaderCellDef> Percentage </th>
                <td mat-cell *matCellDef="let element"><span class="amount">{{element.rebatePercentage}}%</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="startPeriod">
                <th mat-header-cell *matHeaderCellDef> Start Period </th>
                <td mat-cell *matCellDef="let element"><span>{{element.startPeriod | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="endPeriod">
                <th mat-header-cell *matHeaderCellDef> End Period </th>
                <td mat-cell *matCellDef="let element"><span>{{element.endPeriod | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="dateModified">
                <th mat-header-cell *matHeaderCellDef> Date Modified </th>
                <td mat-cell *matCellDef="let element"><span>{{element.dateModified | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef> Active </th>
                <td mat-cell *matCellDef="let element"><span>{{element.isActive | active}}</span>
                </td>
            </ng-container>
        </table>
    </ng-container>

    <h3>Communities</h3>

    <div class="filters-container">
        <div>
            <mat-form-field>
                <input matInput placeholder="Filter Communities" [formControl]="communityFilter">
            </mat-form-field>
        </div>

        <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>

    <div class="reset-action-container">
        <button *ngIf="filtersApplied" mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Filters
        </button>
    </div>

    <p *ngIf="getCommunitiesAssignmentsData().length === 0">No communities to show.</p>

    <ng-container *ngIf="getCommunitiesAssignmentsData().length > 0">
        <table mat-table [dataSource]="getCommunitiesAssignmentsData()" class="mat-elevation-z0 table">
            <tr mat-header-row *matHeaderRowDef="communitiesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: communitiesDisplayedColumns;"></tr>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Community </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="rebateGroup">
                <th mat-header-cell *matHeaderCellDef> Rebate Group </th>
                <td mat-cell *matCellDef="let element"> {{element.rebateGroup}} </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
                <th mat-header-cell *matHeaderCellDef> Percentage </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.rebatePercentage !== null" class="amount">{{element.rebatePercentage}}%</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="startPeriod">
                <th mat-header-cell *matHeaderCellDef> Start Period </th>
                <td mat-cell *matCellDef="let element"><span>{{element.startPeriod | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="endPeriod">
                <th mat-header-cell *matHeaderCellDef> End Period </th>
                <td mat-cell *matCellDef="let element"><span>{{element.endPeriod | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="dateModified">
                <th mat-header-cell *matHeaderCellDef> Date Modified </th>
                <td mat-cell *matCellDef="let element"><span>{{element.dateModified | date}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef> Active </th>
                <td mat-cell *matCellDef="let element"><span>{{element.isActive | active}}</span>
                </td>
            </ng-container>
        </table>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>