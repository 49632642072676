<mat-dialog-content>
    <mat-card class="mat-elevation-z2">
      <button (click)="closeXWithoutSaving()" mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
        <mat-card-header >
            <h1 class="mat-h1">
                Archived Locations
             </h1>
        </mat-card-header>
        <mat-card-content>
          <form style="display: flex;" [formGroup]="formControl">
            <mat-form-field>
              <input matInput formControlName="word" placeholder="Keyword" >
            </mat-form-field>
          </form>
          <br>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource"  class="mat-elevation-z8 table" matSort #tblSort="matSort">
                    
                  <tr mat-header-row *matHeaderRowDef="archivedLocationDisplayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: archivedLocationDisplayedColumns; let i = index"></tr>

                  <ng-container matColumnDef="locationName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Name</th>
                    <td mat-cell  *matCellDef="let element">{{element.locationName}}</td>
                  </ng-container>
    
                  <ng-container matColumnDef="locationID">
                    <th mat-header-cell *matHeaderCellDef>Location ID</th>
                    <td mat-cell  *matCellDef="let element">{{element.locationID}}</td>
                  </ng-container>

                  <ng-container matColumnDef="unArchive">
                    <th mat-header-cell *matHeaderCellDef>UnArchive</th>
                      <td mat-cell *matCellDef="let row; let i = index" class="header-cell-actions">
                        <mat-checkbox (change)="addLocationToUnArchive(i, row, $event)"></mat-checkbox>
                      </td>
                  </ng-container>
                
              </table>
              </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="closeWithOutSaving()">Cancel</button>
            <button mat-raised-button color="accent" class="btn-modal margin-btn" (click)="unArchiveSelectedLocations()" *ngIf="locationsToUnArchice.length > 0">UnArchive</button>
        </mat-card-actions>
    </mat-card>
</mat-dialog-content>