export enum CriteriaType {
    OrderHasToMeetACertainDollarAmount = 1,
    OrderHasToMeetACertainNumberOfCustomItems = 2,
    OrderMustBeShippingInTheLower48 = 3,
    OrderIsForASpecificColor = 4,
    OrderIsForASpecificStyle = 5,
    OrderIsForASpecificConstruction = 6,
    ItsForNewCustomerOnly = 7,
    OrderHasAMaximumNumberOfCustomItems = 8
}
export interface PromoCodeCriteriaType {
    id: number;
    type: CriteriaType;
    description?: string;
    requiresValue?: boolean;
}
