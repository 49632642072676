<mat-card>
    <h1 class="mat-h1">
        <ng-container
            *ngIf="workCenterTypeFilter.value && workCenterTypeFilter.value.name">{{workCenterTypeFilter.value.name}}</ng-container>
        Work Center
        <div>
            <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
                Auto Refresh
            </mat-slide-toggle>
            <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter Work Centers
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <mat-select placeholder="Work Center Type" [formControl]="workCenterTypeFilter"
                                (selectionChange)="workCenterTypeChange($event)" required>
                                <mat-option *ngFor="let workCenterType of workCenterTypes" [value]="workCenterType">
                                    {{workCenterType.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="workCenterTypeFilter.invalid">
                                You must select a Work Center Type
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="Operator" [formControl]="operatorFilter" required>
                                <mat-option *ngFor="let operator of operators" [value]="operator">
                                    {{operator.firstName}} {{operator.lastName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="operatorFilter.invalid">
                                You must select a Operator
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="Work Center" [formControl]="workCenterFilter" required>
                                <mat-option *ngFor="let workCenter of workCenters" [value]="workCenter">
                                    {{workCenter.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="workCenterFilter.invalid">
                                You must select a Work Center
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br />

    <div class="reset-action-container" *ngIf="filtersApplied">
        <button mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Filters
        </button>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <br />

    <div class="container">
        <h3 *ngIf="!loading && filtersApplied && workCenterItems.length === 0"> No work center items to show.</h3>

        <ng-template *ngIf="!loading" ngFor let-item [ngForOf]="workCenterItems">
            <mat-card matRipple (click)="updateProductionStatus(item)"
                [class.started]="item.productionStatus === assemblyStartedStatus">
                <div class="header">
                    <div>
                        <small>cart + slots</small>
                        <p class="label">
                            <strong>{{item.cartLetter}}:</strong>
                            &nbsp;
                            <span>{{item.beginSlot}}</span>
                            -
                            <span>{{item.endSlot}}</span>
                        </p>
                    </div>

                    <span *ngIf="item.productionStatus === assemblyStartedStatus" class="status">
                        In Progress
                    </span>
                </div>
                <ul>
                    <li><strong>Train: {{item.trainNumber}}</strong></li>
                    <li><strong>Customer:</strong> {{item.customerName}}</li>
                    <li><strong>Project:</strong> {{item.project}}</li>
                    <li><strong>Apartment:</strong> {{item.apartmentNumber}}</li>
                    <li><strong>PO #: </strong> {{item.productionOrderNumber}}</li>
                    <li><strong>Door Style: </strong> {{item.style}}</li>
                    <li><strong>Color: </strong> {{item.color}}</li>
                    <li><strong>Total Parts:</strong> {{item.totalParts}}</li>
                </ul>

                <mat-card-actions>
                    <button
                        *ngIf="workCenterTypeFilter.value && workCenterTypeFilter.value.name === assemblyWorkCenterType"
                        class="mat-raised-button" (click)="openResetProductionStatus($event, item)">
                        Reset
                    </button>
                </mat-card-actions>
            </mat-card>
        </ng-template>
    </div>
</mat-card>