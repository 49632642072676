
<div fxLayout="row wrap" >
    <div fxFlex="33.3%" class="outLine">
        <div class="content">
            <mat-card class="mat-elevation-z4">
                <mat-card-header>
                    <mat-card-title style="color: gray; font-weight: bold;">Customer Info</mat-card-title>
                </mat-card-header>
                <mat-card-content style="padding: inherit;">
                    <app-customer-info [custInfo]="info"></app-customer-info>
                </mat-card-content>
                <div mat-dialog-actions align="left">
                    <mat-label class="matlbl">Archived Units: {{archivedUnit?.length}}
                    </mat-label> 
                        <button *ngIf="archivedUnit?.length > 0" 
                            mat-raised-button 
                            color="accent" 
                            (click)="archivedUnitDetailModal()" >Unarchive Units
                        </button>
                    <mat-label class="matlbl">Archived Locations: {{archivedLocation?.length}}
                    </mat-label>
                        <button *ngIf="archivedLocation?.length > 0" 
                            mat-raised-button 
                            color="accent" 
                            (click)="archivedLocationDetailModal()" >Unarchive Locations</button>
                  </div>
            </mat-card>
        </div>
    </div>
    <div fxFlex="33.3%" class="outLine">
        <div class="content">
            <mat-card class="mat-elevation-z4">
                <mat-card-header>
                    <mat-card-title style="color: gray; font-weight: bold;">Invoicing Info</mat-card-title>
                </mat-card-header>
                <mat-card-content style="padding: inherit;">
                    <app-invoicing-info [invInfo]="info"></app-invoicing-info>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <!-- <div fxFlex="33.3%" *ngIf="1==2">
        <div class="content">
            <mat-card class="mat-elevation-z4">
                <mat-card-header>
                    <mat-card-title style="color: gray; font-weight: bold;">Other Info</mat-card-title>
                </mat-card-header>
                <mat-card-content style="padding: inherit;">
                  
                </mat-card-content>
            </mat-card>
        </div>
    </div> -->
</div>
   