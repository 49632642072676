<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Delete Router Schedule
    </h1>
    <hr />
    <div *ngIf="routerSchedule" class="content">
        <ul>
            <li><strong>Id:</strong> {{routerSchedule?.id}}</li>
            <li><strong>Router:</strong> {{routerSchedule?.routerStr}}</li>
            <li><strong>Date:</strong> {{routerSchedule?.date | date}}</li>
            <li><strong>Time Start:</strong> {{formatTime(routerSchedule.date, routerSchedule.timeStart)}}</li>
            <li><strong>Time End:</strong> {{formatTime(routerSchedule.date, routerSchedule.timeEnd)}}</li>
            <li><strong>Date Created:</strong> {{routerSchedule?.dateCreated | date}}</li>
            <li><strong>Created By:</strong> {{routerSchedule?.createdByUserId}}</li>
            <li><strong>Date Modified:</strong> {{routerSchedule?.dateModified | date}}</li>
            <li><strong>Modified By:</strong> {{routerSchedule?.modifiedByUserId}}</li>
        </ul>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="deleteRouterSchedule()">Delete</button>
    </div>
</mat-card>