<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">
    Delete Role
  </h1>
  <hr />
  <p>Are you sure you want to delete this role?</p>
  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{role?.id}}</li>
      <li><strong>Name:</strong> {{role?.name}}</li>
      <li><strong>Description:</strong> {{role?.description}}</li>
      <li><strong>Date Created:</strong> {{role?.dateCreated | date}}</li>
    </ul>
  </div>
  <div class="actions">
      <button mat-raised-button color="accent" (click)="deleteRole()">Delete</button>
  </div>
</mat-card>
