<mat-card>
    <h1 class="mat-h1">Commission Report</h1>
    <hr />
    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel" [ngClass]="{displaced: filterFormPanelDisplaced}" (opened)="onFiltersPanelOpened()" (closed)="onFiltersPanelClosed()">
            <mat-expansion-panel-header>
                <mat-panel-title>Filters</mat-panel-title>
                <mat-panel-description>Filters commission report</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerFrom" [formControl]="reportFromDateFilter" placeholder="From" (dateChange)="onRequestedFromDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerTo" [formControl]="reportToDateFilter" placeholder="To" (dateChange)="onRequestedToDateFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field *ngIf="!salesPersonsCharge">
                            <mat-label>Select individuals</mat-label>
                            <mat-select #dllTeamSales [formControl]="reportSaleRepFilter" multiple>
                                <mat-select-trigger>
                                    {{reportSaleRepFilter.value ? reportSaleRepFilter.value[0]?.salesPersonName : ''}}
                                    <span *ngIf="reportSaleRepFilter.value?.length > 1" class="additional-selection">
                                      (+{{reportSaleRepFilter.value.length - 1}} {{reportSaleRepFilter.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [value]="{ salesPersonId: 0, salesPersonName: 'All' }" (onSelectionChange)="onAllSelectionChange($event.source)">All</mat-option>
                                <mat-option *ngFor="let item of salesPersons" [value]="item">
                                    {{ item.salesPersonName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="header-buttons-actions">
                        <button mat-flat-button color="accent" (click)="runReport()" [disabled]="loading || disableRunFunction">Run report</button>
                        <button mat-flat-button color="accent" (click)="preventCalculateCommission()" class="margin-btn" [disabled]="loading" *ngIf="!isRolOnlyView">Run recalculate</button>
                        <button mat-flat-button color="accent" *ngIf="getReportDataSource().length !== 0" (click)="getXlsxCommissionReport()" class="margin-btn" [disabled]="loading">Download XLSX</button>
                        <button mat-flat-button color="accent" *ngIf="getReportDataSource().length !== 0" (click)="getCommissionReport()" class="margin-btn" [disabled]="loading">Download ZIP</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>
    <ng-container *ngFor="let item of getReportDataSource()">
        <mat-toolbar class="base-title">
            <mat-icon>person</mat-icon>
            <span>{{ item.salesPersonName }} | {{ item.salesPersonMail }} | {{ item.salesPersonNumber }}</span>
        </mat-toolbar>
        <table *ngIf="item.customerLedgersList?.length !== 0">
            <thead>
                <tr>
                    <th *ngFor="let subColumn of displayedColumnsDetails" class="width-10">
                        {{ subColumn }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let customer of item.customerLedgersList">
                    <tr>
                        <td class="width-10" rowspan="2">
                            <b>{{ customer.customerName }} <br> {{ customer.customerNumber }}</b>
                        </td>
                        <td colspan="9" class="width-90">
                            <table>
                                <tbody>
                                    <tr *ngFor="let community of customer.communityLegdersList">
                                        <td class="width-10">{{ community.communityName }}</td>
                                        <td class="width-90">
                                            <table class="table-clean">
                                                <tbody>
                                                    <tr *ngFor="let detail of community.commissionLedgersDetails">
                                                        <td class="width-ledgers-details">{{ detail.apartmentNumber }}</td>
                                                        <td class="width-ledgers-details">{{ detail.postedSalesInvoiceNumber }}</td>
                                                        <td class="width-ledgers-details">{{ detail.invoiceDate }}</td>
                                                        <td class="width-ledgers-details money-cell">{{ detail.preTaxSaleAmount }}</td>
                                                        <td class="width-ledgers-details">{{ detail.commissionTypeName }}</td>
                                                        <td class="width-ledgers-details">{{ detail.commissionPercentage }}</td>
                                                        <td class="width-ledgers-details">{{ detail.splitPercentage }}</td>
                                                        <td class="width-ledgers-details money-cell">{{ detail.commissionAmount }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr class="total-customer">
                        <!-- <td class="white-space"></td> -->
                        <td colspan="4" class="total-customer-total">Customer Totals</td>
                        <td class="total-customer-money">{{ customer.totalCustomerSaleAmount }}</td>
                        <td colspan="4" class="total-customer-money">{{ customer.totalCustomerCommissionAmount }}</td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <td colspan="5" class="base-foot-total-leyend">
                    <b>Grand Totals</b>
                </td>
                <td class="base-foot-total-money">
                    <b>{{ item.totalRevenue }}</b>
                </td>
                <td colspan="4" class="base-foot-total-money">
                    <b>{{ item.totalCommissionAmount }}</b>
                </td>
            </tfoot>
        </table>
    </ng-container>
</div>